export const PaginationModel = {
    controls: [{
        type: "text",
        name: "nxtPageSelector",
        required: true
    },
    {
        type: "text",
        name: "prevPageSelector",
        required: true
    },
    {
        type: "text",
        name: "outputElementSelector",
        required: true
    }
    ]
}