import React, { useState } from "react";
import { SiGooglesheets } from "react-icons/si";
import { useNavigate } from "react-router-dom";
import AddPracticalXslx from "./AddPracticalXslx";
import AddQuestionXslx from "./AddQuestionXslx";
// import "./form.scss";

const ManageQuestion = (props) => {
  const [page, setPage] = useState({ selected: 1, choice: "" });
  const [renderChoice, setRenderChoice] = useState();

  const navigate = useNavigate()

  const changeChoice = (ch) => {
    switch (ch) {
      case 1:
        setPage({ selected: 2, choice: "AddPractical" });
        setRenderChoice(<AddPracticalXslx props={props} />);
        break;
      case 2:
        setPage({ selected: 2, choice: "AddQuestionXlsx" });
        setRenderChoice(<AddQuestionXslx props={props} />);
        break;
      default:
        break;
    }
  };


  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0 py-2">
            <li
              className={
                page.selected === 1
                  ? "breadcrumb-item active"
                  : "breadcrumb-item text-primary"
              }
              style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
              onClick={() => {
                setPage({ selected: 1, choice: "" });
              }}
            >
              Manage Questions
            </li>
            <li
              className={
                page.selected === 2 ? "breadcrumb-item active" : "d-none"
              }
            >
              {page.choice}
            </li>
          </ol>
        </nav>
      </div>

      <div className="container px-3">
        <div className="form px-lg-5">
          {page.selected === 1 && (
            <div className="row g-4 listcourses py-4 pt-5">
              <div className="col-lg-3">
                <div
                  className="bg-white pt-4 pb-1 rounded citem text-center color-dback"
                  onClick={() => {
                    changeChoice(2);
                  }}
                >
                  <SiGooglesheets className="fs-1 my-2" />
                  <p className="py-2">Add MCQS</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div
                  className="bg-white pt-4 pb-1 rounded citem text-center color-dback"
                  onClick={() => {
                    changeChoice(1);
                  }}
                >
                  <SiGooglesheets className="fs-1 my-2" />
                  <p className="py-2">Add Practical</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div
                  className="bg-white pt-4 pb-1 rounded citem text-center color-dback"
                  onClick={() => {
                    navigate('/admin/manage-testcase/list')
                  }}
                >
                  <SiGooglesheets className="fs-1 my-2" />
                  <p className="py-2">Add Actions</p>
                </div>
              </div>
              <div className="col-lg-3">
                <div
                  className="bg-white pt-4 pb-1 rounded citem text-center color-dback"
                  onClick={() => {
                    navigate('/admin/manage-spec/list')
                  }}
                >
                  <SiGooglesheets className="fs-1 my-2" />
                  <p className="py-2">Add Testcases</p>
                </div>
              </div>
            </div>
          )}
          {page.selected === 2 && renderChoice}
        </div>
      </div>
    </>
  );
};

export default ManageQuestion;
