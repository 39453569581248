import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import VideoService from '../../services/VideoService';
import checkIcon from '../../assets/images/check-icon.svg'


const Mp4VideoUploadStatus = () => {
    const [concurrencyCount, setConcurrencyCount] = React.useState(0);
    const [uploadHistoryArr, setUploadHistoryArr] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {

        const token = localStorage.getItem('token')
        if (!token) {
            navigate("/login");
        }

        (async () => {

            const response = await VideoService.getUploadStatus(token);
            if (response.status === 200) {
                setConcurrencyCount(response.data.noOfConcurrencyUpload);
                setUploadHistoryArr(response.data.data);
            }
        })()
    }, []);

    const renderFiles = (files) => {
        return files.map((file) => (
            <li key={file.fileName}>
                {file.fileName}
                <ul>
                    <li>{file.convertedToMp4 ? (
                        <>
                            Converted <img src={checkIcon} />
                        </>
                    ) : <>
                        Converted
                    </>}</li>

                    <li>{file.bindedInMongoDB ? (
                        <>
                            Binded with MongoDB <img src={checkIcon} />
                        </>
                    ) : <>
                        Binded with MongoDB
                    </>}</li>
                </ul>
            </li>
        ));
    };

    return (
        <div className="container">
            <h1>Video Upload Status</h1>
            <p>Number of Concurrent Uploads: <span>{concurrencyCount}</span></p>

            <table className="table table-bordered table-hover">
                <thead>
                    <tr>
                        <th>Course Name</th>
                        <th>SubCourse Name</th>
                        <th>Topic</th>
                        <th>Uploaded By</th>
                        <th>Files</th>
                        <th>Uploaded At</th>
                        <th>Completed</th>
                    </tr>
                </thead>
                <tbody>
                    {uploadHistoryArr.map((upload) => (
                        <tr key={upload.id}>
                            <td>{upload.courseName}</td>
                            <td>{upload.subCourseName}</td>
                            <td>{upload.topic}</td>
                            <td>{upload.uploadedBy}</td>
                            <td><ul>{renderFiles(upload.files)}</ul></td>
                            <td>{new Date(upload.uploadedAt).toLocaleString()}</td>
                            <td>{upload.completed ? (<>Sucessfully Uploaded <img src={checkIcon} /></>) : upload.error}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default Mp4VideoUploadStatus;
