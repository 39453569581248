export class QueWithTestCaseModel {

    que = '';
    testCase = '';
    _id = '';

    constructor(que, testCase, _id) {
        this.que = que;
        this.testCase = testCase;
        this._id = _id;
    }
}