export const SearchModel = {
    controls: [{
        type: "text",
        name: "searchInputTagSelector",
        required: true
    },
    {
        type: "text",
        name: "elementToSearchOn",
        required: true
    },
    {
        type: "text",
        name: "searchButtonSelector",
        required: false
    },
    {
        type: "text",
        name: "valueToSearch",
        required: true,
        storePath: "items"
    }
    ]
}