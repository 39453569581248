import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import { BiCommentDetail } from "react-icons/bi";
import Select from "react-select";
import BatchManagementServices from "../../services/BatchManagementServices";
import variable from "../../servicesvariable";
// import "./ResultBatchParent2.css";


ReactHTMLTableToExcel.format = (s, c) => {
  if (c && c["table"]) {
    const html = c.table;
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");
    const rows = doc.querySelectorAll("tr");
    c.table = doc.querySelector("table").outerHTML;
  }

  return s.replace(/{(\w+)}/g, (m, p) => c[p]);
};

const sortingOptions = [
  {
    value: "username-asc",
    label: "username asc",
  },
  {
    value: "username-desc",
    label: "username desc",
  },
  {
    value: "result-asc",
    label: "result asc",
  },
  {
    value: "result-desc",
    label: "result desc",
  },
  {
    value: "confirmation-asc",
    label: "confirmation asc",
  },
  {
    value: "confirmation-desc",
    label: "confirmation desc",
  },
];

export default function ResultBatchParent2(props) {
  const [parentBatches, setParentBatches] = useState([]);
  const [parentBatchesOptions, setParentBatchesOptions] = useState([]);
  const [selectedParentBatch, setSelectedParentBatch] = useState([]);
  const [batchReltedUser, setBatchRealtedUser] = useState([]);
  const [skills, setSkills] = useState([]);
  const [users, setUsers] = useState([]);
  const [batchesOptions, setBatchesOptions] = useState([]);
  const [selectedSkills, setSelectedSkills] = useState([]);
  const [nameSearch, setNameSearch] = useState([]);
  const [skill, setSkill] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState("");
  const [showParentBatch, setShowParentBatch] = useState(false);
  const [showBatch, setShowBatch] = useState(false);
  const [attendance, setAttendance] = useState([]);
  const [loading, setLoading] = useState(false);
  const [batchResults, setBatchResults] = useState([]);
  const [tableProps, setTableProps] = useState([]);
  const [selectedParentSkills, setSelectedParentSkills] = useState([]);
  const [selectedSorting, setSelectedSorting] = useState(sortingOptions[0]);
  const [avearageStatus, setAvearageStatus] = useState(false);

  useEffect(() => {
    setLoading(true);
    getAllParentBatch();
    getAttendance();
    setLoading(false);
  }, []);

  useEffect(() => { }, [selectedSorting]);

  const getAttendance = async () => {
    const token = localStorage.getItem("token");
    BatchManagementServices.getAllAttendanceMis(token)
      .then((res) => {
        setAttendance(res.data.data);
      })
      .catch((err) => {
        console.log("getting mis attendance", err);
      });
  };

  const getAllParentBatch = () => {
    BatchManagementServices.getParentBatches(localStorage.getItem("token"))
      .then((res) => {
        const parentBatchesOptions = res.data.data.map((i) => {
          return { value: i.name, label: i.name, id: i._id };
        });
        setParentBatches(res.data.data);
        setParentBatchesOptions(parentBatchesOptions);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getResultByParentBatch = (id) => {
    setLoading(true);
    const parentBatch = parentBatches.find((i) => i._id === id);
    const batchesOptions = parentBatch.trainingbatches.map((i) => {
      return { value: i.name, label: i.name, id: i._id };
    });
    setBatchesOptions(batchesOptions);
    BatchManagementServices.getResultByBatchParent(
      id,
      localStorage.getItem("token")
    )
      .then((res) => {
        let parentBatchResults = res.data.data.results;
        let parentBatchSkills = res.data.data.skills;

        setSkills(parentBatchSkills);
        setUsers(parentBatchResults);
        setSelectedParentSkills(parentBatchSkills);
        setBatchRealtedUser(findUsers(parentBatchResults));
        setBatchResults(res.data.data.batchesResult);
        getTableProps(res.data.data.batchesResult);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const findUsers = (batchUserlist) => {
    if (batchUserlist && batchUserlist.length > 0) {
      let uniqueObjArray = [
        ...new Map(
          batchUserlist.map((item) => [item["userId"]._id, item])
        ).values(),
      ];
      const prarentOption = [];
      if (uniqueObjArray && uniqueObjArray.length > 0) {
        for (let i = 0; i < uniqueObjArray.length; i++) {
          const user = uniqueObjArray[i];
          const username = user.userId.username;
          prarentOption.push({
            label: username,
            value: username,
          });
        }
      }
      return prarentOption
    }
  }

  const getResultBybatch = async (e) => {
    try {
      setLoading(true);
      const percentageTable = {
        id: "percentageTable",
        name: "percentageTable",
      };
      BatchManagementServices.getResultByBatch(
        e.id,
        localStorage.getItem("token")
      )
        .then((res) => {
          setSkills(res.data.data.skills);
          setUsers(res.data.data.results);
          setBatchRealtedUser(findUsers(res.data.data.results));
          setSelectedParentSkills(res.data.data.skills);
          setTableProps([{ id: e.value, name: e.value }, percentageTable]);
          setLoading(false);
        })
        .catch((err) => {
          console.log("error getting batch result", err);
        });
    } catch (error) {
      console.log("error getting result of batch", error);
    }
  };

  const getTableProps = (bachesResult) => {
    const percentageTable = { id: "percentageTable", name: "percentageTable" };
    if (!showParentBatch && showBatch) {
      let singleTable = [...bachesResult, percentageTable];
      setTableProps(singleTable);
    } else {
      const masterTable = bachesResult.map((i) => {
        return {
          id: i.name,
          name: i.name,
        };
      });
      masterTable.push(percentageTable);
      setTableProps(masterTable);
    }
  };

  const handleRadioStatus = (e) => {
    setAvearageStatus(parseInt(e.target.value) === 1 ? true : false)
  }

  return (
    <div className="mt-5 mx-3 pt-5 main-dev">
      <div className="d-flex justify-content-lg-between align-items-center justify-content-center flex-xl-nowrap flex-wrap gap-lg-0 gap-3 container input-option">
        <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3">
          <p>Select parent batch</p>
          {parentBatchesOptions.length > 0 && (
            <Select
              options={parentBatchesOptions}
              className="selectOption"
              onChange={(e) => {
                setSelectedParentBatch(e);
                setShowParentBatch(true);
                setShowBatch(false);
                getResultByParentBatch(e.id);
                if (selectedBatch.value) {
                  setSelectedBatch({});
                }
                if (skill?.length > 0) {
                  setSkill([]);
                }
              }}
              value={selectedParentBatch}
            />
          )}
        </div>
        <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3">
          <p>Select batch</p>
          <Select
            options={batchesOptions}
            onChange={(e) => {
              if (e !== null) {
                getResultBybatch(e);
                setShowParentBatch(false);
                setShowBatch(true);
                setSelectedBatch(e);
              } else {
                setSelectedBatch({});
                setShowParentBatch(true);
                setShowBatch(false);
                getResultByParentBatch(selectedParentBatch.id);
              }
              if (skill?.length > 0) {
                setSkill([]);
              }
            }}
            value={selectedBatch}
            isLoading={loading}
            isClearable={selectedBatch?.value ? true : false}
            isDisabled={loading && batchesOptions.length > 0}
          />
        </div>
        <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3">
          <>
            <p>Select skills</p>
            <Select
              options={
                skills?.length > 0 &&
                skills.map((i) => {
                  return { value: i.name, label: i.name, ...i };
                })
              }
              value={
                skill?.length > 0 &&
                skill.map((i) => {
                  return { value: i.name, label: i.name, ...i };
                })
              }
              isMulti
              onChange={(e) => {
                setSkill(e);
                if (e.length === 0) {
                  setSelectedParentSkills(skills);
                } else {
                  setSelectedParentSkills(e);
                }
              }}
              isClearable={true}
              isLoading={loading}
              isDisabled={loading && skills.length > 0}
            />
          </>
          {/* )} */}
        </div>
        <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3">
          <p>Select users</p>
          <Select
            isClearable
            isMulti
            options={batchReltedUser}
            onChange={(e) => {
              setNameSearch(e);
            }}
            isLoading={loading}
            isDisabled={loading && batchReltedUser.length > 0}
          />
          {/* )} */}
        </div>
        <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3 ">
          <p>Skill Result</p>
          <div onChange={(e) => handleRadioStatus(e)}>
            <input type="radio" value={1} checked={avearageStatus === true} name="average" /> Average
            <input type="radio" value={0} checked={avearageStatus === false} name="average" className="ms-2" /> Last Score
          </div>
        </div>
        <div className="mx-lg-2 col-xl-2 col-sm-5 col-12 input-box mb-xl-0 mb-3">
          <p>Sort By:</p>
          <Select
            isClearable
            options={sortingOptions}
            onChange={(e) => {
              setSelectedSorting(e);
            }}
            isLoading={loading}
            isDisabled={loading}
            value={selectedSorting}
          />
        </div>
      </div>
      <div className="position-relative mx-3 text-end mt-4 d-flex justify-content-xl-between justify-content-md-evenly justify-content-center align-items-center info flex-md-row flex-column-reverse gap-md-0 gap-3 ">
        {!loading &&
          showParentBatch &&
          !showBatch &&
          batchResults.length > 0 &&
          tableProps.length > 0 && (
            <>
              <table className="info-table ">
                <thead>
                  <tr>
                    <th>Marks Range</th>
                    <th>Grade</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="bg-gray-200 fw-semibold">90 to  100</td>
                    <td className="text-white fw-semibold bg-success">A+</td>
                  </tr>
                  <tr>
                    <td>80 to 89</td>
                    <td className="text-white fw-semibold bg-primary">A</td>
                  </tr>
                  <tr>
                    <td className="bg-gray-200 fw-semibold">60 to 79</td>
                    <td className="text-white fw-semibold bg-infos">B</td>
                  </tr>
                  <tr>
                    <td>50 to 69</td>
                    <td className="text-white fw-semibold bg-warning">C</td>
                  </tr>
                  <tr>
                    <td className="bg-gray-200 fw-semibold">0 to 49</td>
                    <td className="text-white fw-semibold bg-danger">D</td>
                  </tr>
                </tbody>
              </table>
              <p></p>
              {/* <ExportCsv csvData={[]} fileName={"myFile"} tables={tableProps}>
                Download to XLS
              </ExportCsv> */}
              <ReactHTMLTableToExcel
                // id="test-table-xls-button"
                className="download-table-xls-button btn btn-primary"
                table={tableProps[0]?.id}
                filename="test"
                sheet="tablexls"
                buttonText="Download as XLS"
              />
            </>
          )}

        {!loading && !showParentBatch && showBatch && selectedBatch.value && (
          <>
            {/* <ExportCsv csvData={[]} fileName={"myFile"} tables={tableProps}>
              Download to XLS
            </ExportCsv> */}
            <ReactHTMLTableToExcel
              // id="test-table-xls-button"
              className="download-table-xls-button btn btn-primary"
              table={tableProps[0]?.id}
              filename="test"
              sheet="tablexls"
              buttonText="Download as XLS"
            />
          </>
        )}
      </div>
      <div className="overflow-scrolling">
        {!loading &&
          showParentBatch &&
          !showBatch &&
          batchResults.length > 0 && (
            <>
              {batchResults.map((item, index) => {
                return (
                  <div key={index} className={`${index !== 0 && "d-none"}`}>
                    <Table
                      skills={index === 0 ? selectedParentSkills : item.skills}
                      users={item.results}
                      batchname={item.name}
                      attendance={attendance}
                      nameSearch={nameSearch}
                      setNameSearch={setNameSearch}
                      setBatchRealtedUser={setBatchRealtedUser}
                      id={item.name}
                      sorting={selectedSorting?.value}
                      avearageStatus={avearageStatus}
                    />
                    <PercentageTable
                      id="percentageTable"
                      name="percentageTable"
                    />
                  </div>
                );
              })}
            </>
          )}

        {!loading && !showParentBatch && showBatch && selectedBatch.value && (
          <>
            <Table
              skills={selectedParentSkills}
              users={users}
              batchname={selectedBatch.name}
              attendance={attendance}
              nameSearch={nameSearch}
              setNameSearch={setNameSearch}
              setBatchRealtedUser={setBatchRealtedUser}
              id={selectedBatch.value}
              sorting={selectedSorting?.value}
              avearageStatus={avearageStatus}
            />
            <PercentageTable id="percentageTable" name="percentageTable" />
          </>
        )}
      </div>
    </div>
  );
}

const Table = ({
  skills,
  users,
  batchname,
  attendance,
  nameSearch,
  id,
  sorting,
  avearageStatus
}) => {
  const [openResultModal, setOpenResultModal] = useState(false);
  const [getExamData, setExamData] = useState({});
  let uniqueObjArray = [
    ...new Map(users.map((item) => [item["userId"]._id, item])).values(),
  ];
  // batchReltedUser
  const getSkillOfUser = (userId, skillId) => {
    const result = users.find((u) => {
      if (!u.subCourceId) {
        return null;
      }
      return (
        u.userId && u.userId._id === userId && u.subCourceId._id === skillId
      );
    });
    return result;
  };
  const getGrade = (average) => {
    if (average >= 90) {
      return (
        <td
          className="bg-success text-white"
          style={{ backgroundColor: "#198754", color: "#ffffff" }}
        >
          A+
        </td>
      );
    } else if (average >= 80) {
      return (
        <td
          className="bg-primary text-white"
          style={{ backgroundColor: "#0D6EFD", color: "#ffffff" }}
        >
          A
        </td>
      );
    } else if (average >= 65) {
      return (
        <td
          className="bg-infos text-white"
          style={{ backgroundColor: "#0DCAF0", color: "#ffffff" }}
        >
          B
        </td>
      );
    } else if (average >= 50) {
      return (
        <td
          className="bg-warning text-white"
          style={{ backgroundColor: "#FFC107", color: "#ffffff" }}
        >
          C
        </td>
      );
    } else {
      return (
        <td
          className="bg-danger text-white"
          style={{ backgroundColor: "#DC3545", color: "#ffffff" }}
        >
          D
        </td>
      );
    }
  };

  const allUserResults = [];

  let confirmationAverageDetail = variable.confirmationAverageDetail;
  let specialSkills = confirmationAverageDetail.specialSkills.map(
    (i) => i.skill
  );

  let filteredSkills = [...skills];
  let specialSkillsColumn = [];
  for (let i = 0; i < confirmationAverageDetail.specialSkills.length; i++) {
    const specialSkill = confirmationAverageDetail.specialSkills[i];
    for (let j = 0; j < filteredSkills.length; j++) {
      const skill = filteredSkills[j];
      if (specialSkill.skill === skill.name) {
        filteredSkills.splice(j, 1);
        specialSkillsColumn.push(specialSkill);
      }
    }
  }

  for (let i = 0; i < uniqueObjArray.length; i++) {
    const user = uniqueObjArray[i];
    const deactivate = user?.userId?.deactivate;
    const userId = user.userId._id;
    const username = user.userId.username;

    const hostId = user.userId.hostId;

    let batchName = "";
    if (user.batchId) {
      const batch = user.batchId;
      if (batch.name) {
        batchName = batch.name;
      }
    }
    const empId = user.userId.empId;
    let userAttendance = "";
    let findAttendance = null;
    if (attendance.length > 0) {
      findAttendance = attendance.find((u) => u.EmpCode === empId);
      if (findAttendance) {
        userAttendance = `${findAttendance.PresentDays} / ${findAttendance.TotalWorkingDays}`;
      }
    }

    let confirmationAverage = 0;
    let totalRegularSkills = 0;
    let sumRegularSkills = 0;
    let averageRegularSkills = 0;

    let average = 0;
    if (skills.length === 0) {
      const userResults = users.filter(
        (i) => i.userId._id.toString() === user.userId._id.toString()
      );
      let countAverage = 0;
      const sumAverage = userResults.map(
        (i) => (countAverage += !avearageStatus ? i.average || 0 : i.totalAverage || 0)
      );
      const totalAverage =
        sumAverage[sumAverage.length - 1] / sumAverage.length;
      average = totalAverage;
    } else {
      const temp = [];
      for (let i = 0; i < users.length; i++) {
        const tempUserResult = users[i];
        if (tempUserResult.subCourceId) {
          for (let j = 0; j < skills.length; j++) {
            const tempSkill = skills[j];
            if (
              tempSkill._id === tempUserResult.subCourceId._id &&
              tempUserResult.userId._id === user.userId._id
            ) {
              temp.push(tempUserResult);
            }
          }
        }
      }
      let countAverage = 0;
      const sumAverage = temp.map((i) => (countAverage += !avearageStatus ? i.average || 0 : i.totalAverage || 0));
      // calculate regular skills average
      for (let i = 0; i < temp.length; i++) {
        const result = temp[i];
        if (!specialSkills.includes(result.subCourceId.name)) {
          totalRegularSkills += 1;
          sumRegularSkills += !avearageStatus ? result?.average || 0 : result?.totalAverage || 0
        }
      }

      averageRegularSkills = sumRegularSkills && totalRegularSkills && parseFloat(sumRegularSkills / totalRegularSkills);

      // calculate special skills average
      let specialSkillAverage = 0;
      for (let j = 0; j < confirmationAverageDetail.specialSkills.length; j++) {
        const specialSkill = confirmationAverageDetail.specialSkills[j];
        const findSpecialSkillResult = temp.find(
          (i) => i.subCourceId.name === specialSkill.skill
        );
        if (findSpecialSkillResult) {
          const speacialAvearge = !avearageStatus ? findSpecialSkillResult.average || 0 : findSpecialSkillResult.totalAverage || 0
          specialSkill.average = parseFloat(
            (speacialAvearge * specialSkill.proportion) / 100
          );
          specialSkillAverage += specialSkill.average;
        } else {
          specialSkill.average = 0;
          specialSkillAverage += 0;
        }
      }

      confirmationAverage =
        specialSkillAverage +
        parseFloat(
          (averageRegularSkills * confirmationAverageDetail.rest) / 100
        ) +
        parseFloat(
          temp.length > 0
            ? parseFloat((temp[0].userId?.averageAcademicMarks * 5) / 100)
            : 0
        );

      const totalAverage =
        sumAverage[sumAverage.length - 1] / sumAverage.length;
      average = totalAverage;
    }

    const results = [];
    for (let j = 0; j < skills.length; j++) {
      const skill = skills[j];
      const resultFound = getSkillOfUser(user.userId._id, skill._id);
      let result = false;
      let data = {};
      if (resultFound && resultFound._id) {
        result = true;
        let im = {};
        let em = {};
        let ip = {};
        let ep = {};
        let practicalVariation = false;
        if (resultFound.internalExam.mcq.length > 0) {
          im = {
            data: resultFound.internalExam.mcq,
            hostId: user.userId.hostId,
            skill: skill.name,
            batch: batchname,
            marks:
              resultFound.internalExam.mcq[
                resultFound.internalExam.mcq.length - 1
              ].marks,
          };
        }
        if (resultFound.externalExam.mcq.length > 0) {
          em = {
            data: resultFound.externalExam.mcq,
            hostId: user.userId.hostId,
            skill: skill.name,
            batch: batchname,
            marks:
              resultFound.externalExam.mcq[
                resultFound.externalExam.mcq.length - 1
              ].marks,
          };
        }
        if (resultFound.internalExam.practical.length > 0) {
          ip = {
            data: resultFound.internalExam.practical,
            hostId: user.userId.hostId,
            skill: skill.name,
            batch: batchname,
            marks:
              resultFound.internalExam.practical[
                resultFound.internalExam.practical.length - 1
              ].marks,
            deadline: resultFound.internalExam.practical[
              resultFound.internalExam.practical.length - 1
            ]?.deadline,
            analytics: resultFound.internalExam.practical[
              resultFound.internalExam.practical.length - 1
            ]?.analytics,
            database: resultFound.internalExam.practical[
              resultFound.internalExam.practical.length - 1
            ]?.database,
            ui: resultFound.internalExam.practical[
              resultFound.internalExam.practical.length - 1
            ]?.ui,
            serverside: resultFound.internalExam.practical[
              resultFound.internalExam.practical.length - 1
            ]?.serverside,
          };
        }
        if (resultFound.externalExam.practical.length > 0) {
          ep = {
            data: resultFound.externalExam.practical,
            hostId: user.userId.hostId,
            skill: skill.name,
            batch: batchname,
            marks:
              resultFound.externalExam.practical[
                resultFound.externalExam.practical.length - 1
              ].marks,
            deadline: resultFound.externalExam.practical[
              resultFound.externalExam.practical.length - 1
            ]?.deadline,
            analytics: resultFound.externalExam.practical[
              resultFound.externalExam.practical.length - 1
            ]?.analytics,
            database: resultFound.externalExam.practical[
              resultFound.externalExam.practical.length - 1
            ]?.database,
            ui: resultFound.externalExam.practical[
              resultFound.externalExam.practical.length - 1
            ]?.ui,
            serverside: resultFound.externalExam.practical[
              resultFound.externalExam.practical.length - 1
            ]?.serverside,
          };
        }
        let checkSpecialSkill = resultFound?.deadline;
        let specialData = {};
        if (checkSpecialSkill) {
          specialData = {
            deadline: resultFound?.deadline,
            analytics: resultFound?.analytics,
            database: resultFound?.database,
            ui: resultFound?.ui,
            serverside: resultFound?.serverside,
          };
        }
        if (ip?.marks && ep?.marks) {
          if (Math.abs(ip.marks - ep.marks) > 50) {
            practicalVariation = true;
          }
        }
        data = {
          im: { ...im },
          em: { ...em },
          ip: { ...ip },
          ep: { ...ep },
          average: resultFound?.average,
          totalAverage: resultFound?.totalAverage,
          checkSpecialSkill: checkSpecialSkill,
          specialData: specialData,
          practicalVariation,
        };
      }
      results.push({ result: result, ...data, skill: skill });
    }
    const findBatchSkillWeighatge = (skillId) => {
      const batchSkills = user.batchId.skills;
      const findSkill = batchSkills.find((item) => item.skillId === skillId);
      return findSkill?.weightage ? findSkill.weightage : 0;
    };
    let totalWeighatage = 0;
    let weighatgeAverage = 0;

    for (let i = 0; i < results.length; i++) {
      if (results[i]?.result) {
        const skillIds = results[i].skill._id;
        const weightage = findBatchSkillWeighatge(skillIds);
        totalWeighatage += weightage;
        const finalAvearge = avearageStatus ? results[i].totalAverage || 0 : results[i].average || 0
        const Average = finalAvearge * (weightage / 100);
        weighatgeAverage += Average;
      }
    }
    allUserResults.push({
      userId,
      username,
      batchName,
      empId,
      average: average ? parseFloat(average.toFixed(2)) : 0,
      skillAverage: weighatgeAverage,
      skillFlag: totalWeighatage === 100 ? true : false,
      results,
      attendance: userAttendance,
      attendanceData: findAttendance,
      hostId,
      confirmationAverage: confirmationAverage
        ? parseFloat(confirmationAverage.toFixed(2))
        : 0,
      tenthMarks: user.userId?.tenthMarks,
      twelfthMarks: user.userId?.twelfthMarks,
      averageAcademicMarks: user.userId?.averageAcademicMarks,
      deactivate,
    });
  }
  allUserResults.sort((a, b) => {
    if (sorting) {
      let sortingby = sorting.split("-");
      let sortby = sortingby[0];
      let order = sortingby[1];
      if (sortby === "username") {
        if (order === "asc") {
          return a.username.localeCompare(b.username);
        } else {
          return b.username.localeCompare(a.username);
        }
      } else if (sortby === "result") {
        if (order === "asc") {
          return a.average - b.average;
        } else {
          return b.average - a.average;
        }
      } else if (sortby === "confirmation") {
        if (order === "asc") {
          return a.confirmationAverage - b.confirmationAverage;
        } else {
          return b.confirmationAverage - a.confirmationAverage;
        }
      }
    } else {
      return b.average - a.average;
    }
  });
  allUserResults.sort((a, b) => a.deactivate - b.deactivate);
  function capitalizeWords(str) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const handleAvearge = (result) => {
    const totalMarks = result.reduce((acc, item) => {
      return acc += parseInt(item.marks)
    }, 0)
    if (totalMarks > 0) {
      return totalMarks / result.length
    } else {
      return 0
    }
  }

  const handleClose = () => setOpenResultModal(false);
  const getAnswer = (data, username) => {
    const ans = window.open(`${variable.url}/correctanswer`);
    ans.data = data;
    ans.username = username;
  };

  return (
    <>
      <div className="pb-5">
        <table
          className="table table-bordered text-center mt-4 result-table"
          id={id}
          style={{ border: "1px solid black" }}
        >
          <thead>
            <tr>
              <th rowSpan="2">#</th>
              <th
                rowSpan="2"
                style={{
                  position: "sticky",
                  backgroundColor: "#19456B",
                  color: "white",
                }}
                className=""
              >
                Users
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className=" text-white"
              >
                Batch
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className="text-white"
              >
                EmpId
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className="text-white"
              >
                Attendance
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className="text-white"
              >
                Result(%)
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className="text-white"
              >
                Grade
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className="text-white"
              >
                skillAverage
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className="text-white"
              >
                Skill Grade
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className=" text-white"
              >
                Confirmation Average(%)
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className=" text-white"
              >
                Confirmation Grade
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className=" text-white"
              >
                X
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className=" text-white"
              >
                XII
              </th>
              <th
                rowSpan="2"
                style={{
                  backgroundColor: "#19456B",
                  color: "white",
                  position: "sticky",

                }}
                className=" text-white"
              >
                Academic Average
              </th>

              {specialSkillsColumn.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <th
                      colspan={`${item.columns.length + 2}`}
                      className="text-capitalize text-white "
                      style={{
                        backgroundColor: "#19456B",
                        position: "sticky",

                        color: "#ffffff",
                      }}
                    >
                      {item.skill}
                    </th>
                  </React.Fragment>
                );
              })}
              {filteredSkills.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <th
                      colspan="6"
                      className="text-capitalize text-white "
                      style={{
                        backgroundColor: "#19456B",
                        position: "sticky",

                        color: "#ffffff",
                      }}
                    >
                      {item.name}
                    </th>
                  </React.Fragment>
                );
              })}
            </tr>
            <tr>
              {specialSkillsColumn.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    {item.columns.map((i) => (
                      <th
                        scope="col"
                        className={`${i.length <= 2 ? "text-uppercase " : "text-capitalize"
                          }  text-white`}
                        style={{
                          backgroundColor: "#19456B",
                          position: "sticky",
                          top: 131,
                          color: "#ffffff",
                        }}
                      >
                        {i}
                      </th>
                    ))}
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#19456B",
                        position: "sticky",
                        top: 131,
                        color: "#ffffff",
                      }}
                      className=" text-white"
                    >
                      Average
                    </th>
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#19456B",
                        position: "sticky",
                        top: 131,
                        color: "#ffffff",
                      }}
                      className=" text-white"
                    >
                      Grade
                    </th>
                  </React.Fragment>
                );
              })}
              {filteredSkills.map((item, index) => {
                return (
                  <React.Fragment key={index}>
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#19456B",
                        position: "sticky",
                        top: 131,
                        color: "#ffffff",
                      }}
                      className=" text-white"
                    >
                      IM
                    </th>
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#19456B",
                        position: "sticky",
                        top: 131,
                        color: "#ffffff",
                      }}
                      className=" text-white"
                    >
                      EM
                    </th>
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#19456B",
                        position: "sticky",
                        top: 131,
                        color: "#ffffff",
                      }}
                      className=" text-white"
                    >
                      IP
                    </th>
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#19456B",
                        position: "sticky",
                        top: 131,
                        color: "#ffffff",
                      }}
                      className=" text-white"
                    >
                      EP
                    </th>
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#19456B",
                        position: "sticky",
                        top: 131,
                        color: "#ffffff",
                      }}
                      className=" text-white"
                    >
                      Average
                    </th>
                    <th
                      scope="col"
                      style={{
                        backgroundColor: "#19456B",
                        position: "sticky",
                        top: 131,
                        color: "#ffffff",
                      }}
                      className=" text-white"
                    >
                      Grade
                    </th>
                  </React.Fragment>
                );
              })}
            </tr>
          </thead>
          <tbody style={{ height: "20px !important", overflow: "scroll" }}>
            {allUserResults.length > 0 &&
              allUserResults
                .filter((item) => {
                  if (nameSearch && nameSearch.length === 0) {
                    return item;
                  } else if (
                    item &&
                    item.username &&
                    nameSearch &&
                    nameSearch.length > 0
                  ) {
                    return nameSearch.some(
                      (item1) => item1.label === item.username
                    );
                  }
                })
                .map((item, index) => {
                  return (
                    <tr
                      key={index}
                      className={`${item.deactivate && "bg-danger"}`}
                      style={{
                        backgroundColor: `${item.deactivate
                          ? "#ff0000"
                          : index % 2 === 0
                            ? "#E9ECEF"
                            : "#ffffff"
                          }`,
                      }}
                    >
                      <th scope="row">{index + 1}</th>
                      <th
                        style={{
                          position: "sticky",
                          left: -1,
                        }}
                        className="text-dark text-capitalize bg-white"
                      >
                        {capitalizeWords(item.username)}
                      </th>
                      <td>{item.batchName.split("-").join("")}</td>
                      <td>{item.empId}</td>
                      <td>
                        <Attedance attendance={item.attendanceData} />
                      </td>
                      <td>{item.average}</td>
                      <>{getGrade(item.average)}</>
                      <td
                        className={
                          item.skillFlag ? "text-success" : "text-danger"
                        }
                      >
                        {item.skillAverage.toFixed(2)}
                      </td>
                      <>{getGrade(item.skillAverage)}</>
                      <td>{item.confirmationAverage}</td>
                      <>{getGrade(item.confirmationAverage)}</>
                      <td>{item.tenthMarks}</td>
                      <td>{item.twelfthMarks}</td>
                      <td>{item.averageAcademicMarks}</td>
                      <>
                        {specialSkillsColumn.map((specialResult, index) => {
                          const result = item.results.find(
                            (i) => i.skill.name === specialResult.skill
                          );
                          if (result) {
                            return (
                              <>
                                {result.skill.name === "TrainingProject" ?
                                  <>
                                    {result?.ip && result?.ip?.deadline ? <td>{result.ip.deadline}</td> : <td></td>}
                                    {result?.ip && result?.ip?.analytics ? <td>{result.ip.analytics}</td> : <td></td>}
                                    {result?.ip && result?.ip?.database ? <td>{result.ip.database}</td> : <td></td>}
                                    {result?.ip && result?.ip?.ui ? <td>{result.ip.ui}</td> : <td></td>}
                                    {result?.ip && result?.ip?.serverside ? <td>{result.ip.serverside}</td> : <td></td>}
                                    {result.ip && result.ip.marks ? (
                                      <td scope="col" className="text-primary">
                                        <a
                                          href="#"
                                          // data-bs-toggle="modal"
                                          onClick={() => {
                                            setOpenResultModal(true)
                                            setExamData({
                                              data: [...result.ip.data],
                                              hostId: result.ip.hostId || "",
                                              skill: result.ip.skill,
                                              batch: item.batchName || "",
                                              userId: item.userId,
                                            })
                                          }}
                                        >
                                          {result?.ip?.data?.length > 1 && avearageStatus ? <>{handleAvearge(result.ip.data).toFixed(2)}</> : <>{result.ip.marks.toFixed(2)}</>}
                                        </a>
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {result?.ep && result?.ep?.deadline ? <td>{result.ep.deadline}</td> : <td></td>}
                                    {result?.ep && result?.ep?.analytics ? <td>{result.ep.analytics}</td> : <td></td>}
                                    {result?.ep && result?.ep?.database ? <td>{result.ep.database}</td> : <td></td>}
                                    {result?.ep && result?.ep?.ui ? <td>{result.ep.ui}</td> : <td></td>}
                                    {result?.ep && result?.ep?.serverside ? <td>{result.ep.serverside}</td> : <td></td>}
                                    {result.ep && result.ep.marks ? (
                                      <td scope="col" className="text-primary">
                                        <a
                                          href="#"
                                          // data-bs-toggle="modal"
                                          onClick={() => {
                                            setOpenResultModal(true)
                                            setExamData({
                                              data: [...result.ep.data],
                                              hostId: result.ep.hostId || "",
                                              skill: result.ep.skill,
                                              batch: item.batchName || "",
                                              userId: item.userId,
                                            })
                                          }}
                                        >
                                          {result?.ep?.data?.length > 1 && avearageStatus ? <>{handleAvearge(result.ep.data).toFixed(2)}</> : <>{result.ep.marks.toFixed(2)}</>}
                                        </a>
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                  </> :
                                  <>
                                    {result.ip && result.ip.marks ? (
                                      <td scope="col" className="text-primary">
                                        <a
                                          href="#"
                                          // data-bs-toggle="modal"
                                          onClick={() => {
                                            setOpenResultModal(true)
                                            setExamData({
                                              data: [...result.ip.data],
                                              hostId: result.ip.hostId || "",
                                              skill: result.ip.skill,
                                              batch: item.batchName || "",
                                              userId: item.userId,
                                            })
                                          }}
                                        >
                                          {result?.ip?.data?.length > 1 && avearageStatus ? <>{handleAvearge(result.ip.data).toFixed(2)}</> : <>{result.ip.marks.toFixed(2)}</>}
                                        </a>
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {result.ep && result.ep.marks ? (
                                      <td scope="col" className="text-primary">
                                        <a
                                          href="#"
                                          // data-bs-toggle="modal"
                                          onClick={() => {
                                            setOpenResultModal(true)
                                            setExamData({
                                              data: [...result.ep.data],
                                              hostId: result.ep.hostId || "",
                                              skill: result.ep.skill,
                                              batch: item.batchName || "",
                                              userId: item.userId,
                                            })
                                          }}
                                        >
                                          {result?.ep?.data?.length > 1 && avearageStatus ? <>{handleAvearge(result.ep.data).toFixed(2)}</> : <>{result.ep.marks.toFixed(2)}</>}
                                        </a>
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                  </>
                                }

                                {result?.average || result?.totalAverage ? (
                                  <>
                                    <td scope="col">
                                      {!avearageStatus ? result.average.toFixed(2) : result.totalAverage.toFixed(2)}
                                    </td>
                                    {getGrade(!avearageStatus ? result.average : result.totalAverage)}
                                  </>
                                ) : (
                                  <>
                                    <td></td>
                                    <td></td>
                                  </>
                                )}
                              </>
                            );
                          } else {
                            if (specialResult.skill === "TrainingProject") {
                              return (
                                <>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </>
                              );
                            } else if (specialResult.skill === "LiveCodingTest") {
                              return (
                                <>
                                  <td></td>
                                  <td></td>
                                </>
                              );
                            } else {
                              return (
                                <>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </>
                              );
                            }
                          }
                        })}
                        {filteredSkills.map((skill, index) => {
                          const result = item.results.find(
                            (i) => i.skill.name === skill.name
                          );
                          if (result) {
                            return (
                              <>
                                {result.result ? (
                                  <>
                                    {result.im && result.im.marks ? (
                                      <td scope="col" className="text-primary">
                                        <a
                                          href="#"
                                          onClick={() => {
                                            setOpenResultModal(true)
                                            setExamData({
                                              data: [...result.im.data],
                                              hostId: result.hostId || "",
                                              skill: result.im.skill,
                                              batch: item.batchName || "",
                                              userId: item.userId,
                                            })
                                          }}
                                        >
                                          {result?.im?.data?.length > 1 && avearageStatus ? <>{handleAvearge(result.im.data).toFixed(2)}</> : <>{result.im.marks.toFixed(2)}</>}
                                        </a>
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {result.em && result.em.marks ? (

                                      <td scope="col" className="text-primary">
                                        <a
                                          href="#"
                                          onClick={() => {
                                            setOpenResultModal(true)
                                            setExamData({
                                              data: [...result.em.data],
                                              hostId: result.em.hostId || "",
                                              skill: result.em.skill,
                                              batch: item.batchName || "",
                                              userId: item.userId,
                                            })
                                          }}
                                        >
                                          {result?.em?.data?.length > 1 && avearageStatus ? <>{handleAvearge(result.em.data).toFixed(2)}</> : <>{result.em.marks.toFixed(2)}</>}
                                        </a>
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {result.ip && result.ip.marks ? (
                                      <td
                                        scope="col"
                                        className={`text-primary ${result.practicalVariation &&
                                          "border border-danger border-5"
                                          }`}
                                      >
                                        <a
                                          href="#"
                                          onClick={() => {
                                            console.log({
                                              data: [...result.ip.data],
                                              hostId: result.ip.hostId || "",
                                              skill: result.ip.skill,
                                              batch: item.batchName || "",
                                              userId: item.userId,
                                            })
                                            setOpenResultModal(true)
                                            setExamData({
                                              data: [...result.ip.data],
                                              hostId: result.ip.hostId || "",
                                              skill: result.ip.skill,
                                              batch: item.batchName || "",
                                              userId: item.userId,
                                            })
                                          }}
                                        >
                                          {result?.ip?.data?.length > 1 && avearageStatus ? <>{handleAvearge(result.ip.data).toFixed(2)}</> : <>{result.ip.marks.toFixed(2)}</>}
                                        </a>
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {result.ep && result.ep.marks ? (
                                      <td
                                        scope="col"
                                        className={`text-primary ${result.practicalVariation &&
                                          "border border-danger border-5"
                                          }`}
                                      >
                                        <a
                                          href="#"
                                          onClick={() => {
                                            setOpenResultModal(true)
                                            setExamData({
                                              data: [...result.ep.data],
                                              hostId: result.ep.hostId || "",
                                              skill: result.ep.skill,
                                              batch: item.batchName || "",
                                              userId: item.userId,
                                            })
                                          }}
                                        >
                                          {result?.ep?.data?.length > 1 && avearageStatus ? <>{handleAvearge(result.ep.data).toFixed(2)}</> : <>{result.ep.marks.toFixed(2)}</>}
                                        </a>
                                      </td>
                                    ) : (
                                      <td></td>
                                    )}
                                    {result?.average || result?.totalAverage ? <>  <td scope="col">
                                      {!avearageStatus ? result?.average?.toFixed(2) : result?.totalAverage?.toFixed(2)}
                                    </td>
                                      {getGrade(!avearageStatus ? result?.average : result?.totalAverage)}</> : <>
                                      <td></td>
                                      <td></td>
                                    </>}
                                  </>
                                ) : (
                                  <>
                                    <td scope="col"></td>
                                    <td scope="col"></td>
                                    <td scope="col"></td>
                                    <td scope="col"></td>
                                    <td scope="col"></td>
                                    <td scope="col"></td>
                                  </>
                                )}
                              </>
                            );
                          } else {
                            return (
                              <>
                                <td scope="col"></td>
                                <td scope="col"></td>
                                <td scope="col"></td>
                                <td scope="col"></td>
                                <td scope="col"></td>
                                <td scope="col"></td>
                              </>
                            );
                          }
                        })}
                      </>
                    </tr>
                  );
                })}
          </tbody>
        </table>
      </div>
      <Modal size={getExamData?.data?.find((item) => (item.remark ? item.remark : false)) ? "xl" : "lg"} show={openResultModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Exam Result</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {getExamData?.data?.length > 0 ? <table class="table">
            <thead>
              <tr>
                <th scope="col">
                  #
                </th>
                <th scope="col">
                  Exam Code
                </th>
                <th scope="col">
                  Mark(%)
                </th>
                <th scope="col">
                  Grade
                </th>
                {getExamData?.data?.find((item) => (item.remark ? item.remark : false)) ? <><th scope="col">
                  Remarks
                </th><th scope="col">
                    Video Link
                  </th><th scope="col">
                    Review By
                  </th></> : <th scope="col">
                  Answer
                </th>}
                <th scope="col">
                  Date
                </th>
              </tr>
            </thead>
            <tbody id="tableBody">
              {
                getExamData?.data?.map((marks, index) => {
                  const date = new Date(marks.reviewDate);
                  let temp = `${date.getDate()}/${date.getMonth() + 1
                    }/${date.getFullYear()}`;
                  return (
                    <tr>
                      <td>{index + 1}</td>
                      {marks?.examId?.examId ? <td>{marks.examId.examId}</td> : <td>{marks.examId}</td>}
                      <td>{marks && marks.marks.toFixed(2)}</td>
                      <td>{getGrade(marks.marks)}</td>
                      {marks?.remark ? <>
                        <td>{marks.remark}</td>
                        <td><a
                          target="_blank"
                          href={`/ExamVideo/${getExamData?.batch}/${getExamData?.skill}/${marks.examId
                            }/${getExamData?.hostId}/${getExamData?.userId
                            }`}
                        >
                          <i class="fa-solid fa-play-circle fs-5"></i>
                        </a></td>
                        <td>
                          {marks?.reviewBy}</td></> : <td><BiCommentDetail
                            onClick={() => getAnswer(marks, getExamData?.hostId)}
                          ></BiCommentDetail></td>}
                      <td>{temp}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table> : <span className="text-danger text-center">No data founded</span>}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    </>
  );
};

function Attedance({ attendance }) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const formatDate = (date) => {
    if (date) {
      let today = new Date(date);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      today = dd + "/" + mm + "/" + yyyy;
      return today;
    } else {
      return;
    }
  };
  var leavedetails = [];
  if (attendance && attendance.LeaveDetails) {
    leavedetails = JSON.parse(attendance.LeaveDetails);
  }

  return (
    <>
      {attendance ? (
        <>
          <p className="text-primary btn border-bottom" onClick={handleShow}>
            {attendance.PresentDays} / {attendance.TotalWorkingDays}
          </p>

          <Modal size="lg" show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Attendance</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="col col-6">
                  <p>User: {attendance.EmployeeName}</p>
                </div>
                <div className="col col-6">
                  <p>Joining Date: {formatDate(attendance.JoinDate)}</p>
                </div>
                <div className="col col-6">
                  <p>
                    Present Days: {attendance.PresentDays} /{" "}
                    {attendance.TotalWorkingDays}{" "}
                  </p>
                </div>
                <div className="col col-6">
                  <p>Leave Days: {attendance.LeaveDays || 0}</p>
                </div>
                <div className="col col-4">
                  <p>Productive Time: {attendance.TotalProductiveTime}</p>
                </div>
                <div className="col col-4">
                  <p>Desktime: {attendance.TotalDeskTime}</p>
                </div>
                <div className="col col-4">
                  <p>Required Desktime: {attendance.RequiredDeskTime}</p>
                </div>
              </div>

              {leavedetails.length > 0 && (
                <>
                  <p>Leave Details:</p>
                  <table id="table" class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Status</th>
                        <th scope="col">Type</th>
                        <th scope="col">Days</th>
                        <th scope="col">From Date</th>
                        <th scope="col">To Date</th>
                        <th scope="col">Reason</th>
                      </tr>
                    </thead>
                    <tbody id="tableBody">
                      {leavedetails.map((details, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{details.LeaveStatus}</td>
                            <td>{details.LeaveType}</td>
                            <td>{details.Days}</td>
                            <td>{details.FromDate}</td>
                            <td>{details.ToDate}</td>
                            <td>{details.Reason}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={handleClose}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

const PercentageTable = ({ id, name }) => {
  return (
    <table>
      <table className={`table d-none ${name}`} id={id}>
        <thead>
          <tr>
            <th scope="col">Task</th>
            <th scope="col">Percentage(%)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>TrainingProject</td>
            <td>60</td>
          </tr>
          <tr>
            <td>LiveCodingTest</td>
            <td>10</td>
          </tr>
          <tr>
            <td>All skills</td>
            <td>25</td>
          </tr>
          <tr>
            <td>Academic marks</td>
            <td>5</td>
          </tr>
        </tbody>
      </table>
    </table>
  );
};
