import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaPen, FaTimes } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Loader from '../../components/loader/Loader';
import CourseService from "../../services/CourseService";
import ToolTip from "../BsComponents/ToolTip";
import AddPracticalXslx from "./AddPracticalXslx";
const { getUserName } = require("../../utils/user/getUserName");
const { format } = require("../../../src/utils/Date/format");

export default function ManagePracticalQuestionList(props) {
    const navigate = useNavigate();
    const [examTypes, setExamTypes] = useState([
    ]);
    const [questionCountPerPage, setQuestionCountPerPage] = useState(3);
    const [activeQuestion, setActiveQuestion] = useState([]);
    const [testCaseSelectOptions, setTestCaseSelectOptions] = useState([
        {
            label: 'All Questions', value: null
        },
        { 
            label: 'Questions with Test Cases', value: { $and: [{ testCaseJson: { $exists: true } }, { testCaseJson: { $type: ['object', 'string'] } }, { $expr: { $gt: [{ $strLenCP: '$testCaseJson' }, 1] } }] } },
        {
            label: 'Questions without Test Cases', value: { $or: [{ testCaseJson: { $exists: false } }, { $expr: { $lte: [{ $strLenCP: '$testCaseJson' }, 1] } }] }
        }
    ])
    const [testCaseSelectValue, setTestCaseSelectValue] = useState(null);
    const [difficultyList, setDifficultyList] = useState([
    ]);
    const [examQuestion, setExamQuestion] = useState([]);
    const [readMore, setReadMore] = useState([]);
    const [subcourseOptions, setSubcoursesOptions] = useState([]);
    const [confirmBox, setConfirmBox] = useState(false);
    const [message, setMessage] = useState({ status: false, msg: "" });
    const [openModal, setOpenModal] = useState(false);
    const [questionId, setQuestionId] = useState("");
    const [updateData, setUpdateData] = useState({});
    const [updated, setUpdated] = useState(false);
    const [filterQuestions, setFilterQuestions] = useState({});
    const [loader, setLoader] = useState(false)
    const [page, setPage] = useState({ selected: 0 });
    const [currentpage, setcurrentPage] = useState(1);
    const [practicalCount, setPracticalCount] = useState(0)
    const [subCourseTopics, setSubCourseTopics] = useState([])
    const [questionDifficultyCount, setQuestionDifficultyCount] = useState({})
    const [searchQuestion, setSearchQuestion] = useState("");
    const handlecreatedAndUpdateby = (createdupdatedby) => {
        return getUserName(createdupdatedby);
    };
    const handleCreateUpadateDate = (date) => {
        return format(date);
    };

    const toChars = (n) =>
        `${n >= 26 ? toChars(Math.floor(n / 26) - 1) : ""}${"ABCDEFGHIJKLMNOPQRSTUVWXYZ"[n % 26]
        }`;

    const handleMessage = (message, status, magtime = false) => {
        setConfirmBox(true)
        setMessage({ msg: message, status: status })
        if (magtime) {
            setTimeout(() => setConfirmBox(false), 3000)
        }
    }

    const handleCatchError = (err) => {
        setLoader(false)
        if (err["response"]) {
            if (err["response"].status === 401) {
                if (err["response"]?.data?.message === "You are not authorized to view this content") {
                    handleMessage(err["response"].data.message, false)
                } else {
                    localStorage.removeItem("token");
                    navigate("/login");
                }
            } else if (err["response"].status === 404) {
                handleMessage(err["response"]?.data?.message, false)
            } else {
                localStorage.removeItem("token");
                navigate("/login");
            }
        }
    }


    const getsubcourseOptions = (filterQuestions, page = 1) => {
        const token = localStorage.getItem('token');
        setLoader(true)
        CourseService.getPracticalsQuestionsList(filterQuestions, page, token).then((res) => {
            if (res.data) {
                setLoader(false)
                setExamQuestion(res.data.filterQuestions);
                setPracticalCount(res.data.totalPractical)
                setQuestionDifficultyCount(res.data.difficultyList)
                setPage({ selected: 1 })
                setcurrentPage(page)
                if (res.data.dataStatus) {
                    setExamTypes(res.data.examTypes)
                    setActiveQuestion(res.data.questionStatus)
                    setDifficultyList(res.data.Difficulty)
                    setSubCourseTopics(res.data.subTopics)
                }
            }
        }).catch((err) => {
            handleCatchError(err)
        });
    };

    useEffect(() => {
        setLoader(true)
        CourseService.getSubCourses().then((res) => {
            if (res.data) {
                var subcourses = [];
                res.data.map(async (item, index) => {
                    await subcourses.push({
                        id: index + 1,
                        value: item._id,
                        label: item.name,
                    });
                });
                setSubcoursesOptions(subcourses);
                getsubcourseOptions({
                    ...filterQuestions,
                    'Question': searchQuestion ? {
                        label: searchQuestion,
                        value: { $regex: searchQuestion, $options: "i" },
                    } : null
                }) //(filterQuestions)
                // setSubcoursesList(res.data)
            }
        }).catch(error => {
            handleCatchError(error);
        });
    }, []);


    useEffect(() => {
        const timeoutId = setTimeout(() => {
            getsubcourseOptions({
                ...filterQuestions,
                'Question': searchQuestion ? {
                    label: searchQuestion,
                    value: { $regex: searchQuestion, $options: "i" },
                } : null
            }, 1);
        }, 500);
        return () => clearTimeout(timeoutId);
    }, [searchQuestion]);

    const handleIndex = (index) => {
        const finalNumber = ((currentpage * 3) - 3)
        return finalNumber + index
    };

    const difficultyLeval = (difficultyType) => {
        if (difficultyType === 1) {
            return "Easy";
        } else if (difficultyType === 2) {
            return "Medium";
        } else {
            return "Hard";
        }
    };

    const handleChangeQuestionStatus = async (e, questionId, index) => {
        const token = localStorage.getItem("token")
        const questionType = "Practical";
        if (e.target.checked) {
            const questionRight = true;
            const res = await CourseService.changeQuestionsRights(
                questionId,
                questionType,
                questionRight,
                token
            );
            // if (res.data) {
            //     getsubcourseOptions(filterQuestions);
            // }
        } else {
            const questionRight = false;
            const res = await CourseService.changeQuestionsRights(
                questionId,
                questionType,
                questionRight,
                token
            );
            // if (res.data) {
            //     getsubcourseOptions(filterQuestions);
            // }
        }
        setExamQuestion(examQuestion => {
            let copyQuestions = [...examQuestion]
            copyQuestions[index]['questionApprove'] = !e.target.checked
            return copyQuestions
        })
    };

    const handleDelete = () => {
        const token = localStorage.getItem("token");
        if (!token) {
            navigate("/login");
        } else {
            if (questionId) {
                setLoader(true)
                const questionType = "Practical";
                CourseService.deleteQuestion(questionId, questionType, token).then(
                    (res) => {
                        if (res.data) {
                            window.scrollTo(0, 0);
                            setLoader(false)
                            setMessage({ msg: res.data.message, status: true });
                            setConfirmBox(true);
                            setTimeout(() => setConfirmBox(false), 5000);
                            setOpenModal(false);
                            getsubcourseOptions({
                                ...filterQuestions,
                                'Question': searchQuestion ? {
                                    label: searchQuestion,
                                    value: { $regex: searchQuestion, $options: "i" },
                                } : null
                            })
                        }
                    }
                ).catch(error => {
                    handleCatchError(error);
                });
            } else {
                window.scrollTo(0, 0);
                setMessage({ msg: "delete id is not found", status: false });
                setConfirmBox(true);
                setTimeout(() => setConfirmBox(false), 3000);
            }
        }
    };

    let closeModal = () => {
        setOpenModal(false);
    };

    const handleFilters = (name, value) => {
        let filterQuestionValue = {};
        if (name === 'subCourseId') {
            if (value?.length > 0) {
                const testCaseJson = filterQuestions['testCaseJson']; // retain the current state of Test Case status
                // Bug resolved K: For removing other filters on skill change
                setFilterQuestions({ [name]: value, testCaseJson });
                filterQuestionValue = { [name]: value } //{ [name]: value }

                //     setFilterQuestions({ ...filterQuestions, [name]: value });
                // filterQuestionValue = { ...filterQuestions, [name]: value }
            } else {
                setFilterQuestions({});
                filterQuestionValue = {} //{}
                setExamTypes([])
                setActiveQuestion([])
                setDifficultyList([])
            }
        } else if (name === 'Question') { // filtering for Search question textbox
            if (value?.length > 0) {
                setFilterQuestions({
                    ...filterQuestions,
                    [name]: value,
                });
            } else {
                setFilterQuestions((filterData) => {
                    const copyFilterData = { ...filterData };
                    delete copyFilterData[name];
                    return copyFilterData;
                });
            }
            filterQuestionValue = {
                ...filterQuestions, [name]: {
                    label: value,
                    value: { $regex: value, $options: "i" },
                }
            }
        } else if (name === 'testCaseJson') {
            setFilterQuestions({ ...filterQuestions, [name]: value });
            filterQuestionValue = {
                ...filterQuestions,
                [name]: value
            }
        }
        else {
            setFilterQuestions({ ...filterQuestions, [name]: value });
            filterQuestionValue = { ...filterQuestions, [name]: value }
        }
        if (filterQuestionValue) {
            getsubcourseOptions({
                ...filterQuestionValue,
                'Question': searchQuestion ? {
                    label: searchQuestion,
                    value: { $regex: searchQuestion, $options: "i" },
                } : null
            })
        }
    }

    const handlePageChange = (pagenumber) => {
        // debouncing for filter
        getsubcourseOptions({
            ...filterQuestions,
            'Question': searchQuestion ? {
                label: searchQuestion,
                value: { $regex: searchQuestion, $options: "i" },
            } : null
        }, pagenumber)
        window.scrollTo(0, 0);
    }

    /*
    *
    *   Function to Change question status in batch.
    * 
    */
    const handleQuestionStatusBatchToggle = async (e) => {

        const queStatus = e.target.checked;
        const paginationSize = Math.ceil(practicalCount / questionCountPerPage);
        const filterOptions = filterQuestions;
        const token = localStorage.getItem('token');
        const questionType = "Practical";

        setLoader(true);

        let currPage = 1;

        // statusChange arr
        let statusChangeArr = [];
        // Traverse from page 1 to paginationSize
        while (currPage <= paginationSize) {
            await CourseService.getPracticalsQuestionsList(filterOptions, currPage, token)
                .then(async (res) => {
                    if (res.data && res.data.filterQuestions) {
                        statusChangeArr.push(...res.data.filterQuestions)
                    }

                }).catch((err) => {
                    handleCatchError(err);
                })
            currPage++;
        }

        // Toggle the status of each Question
        await statusChangeArr.forEach(async (que) => {
            await CourseService.changeQuestionsRights(
                que._id,
                questionType,
                queStatus,
                token
            )
        });

        // Change the status Dropdown From Active to Inactive or vice-versa
        const dropdownValue = { label: !queStatus ? 'inActive' : 'Active', value: queStatus };

        handleFilters("questionApprove", dropdownValue)
        setLoader(false);
    }

    return (
        <>
            <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
                <nav>
                    <ol className="breadcrumb mb-0 py-2">
                        <li
                            className={
                                page.selected === 1
                                    ? "breadcrumb-item active"
                                    : "breadcrumb-item text-primary"
                            }
                            style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
                            onClick={() => {
                                setPage({ selected: 1 });
                            }}
                        >
                            Handle practical questions
                        </li>
                        {page.selected === 2 ? (
                            <li
                                className={
                                    page.selected === 2 ? "breadcrumb-item active" : "d-none"
                                }
                            >
                                Update practical questions
                            </li>
                        ) : null}
                    </ol>
                </nav>
            </div>
            {loader &&
                <Loader loaderStatus={loader} message="Loading ....." />
            }
            <div className="container my-2 bg-white p-4" style={{ maxWidth: "90%" }}>
                {confirmBox && (
                    <div className="formloade">
                        <div className="row text-center">
                            <div className="col-6 w-100 confirmbox">
                                <div
                                    className={`alert ${message.status ? "alert-success" : "alert-danger"
                                        }`}
                                >
                                    {message.msg}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {page.selected === 1 && (
                    <>
                        <div className="mx-auto d-flex justify-content-center mt-2">
                            <div className="me-5" style={{ width: "200px" }}>
                                <label>Select Skill</label>
                                <Select
                                    options={subcourseOptions}
                                    placeholder={"Select Skill"}
                                    value={filterQuestions.subCourseId}
                                    isSearchable
                                    isClearable
                                    isMulti
                                    onChange={(e) => handleFilters("subCourseId", e)}
                                />
                            </div>
                            <div className="me-5" style={{ width: "200px" }}>
                                <label>Select examType</label>
                                <Select
                                    options={examTypes}
                                    placeholder={"Select examType"}
                                    value={filterQuestions?.typeOfExam ? filterQuestions?.typeOfExam : null}
                                    isSearchable
                                    isClearable
                                    isDisabled={examTypes?.length > 1 ? false : true}
                                    onChange={(e) => handleFilters("typeOfExam", e)}
                                />
                            </div>
                            <div className="me-5" style={{ width: "200px" }}>
                                <label>Select Difficulty</label>
                                <Select
                                    options={difficultyList}
                                    placeholder={"Select Difficulty"}
                                    value={filterQuestions?.difficulty ? filterQuestions?.difficulty : null}
                                    isSearchable
                                    isMulti
                                    isDisabled={difficultyList?.length > 1 ? false : true}
                                    onChange={(e) => handleFilters("difficulty", e)}
                                />
                            </div>
                            <div className="me-5" style={{ width: "200px" }}>
                                <label>Select subTopic</label>
                                <Select
                                    options={subCourseTopics}
                                    placeholder={"Select Topic"}
                                    value={filterQuestions?.subQuestions}
                                    isSearchable
                                    isMulti
                                    isDisabled={subCourseTopics?.length > 1 ? false : true}
                                    onChange={(e) => handleFilters("questionTopic", e)}
                                />
                            </div>
                            <div className="me-5" style={{ width: "200px" }}>
                                <label>Select status</label>
                                <Select
                                    options={activeQuestion}
                                    placeholder={"Select status"}
                                    value={filterQuestions?.questionApprove ? filterQuestions.questionApprove : null}
                                    isSearchable
                                    isClearable
                                    isDisabled={activeQuestion?.length > 1 ? false : true}
                                    onChange={(e) => handleFilters("questionApprove", e)}
                                />
                            </div>
                            <div className="me-5" style={{ width: "200px" }}>
                                <label>Question Test Status</label>
                                <Select
                                    options={testCaseSelectOptions}
                                    placeholder={"Select status"}
                                    value={filterQuestions?.testCaseJson ? filterQuestions.testCaseJson : null}
                                    isClearable
                                    onChange={(e) => handleFilters("testCaseJson", e)}
                                />
                            </div>
                        </div>
                        <div className="text-end my-2">
                            <input
                                type="text"
                                className="form-control"
                                name="Question"
                                placeholder="Search question"
                                value={searchQuestion}
                                onChange={(e) => setSearchQuestion(e.target.value)}
                            />
                            <div className="d-flex align-items-center mt-1">
                                <span className="flex-grow-1"></span>
                                {questionDifficultyCount?.Easy > 0 && <p className="mx-2 badge rounded-pill bg-success text-dark h6">
                                    Easy {questionDifficultyCount.Easy}
                                </p>}
                                {questionDifficultyCount?.Medium > 0 && <p className="mx-2 badge rounded-pill bg-info text-dark h6">
                                    Medium {questionDifficultyCount.Medium}
                                </p>}
                                {questionDifficultyCount?.Hard > 0 && <span className="mx-2 badge rounded-pill bg-danger text-dark h6">
                                    Hard {questionDifficultyCount.Hard}
                                </span>}
                                {/* Toggle All Question Active/ InActive Switch */}
                                {filterQuestions.questionApprove &&
                                    <label className="d-flex">
                                        Set All Question {filterQuestions.questionApprove.value ? 'Inactive' : 'Active'}
                                        <span className="form-check form-switch mx-2">

                                            <ToolTip title={`${filterQuestions.questionApprove.value ? 'Active' : 'InActive'} Question`}>
                                                <input
                                                    className="form-check-input"
                                                    type="checkbox"
                                                    checked={filterQuestions.questionApprove.value}
                                                    onChange={(e) => {
                                                        handleQuestionStatusBatchToggle(e);
                                                    }}
                                                />
                                            </ToolTip>
                                        </span>
                                    </label>}
                            </div>
                        </div>
                    </>
                )}

                {page.selected === 1 && <>
                    {examQuestion?.length > 0 ?
                        <>
                            <div className="d-flex justify-content-center mb-5" style={{ marginBottom: '0rem!important' }}>
                                <Pagination
                                    activePage={currentpage}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    itemsCountPerPage={questionCountPerPage}
                                    totalItemsCount={practicalCount}
                                    pageRangeDisplayed={5}
                                    onChange={(e) => handlePageChange(e)}
                                /></div>
                            <div></div>
                            <ol class="pt-5">
                                {examQuestion.map((item, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="row col-md-12 my-2">
                                                <div className="col-md-2">
                                                    <span className="text-primary fw-normal">
                                                        ({handleIndex(index + 1)}). {item?.subCourseId?.name}
                                                    </span>
                                                </div>
                                                <div className="col-md-2">
                                                    <span className="text-primary fw-normal">
                                                        Difficulty:
                                                    </span>
                                                    {difficultyLeval(item.difficulty)}
                                                </div>
                                                <div className="col-md-1">
                                                    <span className="text-primary fw-normal">
                                                        {item.typeOfExam}
                                                    </span>
                                                </div>
                                                <div className="col-md-1">
                                                    <span className="text-primary fw-normal">
                                                        TotalTime:
                                                    </span>
                                                    {item.Time}
                                                </div>
                                                <div className="col-md-1 mx-2">
                                                    <span className="text-primary fw-normal">
                                                        Reminder:
                                                    </span>
                                                    {item.remainder}
                                                </div>
                                                <div className="col-md-2 row">
                                                    <span className="text-primary fw-normal">
                                                        {handlecreatedAndUpdateby(item.createdBy) && item.createdAt
                                                            ? `${handlecreatedAndUpdateby(item.createdBy)} on ${handleCreateUpadateDate(item.createdAt)}`
                                                            : ''}
                                                    </span>
                                                    {/* {item.createdBy['username']} */}
                                                </div>
                                                <div className="col-md-2 row">
                                                    <span className="text-primary fw-normal">
                                                        {handlecreatedAndUpdateby(item.createdupdatedby) && item.updatedAt
                                                            ? `${handlecreatedAndUpdateby(item.createdupdatedby)} on ${handleCreateUpadateDate(item.updatedAt)}`
                                                            : ''}
                                                    </span>
                                                </div>
                                                <div className="col-md-1 row">
                                                    <div className="col-md-6">
                                                        <div className="d-flex">
                                                            {/* Active- InActive Question */}
                                                            <div className="form-check form-switch ms-3">
                                                                {!item.questionApprove ? (
                                                                    <ToolTip title="Active Question">
                                                                        <input
                                                                            className="form-check-input"
                                                                            type="checkbox"
                                                                            checked={item.questionApprove}
                                                                            onChange={(e) => {
                                                                                handleChangeQuestionStatus(e, item._id, index);
                                                                            }}
                                                                        />
                                                                    </ToolTip>
                                                                ) :
                                                                    (
                                                                        <ToolTip title="InActive Question">
                                                                            <input
                                                                                className="form-check-input"
                                                                                type="checkbox"
                                                                                checked={item.questionApprove}
                                                                                onChange={(e) => {
                                                                                    handleChangeQuestionStatus(e, item._id, index);
                                                                                }}
                                                                            />
                                                                        </ToolTip>
                                                                    )}

                                                            </div>
                                                            {/* Edit question */}
                                                            <div>
                                                                <button
                                                                    className="btn btn-warning px-2 py-1 mx-1 fs-8"
                                                                    onClick={() => {
                                                                        setPage({ selected: 2 });
                                                                        setQuestionId(item._id);
                                                                        const temp = { ...item };
                                                                        temp.cid = item.subCourseId._id;
                                                                        temp.type = item.typeOfExam;
                                                                        temp.time = item.Time;
                                                                        temp.remainder = item.ReminderPrompt;
                                                                        temp.data = item.Question;
                                                                        temp.questionTopic = { label: item.questionTopic, value: item.questionTopic }
                                                                        const subQuestions = item.subQuestions.map(
                                                                            (i) => {
                                                                                return {
                                                                                    data: i.Question,
                                                                                    time: i.Time,
                                                                                    remainder: i.ReminderPrompt,
                                                                                    difficulty: i.difficulty,
                                                                                };
                                                                            }
                                                                        );
                                                                        temp.testcase = item.testcase
                                                                        temp.subQuestions = subQuestions;
                                                                        setUpdateData({ ...temp });
                                                                    }}
                                                                >
                                                                    <FaPen className="color-white text-white" />
                                                                </button>
                                                            </div>
                                                            {/* Delete question */}
                                                            <div>
                                                                <ToolTip title="Delete Question">
                                                                    <button
                                                                        className="btn btn-secondary px-2 py-1 mx-1"
                                                                        id={item._id}
                                                                        onClick={() => {
                                                                            setQuestionId(item._id);
                                                                            setOpenModal(true);
                                                                        }}
                                                                    >
                                                                        <FaTimes className="color-white text-white" />
                                                                    </button>
                                                                </ToolTip>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                className="mx-2"
                                                dangerouslySetInnerHTML={{
                                                    __html: item.Question,
                                                }}
                                            ></div>
                                            <ul className="ms-5">
                                                {item.subQuestions.length > 0 &&
                                                    item.subQuestions.map((item2, index) => {
                                                        return (
                                                            <>
                                                                <div className="d-flex">
                                                                    <div className="me-2">
                                                                        <span>{toChars(index)} .</span>
                                                                    </div>
                                                                    <div>
                                                                        <span
                                                                            className="list-unstyled"
                                                                            dangerouslySetInnerHTML={{
                                                                                __html: `${item2.Question}`,
                                                                            }}
                                                                        ></span>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        );
                                                    })}
                                            </ul>
                                        </React.Fragment>
                                    );
                                })}
                            </ol>
                            <div className="d-flex justify-content-center mb-5">
                                <Pagination
                                    activePage={currentpage}
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    itemsCountPerPage={questionCountPerPage}
                                    totalItemsCount={practicalCount}
                                    pageRangeDisplayed={5}
                                    onChange={(e) => handlePageChange(e)}
                                /></div>
                            <div>
                                <Modal
                                    show={openModal}
                                    onHide={closeModal}
                                    style={{ marginTop: "15%" }}
                                    aria-labelledby="ModalHeader"
                                >
                                    <Modal.Body>
                                        <h5>Are you sure you want to delete this question ?</h5>
                                    </Modal.Body>
                                    <Modal.Footer class="text-center mb-2">
                                        <button
                                            className="btn btn-primary me-3"
                                            onClick={handleDelete}
                                        >
                                            Yes
                                        </button>
                                        <button
                                            className="btn btn-primary"
                                            onClick={(e) => setOpenModal(false)}
                                        >
                                            No
                                        </button>
                                    </Modal.Footer>
                                </Modal>
                            </div>
                        </> : <div className="text-center text-danger my-5">No Records Founds</div>
                    }
                </>}
                {updateData._id && page.selected === 2 && (
                    <AddPracticalXslx
                        updateData={updateData}
                        updated={(value) => getsubcourseOptions({
                            ...filterQuestions,
                            'Question': searchQuestion ? {
                                label: searchQuestion,
                                value: { $regex: searchQuestion, $options: "i" },
                            } : null
                        })}
                    />
                )}
            </div>
        </>
    );
}
