import axios from 'axios';
import variable from '../servicesvariable';

const { TUTORIAL_API_VIDEO_URL } = variable;


class VideoService {

    static async uploadMp4Video(data, token) {
        try {
            const response = await axios.post(TUTORIAL_API_VIDEO_URL, data, {
                headers: { 
                    Authorization: token,
                    'Content-Type': 'multipart/form-data',
                },
            });

            return response;
        } catch (error) {
            throw error;
        }
    }

    static async getUploadStatus(token) {
        try {
            const response = await axios.get(variable.mp4VideoUrl + '/mp4LogFile.json', {
                headers: {
                    Authorization: token
                }
            })

            return response;
        }catch(error) {
            throw error;
        }
    }
}

export default VideoService;