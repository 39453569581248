import React, { useState, useEffect } from "react";
import BatchManagementServices from "../../../services/BatchManagementServices";
import Select, { components } from "react-select";
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import { FaPen } from "react-icons/fa";
import { RiDeleteBin3Line } from "react-icons/ri";

export default function ParentBatch() {
  const [postData, setPostData] = useState({
    name: "",
    startDate: "",
    days: 0,
    trainingbatches: [],
    _id: "",
    status: true
  });
  const [submitData, setSubmitData] = useState({ submit: false, msg: "" });
  const [parentBatches, setParentBatches] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState([]);
  const [allBatches, setAllBatches] = useState([]);
  const [operation, setOperation] = useState({
    op: "Create",
    class: "btn-primary",
  });
  const [isActiveParentBatch, setIsActiveParentBatch] = useState(true);
  const [parentBatchWithoutFilter, setParentBatchWithoutFilter] = useState([]);
  useEffect(() => {
    getAllParentBatch();
  }, [submitData.submit]);

  useEffect(() => {
    getTrainingBatches();
  }, []);

  const resetNotification = () => {
    setTimeout(() => {
      setSubmitData({
        submit: false,
        msg: "",
      });
    }, 9000);
  };
  const handleParentBatchSubmit = () => {
    if (operation.op === "Create") {
      const trainingBatchesId = Array.from(selectedBatches, (i) => i.id);
      const data = {
        ...postData,
        trainingbatches: [...trainingBatchesId],
      };
      BatchManagementServices.postParentBatches(
        data,
        localStorage.getItem("token")
      )
        .then((res) => {
          setPostData({
            name: "",
            startDate: "",
            days: 0,
            trainingbatches: [],
            _id: "",
            status: true
          });
          setSelectedBatches([]);
          setOperation({
            op: "Create",
            class: "btn-primary",
          });
          setSubmitData({
            submit: true,
            msg: "Parent Batch created",
          });
        })
        .catch((err) => {
          console.log(err);
          setSubmitData({
            submit: false,
            msg: err.response ? err.response.data.msg : "Something went wrong",
          });
        });
    } else if (operation.op === "Update") {
      const trainingBatchesId = Array.from(selectedBatches, (i) => i.id);
      const data = {
        ...postData,
        trainingbatches: [...trainingBatchesId],
      };
      BatchManagementServices.updateParentBatches(
        postData._id,
        data,
        localStorage.getItem("token")
      )
        .then((res) => {
          setPostData({
            name: "",
            startDate: "",
            days: 0,
            trainingbatches: [],
            _id: "",
            status: true
          });
          setSelectedBatches([]);
          setOperation({
            op: "Create",
            class: "btn-primary",
          });
          setSubmitData({
            submit: true,
            msg: "Parent Batch updated",
          });
        })
        .catch((err) => {
          console.log(err);
          setSubmitData({
            submit: false,
            msg: err.response ? err.response.data.msg : "Something went wrong",
          });
        });
    } else {
      BatchManagementServices.deleteParentBatches(
        postData._id,
        localStorage.getItem("token")
      )
        .then((res) => {
          setPostData({
            name: "",
            startDate: "",
            days: 0,
            trainingbatches: [],
            _id: "",
            status: true
          });
          setSelectedBatches([]);
          setOperation({
            op: "Create",
            class: "btn-primary",
          });
          setSubmitData({
            submit: true,
            msg: "Parent Batch deleted",
          });
        })
        .catch((err) => {
          console.log(err);
          setSubmitData({
            submit: false,
            msg: err.response ? err.response.data.msg : "Something went wrong",
          });
        });
    }
  };

  const getAllParentBatch = () => {
    BatchManagementServices.getParentBatches(localStorage.getItem("token"))
      .then((res) => {
        let parentBatch = res.data.data.filter((item => { return item.status == isActiveParentBatch }))
        setParentBatches(parentBatch);
        setParentBatchWithoutFilter(res.data.data);
      })
      .catch((err) => {
        console.log(err);
        setSubmitData({
          submit: false,
          msg: err.response ? err.response.data.msg : "Something went wrong",
        });
      });
    resetNotification();
  };

  const getTrainingBatches = () => {
    BatchManagementServices.GetAllBatch(localStorage.getItem("token"))
      .then((res) => {
        const batches = res.data.data;
        const tempBatches = [];
        for (let i = 0; i < batches.length; i++) {
          const batch = batches[i];
          if (batch.status) {
            const temp = {
              label: batch.name,
              value: batch.name,
              id: batch._id,
            };
            tempBatches.push(temp);
          }
        }
        setAllBatches([...tempBatches]);
      })
      .catch((err) => {
        setSubmitData({
          submit: false,
          msg: err.response ? err.response.data.message : "Error getting batches",
        });
      });
    resetNotification();
  };

  const DateFormat = (dateValue) => {
    let date = new Date(dateValue);
    return `${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}/${date.getMonth() + 1 > 9 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`
      }/${date.getFullYear()}`;
  };
  function arrayMove(array, from, to) {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }
  const SortableMultiValue = SortableElement((props) => {
    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };
    const innerProps = { onMouseDown };
    return <components.MultiValue {...props} innerProps={innerProps} />;
  });
  const SortableSelect = SortableContainer(Select);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selectedBatches, oldIndex, newIndex);
    setSelectedBatches(newValue);
  };
  const handleSelectChange = (selectedOptions, e) => {
    setSelectedBatches(selectedOptions);
  };

  const filterByActiveParentBatch = (isActive) => {
    setIsActiveParentBatch(isActive);
    let batch = parentBatchWithoutFilter.filter(item => { return item.status == isActive });
    setParentBatches(batch);
  }

  return (
    <div className="container mt-5">
      {submitData.msg && (
        <div
          className={`alert ${submitData.submit ? "alert-success" : "alert-danger"
            }`}
          role="alert"
        >
          {submitData.msg}
        </div>
      )}

      <div className="d-flex">
        <div className="me-auto">
          <h2 className="h4">Parent Batches</h2>
        </div>
        <div className="">
          <div className="form-check form-switch">
            <input
              className="form-check-input me-2"
              type="checkbox"
              id="disableSwitch"
              checked={isActiveParentBatch}
              onChange={(e) =>
                filterByActiveParentBatch(!isActiveParentBatch)
              }
            />Active Parent Batch
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col col-3 mt-4">
          <form className="">
            <div className="mb-3">
              <label for="exampleInputName" className="form-label">
                Name
              </label>
              <input
                type="text"
                className="form-control"
                aria-describedby="parentBatchHelp"
                value={postData.name}
                onChange={(e) =>
                  setPostData({ ...postData, name: e.target.value })
                }
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="exampleInputStartDate">Start Date</label>
              <input
                value={postData.startDate}
                onChange={(e) => {
                  setPostData({ ...postData, startDate: e.target.value });
                }}
                type="date"
                className="form-control "
                placeholder="Start date"
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="exampleInputDays">Days</label>
              <input
                value={postData.days}
                onChange={(e) => {
                  setPostData({ ...postData, days: e.target.value });
                }}
                type="number"
                className="form-control"
                placeholder="Days"
              />
            </div>
            <div className="form-group mb-3">
              <label htmlFor="exampleInputPassword1">Select Batches</label>
              <SortableSelect
                axis="xy"
                onSortEnd={onSortEnd}
                distance={4}
                getHelperDimensions={({ node }) => node.getBoundingClientRect()}
                isMulti
                options={allBatches}
                value={selectedBatches}
                onChange={handleSelectChange}
                components={{
                  MultiValue: SortableMultiValue,
                }}
                closeMenuOnSelect={false}
              />
            </div>
            {operation.op == 'Update' && <div className="form-check form-switch my-3">
              <input
                className="form-check-input"
                type="checkbox"
                id="disableSwitch"
                checked={postData.status}
                onClick={(e) =>
                  setPostData({ ...postData, status: !postData.status })
                }
              /> <label>Active</label>
            </div>}
            <button
              type="button"
              className={`btn ${operation.class} me-2`}
              onClick={handleParentBatchSubmit}
            >
              {operation.op}
            </button>
            <button
              className="btn btn-secondary"
              type="button"
              onClick={() => {
                setPostData({
                  name: "",
                  startDate: "",
                  days: 0,
                  trainingbatches: [],
                  _id: "",
                });
                setOperation({ op: "Create", class: "btn-primary" });
                setSelectedBatches([]);
              }}
            >
              Cancel
            </button>
          </form>
        </div>
        <div className="col col-9 mt-5">
          {parentBatches.length > 0 ? (
            <table className="table">
              <thead>
                <tr>
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Start Date</th>
                  <th scope="col">End Date</th>
                  <th scope="col">Handle</th>
                </tr>
              </thead>
              <tbody>
                {parentBatches.length > 0 &&
                  parentBatches.map((item, index) => {
                    return (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td>{item.name}</td>
                        <td>{DateFormat(item.startDate)}</td>
                        <td>{DateFormat(item.endDate)}</td>
                        <td>
                          <div className="d-flex">
                            <div>
                              <button
                                className="btn btn-warning px-2 py-1 mx-1 rounded-circle"
                                onClick={() => {
                                  setPostData({
                                    ...postData,
                                    name: item.name,
                                    startDate: item.startDate.split("T")[0],
                                    days: Math.ceil(
                                      Math.abs(
                                        new Date(item.endDate) -
                                        new Date(item.startDate)
                                      ) /
                                      (1000 * 60 * 60 * 24)
                                    ),
                                    trainingbatches: [],
                                    _id: item._id,
                                    status: item.status
                                  });
                                  const temp = [...item.trainingbatches];
                                  const batches = temp.map((i) => {
                                    return {
                                      id: i._id,
                                      label: i.name,
                                      value: i.name,
                                    };
                                  });
                                  setSelectedBatches(batches);
                                  setOperation({
                                    op: "Update",
                                    class: "btn-warning",
                                  });
                                }}
                              >
                                <FaPen className="color-white text-white" />
                              </button>
                            </div>
                            <div>
                              <button
                                className="btn btn-danger px-2 py-1 mx-1 rounded-circle"
                                onClick={() => {
                                  setPostData({
                                    ...postData,
                                    name: item.name,
                                    startDate: item.startDate.split("T")[0],
                                    days: Math.ceil(
                                      Math.abs(
                                        new Date(item.endDate) -
                                        new Date(item.startDate)
                                      ) /
                                      (1000 * 60 * 60 * 24)
                                    ),
                                    trainingbatches: [],
                                    _id: item._id,
                                    status: item.status
                                  });
                                  const temp = [...item.trainingbatches];
                                  const batches = temp.map((i) => {
                                    return {
                                      id: i._id,
                                      label: i.name,
                                      value: i.name,
                                    };
                                  });
                                  setSelectedBatches(batches);
                                  setOperation({
                                    op: "Delete",
                                    class: "btn-danger",
                                  });
                                }}
                              >
                                <RiDeleteBin3Line />
                              </button>
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          ) :
            (<p className="p-5 text-center">No Parent Batches Found ...</p>)}
        </div>
      </div>
    </div>
  );
}
