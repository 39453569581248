import React, { useEffect, useState } from "react";
import CourseService from "../../services/CourseService";
import DiscussionCard from "../../components/cards/DiscussionCard";
import jwt_decode from "jwt-decode";
import { useNavigate, useParams } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
import formloader from "../../images/formloading.svg";
import $ from "jquery";

export default function Ppts(props) {
  const cid = props.cid;
  const { cname } = useParams();
  const navigate = useNavigate();

  const [disc, setDisc] = useState([]);
  const [newdisc, setNewdisc] = useState({
    sname: "",
    cid: "",
    message: "",
    review: "pending",
  });
  const [quebox, setQuebox] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const [colors, setColors] = useState({});
  const [loading, setLoading] = useState(false);
  const [sidelg, setSideLG] = useState(true);
  const [sidesm, setSideSM] = useState(false);

  useEffect(() => {
    $(window).scrollTop(0);
  }, [window.location.href]);

  $(document).ready(function () {
    $("a.scrollLink").click(function () {
      setTimeout(function () {
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          0.0001
        );
      }, 0);
    });
    var headerHeight = $("header").height();
    var bannerHeight = $(".tutorial-banner").height();
    var footerHeight = $("footer").height();
    var totalHeight = headerHeight + bannerHeight + footerHeight;
    if ($(window).width() > 1025) {
      $(".sidebar").css("height", "calc(99vh - " + totalHeight + "px - 90px");
      $(".video-tutorial").css(
        "height",
        "calc(99vh - " + totalHeight + "px - 50px"
      );
      sidebarlg();
      setSideSM(false);
    } else {
      sidebarsm();
      setSideLG(false);
    }
    $(window).scroll(function (e) {
      topbarSticky();
      sideBarSticky();
    });
    $(window).resize(function (e) {
      if ($(window).width() > 1025) {
        $(".sidebar").css("height", "calc(99vh - " + totalHeight + "px - 90px");
        $(".video-tutorial").css(
          "height",
          "calc(99vh - " + totalHeight + "px - 50px"
        );
        $(".sidebar-wrapper").css({
          top: $("header").height() + $(".tutorial-banner").height() + 20,
        });
        $(".sidebar-wrapper").css(
          "height",
          "calc(99vh - " + totalHeight + "px - 90px"
        );

        sidebarlg();
        setSideSM(false);
      } else {
        sidebarsm();
        setSideLG(false);
      }
      $(window).scroll(function (e) {
        topbarSticky();
        sideBarSticky();
      });
    });
    $(".sidebar-wrapper").css({
      top: $("header").height() + $(".tutorial-banner").height() + 50,
    });
  });

  const hidebar = () => {
    $(document).ready(function () {
      if ($(window).width() < 1025) {
        setSideSM(false);
        $(".sidebar-wrapper").addClass("collapse-sidebar");
        $("body").removeClass("overflow-hidden");
        $(".sidebar-bg").addClass("d-none");
      }
    });
  };
  function sideBarSticky() {
    var headerHeight = $("header").height();
    var bannerHeight = $(".tutorial-banner").height();
    var footerHeight = $("footer").height();
    var totalHeight = headerHeight + bannerHeight + footerHeight;

    if ($(window).scrollTop() > 0) {
      $(".sidebar-wrapper").css({
        top: $("header").height() + $(".tutorial-banner").height() + 50,
      });
    } else {
      $(".sidebar-wrapper").css({
        top: $("header").height() + $(".tutorial-banner").height() + 50,
      });
    }
  }
  function topbarSticky() {
    var $el = $(".tutorial-banner");
    if ($(window).scrollTop() > 50) {
      $("header").css({ height: "0px", overflow: "hidden" });
      $el.css({ top: "0px" });
      $el.addClass("position-sticky");
    } else {
      $el.removeClass("position-sticky");
      $("header").css({ height: "", overflow: "" });
    }
  }
  function sidebarsm() {
    $(".smsidebar").css("height", "99vh");
    $(".sidebar-wrapper").addClass("collapse-sidebar");
    $(".sidebar-wrapper").removeClass("col-lg-3 col-12  pt-4");
    $(".toggle-sidebar").click(function (e) {
      setSideSM(true);
      $(".sidebar-wrapper").removeClass("collapse-sidebar");
      $("body").addClass("overflow-hidden");
      $(".sidebar-bg").removeClass("d-none");
    });
    $(".close-sidebar").click(function (e) {
      setSideSM(false);
      $(".sidebar-wrapper").addClass("collapse-sidebar");
      $("body").removeClass("overflow-hidden");
      $(".sidebar-bg").addClass("d-none");
    });
  }
  function sidebarlg() {
    $(".collapse-btn").click(function () {
      setSideLG(false);
      $(".sidebar-wrapper").addClass("collapse-sidebar");
      $(".sidebar-wrapper").removeClass("col-lg-3 col-12  pt-4");
      $(".tab-contents").addClass("mx-auto");
    });
    $(".slider-right-btn").click(function () {
      setSideLG(true);
      $(".sidebar-wrapper").removeClass("collapse-sidebar");
      $(".sidebar-wrapper").addClass("col-lg-3 col-12  pt-4");
      $(".tab-contents").removeClass("mx-auto");
    });
  }

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwt_decode(token);
      setNewdisc({ sname: decoded.username, cid: cid });
      CourseService.getDescussions(cname, token)
        .then((res) => {
          if (res.data.length > 0) {
            setDisc(res.data);
            let tdiscs = res.data;
            let counter = 1;
            let tcolors = {};
            tdiscs.map((item) => {
              if (counter >= 7) {
                counter = 1;
              }
              const tmpsname = item.sname.replace(/ /g, "");
              if (!tcolors[tmpsname]) {
                tcolors[tmpsname] = "clr" + counter;
                counter++;
              }
              item.replies.map((subitem) => {
                const tmpsubname = subitem.sname.replace(/ /g, "");
                if (counter >= 7) {
                  counter = 1;
                }
                if (!tcolors[tmpsubname]) {
                  tcolors[tmpsubname] = "clr" + counter;
                  counter++;
                }
                return null;
              });
              return null;
            });
            setColors(tcolors);
          }
          setLoading(false);
        })
        .catch((ex) => console.log(ex));
    } else {
      navigate("/login");
    }
  }, [confirm]);

  const handleAskQuestion = (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token')
    if (newdisc.cid && newdisc.sname && newdisc.message) {
      CourseService.postDescussion(newdisc, token)
        .then((res) => {
          if (res.data) {
            setConfirm((d) => !d);
            setQuebox(false);
          }
        })
        .catch((ex) => console.log(ex));
    }
  };

  return (
    <div class="row">
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">Loading...</div>
          </div>
        </div>
      )}
      <div class="col-12 col-xl-3 col-lg-4 sidebar-wrapper">
        <div class="sidebar px-4 pb-4 sidebar-module">
          {sidelg == true && (
            <div class="accordion-content course-detail-accordion ">
              <p className="">Discussions</p>
            </div>
          )}
        </div>

        {sidesm == true && (
          <div class="col-12 col-xl-3 col-lg-4 sidebar-wrapper">
            <div class="accordion-content course-detail-accordion">
              Discussions
            </div>
          </div>
        )}
      </div>
      <div className="col-12 col-xl-9 col-lg-8 mb-lg-4 tab-contents sidebar-course-content pt-1">
        <section class="text-dark-gray px-lg-0 px-xl-4 pt-4 pb-0">
          <div className="dataframe bg-white p-3">
            <div className="py-2 px-1">
              {quebox ? (
                <div>
                  <h5>Ask Questions</h5>
                  <form onSubmit={handleAskQuestion}>
                    <div className="mb-3">
                      <textarea
                        name="message"
                        rows="2"
                        className="form-control"
                        value={newdisc.message}
                        placeholder="Write something here..."
                        onChange={(e) => {
                          setNewdisc((d) => ({
                            ...d,
                            message: e.target.value,
                          }));
                        }}
                        required
                      ></textarea>
                    </div>
                    <div className="d-flex justify-content-end py-2 mb-3">
                      <button
                        className="btn mx-1"
                        onClick={() => {
                          setNewdisc({ message: "" });
                          setQuebox(false);
                        }}
                      >
                        Cancel
                      </button>
                      <button type="submit" className="btn btn-blue mx-1">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              ) : null}
              <div className="d-md-flex justify-content-between align-items-center p-0 m-0">
                <h5 className="h5">All Discussions</h5>
                {!quebox && (
                  <button
                    className="btn btn-blue fs-6"
                    onClick={() => {
                      setQuebox(true);
                    }}
                  >
                    <FaPlus className="mb-1 me-1" /> Ask Question
                  </button>
                )}
              </div>
              <div className="row m-0 pt-3">
                {disc.map((item, index) => {
                  return (
                    <DiscussionCard
                      item={item}
                      myname={newdisc.sname}
                      setConfirm={setConfirm}
                      review="pending"
                      colors={colors}
                      key={index}
                      time={item.time}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
