import React, { useState, useEffect } from "react";
import LoginService from "../../services/LoginService";
import jwt_decode from "jwt-decode";
import { useNavigate } from "react-router-dom";

export default function Home(props) {
  const [courses, setCourses] = useState([]);
  const navigate = useNavigate();
  const [page, setPage] = useState({ selected: 1, cname: "" });
  const [invalidmsg, setInvalidmsg] = useState("");
  const [confirmBox, setConfirmBox] = useState(false);
  const [hostId, setHostId] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded = jwt_decode(token);
      LoginService.getSingleUser(decoded.id, token)
        .then((res) => {
          if (res.data) {
            setHostId(res.data.hostId);
          }
        })
        .catch((ex) => { });
    } else {
      navigate("/login");
    }
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      const decoded = jwt_decode(token);
      if (decoded.id && token) {
        LoginService.putHostIdByUser(hostId, decoded.id, token)
          .then((res) => {
            setInvalidmsg("User Data successfully updated");
            setConfirmBox(true);
          })
          .catch((ex) => {
            // setInvalidmsg("HostID not Added");
            // setConfirmBox(true);
          });
      }
    }
    {
      setTimeout(() => setConfirmBox(false), 3000);
    }
  };
  return (
    <>
      <section className="container login my-profile">
        <div className="row m-0 bg-white">
          <div className="col-lg-6 py-lg-5 px-lg-5 py-3 formcard">
            <h1 className="text-dark-gray heading">Edit Profile</h1>
            <form onSubmit={handleSubmit} className="py-4" method="post">
              {confirmBox && (
                <div className="formloade">
                  <div className="row text-center">
                    <div className="col-6 w-100 confirmbox">
                      <div
                        className={`alert ${invalidmsg !== "" ? "alert-success" : null
                          }`}
                      >
                        {invalidmsg}
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="mb-3">
                <label htmlFor="uname" className="form-label color-dback">
                  Username
                </label>
                <input
                  type="text"
                  name="uname"
                  id="uname"
                  placeholder="Enter Username"
                  className="form-control"
                  value={jwt_decode(localStorage.getItem("token")).username}
                  required
                  disabled
                />
              </div>
              <div className="mb-3">
                <label htmlFor="uname" className="form-label color-dback">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="uemail"
                  placeholder="Enter Username"
                  className="form-control"
                  value={jwt_decode(localStorage.getItem("token")).email}
                  required
                  disabled
                />
              </div>
              {/* <div className="mb-3">
                <label htmlFor="uname" className="form-label color-dback">
                  HostID
                </label>
                <input
                  type="text"
                  name="sid"
                  id="sid"
                  placeholder="Enter Machine ID"
                  className="form-control"
                  value={hostId}
                  onChange={(e) => {
                    setHostId(e.target.value);
                  }}
                  required
                />
              </div> */}
              <div className="footer pt-lg-4 pt-3">
                <button type="submit" className="btn btn-blue">
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
