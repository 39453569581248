import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CourseService from "../../services/CourseService";
import Chart from "../forms/Chart";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";
import LoginService from "../../services/LoginService";

const ReviewChart = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [cnames, setCnames] = useState([]);
  const [selectCourseName, setSelectCourseName] = useState("");

  var y = 0;
  var label = "";
  const { id } = useParams();
  const [allcategory, setallcategory] = useState([]);
  const [someusers, setsomeUsers] = useState([]);
  const [pageno, setPageno] = useState(1);
  const [user, setUser] = useState([]);
  const [subcoursename, setSubCoursename] = useState("Select SubCourses");
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (id === undefined) {
      return;
    }
    setSubCoursename(`Select ${state} SubCourses`);
    setSelectCourseName(state);
    CourseService.getCourses()
      .then((res) => {
        if (res.data) {
          setCnames(res.data);
        }
      })
      .catch((ex) => console.log(ex));
    LoginService.getUsers(token)
      .then((res) => {
        if (res.data) {
          setUser(res.data);
          setsomeUsers(res.data);
          setPageno(1);
        }
      })
      .catch((ex) => console.log(ex));
    CourseService.getSinglesubcourse(id).then((res) => {
      if (res.data) {
        setallcategory(res.data);
      }
    });
  }, []);

  const handlecount = (count) => {
    var users = [];
    cnames.map((item) => {
      user.map((item1) => {
        item1.courses.map(async (subscribe) => {
          if (state == subscribe.name) {
            users.push(item1);
          }
        });
      });
    });

    setsomeUsers(
      users.map((item, index) => {
        if (index >= count * 5 - 5 && index < count * 5) {
          return item;
        }
      })
    );
    setPageno(count);
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li
              className={"breadcrumb-item text-primary"}
              onClick={() => {
                navigate("/admin/user/manage-user-analysis");
              }}
              style={{ cursor: "pointer" }}
            >
              Courses
            </li>
            <li className={"breadcrumb-item active"}>{state}</li>
          </ol>
        </nav>
      </div>

      <div className="container p-2 px-3">
        <div className="form px-lg-5 pt-3">
          {allcategory[0] !== undefined && (
            <div className="dropdown">
              <button
                class="btn btn-secondary dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {subcoursename}
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                {allcategory.map((cat, index) => {
                  return (
                    <li>
                      <a
                        className="dropdown-item"
                        id="subcourse"
                        href="javascript:void(0)"
                        onClick={(e) => {
                          setSubCoursename(cat.name);
                          setSelectCourseName(cat.name);
                        }}
                      >
                        {cat.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {allcategory.map((item, index) => {
            var count = 0;
            var usercount = 0;
            var someusercount = 0;
            return (
              <>
                {user.map((element, index) => {
                  if (element.courses) {
                    return element.courses.map((subscribe) => {
                      if (
                        // subscribe.Subscribe === true &&
                        subscribe._id === item._id
                      ) {
                        count++;
                        return <div></div>;
                      }
                      return null;
                    });
                  }
                  return null;
                })}

                {selectCourseName === item.name && (
                  <div className="row g-4 listcourses py-2">
                    <div className="d-none">
                      {(y = (count * 100) / user.length)}
                      {(label = item.name)}
                    </div>
                    <Chart y={Math.round(y)} label={label} />

                    {y !== 0 && (
                      <table
                        className="table caption-top border"
                        style={{ width: "50%", margin: "auto" }}
                      >
                        <caption className="fw-bold text-dark text-center fs-5">
                          List of subscribe users
                        </caption>
                        <thead
                        // className={page.selected !== 1 ? "d-none" : ""}
                        >
                          <tr>
                            <th scope="col">#</th>
                            <th scope="col">Name</th>
                            {/* <th scope="col">Type</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {someusers &&
                            someusers.map((element, index) => {
                              if (element && element.courses) {
                                return element.courses.map(
                                  (subscribe, index1) => {
                                    if (
                                      subscribe.name === selectCourseName &&
                                      someusercount < 5
                                    ) {
                                      someusercount++;
                                      return (
                                        <tr>
                                          {/* <th>{(pageno + 1)%2 == 0 ?(pageno * (pageno + 1)) + someusercount -2 : (pageno * (pageno + 1)) + someusercount -1}</th> */}
                                          <th>
                                            {5 * (pageno - 1) + someusercount}
                                          </th>
                                          <th>{element.username}</th>
                                          {/* <th>subscribe</th> */}
                                        </tr>
                                      );
                                    }
                                    return null;
                                  }
                                );
                              }
                              return null;
                            })}
                        </tbody>
                      </table>
                    )}
                    <div className="col-lg-12 d-flex justify-content-center">
                      <nav className="mt-2 p-0 pagefooter">
                        <ul className="pagination row g-1" id="pageno">
                          <li className="page-item col-auto">
                            <button
                              className="btn page-link px-3 py-2 shadow-none"
                              disabled={pageno === 1 ? true : false}
                              onClick={() => {
                                handlecount(pageno - 1);
                              }}
                            >
                              <FaChevronLeft className="text-muted" />
                            </button>
                          </li>
                          {(() => {
                            const element = [];

                            for (
                              let index = 1;
                              index <= Math.ceil(count / 5);
                              index++
                            ) {
                              element.push(
                                <li className="page-item col-auto">
                                  <button
                                    className={
                                      pageno === index
                                        ? "page-link rounded-pill px-3 py-2 active bg-primary text-white shadow-none"
                                        : "page-link rounded-pill border border-primary px-3 py-2 "
                                    }
                                    onClick={() => {
                                      handlecount(index);
                                    }}
                                  >
                                    {index}
                                  </button>
                                </li>
                              );
                            }
                            return element;
                          })()}
                          <li className="page-item col-auto">
                            <button
                              className="btn page-link px-3 py-2 shadow-none"
                              disabled={
                                pageno === Math.ceil(count / 5) ? true : false
                              }
                              onClick={() => {
                                handlecount(pageno + 1);
                              }}
                            >
                              <FaChevronRight className="text-muted" />
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                )}
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default ReviewChart;
