export const NumberModel = {
    controls: [
        {
            type: "number",
            name: "start",
            required: true,
        },
        {
            type: "number",
            name: "end",
            required: true,
        },
    ]
}