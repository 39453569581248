export const FormValidationModel = {
    controls: [{
        type: "boolean",
        name: "clearDefaultFormInputs",
        required: true
    },
    {
        type: "text",
        name: "submitButtonSelector",
        required: true
    },
    {
        type: "number",
        name: "count",
        required: true
    },
    {
        type: "dropdown",
        name: "preFormFillActions",
        required: false,
        sourceName: 'actionSource'
    },
    {
        type: "dropdown",
        name: "preSubmitActions",
        required: false,
        sourceName: 'actionSource'
    },
    {
        type: "dropdown",
        name: "postFormFillActions",
        required: false,
        sourceName: 'actionSource'
    },
    ]
}