export const FilterModel = {
    controls: [{
        type: "text",
        name: "outputElementSelector",
        required: true
    },
    {
        type: "dropdown",
        name: "actions",
        required: true,
        sourceName: 'actionSource'
    }
    ]
}