export const LoopModel = {
    controls: [{
        type: "text",
        name: "type",
        required: true
    },
    {
        type: "number",
        name: "start",
        required: true
    },
    {
        type: "number",
        name: "end",
        required: true
    },
    {
        type: "dropdown",
        name: "actions",
        required: true,
        sourceName: 'actionSource'
    }
    ]
}