import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import LoginService from "../../services/LoginService";
import { FaPen, FaTimes } from "react-icons/fa";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

export default function Leave(props) {
  const navigate = useNavigate();
  const [days, setDays] = useState([]);
  const [userData, setUserData] = useState({});
  const [FromDateDayName, setFromDateDayName] = useState("");
  const [ToDateDayName, setToDateDayName] = useState("");
  const [totalLeavesNumber, setTotalLeavesNumber] = useState(0);
  const [leaveSandwish, setLeaveSandwish] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [leaveType, setLeaveType] = useState([]);
  const [alertMsg, setAlertMsg] = useState({ status: false, msg: "" });
  const [leaveId, setLeaveId] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [comments, setComments] = useState("");
  const weekday = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const month = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const [applyLeaveData, setApplyLeaveData] = useState({
    EmpCode: "",
    FromDate: "",
    ToDate: "",
    LeaveTypeID: "",
    FromDateHalfID: "",
    ToDateHalfID: "",
    Reason: "",
    ContactInformation: "",
  });

  const css = `
    .not-allowed {cursor: not-allowed;}
   label {
     color:"white !important",
     padding:"5px !important"
   }
`;
  var token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      const userId = jwt_decode(localStorage.getItem("token")).id;
      LoginService.getSingleUserById(userId, token)
        .then((res) => {
          if (res.data) {
            setApplyLeaveData({
              ...applyLeaveData,
              EmpCode: parseInt(res.data.empId),
            });
          }
        })
        .catch((err) => console.log(err));
    }
  }, [confirmBox]);

  useEffect(() => {
    if (applyLeaveData.EmpCode !== "") {
      LoginService.getUserLeaveBalance(applyLeaveData.EmpCode, token)
        .then((res) => {
          setUserData(res.data.data);
          setDays(res.data.data.LeaveHalfs);
          setLeaveType(res.data.data.LeaveTypes);
          setApplyLeaveData({
            ...applyLeaveData,
            FromDateHalfID: res.data.data.FromDateHalfID,
            ToDateHalfID: res.data.data.ToDateHalfID,
            ContactInformation: res.data.data.ContactInformation,
          });
        })
        .catch((err) => console.log(err));
    }
  }, [applyLeaveData.EmpCode, confirmBox]);

  const handleDayReatedLeavesCount = (puredays) => {
    if (
      applyLeaveData.FromDateHalfID === 9512 &&
      applyLeaveData.ToDateHalfID === 9512
    ) {
      setTotalLeavesNumber(puredays + 1);
    } else {
      if (applyLeaveData.FromDateHalfID === 9511) {
        const TotalDays = puredays + 1 - 0.5;
        if (applyLeaveData.ToDateHalfID === 9510) {
          const finalTotalDays = TotalDays - 0.5;
          setTotalLeavesNumber(finalTotalDays);
        } else {
          setTotalLeavesNumber(TotalDays);
        }
      } else {
        const TotalDays = puredays + 1 - 0.5;
        if (applyLeaveData.ToDateHalfID === 9511) {
          const finalTotalDays = TotalDays - 0.5;
          setTotalLeavesNumber(finalTotalDays);
        } else {
          setTotalLeavesNumber(TotalDays);
        }
      }
    }
  };
  const handleFromDateAndToDateValidation = (
    FromDateValue,
    ToDateValue,
    LeaveValue
  ) => {
    const LeaveID = parseInt(LeaveValue);
    if (FromDateValue !== "" && ToDateValue !== "") {
      const FromDate = new Date(FromDateValue);
      const FromDateDayNumber = FromDate.getDay();
      const ToDate = new Date(ToDateValue);
      const ToDateDayNumber = ToDate.getDay();
      if (FromDate.getTime() === ToDate.getTime()) {
        const dayName = weekday[FromDateDayNumber];
        if (dayName === "Sun") {
          setTotalLeavesNumber(0);
          setConfirmBox(true);
          setAlertMsg({
            status: false,
            msg: "Leave apply From or To Date should not be Non Working days.",
          });
        } else {
          setConfirmBox(false);
          if (LeaveID === 6812 || LeaveID === 6809) {
            setApplyLeaveData({
              ...applyLeaveData,
              LeaveTypeID: LeaveID,
              FromDate: FromDateValue,
              ToDate: ToDateValue,
            });
            setFromDateDayName(dayName);
            setToDateDayName(dayName);
            setTotalLeavesNumber(1.0);
            setLeaveSandwish(false);
            setAlertMsg({ status: false, msg: "" });
          } else {
            if (LeaveID === 6811 || LeaveID === 6814 || LeaveID === 6813) {
              const dayValue = applyLeaveData.FromDateHalfID;
              setApplyLeaveData({
                ...applyLeaveData,
                LeaveTypeID: LeaveID,
                FromDate: FromDateValue,
                ToDate: ToDateValue,
                FromDateHalfID: dayValue,
                ToDateHalfID: dayValue,
              });
            }
            if (applyLeaveData.FromDateHalfID === 9512) {
              setTotalLeavesNumber(1.0);
              setLeaveSandwish(false);
            } else {
              setTotalLeavesNumber(0.5);
              setLeaveSandwish(false);
            }
          }
        }
      } else {
        if (FromDate < ToDate) {
          setConfirmBox(false);
          const fromDayName = weekday[FromDateDayNumber];
          const toDayName = weekday[ToDateDayNumber];
          setApplyLeaveData({
            ...applyLeaveData,
            LeaveTypeID: LeaveID,
            FromDate: FromDateValue,
            ToDate: ToDateValue,
          });
          if (fromDayName === "Sun" || toDayName === "Sun") {
            setTotalLeavesNumber(0);
            setConfirmBox(true);
            setAlertMsg({
              status: false,
              msg: "Leave apply From or To Date should not be Non Working days.",
            });
          } else {
            setConfirmBox(false);
            var time_difference = ToDate.getTime() - FromDate.getTime();
            //calculate days difference by dividing total milliseconds in a day
            var days_difference = time_difference / (1000 * 60 * 60 * 24);
            const totalDay = days_difference + 1;
            if (LeaveID === 6809 || LeaveID === 6814) {
              if (totalDay > 5) {
                if (LeaveID === 6809) {
                  setTotalLeavesNumber(totalDay);
                } else {
                  handleDayReatedLeavesCount(days_difference);
                }
                setLeaveSandwish(true);
              } else {
                function isWeekend(date1, date2) {
                  let isWeekend = false;

                  while (date1 < date2) {
                    var day = date1.getDay();
                    isWeekend = day === 0;
                    if (isWeekend) {
                      return true;
                    } // return immediately if weekend found
                    date1.setDate(date1.getDate() + 1);
                  }
                  return false;
                }
                const date = isWeekend(FromDate, ToDate);
                setLeaveSandwish(date);
                if (LeaveID === 6809) {
                  setTotalLeavesNumber(totalDay);
                } else {
                  handleDayReatedLeavesCount(days_difference);
                }
              }
            } else {
              function getSundays(startDate, endDate) {
                var totalWeekends = 0;
                for (
                  var i = startDate;
                  i <= endDate;
                  i.setDate(i.getDate() + 1)
                ) {
                  var dt = new Date(i);
                  if (dt.getDay() === 0) totalWeekends++;
                }
                return totalWeekends;
              }
              var sundayCount = getSundays(FromDate, ToDate);
              var puredays = Math.round(days_difference) - sundayCount;
              handleDayReatedLeavesCount(puredays);
            }
          }
        } else {
          setApplyLeaveData({
            ...applyLeaveData,
            LeaveTypeID: LeaveID,
            FromDate: FromDateValue,
            ToDate: ToDateValue,
          });
          setConfirmBox(true);
          setAlertMsg({
            status: false,
            msg: "From Date can't be greater than To Date",
          });
          setTotalLeavesNumber(0);
        }
      }
    } else {
      setTotalLeavesNumber(0);
    }
  };

  const handleLeaveType = (e) => {
    const leaveValue = parseInt(e.target.value);
    if (leaveValue === 6813) {
      setApplyLeaveData({
        ...applyLeaveData,
        LeaveTypeID: leaveValue,
        ToDate: applyLeaveData.FromDate,
      });
      handleFromDateAndToDateValidation(
        applyLeaveData.FromDate,
        applyLeaveData.FromDate,
        6813
      );
    } else if (leaveValue === 6809) {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDateHalfID: 9512,
        ToDateHalfID: 9512,
        LeaveTypeID: leaveValue,
      });
      handleFromDateAndToDateValidation(
        applyLeaveData.FromDate,
        applyLeaveData.ToDate,
        6809
      );
    } else if (leaveValue === 6812) {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDateHalfID: 9512,
        ToDateHalfID: 9512,
        LeaveTypeID: leaveValue,
        ToDate: applyLeaveData.FromDate,
      });
      handleFromDateAndToDateValidation(
        applyLeaveData.FromDate,
        applyLeaveData.FromDate,
        6812
      );
    } else if (leaveValue === 6811) {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDateHalfID: 9512,
        ToDateHalfID: 9512,
        LeaveTypeID: leaveValue,
      });
      handleFromDateAndToDateValidation(
        applyLeaveData.FromDate,
        applyLeaveData.ToDate,
        6811
      );
    } else {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDateHalfID: 9512,
        ToDateHalfID: 9512,
        LeaveTypeID: leaveValue,
      });
      handleFromDateAndToDateValidation(
        applyLeaveData.FromDate,
        applyLeaveData.ToDate,
        6814
      );
    }
  };

  const handleFromDate = (fromDate, toDate) => {
    if (applyLeaveData.LeaveTypeID === 6813) {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDate: fromDate,
        ToDate: fromDate,
      });
      const date = new Date(fromDate);
      let dayName = weekday[date.getDay()];
      setFromDateDayName(dayName);
      setToDateDayName(dayName);
      if (dayName === "Sun") {
        setConfirmBox(true);
        setAlertMsg({
          status: false,
          msg: "Leave apply From or To Date should not be Non Working days.",
        });
        setTotalLeavesNumber(0);
      } else {
        setConfirmBox(false);
        if (applyLeaveData.FromDateHalfID === 9512) {
          setTotalLeavesNumber(1.0);
        } else {
          setTotalLeavesNumber(0.5);
        }
      }
    } else if (applyLeaveData.LeaveTypeID === 6809) {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDate: fromDate,
      });
      const date = new Date(fromDate);
      let dayName = weekday[date.getDay()];
      setFromDateDayName(dayName);
      if (dayName === "Sun") {
        setConfirmBox(true);
        setAlertMsg({
          status: false,
          msg: "Leave apply From or To Date should not be Non Working days.",
        });
      } else {
        setConfirmBox(false);
      }
      handleFromDateAndToDateValidation(fromDate, toDate, 6809);
    } else if (applyLeaveData.LeaveTypeID === 6812) {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDateHalfID: 9512,
        ToDateHalfID: 9512,
        FromDate: fromDate,
        ToDate: fromDate,
      });
      const date = new Date(fromDate);
      let dayName = weekday[date.getDay()];
      setFromDateDayName(dayName);
      setToDateDayName(dayName);
      if (dayName === "Sun") {
        setConfirmBox(true);
        setAlertMsg({
          status: false,
          msg: "Leave apply From or To Date should not be Non Working days.",
        });
      } else {
        setConfirmBox(false);
        setTotalLeavesNumber(1);
      }
    } else if (applyLeaveData.LeaveTypeID === 6811) {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDate: fromDate,
      });
      const date = new Date(fromDate);
      let dayName = weekday[date.getDay()];
      setFromDateDayName(dayName);
      if (dayName === "Sun") {
        setConfirmBox(true);
        setAlertMsg({
          status: false,
          msg: "Leave apply From or To Date should not be Non Working days.",
        });
      } else {
        setConfirmBox(false);
      }
      handleFromDateAndToDateValidation(fromDate, toDate, 6811);
    } else {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDate: fromDate,
      });
      const date = new Date(fromDate);
      let dayName = weekday[date.getDay()];
      setFromDateDayName(dayName);
      if (dayName === "Sun") {
        setConfirmBox(true);
        setAlertMsg({
          status: false,
          msg: "Leave apply From or To Date should not be Non Working days.",
        });
      } else {
        setConfirmBox(false);
      }
      handleFromDateAndToDateValidation(fromDate, toDate, 6814);
    }
  };

  const handleToDate = (fromDate, toDate) => {
    if (applyLeaveData.LeaveTypeID === 6809) {
      const date = new Date(toDate);
      let dayName = weekday[date.getDay()];
      setToDateDayName(dayName);
      if (dayName === "Sun") {
        setConfirmBox(true);
        setAlertMsg({
          status: false,
          msg: "Leave apply From or To Date should not be Non Working days.",
        });
      } else {
        setConfirmBox(false);
      }
      handleFromDateAndToDateValidation(fromDate, toDate, 6809);
    }
    if (applyLeaveData.LeaveTypeID === 6811) {
      const date = new Date(toDate);
      let dayName = weekday[date.getDay()];
      setToDateDayName(dayName);
      if (dayName === "Sun") {
        setConfirmBox(true);
        setAlertMsg({
          status: false,
          msg: "Leave apply From or To Date should not be Non Working days.",
        });
      } else {
        setConfirmBox(false);
      }
      handleFromDateAndToDateValidation(fromDate, toDate, 6811);
    }
    if (applyLeaveData.LeaveTypeID === 6814) {
      const date = new Date(toDate);
      let dayName = weekday[date.getDay()];
      setToDateDayName(dayName);
      if (dayName === "Sun") {
        setConfirmBox(true);
        setAlertMsg({
          status: false,
          msg: "Leave apply From or To Date should not be Non Working days.",
        });
      } else {
        setConfirmBox(false);
      }
      handleFromDateAndToDateValidation(fromDate, toDate, 6814);
    }
  };

  const handleDayType = (e, dateType) => {
    const dayValue = parseInt(e.target.value);
    if (applyLeaveData.LeaveTypeID === 6813 && applyLeaveData.FromDate !== "") {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDateHalfID: dayValue,
        ToDateHalfID: dayValue,
      });
      if (dayValue === 9512) {
        setTotalLeavesNumber(1.0);
      } else {
        setTotalLeavesNumber(0.5);
      }
    } else if (applyLeaveData.LeaveTypeID === 6809) {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDateHalfID: 9512,
        ToDateHalfID: 9512,
      });
    } else if (applyLeaveData.LeaveTypeID === 6812) {
      setApplyLeaveData({
        ...applyLeaveData,
        FromDateHalfID: 9512,
        ToDateHalfID: 9512,
      });
    } else if (applyLeaveData.LeaveTypeID === 6811) {
      if (applyLeaveData.FromDate !== "" && dateType === "FromDate") {
        if (dayValue === 9510) {
          setApplyLeaveData({
            ...applyLeaveData,
            FromDateHalfID: 9512,
          });
        } else {
          setApplyLeaveData({
            ...applyLeaveData,
            FromDateHalfID: dayValue,
          });
        }
      }
      if (applyLeaveData.ToDate !== "" && dateType === "ToDate") {
        if (dayValue === 9511) {
          setApplyLeaveData({
            ...applyLeaveData,
            ToDateHalfID: 9512,
          });
        } else {
          setApplyLeaveData({
            ...applyLeaveData,
            ToDateHalfID: dayValue,
          });
        }
      }
      if (applyLeaveData.FromDate !== "" && applyLeaveData.ToDate !== "") {
        const FromDate = new Date(applyLeaveData.FromDate);
        const ToDate = new Date(applyLeaveData.ToDate);
        if (FromDate.getTime() === ToDate.getTime()) {
          if (applyLeaveData.FromDate !== "") {
            if (dateType === "FromDate") {
              setApplyLeaveData({
                ...applyLeaveData,
                FromDateHalfID: dayValue,
                ToDateHalfID: dayValue,
              });
              if (dayValue === 9512) {
                setTotalLeavesNumber(1.0);
              } else {
                setTotalLeavesNumber(0.5);
              }
            }
          }
        } else {
          if (FromDate < ToDate) {
            setConfirmBox(false);
            var time_difference = ToDate.getTime() - FromDate.getTime();
            //calculate days difference by dividing total milliseconds in a day
            var days_difference = time_difference / (1000 * 60 * 60 * 24);
            var Weeks = Math.round(days_difference) / 7;

            var totalWeekends = Math.round(Weeks) * 2;

            var puredays = Math.round(days_difference) - totalWeekends;
            const totalDay = puredays + 1;
            if (dateType === "FromDate") {
              if (dayValue === 9512) {
                const TotalDays = totalDay;
                if (applyLeaveData.ToDateHalfID === 9512) {
                  setTotalLeavesNumber(totalDay);
                } else if (applyLeaveData.ToDateHalfID === 9510) {
                  const finalDays = TotalDays - 0.5;
                  setTotalLeavesNumber(finalDays);
                } else {
                  setTotalLeavesNumber(TotalDays);
                }
              }
              if (dayValue === 9511) {
                const TotalDays = totalDay - 0.5;
                if (applyLeaveData.ToDateHalfID === 9512) {
                  setTotalLeavesNumber(TotalDays);
                } else if (applyLeaveData.ToDateHalfID === 9510) {
                  const finalDays = TotalDays - 0.5;
                  setTotalLeavesNumber(finalDays);
                } else {
                  setTotalLeavesNumber(TotalDays);
                }
              }
            } else {
              if (dayValue === 9512) {
                const TotalDays = totalDay;
                if (applyLeaveData.FromDateHalfID === 9512) {
                  setTotalLeavesNumber(TotalDays);
                } else if (applyLeaveData.FromDateHalfID === 9511) {
                  const finalDays = TotalDays - 0.5;
                  setTotalLeavesNumber(finalDays);
                } else {
                  setTotalLeavesNumber(TotalDays);
                }
              }
              if (dayValue === 9510) {
                const TotalDays = totalDay - 0.5;
                if (applyLeaveData.FromDateHalfID === 9512) {
                  setTotalLeavesNumber(TotalDays);
                } else if (applyLeaveData.FromDateHalfID === 9511) {
                  const finalDays = TotalDays - 0.5;
                  setTotalLeavesNumber(finalDays);
                } else {
                  setTotalLeavesNumber(TotalDays);
                }
              }
            }
          } else {
            setConfirmBox(true);
            setAlertMsg({
              status: false,
              msg: "From Date can't be greater than To Date",
            });
            setTotalLeavesNumber(0);
          }
        }
      } else {
        setTotalLeavesNumber(0);
      }
    } else {
      if (applyLeaveData.FromDate !== "" && dateType === "FromDate") {
        if (dayValue === 9510) {
          setApplyLeaveData({
            ...applyLeaveData,
            FromDateHalfID: 9512,
          });
        } else {
          setApplyLeaveData({
            ...applyLeaveData,
            FromDateHalfID: dayValue,
          });
        }
      }
      if (applyLeaveData.ToDate !== "" && dateType === "ToDate") {
        if (dayValue === 9511) {
          setApplyLeaveData({
            ...applyLeaveData,
            ToDateHalfID: 9512,
          });
        } else {
          setApplyLeaveData({
            ...applyLeaveData,
            ToDateHalfID: dayValue,
          });
        }
      }
      if (applyLeaveData.FromDate !== "" && applyLeaveData.ToDate !== "") {
        const FromDate = new Date(applyLeaveData.FromDate);
        const ToDate = new Date(applyLeaveData.ToDate);
        if (FromDate.getTime() === ToDate.getTime()) {
          if (applyLeaveData.FromDate !== "") {
            if (dateType === "FromDate") {
              setApplyLeaveData({
                ...applyLeaveData,
                FromDateHalfID: dayValue,
                ToDateHalfID: dayValue,
              });
              if (dayValue === 9512) {
                setTotalLeavesNumber(1.0);
              } else {
                setTotalLeavesNumber(0.5);
              }
            } else {
              setApplyLeaveData({
                ...applyLeaveData,
                ToDateHalfID: 9512,
              });
            }
          }
        } else {
          if (FromDate < ToDate) {
            setConfirmBox(false);
            var time_difference = ToDate.getTime() - FromDate.getTime();
            //calculate days difference by dividing total milliseconds in a day
            var days_difference = time_difference / (1000 * 60 * 60 * 24);
            const totalDay = days_difference + 1;
            if (dateType === "FromDate") {
              if (dayValue === 9512) {
                const TotalDays = totalDay;
                if (applyLeaveData.ToDateHalfID === 9512) {
                  setTotalLeavesNumber(totalDay);
                } else if (applyLeaveData.ToDateHalfID === 9510) {
                  const finalDays = TotalDays - 0.5;
                  setTotalLeavesNumber(finalDays);
                } else {
                  setTotalLeavesNumber(TotalDays);
                }
              }
              if (dayValue === 9511) {
                const TotalDays = totalDay - 0.5;
                if (applyLeaveData.ToDateHalfID === 9512) {
                  setTotalLeavesNumber(TotalDays);
                } else if (applyLeaveData.ToDateHalfID === 9510) {
                  const finalDays = TotalDays - 0.5;
                  setTotalLeavesNumber(finalDays);
                } else {
                  setTotalLeavesNumber(TotalDays);
                }
              }
            } else {
              if (dayValue === 9512) {
                const TotalDays = totalDay;
                if (applyLeaveData.FromDateHalfID === 9512) {
                  setTotalLeavesNumber(TotalDays);
                } else if (applyLeaveData.FromDateHalfID === 9511) {
                  const finalDays = TotalDays - 0.5;
                  setTotalLeavesNumber(finalDays);
                } else {
                  setTotalLeavesNumber(TotalDays);
                }
              }
              if (dayValue === 9510) {
                const TotalDays = totalDay - 0.5;
                if (applyLeaveData.FromDateHalfID === 9512) {
                  setTotalLeavesNumber(TotalDays);
                } else if (applyLeaveData.FromDateHalfID === 9511) {
                  const finalDays = TotalDays - 0.5;
                  setTotalLeavesNumber(finalDays);
                } else {
                  setTotalLeavesNumber(TotalDays);
                }
              }
            }
          } else {
            setConfirmBox(true);
            setAlertMsg({
              status: false,
              msg: "From Date can't be greater than To Date",
            });
            setTotalLeavesNumber(0);
          }
        }
      }
    }
  };

  let closeModal = () => {
    setOpenModal(!openModal);
    setComments("");
    setLeaveId("");
  };

  const DateFormat = (dateValue, dateType) => {
    let date = new Date(dateValue);
    if (dateType === "BalanceSheetDate") {
      return `${date.getFullYear()}-${
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`
      }-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`}`;
    } else {
      return `${date.getFullYear()}-${
        date.getMonth() + 1 > 9
          ? date.getMonth() + 1
          : `0${date.getMonth() + 1}`
      }-${date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`} ${
        date.getHours() > 9 ? date.getHours() : `0${date.getHours()}`
      }:${date.getMinutes() > 9 ? date.getMinutes() : `0${date.getMinutes()}`}`;
    }
  };

  const DateVerify = (FromDate) => {
    const todayDate = new Date();
    const userDate = new Date(FromDate);
    if (todayDate.getTime() === userDate.getTime()) {
      return true;
    } else {
      if (todayDate < userDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  const LeaveTypeColor = (leaveId) => {
    if (leaveId === 6809) {
      return "text-start text-info";
    } else if (leaveId === 6810) {
      return "text-start text-dark";
    }
    if (leaveId === 6811) {
      return "text-start text-warning";
    }
    if (leaveId === 6812) {
      return "text-start text-success";
    }
    if (leaveId === 6813) {
      return "text-start text-primary";
    }
    if (leaveId === 6814) {
      return "text-start text-danger";
    }
  };

  const handleSubmit = () => {
    if (
      applyLeaveData.FromDate &&
      applyLeaveData.ToDate &&
      applyLeaveData.LeaveTypeID &&
      applyLeaveData.Reason &&
      confirmBox === false
    ) {
      LoginService.postApplyUserLeave(applyLeaveData, token)
        .then((res) => {
          if (res.data.data.Code == "1") {
            setConfirmBox(true);
            setAlertMsg({ status: true, msg: res.data.data.Message });
            setApplyLeaveData({
              ...applyLeaveData,
              FromDate: "",
              ToDate: "",
              LeaveTypeID: "",
              Reason: "",
              FromDateHalfID: 9512,
              ToDateHalfID: 9512,
            });
            setTimeout(() => {
              setApplyLeaveData({
                ...applyLeaveData,
                FromDate: "",
                ToDate: "",
                LeaveTypeID: "",
                Reason: "",
                FromDateHalfID: 9512,
                ToDateHalfID: 9512,
              });
              setTotalLeavesNumber(0);
              setFromDateDayName("");
              setToDateDayName("");
              setConfirmBox(false);
            }, 5000);
          } else {
            setConfirmBox(true);
            setAlertMsg({ status: false, msg: res.data.data.Message });
          }
        })
        .catch((err) => console.log(err));
    } else {
      setConfirmBox(true);
      setAlertMsg({
        status: false,
        msg: `Please verify ${
          applyLeaveData.LeaveTypeID === "" && "LeaveType ,"
        } ${applyLeaveData.FromDate === "" && "FromDate ,"} ${
          applyLeaveData.ToDate === "" && "ToDate ,"
        } ${applyLeaveData.Reason === "" && "Reason ,"} filed`,
      });
    }
  };

  const handleEdit = () => {
    if (
      applyLeaveData.FromDate &&
      applyLeaveData.ToDate &&
      applyLeaveData.LeaveTypeID &&
      applyLeaveData.Reason &&
      confirmBox === false
    ) {
      LoginService.updateApplyUserLeave(leaveId, applyLeaveData, token)
        .then((res) => {
          if (res.data.data.Code === "1") {
            setConfirmBox(true);
            setAlertMsg({ status: true, msg: res.data.data.Message });
            setLeaveId("");
            setApplyLeaveData({
              ...applyLeaveData,
              FromDate: "",
              ToDate: "",
              LeaveTypeID: "",
              Reason: "",
              FromDateHalfID: 9512,
              ToDateHalfID: 9512,
            });
            setTimeout(() => {
              setApplyLeaveData({
                ...applyLeaveData,
                FromDate: "",
                ToDate: "",
                LeaveTypeID: "",
                Reason: "",
                FromDateHalfID: 9512,
                ToDateHalfID: 9512,
              });
              setTotalLeavesNumber(0);
              setFromDateDayName("");
              setToDateDayName("");
              setConfirmBox(false);
            }, 5000);
          } else {
            setConfirmBox(true);
            setAlertMsg({ status: false, msg: res.data.data.Message });
          }
        })
        .catch((err) => console.log(err));
    } else {
      setConfirmBox(true);
      setAlertMsg({
        status: false,
        msg: `Please verify ${
          applyLeaveData.LeaveTypeID === "" && "LeaveType ,"
        } ${applyLeaveData.FromDate === "" && "FromDate ,"} ${
          applyLeaveData.ToDate === "" && "ToDate ,"
        } ${applyLeaveData.Reason === "" && "Reason ,"} filed`,
      });
    }
  };

  const handleCancel = () => {
    const cancelLeaveData = {
      LeaveRequestID: leaveId,
      Comments: comments,
    };
    LoginService.deleteApplyLeave(cancelLeaveData, token).then((res) => {
      if (res.data.data) {
        setOpenModal(false);
        if (res.data.data.Code == "1") {
          setConfirmBox(true);
          setAlertMsg({ status: true, msg: res.data.data.Message });
          setTimeout(() => {
            setConfirmBox(false);
            setLeaveId("");
            setComments("");
          }, 5000);
        } else {
          setConfirmBox(true);
          setAlertMsg({ status: false, msg: res.data.data.Message });
          setTimeout(() => {
            setConfirmBox(false);
            setLeaveId("");
            setComments("");
          });
        }
      }
    });
  };
  return (
    <>
      <section className="container-fluid">
        <style>{css}</style>
        <div className="ms-2 mt-3 mt-xl-0 mb-2 fs-4">
          {userData.EmployeeName}
        </div>
        <div className="row m-0">
          <div className="col-md-7 row">
            {confirmBox && (
              <div className="formloade">
                <div className="row text-center">
                  <div className="col-6 w-100 confirmbox">
                    <div
                      className={`alert ${
                        alertMsg.status ? "alert-success" : "alert-danger"
                      }`}
                      dangerouslySetInnerHTML={{
                        __html: alertMsg.msg,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
            <div className="col-md-6 col-sm-7 row mb-2">
              <label
                for="inputPassword"
                class="col-md-4 col-sm-4 col-form-label"
              >
                Leave Type <span className="text-danger">*</span>
              </label>
              <div class="col-md-8 col-sm-8">
                <select
                  class="form-select"
                  aria-label="Default select example"
                  value={applyLeaveData.LeaveTypeID}
                  onChange={(e) => {
                    handleLeaveType(e);
                  }}
                >
                  <option value="">Select Leave Type</option>
                  {leaveType &&
                    leaveType.length > 0 &&
                    leaveType.map((item) => {
                      return (
                        <option value={item.ObjectId}>{item.ObjectName}</option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-md-6 col-sm-5 row mb-lg-2">
              <label
                for="inputPassword"
                class="col-md-4 col-sm-7 col-8 col-form-label"
              >
                No of Day(s): <span className="text-danger">*</span>
              </label>
              <div class="col-md-2 col-sm-3 col-2 pt-2">
                {totalLeavesNumber === 0 ? (
                  0
                ) : totalLeavesNumber.toString().includes(".") ? (
                  <div>
                    {totalLeavesNumber}0{" "}
                    <span style={{ backgroundColor: "pink" }}>
                      {(applyLeaveData.LeaveTypeID === 6809 &&
                        leaveSandwish === true) ||
                      (applyLeaveData.LeaveTypeID === 6814 &&
                        leaveSandwish === true)
                        ? "sandwich"
                        : ""}
                    </span>
                  </div>
                ) : (
                  <div>
                    {totalLeavesNumber}.00{" "}
                    <span
                      style={{ backgroundColor: "pink", fontWeight: "normal" }}
                    >
                      {(applyLeaveData.LeaveTypeID === 6809 &&
                        leaveSandwish === true) ||
                      (applyLeaveData.LeaveTypeID === 6814 &&
                        leaveSandwish === true)
                        ? "sandwich"
                        : ""}
                    </span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-6 col-sm-7 row mb-2">
              <label
                htmlFor="inputPassword1"
                class="col-md-4 col-sm-4 col-form-label"
              >
                From Date <span className="text-danger">*</span>
              </label>
              <div class="col-md-8 col-sm-8">
                <input
                  type="date"
                  class="form-control"
                  id="inputPassword1"
                  value={applyLeaveData.FromDate}
                  onChange={(e) => {
                    handleFromDate(e.target.value, applyLeaveData.ToDate);
                  }}
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-5 row mb-2 ">
              <div className="col-md-3 col-sm-4 my-2 my-lg-0 text-center">
                {FromDateDayName !== "" && `(${FromDateDayName})`}
              </div>
              <div className="col-md-5 col-sm-7">
                <select
                  className={
                    applyLeaveData.LeaveTypeID === 6809 ||
                    applyLeaveData.LeaveTypeID === 6812
                      ? "form-select not-allowed"
                      : "form-select"
                  }
                  aria-label="Default select example"
                  value={applyLeaveData.FromDateHalfID}
                  onChange={(e) => handleDayType(e, "FromDate")}
                  disabled={
                    applyLeaveData.LeaveTypeID === 6809 ||
                    applyLeaveData.LeaveTypeID === 6812
                      ? true
                      : false
                  }
                >
                  {days &&
                    days.length > 0 &&
                    days.map((item, index) => {
                      return (
                        <option value={item.ObjectId} key={index}>
                          {item.ObjectName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-md-6 col-sm-7 row mb-2">
              <label
                htmlFor="inputPassword2"
                class="col-md-4 col-sm-4 col-form-label"
              >
                To Date <span className="text-danger">*</span>
              </label>
              <div class="col-md-8 col-sm-8">
                <input
                  type="date"
                  id="inputPassword2"
                  className={
                    applyLeaveData.LeaveTypeID === 6813 ||
                    applyLeaveData.LeaveTypeID === 6812
                      ? "form-control not-allowed"
                      : "form-control"
                  }
                  value={applyLeaveData.ToDate}
                  onChange={(e) => {
                    handleToDate(applyLeaveData.FromDate, e.target.value);
                  }}
                  disabled={
                    applyLeaveData.LeaveTypeID === 6813 ||
                    applyLeaveData.LeaveTypeID === 6812
                      ? true
                      : false
                  }
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-5 row mb-2 ">
              <div className="col-md-3 col-sm-4 my-2 my-lg-0 text-center">
                {ToDateDayName !== "" && `(${ToDateDayName})`}
              </div>
              <div className="col-md-5 col-sm-7">
                <select
                  className={
                    applyLeaveData.LeaveTypeID === 6813 ||
                    applyLeaveData.LeaveTypeID === 6809 ||
                    applyLeaveData.LeaveTypeID === 6812
                      ? "form-select not-allowed"
                      : "form-select"
                  }
                  aria-label="Default select example"
                  value={applyLeaveData.ToDateHalfID}
                  onChange={(e) => handleDayType(e, "ToDate")}
                  disabled={
                    applyLeaveData.LeaveTypeID === 6813 ||
                    applyLeaveData.LeaveTypeID === 6809 ||
                    applyLeaveData.LeaveTypeID === 6812
                      ? true
                      : false
                  }
                >
                  {days &&
                    days.length > 0 &&
                    days.map((item, index) => {
                      return (
                        <option value={item.ObjectId} key={index}>
                          {item.ObjectName}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            <div className="col-md-12 col-sm-9 row mb-2">
              <label
                for="inputPassword"
                class="col-md-2 col-sm-3 col-form-label"
              >
                Reason <span className="text-danger">*</span>
              </label>
              <div class="col-md-8 col-sm-9">
                <textarea
                  value={applyLeaveData.Reason}
                  onChange={(e) =>
                    setApplyLeaveData({
                      ...applyLeaveData,
                      Reason: e.target.value,
                    })
                  }
                  class="form-control"
                  rows="3"
                />
              </div>
            </div>
            <div class="col-sm-3"></div>
            <div class="col-md-12 col-sm-9 row">
              <label
                for="inputPassword"
                className="col-md-2 col-sm-3 col-form-label"
              >
                Contact No <span className="text-danger">*</span>
              </label>
              <div className="col-md-8 col-sm-9">
                <input
                  type="text"
                  inputmode="numeric"
                  class="form-control not-allowed"
                  value={applyLeaveData.ContactInformation}
                  disabled={true}
                  onChange={(e) =>
                    setApplyLeaveData({
                      ...applyLeaveData,
                      ContactInformation: e.target.value,
                    })
                  }
                />
              </div>
            </div>
            <div class="mb-2 mb-lg-0">
              {applyLeaveData.FromDate !== "" &&
              applyLeaveData.ToDate !== "" &&
              applyLeaveData.LeaveTypeID !== "" &&
              applyLeaveData.Reason !== "" &&
              confirmBox === false ? (
                <button
                  type="submit"
                  className="btn btn-submit btn-success mt-3 ms-2"
                  onClick={leaveId !== "" ? handleEdit : handleSubmit}
                  // onClick={scrollToTop}
                  // style={{ display: visible ? "inline" : "none" }}
                >
                  Apply
                </button>
              ) : (
                <button
                  type="submit"
                  className="btn btn-submit btn-success mt-3 ms-2"
                  disabled
                  // onClick={scrollToTop}
                  // style={{ display: visible ? "inline" : "none" }}
                >
                  Apply
                </button>
              )}
              <button
                type="submit"
                className="btn btn-submit btn-secondary mt-3 ms-2"
                onClick={() => {
                  setApplyLeaveData({
                    ...applyLeaveData,
                    FromDate: "",
                    ToDate: "",
                    LeaveTypeID: "",
                    Reason: "",
                    FromDateHalfID: 9512,
                    ToDateHalfID: 9512,
                  });
                  setLeaveId("");
                  setTotalLeavesNumber(0);
                  setToDateDayName("");
                  setFromDateDayName("");
                  setLeaveSandwish(false);
                  setConfirmBox(false);
                }}
                //   onClick={handleSubmit}
                // onClick={scrollToTop}
                // style={{ display: visible ? "inline" : "none" }}
              >
                Reset
              </button>
            </div>
          </div>
          <div className="col-md-5 ">
            <div className="row border">
              <div className="row border-bottom py-2 ms-0 bg-blue text-white">
                <div className="col-md-8 col-sm-7">
                  Balance of Month{" "}
                  <b>
                    {`${month[userData.LeaveBalanceMonth - 1]}-${
                      userData.LeaveBalanceYear
                    }`}
                    <span
                      class="ms-2"
                      style={{ fontSize: "13px" }}
                    >{`(As of ${DateFormat(
                      userData.LeaveBalanceModifiedDate,
                      "BalanceSheetDate"
                    )})`}</span>
                  </b>
                </div>
                <div className="col-md-4 col-sm-5 text-end">
                  Comp Off Claims As of Now
                </div>
              </div>
              <div className="row py-3 fs-6">
                <div className="col-md-2 col-sm-3 fw-normal">
                  Opening Balance :
                </div>
                <div className="col-md-2 col-sm-1 text-warning">
                  PL: {userData.PL}
                </div>
                <div className="col-md-2 col-sm-1 text-primary">
                  CL: {userData.CL}
                </div>
                <div className="col-md-2 col-sm-1 text-danger">
                  SL: {userData.SL}
                </div>
                <div className="col-md-2 col-sm-1 text-success">
                  EL:{userData.EL}
                </div>
                <div className="col-md-2 col-sm-5 text-sm-end text-info">
                  CO: {userData.CO}
                </div>
              </div>
              <div className="row py-3 fs-6">
                <div className="col-md-2 col-sm-3 fw-normal">Taken Leave :</div>
                <div className="col-md-2 col-sm-1 text-warning">
                  PL: {userData.TakenPL}
                </div>
                <div className="col-md-2 col-sm-1 text-primary">
                  CL: {userData.TakenCL}
                </div>
                <div className="col-md-2 col-sm-1 text-danger">
                  SL: {userData.TakenSL}
                </div>
                <div className="col-md-2 col-sm-1 text-success">
                  EL: {userData.TakenEL}
                </div>
              </div>
              <div className="row py-3 fs-6">
                <div className="col-md-2 col-sm-3 fw-normal">Balance :</div>
                <div className="col-md-2 col-sm-1 text-warning">
                  PL: {userData.BalancePL}
                </div>
                <div className="col-md-2 col-sm-1 text-primary">
                  CL: {userData.BalanceCL}
                </div>
                <div className="col-md-2 col-sm-1 text-danger">
                  SL: {userData.BalanceSL}
                </div>
                <div className="col-md-2 col-sm-1 text-success">
                  EL: {userData.BalanceEL}
                </div>
              </div>
            </div>
            <div className="row mt-3">
              <div className="col-md-12 row">
                {totalLeavesNumber <= 1 && (
                  <>
                    <div className="col-md-3 col-sm-4 col-6">
                      Level-1 Approved By:
                    </div>
                    <div className="col-md-9 col-sm-6 col-6 fw-bold">
                      {userData.Level1ApprovedByName}
                    </div>
                  </>
                )}
              </div>
              <div className="col-md-12 row">
                {totalLeavesNumber > 1 && (
                  <>
                    <div className="col-md-3 col-sm-4 col-6">
                      Level-1 Approved By:
                    </div>
                    <div className="col-md-9 col-sm-6 col-6 fw-bold">
                      {userData.Level1ApprovedByName}
                    </div>
                    <div className="col-md-3 col-sm-4 col-6">
                      Level-2 Approved By:
                    </div>
                    <div className="col-md-9 col-sm-6 col-6 fw-bold">
                      {userData.Level2ApprovedByName}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {userData.LeaveHistoryList && userData.LeaveHistoryList.length > 0 && (
          <>
            <h4 class="mt-2 mx-2">Leave History</h4>
            <table class="table mt-2 table-striped result_table">
              <thead>
                <tr>
                  <th scope="col" class="text-start">
                    Status
                  </th>
                  <th scope="col" class="text-start">
                    Type
                  </th>
                  <th scope="col" class="text-start">
                    Days
                  </th>
                  <th scope="col" class="text-start">
                    From Date
                  </th>
                  <th scope="col" class="text-start">
                    To Date
                  </th>
                  <th scope="col" class="text-start">
                    Applied On
                  </th>
                  <th scope="col" class="text-start">
                    Applied By
                  </th>
                  <th scope="col" class="text-start">
                    Handle
                  </th>
                </tr>
              </thead>
              <tbody>
                {userData.LeaveHistoryList.map((leaves) => {
                  return (
                    <tr>
                      {leaves.LeaveStatusName === "Applied" ? (
                        <td class="text-start text-primary fw-normal">
                          {leaves.LeaveStatusName}
                        </td>
                      ) : leaves.LeaveStatusName === "Approved" ? (
                        <td class="text-start text-success fw-normal">
                          {leaves.LeaveStatusName}
                        </td>
                      ) : (
                        <td class="text-start text-danger fw-normal">
                          {leaves.LeaveStatusName}
                        </td>
                      )}
                      <td class={LeaveTypeColor(leaves.LeaveTypeID)}>
                        {leaves.LeaveTypeName}
                      </td>
                      <td class="text-start">{leaves.Days}</td>
                      <td class="text-start">{leaves.FromDateDay}</td>
                      <td class="text-start">{leaves.ToDateDay}</td>
                      <td class="text-start">{DateFormat(leaves.AppliedOn)}</td>
                      <td class="text-start">{leaves.AppliedByName}</td>
                      <td>
                        {leaves.LeaveStatusName === "Applied" &&
                        DateVerify(leaves.FromDate) ? (
                          <>
                            <button
                              class="btn btn-primary px-2 py-1 mx-1 my-sm-2 my-2 my-md-0"
                              onClick={() => {
                                setLeaveId(leaves.LeaveRequestID);
                                setApplyLeaveData({
                                  ...applyLeaveData,
                                  FromDate: leaves.FromDate,
                                  ToDate: leaves.ToDate,
                                  LeaveTypeID: leaves.LeaveTypeID,
                                  Reason: leaves.Reason,
                                  FromDateHalfID: leaves.FromDateHalfID,
                                  ToDateHalfID: leaves.ToDateHalfID,
                                });
                                setTotalLeavesNumber(leaves.Days);
                                setLeaveSandwish(leaves.IsSandwich);
                                const FromDayName =
                                  leaves.FromDateDay.split("(");
                                const ToDayName = leaves.ToDateDay.split("(");
                                setFromDateDayName(FromDayName[1].slice(0, 3));
                                setToDateDayName(ToDayName[1].slice(0, 3));
                              }}
                            >
                              <FaPen className="color-white text-white" />
                            </button>
                            <button
                              class="btn btn-danger px-2 py-1 mx-1"
                              onClick={() => {
                                setOpenModal(!openModal);
                                setLeaveId(leaves.LeaveRequestID);
                              }}
                            >
                              <FaTimes className="color-white text-white" />
                            </button>
                          </>
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </>
        )}
        <div>
          <Modal
            show={openModal}
            onHide={closeModal}
            // style={{ marginTop: "5%" }}
            aria-labelledby="ModalHeader"
          >
            <Modal.Header>
              <Modal.Title class="fw-bold">Cancel Leave</Modal.Title>
              <button
                onClick={(e) => {
                  setOpenModal(!openModal);
                  setComments("");
                  setLeaveId("");
                }}
              >
                <FaTimes />
              </button>
            </Modal.Header>
            <Modal.Body>
              <div className="col-12 row mb-2">
                <label class="col-4 col-form-label">
                  Comments <span className="text-danger">*</span>
                </label>
                <div class="col-8">
                  <textarea
                    value={comments}
                    placeholder="Please Specify Reason for canceling leave"
                    onChange={(e) => setComments(e.target.value)}
                    class="form-control"
                    rows="4"
                  />
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {comments !== "" ? (
                <button className="btn btn-success" onClick={handleCancel}>
                  Apply
                </button>
              ) : (
                <button
                  className="btn btn-success"
                  onClick={handleCancel}
                  disabled
                >
                  Apply
                </button>
              )}
              <button
                className="btn btn-danger"
                onClick={() => {
                  setOpenModal(!openModal);
                  setComments("");
                  setLeaveId("");
                }}
              >
                Cancel
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </section>
    </>
  );
}
