import axios from "axios";
import variable from "../servicesvariable";
// import config from "../../server/setup/config";

class UserServies{
    getUsers(token) {
        return axios.get(variable.TUTORIAL_API_API_USER + "student", {
            headers: {
              Authorization: token,
            },
          })
        .then(users => users.data.filter(user => user.deactivate === false));
    }
    getUser(token, id) {
        return axios.get(variable.TUTORIAL_API_API_USER  + id, {
            headers: {
              Authorization: token,
            },
          })
        .then(users => users.data);
    }
}

export default new UserServies();