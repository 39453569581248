export const NodeFunctionModel = {
    controls: [
        {
            type: "text",
            name: "functionName",
            required: true
        },
        {
            type: "text",
            name: "filePath",
            required: true
        },
    ]
}