import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageList from "../../components/images/ImageList";
import CourseService from "../../services/CourseService";
import TestCaseService from "../../services/TestCaseService";
import url from "../../servicesvariable";
import passAssessmentSVG from '../../assets/images/pass-assessment.svg'
import failAssessmentSVG from '../../assets/images/fail-assessment.svg'
import videoPlayerSVG from '../../assets/images/video_player_icon.svg'
import './ExamVideo.scss'

export default function ExamVideo() {
  let { batchName, skillName, examCode, hostId, userId } = useParams();
  const awsRootPath = url.examVideo.replace('trainees', '');
  const [questions, setQuestions] = useState([]);
  const [userName, setUserName] = useState("");
  const [questionNumber, setQuestionNumber] = useState("Q001");
  const [examType, setExamType] = useState("");
  const [examSkill, setExamSkill] = useState("");
  const [videoType, setVideoType] = useState("");
  const [msg, setMsg] = useState("");
  const [passedCaseImage, setPassedCaseImage] = useState([])
  const [failedCaseImage, setFailedCaseImage] = useState([])
  const navigate = useNavigate();
  /*
  * testResult Schema
  * {
  *   Q001: {passed: [], failed: [], error: []},
  *   Q002: {passed: [], failed: [], error: []}
  * }
  */
  const [testResult, setTestResult] = useState([])
  const [studentOverallResult, setStudentOverallResult] = useState({
    noOfPassedTestCase: 0,
    noOfFailedTestCase: 0,
    overallPercentage: 0,
  })

  const getAwsImageUrl = (relativeImageUrl) => {
    return awsRootPath + relativeImageUrl;
  }

  const getTestCaseImage = async (token) => {

    const response = await TestCaseService.getTestCasesImages(batchName, skillName, examCode, hostId, questionNumber, token)

    if (response.status === 200) {

      const { failedTestCaseImg, passedTestCaseImg } = response.data.data;

      setFailedCaseImage(failedTestCaseImg.map(img => {
        return {
          ...img,
          src: getAwsImageUrl(img.imgUrl),
          alt: img.imgUrl,
          className: 'w-100 h-100 object-fit-cover'
        }
      }))

      setPassedCaseImage(passedTestCaseImg.map(img => {
        return {
          ...img,
          src: getAwsImageUrl(img.imgUrl),
          alt: img.imgUrl,
          className: 'w-100 h-100 object-fit-cover'
        }
      }))
    }
  }

  const mystyle = {
    fontSize: "0.9rem",
    fontWeight: "500",
    fontFamily: "Poppins, sans-serif",
  };
  const videoWidth = {
    width: "auto",
  };

  const handleDwonloadZip = () => {
    var src =
      url.examVideo + "/" +
      batchName +
      "/" +
      skillName +
      "/" +
      examCode +
      "/" +
      hostId +
      "/" +
      questionNumber +
      "/Source.zip";
    window.location.href = src;
  };

  const questionVideo =
    url.examVideo + "/" +
    batchName +
    "/" +
    skillName +
    "/" +
    examCode +
    "/" +
    hostId +
    "/" +
    examType +
    "_" +
    questionNumber +
    videoType;
  const allQuestionVideo =
    url.examVideo + "/" +
    batchName +
    "/" +
    skillName +
    "/" +
    examCode +
    "/" +
    hostId +
    "/" +
    examType +
    videoType;

  const getAbsolutePath = (queNumber) => {
    return `${url.examVideo}/${batchName}/${skillName}/${examCode}/${hostId}/${queNumber}/results/`;
  }

  const getTextColorBasedOnPercentage = (percentage) => {

    if (percentage >= 90) {
      return 'text-success'
    }

    if (percentage >= 80) {
      return 'text-primary'
    }

    if (percentage >= 65) {
      return 'text-warning'
    }

    if (percentage >= 50) {
      return 'text-info'
    }

    return 'text-danger'
  }

  useEffect(() => {

    // reset the Student Overall Result
    setStudentOverallResult({
      ...studentOverallResult,
      noOfPassedTestCase: 0,
      noOfFailedTestCase: 0,
    })

    // calculate the Number of PassedTestCase Number of FailedTestCase
    let overallPercentage = 0;
    for (let key in testResult) {

      setStudentOverallResult((prev) => ({
        ...prev,
        noOfPassedTestCase: (testResult[key]?.passCases.length ?? 0) + prev.noOfPassedTestCase,
        noOfFailedTestCase: (testResult[key]?.failedCases.length ?? 0) + prev.noOfFailedTestCase,
      }))

      if ((testResult[key].failedCases.length + testResult[key].passCases.length) !== 0) {
        overallPercentage += (testResult[key].passCases.length / (testResult[key].failedCases.length + testResult[key].passCases.length)) * 100;
      }
    }

    setStudentOverallResult((prev) => ({
      ...prev,
      overallPercentage: overallPercentage / questions.length
    }))


    setStudentOverallResult((prev) => ({
      ...prev,
      overallPercentage: isNaN(prev.overallPercentage) ? 0 : prev.overallPercentage.toFixed(2),
    }))

  }, [testResult])

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {

      CourseService.getSingleExamSchedule(examCode, userId, token)
        .then(async (res) => {
          if (res.data) {
            if (res.data.success) {
              // await handleTestCase(res.data.Questions) TODO REMOVE
              setQuestions(res.data.Questions);
              setUserName(res.data.Name);
              setExamType(res.data.typeExam);
              setExamSkill(res.data.ExamSkill);
              setVideoType(res.data.VideoType);
            } else {
              setMsg(res.data.msg);
            }
          }
        })
        .catch((err) => console.log(err));
    }
  }, []);

  useEffect(() => {

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      setTestResult({});

      questions.forEach(async (question) => {

        let obj = {
          passCases: [],
          failedCases: [],
          error: []
        }

        await TestCaseService.getPassedTestCases(batchName, skillName, examCode, hostId, question, token)
          .then(res => {

            obj = {
              ...obj,
              passCases: res.data
            }
          })

        await TestCaseService.getFailedTestCases(batchName, skillName, examCode, hostId, question, token)
          .then(res => {
            obj = {
              ...obj,
              failedCases: res.data
            }
          })

        await TestCaseService.getErrorTestCases(batchName, skillName, examCode, hostId, question, token)
          .then(res => {
            obj = {
              ...obj,
              error: res.data
            }
          })

        setTestResult((prev) => {
          return {
            ...prev,
            [question]: obj
          }

        });
      })
    }
  }, [questions])

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      getTestCaseImage(token)
    }
  }, [questionNumber])

  return (
    <div className="container-fluid">
      {msg === "" ? (
        <>
          <section className="ExamVideo-module">
            <div className="container-fluid">
              <div className="d-flex justify-content-between flex-wrap align-items-center">
                <div>
                  <h1 className="text-center h3 me-3 mb-0">{examSkill} Questions</h1>
                </div>

                <p className={`mt-0 mb-0 ${getTextColorBasedOnPercentage(studentOverallResult.overallPercentage)}`}><b>Overall Percentage:</b> {studentOverallResult.overallPercentage}</p>
                <p className="mt-0 mb-0"><b>Passed Testcase:</b> {studentOverallResult.noOfPassedTestCase}</p>
                <p className="mt-0 mb-0"><b>Failed Testcase:</b> {studentOverallResult.noOfFailedTestCase}</p>
                <p className="text-dark me-2 mt-2">
                  <h3 className="h2">{userName}</h3>
                  <div className="fs-6">
                  </div>
                </p>
              </div>
              <hr className="mt-4 mb-5" />
              <div className="row">
                <div className="col-xl-2 col-lg-3 col-md-4">

                  <div class="list-group mb-4">
                    {questions &&
                      questions.length > 0 &&
                      questions.map((item, index) => {
                        return (
                          <>
                            <a
                              className={`list-group-item cursor-pointer
                              ${item === questionNumber ? "bg-blue text-white" : ""}`
                              }
                              onClick={(e) => setQuestionNumber(item)}
                            >
                              Question {index + 1}    <span className="text-end" style={{ paddingLeft: "50px" }} >
                                <a target="_blank" className="text-warning" style={{ paddingLeft: "20px", textDecoration: 'none' }} href={getAbsolutePath(questionNumber) + "error.json"}>
                                  {testResult?.[item]?.error.length || 0}

                                </a>
                                <a target="_blank" className="mx-2 text-danger" style={{ textDecoration: 'none' }} href={getAbsolutePath(questionNumber) + "failedCases.json"}>
                                  {testResult[item]?.failedCases.length || 0}
                                </a>
                                <a target="_blank" className="text-success" style={{ textDecoration: 'none' }} href={getAbsolutePath(questionNumber) + "passCases.json"}>
                                  {testResult[item]?.passCases.length || 0}
                                </a>
                              </span>
                            </a>


                          </>
                        );
                      })
                    }
                  </div>
                </div>
                <div className="col-xl-10 col-lg-9 col-md-8">
                  <ul class="nav nav-tabs">
                    <li class="nav-item">
                      <a class="nav-link active" data-bs-toggle="tab" href="#qa-content"><i class="fas fa-laptop-code me-2"></i>Question</a>
                    </li>
                    <li class="nav-item nav-item-custom">
                      <a class="nav-link" data-bs-toggle="tab" href="#qa-video">
                        <img src={videoPlayerSVG} alt='passAssessmentSVG' />
                        Video
                      </a>
                    </li>
                    <li class="nav-item nav-item-custom">
                      <a class="nav-link" data-bs-toggle="tab" href="#qa-passedCaseImage">
                        <img src={passAssessmentSVG} alt='passAssessmentSVG' />
                        Passed Images
                        ({testResult[questionNumber]?.passCases.length || 0})
                      </a>
                    </li>
                    <li class="nav-item nav-item-custom">
                      <a class="nav-link" data-bs-toggle="tab" href="#qa-failedCaseImage">
                        <img src={failAssessmentSVG} alt='passAssessmentSVG' />
                        Failed Images
                        ({testResult[questionNumber]?.failedCases.length || 0})
                      </a>
                    </li>
                    <li class="nav-item">
                      <button className="btn btn-primary bg-blue" onClick={handleDwonloadZip}>
                        Source Code
                        <i class="fas fa-download mx-2"></i>
                      </button>
                    </li>
                  </ul>
                  <div class="tab-content pt-3">
                    <div id="qa-content" class="tab-pane fade in active show">
                      {questionNumber !== "" && (
                        <>
                          <div class="embed-responsive embed-responsive-21by9">
                            <iframe
                              src={
                                url.examVideo + "/" +
                                batchName +
                                "/" +
                                skillName +
                                "/" +
                                examCode +
                                "/" +
                                hostId +
                                "/" +
                                questionNumber +
                                "/" +
                                questionNumber +
                                ".html"
                              }
                              width="100%"
                              height="600"
                              title="Source Code"
                            ></iframe>
                          </div>
                        </>

                      )}
                    </div>
                    <div id="qa-video" class="tab-pane fade">
                      <div className="embed-responsive embed-responsive-16by9">
                        <video
                          id="video"
                          className="w-100"
                          src={questionNumber !== "" ? questionVideo : allQuestionVideo}
                          height="600"
                          controls
                        ></video>
                      </div>
                    </div>
                    <div id="qa-passedCaseImage" class="tab-pane fade">
                      <ImageList images={passedCaseImage} heading={'Passed TestCase Images'} />
                    </div>
                    <div id="qa-failedCaseImage" class="tab-pane fade">
                      <ImageList images={failedCaseImage} heading={'Failed TestCase Images'} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <div class="text-danger text-center pt-3 fs-3">{msg}</div>
      )}
    </div>
  );
}
