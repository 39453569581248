import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import jwt_decode from "jwt-decode";
import ToolTip from "../BsComponents/ToolTip";
export default function ManageCourseCard(props) {
  const item = props.item;
  const itemId = props.itemId;
  const handleDelete = props.handleDelete;
  const handleEdit = props.handleEdit;
  const cateitem = props.cateitem;
  const setCateitem = props.setCateitem;
  const rcate = props.rcate;
  const setRcate = props.setRcate;
  const [confirmBox, setConfirmBox] = useState(false);
  const [confirmBox2, setConfirmBox2] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const [edit, setEdit] = useState(false);

  return (
    <div className="col-lg-6">
      <div className="bg-white p-3 rounded citem position-relative">
        {confirmBox2 && (
          <div className="formloade ">
            <div className="row text-center ">
              <div className=" confirmbox ">
                <div className="alert">
                  Do you really want to remove {item.cname} category?
                  <br />
                  <ToolTip title="Cancel">
                    <button
                      className="btn btn-danger ms-4"
                      onClick={() => {
                        setConfirmBox2(false);
                      }}
                    >
                      Cancel
                    </button>
                  </ToolTip>
                  <ToolTip title="Delete category">
                    <button
                      className="btn btn-success ms-2"
                      onClick={() => {
                        setConfirmBox2(false);
                        setConfirmBox(true);
                        handleDelete(itemId);
                      }}
                    >
                      Remove
                    </button>
                  </ToolTip>
                </div>
              </div>
            </div>
          </div>
        )}
        {edit ? (
          <div
            className="d-flex justify-content-end m-0 mb-2"
            style={{
              right: "0.6rem",
              top: "0.6rem",
              display: "flex",
            }}>
            <ToolTip title="Cancel">
              <button
                className="btn btn-secondary py-1 me-2"
                onClick={() => {
                  setErrMsg("");
                  setEdit(false);
                  setRcate({});
                }}>
                Cancel
              </button>
            </ToolTip>
            {setRcate(item)}
            <ToolTip title="Update category">
              <button
                className="btn btn-success py-1"
                onClick={() => {
                  if (cateitem === "") {
                    setErrMsg("Please enter category name.");
                  } else {
                    setErrMsg("");
                    handleEdit(item);
                    setConfirmBox(true);
                    setEdit(false);
                    setRcate({});
                  }
                }}>
                Update
              </button>
            </ToolTip>
          </div>
        ) : (
          <div
            className="position-absolute"
            style={{
              right: "0.6rem",
              top: "0.6rem",
              display: "flex",
            }}>
            {!confirmBox2 ? (
              <ToolTip title="Edit category">
                <button
                  className="btn btn-warning rounded-pill d-flex justify-content-center align-items-center p-0 me-2 text-white"
                  style={{
                    height: "1.8rem",
                    width: "1.8rem",
                  }}
                  disabled={Object.keys(rcate).length === 0 ? false : true}
                  onClick={() => {
                    setCateitem(item);
                    setEdit(true);
                  }}>
                  <BiEdit className="fs-6" />
                </button>
              </ToolTip>
            ) : null}
            {localStorage.getItem("token") &&
              jwt_decode(localStorage.getItem("token")).role === "Admin" &&
              !confirmBox2 && (
                <ToolTip title="Delete category">
                  <button
                    className="btn btn-danger rounded-pill d-flex justify-content-center align-items-center p-0"
                    style={{
                      height: "1.8rem",
                      width: "1.8rem",
                    }}
                    onClick={() => {
                      setConfirmBox2(true);
                    }}>
                    <FaTrash className="fs-6" />
                  </button>
                </ToolTip>
              )}
          </div>
        )}

        <form className="">
          <table className="table table-sm">
            <tbody className="fs-6">
              <tr>
                <td>
                  {edit ? (
                    <div className="position-relative">
                      <input
                        type="text"
                        maxLength={20}
                        value={cateitem}
                        className="form-control"
                        style={
                          !errMsg
                            ? null
                            : {
                              border: "1px solid #df4957",
                              boxShadow: "0 0 5px #df4957",
                            }
                        }
                        onChange={(e) => {
                          setCateitem(e.target.value);
                          if (e.target.value.length === 0) {
                            setErrMsg("Please enter category name.");
                          } else {
                            setErrMsg("");
                          }
                        }}
                      />
                      {errMsg !== "" ? (
                        <div
                          className="position-absolute rounded px-2 py-1 text-white mt-1"
                          style={{
                            background: "rgba(220,53,69,.9)",
                            zIndex: "1",
                          }}>
                          {errMsg}
                        </div>
                      ) : null}
                    </div>
                  ) : (
                    <p>{item}</p>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
}
