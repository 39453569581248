export const StringModel = {
    controls: [
        {
            type: "number",
            name: "specialCharacters",
            required: true,
        },
        {
            type: "number",
            name: "numeric",
            required: true,
        },
        {
            type: "number",
            name: "length",
            required: true,
        },
    ]
}