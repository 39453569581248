import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import img2 from "../../assets/images/feather-bar-chart.svg";
import img3 from "../../assets/images/material-timelapse.svg";
import img4 from "../../assets/images/material-view-module.svg";
import img5 from "../../assets/images/video_library_icon.svg";
import formloader from "../../images/formloading.svg";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import global from "../../servicesvariable";
import { getNoOfPpts } from "../../utils/course/getNoOfPpts";
import { getNoOfVideo } from '../../utils/course/getNoOfVideo';
import { handleAutoSubscribe } from "../../utils/course/handleAutoSubscribe";
import { handleContinue } from "../../utils/course/handleContinue";
import { handleImageLoadingError } from '../../utils/image/handleImageLoadingError';
import "./courseSlider.scss";

export default function CourseSlider() {
  const [userprogress, setUserprogress] = useState([]);
  const [tcounter, setTcounter] = useState([]);
  const [selectedcourses, setSelectedcourses] = useState([]);
  const token = localStorage.getItem("token");
  const [allsubcourse, setallsubcourse] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();
  var settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.5,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const getAllStudentColleagueProgressedCourses = async () => {
    try {
      setLoading(true);
      const limit = 8;
      const progressedCourses =
        await CourseService.getAllStudentColleagueProgressedCourses(limit);
      let courses = progressedCourses.data.data;

      // Injecticting noOfPPts and noOfVideos
      await courses.forEach(async (course, index) => {
        courses[index] = {
          ...course,
          courseId: {
            ...course.courseId,
            noOfPPts: await getNoOfPpts(course.courseId.name),
            noOfVideos: await getNoOfVideo(course.courseId.name)
          }
        }
      })

      setUserprogress(courses);
      if (tcounter.length === 0) {
        for (let i = 0; i < courses.length; i++) {
          const tplan = await CourseService.getTrainingPlan(
            courses[i].courseId.name
          );
          if (tplan.data) {
            let length = {
              length: tplan.data.length,
              name: courses[i].courseId.name,
            };
            setTcounter((d) => [...d, length]);
          } else {
            let length = {
              length: 0,
              name: courses[i].courseId.name,
            };
            setTcounter((d) => [...d, length]);
          }
          if (i === courses.length - 1) {
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(
        "error getting getAllStudentColleagueProgressedCourses",
        error
      );
    }
  };

  useEffect(() => {
    getAllStudentColleagueProgressedCourses();
  }, []);

  useEffect(() => {
    setLoading(true);
    setConfirmBox(false);
    CourseService.getSubCourses()
      .then((res) => {
        if (res.data) {
          setallsubcourse(res.data);
        }
      })
      .catch(() => { });
    if (token) {
      const decoded = jwt_decode(token);

      LoginService.getSingleUser(decoded.id, token)
        .then((res) => {
          if (res.data) {
            CourseService.getSubCoursesIn(
              { courses: res.data.courses },
              token
            ).then((result) => {
              if (result.data) {
                setSelectedcourses(result.data);
              } else {
                setSelectedcourses([]);
              }
            });
          } else {
            navigate("/login");
          }
          setLoading(false);
        })
        .catch(() => { });
    }
  }, [confirmBox]);

  return (
    <>
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">Loading...</div>
          </div>
        </div>
      )}
      {allsubcourse && allsubcourse.length > 0 && userprogress && userprogress.length > 0 && (
        <section className={`container ${token ? "py-0" : ""}`}>
          <div class="row">
            <div class="learning-card-wrapper">
              <div class="learn-course position-relative">
                <h2 class="text-dark-gray font-weight-400 mb-2">
                  What your colleagues are learning
                </h2>
                <div class="px-12px">
                  <Slider {...settings}>
                    {userprogress.map((item, index) => {

                      // Get No of modules
                      let noOfModules = 0;

                      const findCourseWithModuleLength = tcounter.find(currCourse => currCourse.name === item.courseId.name);
                      if (findCourseWithModuleLength) {
                        noOfModules = findCourseWithModuleLength.length;
                      }

                      // No of Total Hours
                      const noOfTotalHours = Math.ceil(noOfModules * 8.5);

                      // No of PPts
                      const noOfPPts = item.courseId.noOfPPts;

                      // No of Videos
                      const noOfVideos = item.courseId.noOfVideos;

                      // find the subcourse of this particular item
                      const subcourse = allsubcourse.find(subcourse => subcourse.name === item.courseId.name);

                      if (noOfVideos !== 0 || noOfPPts !== 0 || noOfModules !== 0) {
                        return localStorage.getItem("token") ? (
                          jwt_decode(localStorage.getItem("token")).role ===
                            "Student" ? (
                            Array.from(selectedcourses, (o) => o.name).indexOf(
                              item.courseId.name
                            ) === -1 ? (
                              <SubscribeCourses props={{ _id: item.courseId._id, noOfModules, cname: subcourse.type.name,noOfPPts, sname: item.courseId.name, noOfVideos, noOfTotalHours, setConfirmBox, icon: item.courseId.icon }} />
                            ) : (
                              <SubscribedCourses props={{ _id: item.courseId._id, noOfModules, cname: subcourse.type.name,noOfPPts, sname: item.courseId.name, noOfVideos, noOfTotalHours, setConfirmBox, icon: item.courseId.icon }} />
                            )
                          ) : (
                            <SubscribedCourses props={{ _id: item.courseId._id, noOfModules, cname: subcourse.type.name,noOfPPts, sname: item.courseId.name, noOfVideos, noOfTotalHours, setConfirmBox, icon: item.courseId.icon }} />
                          )
                        ) : (
                          <NewlyAddedCourses props={{ _id: item.courseId._id, noOfModules, cname: subcourse.type.name,noOfPPts, sname: item.courseId.name, noOfVideos, noOfTotalHours, setConfirmBox, icon: item.courseId.icon }} />
                        )
                      }
                    })}
                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}

export const NewlyAddedCourses = ({ props }) => {

  const { _id, icon, sname, cname, desc, noOfPPts, level, noOfVideos, noOfTotalHours, noOfModules, setConfirmBox } = props;
  const navigate = useNavigate();


  return (
    <div class="learncourse-slider w-100">
      <div class="card mb-lg-3">
        <div class="course-item-img">
          {icon === "default.jpg" ? (
            <img
              src={`./../${icon}`}
              class="card-img-top"
              alt={cname}
              height="150"
              width="150"
              data-level="1"
              onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
            ></img>
          ) : icon.match(/http/g) ? (
            <img
              src={icon}
              class="card-img-top"
              alt={cname}
              height="150"
              width="150"
              data-level="1"
              onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
            />
          ) : (
            <>
              <img
                src={`${global.imgURL}/${icon}`}
                class="card-img-top"
                alt={cname}
                height="150"
                width="150"
                data-level="1"
                onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
              ></img>
            </>
          )}
        </div>

        <div class="card-body d-flex flex-column p-0">
          <div class="course-content p-3 pb-3 pb-lg-0">
            <h4
              class="card-title text-black font-weight-400"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {cname.replaceAll("_", " ").replaceAll("-", " ")}
            </h4>
            <p
              className="card-text font-14 text-dark "
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {desc}
            </p>
          </div>
          <div class="course-details mt-auto">
            <div class="px-3 py-2 course-item d-flex align-items-center">
              <img
                src={img4}
                alt="Modules"
                width="16"
                height="21"
                class="me-3"
              ></img>{" "}
              <span class="font-14 text-dark-gray">
                <strong>
                  {noOfModules}
                </strong>{" "}
                Modules
              </span>
            </div>
            <div class="px-3 py-2 course-item d-flex align-items-center">
              <img
                src={img3}
                alt="Hours"
                width="16"
                height="21"
                class="me-3"
              ></img>{" "}
              <span class="font-14 text-dark-gray">
                <strong>
                  {noOfTotalHours}
                </strong>{" "}
                Hours
              </span>
            </div>
            <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
              <img
                src={
                  require("../../assets/images/video_library_icon.svg")
                    .default
                }
                alt="videos"
                width="16"
                height="16"
                class="me-2"
              ></img>{" "}
              <span class="font-14 text-dark-gray">
                <strong>
                  {noOfVideos}
                </strong>{" "}
                Videos
              </span>
            </div>
            <div class="px-3 py-2 course-item d-flex align-items-center">
              {level && (
                <>
                  <img
                    src={img2}
                    alt="level"
                    width="16"
                    height="21"
                    class="me-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    For <strong>{level}</strong>
                  </span>
                </>
              )}
            </div>
            <div class="d-lg-flex d-block justify-content-between p-lg-3 p-2 btn-wrapper">

              <Link
                to={`/course/${cname}/courseDetails/${sname}`}
                class="text-primary sm-white-btn"
                title="Course Details"
              >
                Course Details
              </Link>

              <div
                onClick={() => {
                  const urlString = handleContinue(cname, sname, noOfModules, noOfVideos, noOfPPts)
                  if (urlString) {
                    navigate(urlString);
                  }
                }}
                class="text-primary sm-btn cursor-pointer">
                Subscribe
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SubscribeCourses = ({ props }) => {

  const { _id, icon, sname, cname, noOfPPts, desc, level, noOfVideos, noOfTotalHours, noOfModules, setConfirmBox } = props;
  const navigate = useNavigate();


  return (
    <div class="learncourse-slider">
      <div class="card mb-lg-3">
        <div class="course-item-img">
          {icon === "default.jpg" ? (
            <img
              src={`./../${icon}`}
              class="card-img-top"
              alt={cname}
              height="150"
              width="150"
              data-level="1"
              onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
            ></img>
          ) : icon.match(/http/g) ? (
            <img
              src={icon}
              class="card-img-top"
              alt={cname}
              height="150"
              width="150"
              data-level="1"
              onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
            />
          ) : (
            <>
              <img
                src={`${global.imgURL}/${icon}`}
                class="card-img-top"
                alt={cname}
                height="150"
                data-level="1"
                width="150"
                onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
              ></img>
            </>
          )}
        </div>

        <div class="card-body d-flex flex-column p-0">
          <div class="course-content p-3 pb-3 pb-lg-0">
            <h4
              class="card-title text-black font-weight-400"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {cname.replaceAll("_", " ").replaceAll("-", " ")}
            </h4>
            <p
              className="card-text font-14 text-dark "
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {desc}
            </p>
          </div>
          <div class="course-details mt-auto">
            <div class="px-3 py-2 course-item d-flex align-items-center">
              <img
                src={img4}
                alt="Modules"
                width="16"
                height="21"
                class="me-3"
              ></img>{" "}
              <span class="font-14 text-dark-gray">
                <strong>
                  {noOfModules}
                </strong>{" "}
                Modules
              </span>
            </div>
            <div class="px-3 py-2 course-item d-flex align-items-center">
              <img
                src={img3}
                alt="Hours"
                width="16"
                height="21"
                class="me-3"
              ></img>{" "}
              <span class="font-14 text-dark-gray">
                <strong>
                  {noOfTotalHours}
                </strong>{" "}
                Hours
              </span>
            </div>

            <div class="px-3 py-2 course-item d-flex align-items-center">
              <img
                src={img5}
                alt="Videos"
                width="16"
                height="21"
                class="me-3"
              ></img>{" "}
              <span class="font-14 text-dark-gray">
                <strong>
                  {noOfVideos}
                </strong>{" "}
                Videos
              </span>
            </div>

            <div class="px-3 py-2 course-item d-flex align-items-center">
              {level && (
                <>
                  <img
                    src={img2}
                    alt="level"
                    width="16"
                    height="21"
                    class="me-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    For <strong>{level}</strong>
                  </span>
                </>
              )}
            </div>
            <div class="d-lg-flex d-block justify-content-between p-lg-3 p-2 btn-wrapper">

              <Link
                to={`/course/${cname}/courseDetails/${sname}`}
                class="text-primary sm-white-btn"
                title="Course Details"
              >
                Course Details
              </Link>
              <div
                onClick={async () => {
                  const urlString = await handleAutoSubscribe(_id, cname, sname, noOfModules, noOfVideos, noOfPPts);
                  if (urlString) {
                    navigate(urlString);
                    setConfirmBox(true);
                  }
                }}
                class="text-primary sm-btn cursor-pointer"
              >
                Subscribe
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SubscribedCourses = ({ props }) => {

  const { _id, icon, sname, cname, desc, level, noOfPPts, noOfVideos, noOfTotalHours, noOfModules, setConfirmBox } = props;
  const navigate = useNavigate();

  return (
    <div class="learncourse-slider">
      <div class="card mb-lg-3 subscribe-card">
        <div class="course-item-img">
          {icon === "default.jpg" ? (
            <img
              src={`./../${icon}`}
              class="card-img-top"
              alt={cname}
              height="150"
              width="150"
              data-level="1"
              onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
            ></img>
          ) : icon.match(/http/g) ? (
            <img
              src={icon}
              class="card-img-top"
              alt={cname}
              height="150"
              width="150"
              data-level="1"
              onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
            />
          ) : (
            <>
              <img
                src={`${global.imgURL}/${icon}`}
                class="card-img-top"
                alt={cname}
                height="150"
                width="150"
                data-level="1"
                onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
              ></img>
            </>
          )}
        </div>
        <div class="card-body d-flex flex-column p-0">
          <div class="course-content p-3 pb-3 pb-lg-0">
            <h4
              class="card-title text-black font-weight-400"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "1",
                WebkitBoxOrient: "vertical",
              }}
            >
              {cname.replaceAll("_", " ").replaceAll("-", " ")}
            </h4>
            <p
              className="card-text font-14 text-dark "
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {desc}
            </p>
          </div>
          <div class="course-details mt-auto">
            <div class="px-3 py-2 course-item d-flex align-items-center">
              <img
                src={img4}
                alt="Modules"
                width="16"
                height="21"
                class="me-3"
              ></img>{" "}
              <span class="font-14 text-dark-gray">
                <strong>
                  {noOfModules}
                </strong>{" "}
                Modules
              </span>
            </div>

            <div class="px-3 py-2 course-item d-flex align-items-center">
              <img
                src={img3}
                alt="Hours"
                width="16"
                height="21"
                class="me-3"
              ></img>{" "}
              <span class="font-14 text-dark-gray">
                <strong>
                  {noOfTotalHours}
                </strong>{" "}
                Hours
              </span>
            </div>

            <div class="px-3 py-2 course-item d-flex align-items-center">
              <img
                src={img5}
                alt="Videos"
                width="16"
                height="21"
                class="me-3"
              ></img>{" "}
              <span class="font-14 text-dark-gray">
                <strong>
                  {noOfVideos}
                </strong>{" "}
                Videos
              </span>
            </div>

            <div class="px-3 py-2 course-item d-flex align-items-center">
              {level && (
                <>
                  <img
                    src={img2}
                    alt="level"
                    width="16"
                    height="21"
                    class="me-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    For <strong>{level}</strong>
                  </span>
                </>
              )}
            </div>
            <div class="d-lg-flex d-block justify-content-between p-lg-3 p-2">

              <Link
                to={`/course/${cname}/courseDetails/${sname}`}
                class="text-primary sm-white-btn"
                title="Course Details"
              >
                Course Details
              </Link>
              <div
                onClick={() => {
                  const urlString = handleContinue(cname, sname, noOfModules, noOfVideos, noOfPPts)
                  if (urlString) {
                    navigate(urlString);
                  }
                }}
                class="text-success sm-btn d-flex align-items-center justify-content-center cursor-pointer"
              >
                <img
                  width="16"
                  height="16"
                  alt="bar btn"
                  src={
                    require("../../assets/images/check-icon.svg")
                      .default
                  }
                />
                &nbsp;Continue
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
