
const Image = ({ src, alt, className, title }) => {

    const imageContainerStyle = {
        position: "relative",
        width: "100%",
        height: "500px"
    }

    const imageTitleStyle = {
        position: "absolute",
        bottom: "0",
        left: "0",
        width: "100%",
        background: "linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.8))",
        color: "#fff",
        padding: "10px",
        paddingTop: "20px",
        transition: "background 0.5s ease"
    }

    
    return (
        <div className="container mt-5">
            <div className="row">
                <div>
                    <div style={imageContainerStyle}>
                        <img src={src} className={className} alt={alt} />
                        <div style={imageTitleStyle}>
                            {title}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}



export default Image;