class StringNameHelper {

    /**
     * 
     * @param fileName FileName: String 
     * @param replaceCharacter replaceCharacter: Character to whom to replace with whitespace.
     * @return FileName with replace character.
     */
    static replaceWhiteSpace(fileName, replaceCharacter) {

        return fileName.replaceAll(' ', replaceCharacter);
    }


    /**
     * @param string string: string
     * @param containText containText: string
     * @return true or false if the containText is included in filename
     */
    static containText(string, containText) {

        return string.includes(containText);
    }
}

module.exports = StringNameHelper;