

/**
 * 
 * Returns the url 
 *  If 
 *      Number of PPTs is not zero or Number of Modules is not zero or Number of Videos is not zero
 *  Else
 *      Return Null
 *
 */
export const handleContinue = (cname, sname, noOfModules, noOfVideos, noOfPpts) => {

    if(!cname || !sname || isNaN(noOfModules) || isNaN(noOfVideos) || isNaN(noOfPpts)) {
        throw new Error("Passed Argument is Not a Number");
    }

    if (noOfModules !== 0) {
        return `/course/${cname}/courseOverview/${sname}/tplan`;
    }

    if (noOfVideos !== 0) {
        return `/course/${cname}/courseOverview/${sname}/videos/`
    }

    if (noOfPpts !== 0) {
        return `/course/${cname}/courseOverview/${sname}/additional-resources`
    }

    return null
}