import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import formloader from "../../images/formloading.svg";
import jwt_decode from "jwt-decode";
import CkEditor from "./CkEditor";
import { useNavigate } from "react-router-dom";
import ToolTip from "../BsComponents/ToolTip";

import { QueWithTestCaseModel } from '../../utils/models/queWithTestCase.model.js';
import { isJsonValid } from '../../utils/json/isJsonValid';

import { imgURL } from "../../services/CourseService";
import { FaCheck, FaMinus, FaPen, FaTimes } from "react-icons/fa";

export default function AddTrininigPlan() {
  const initialTrainingPlan = {
    _id: "",
    cid: "",
    tp_day: "",
    tp_onlineref: "",
    tp_note: "",
    tp_practiceimgarr: [],
    tp_assignmentimgarr: [],
    tp_supportedfiles: [],
    formattedWhatToLearn: "",
    formattedNotePlan: "",
    formattedPractices: [],
    formattedAssignments: [],
  };
  const [upTplan, setUpTplan] = useState(initialTrainingPlan);
  const initialCkEditorState = {
    formattedWhatToLearn: false,
    formattedPractices: false,
    formattedAssignments: false,
    tp_onlineref: false,
    formattedNotePlan: false,
    videoUpload: false,
    pptUpload: false,
    supportedFilesUpload: false,
    tp_practiceimgarr: false,
    tp_assignmentimgarr: false,
  };
  const [isOpen, setIsOpen] = useState(initialCkEditorState);
  const [getAllCources, setGetAllCources] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const token = localStorage.getItem("token");
  const [scnames, setSCnames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [videoupload, setupload] = useState("");
  const [validate, setValidate] = useState(false);
  const [planVideos, setPlanVideos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [courses, setCourses] = useState([]);
  const [pptupload, setpptupload] = useState("");
  const [planPpts, setPlanPpts] = useState([]);
  const [errorObj, setErrorObj] = useState({});
  const [ppts, setPpts] = useState([]);
  const [course, setCourse] = useState([]);
  const [courseName, setCourseName] = useState("");
  const [courseId, setCourseId] = useState("");
  const [subCourseId, setSubCourseId] = useState("");
  const [allPpts, setAllPpts] = useState([]);
  const [allVideos, setAllVideos] = useState([]);
  const navigate = useNavigate();
  const [errorMsg, setErrorMsg] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [isUpdateMode, setIsUpdateMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let cources = await CourseService.getCourses();
      setGetAllCources(cources.data);
      if (
        localStorage.getItem("token") &&
        jwt_decode(localStorage.getItem("token")).role === "Trainer"
      ) {
        LoginService.getSingleUser(
          jwt_decode(localStorage.getItem("token")).id,
          localStorage.getItem("token")
        )
          .then((res) => {
            // res.data.courses.map((item) => {
            CourseService.getSubCoursesIn(res.data, token)
              .then((result) => {
                if (result.data?.length > 0) {
                  result.data = result.data.filter((d) => d.type != null);
                  setSCnames(result.data);
                }
              })
              .catch((ex) => console.log(ex));
          })
          // })
          .catch((ex) => console.log(ex));
      } else if (
        localStorage.getItem("token") &&
        jwt_decode(localStorage.getItem("token")).role === "Admin"
      ) {
        CourseService.getSubCourses()
          .then((res) => {
            if (res.data?.length > 0) {
              res.data = res.data.filter((d) => d.type != null);
              setSCnames(res.data);
            }
          })
          .catch((ex) => console.log(ex));
      }
    };

    try {
      fetchData();
    } catch (error) {
      if (error.response.status === 401) {
        navigate("/login");
      }
    }
  }, []);

  const handleCourseChange = async (e, resetDay) => {
    const { name, value } = e.target;

    const setInitialValues = { ...initialTrainingPlan };

    if (name === "cname" && value) {
      delete setInitialValues.cid;
      setUpTplan((prev) => ({ ...prev, ...setInitialValues }));
      setErrorObj((prev) => ({ ...prev, course: "" }));
    }
    if (name === "scname" && value) {
      delete setInitialValues.cid;
      setUpTplan((prev) => ({ ...prev, ...setInitialValues }));
      setErrorObj((prev) => ({ ...prev, subCourse: "" }));
    }

    if (resetDay) {
      setUpTplan((prev) => ({ ...prev, tp_day: "" }));
      return;
    }

    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      const res = await CourseService.getTrainingPlanByID(
        e.target.value,
        token
      );
      const plans = res.data.sort((a, b) => {
        if (a.tp_day > b.tp_day) {
          return 1;
        } else if (a.tp_day < b.tp_day) {
          return -1;
        }
        return 0;
      });
      if (plans.length > 0) {
        setUpTplan((d) => ({
          ...d,
          tp_day: plans[plans.length - 1].tp_day + 1,
        }));
      } else if (plans.length == 0) {
        setUpTplan((d) => ({ ...d, tp_day: 1 }));
      }
    }
  };

  const handlecheck = async (e) => {
    if (e.target.checked === true) {
      setCourses((d) => [...d, e.target.value]);
    } else {
      var cor = [];
      if (courses.length !== 0) {
        cor = courses.filter((j) => j === e.target.value);
      }
      if (cor) {
        const course = courses.filter((j) => j !== e.target.value);
        setCourses(course);
      }
    }
  };
  const handlepptcheck = async (e) => {
    if (e.target.checked === true) {
      setCourse((d) => [...d, e.target.value]);
    } else {
      var cor = [];
      if (course.length !== 0) {
        cor = course.filter((j) => j === e.target.value);
      }
      if (cor) {
        const coursess = course.filter((j) => j !== e.target.value);
        setCourse(coursess);
      }
    }
  };

  const pptuploaded = async () => {
    try {
      const findCourse = scnames.find((i) => i._id === upTplan.cid);
      if (findCourse) {
        CourseService.getPpts(findCourse.name, token)
          .then((res) => {
            if (res.data) {
              if (typeof res.data === "object") {
                const pptsWithDay = res.data.ppts.filter(
                  (ppt, index) => ppt.day === undefined
                );
                setPpts(pptsWithDay);
              } else {
                res.data.map((item, index) => {
                  res.data.ppts.map(async (ppt, index) => {
                    if (ppt.day === undefined) {
                      await setPpts((d) => [...d, ppt]);
                    }
                  });
                });
              }
            }
          })
          .catch((ex) => console.log(ex));
      }
    } catch (error) {
      console.log("error getting ppts", error);
    }
  };

  const videouploaded = async () => {
    try {
      const findCourse = scnames.find((i) => i._id === upTplan.cid);
      if (findCourse) {
        CourseService.getVideos(findCourse.name, token)
          .then((res) => {
            if (res.data.length > 0) {
              const temp = [];
              for (let i = 0; i < res.data.length; i++) {
                for (let j = 0; j < res.data[i].videos.length; j++) {
                  if (res.data[i].videos[j]?.day === undefined) {
                    temp.push(res.data[i].videos[j]);
                  }
                }
              }
              setVideos(temp);
            }
          })
          .catch((ex) => console.log(ex));
      }
    } catch (error) {
      console.log("error getting videos", error);
    }
  };

  const getCoursePpts = async (id, flag) => {
    try {
      const coursePpts = await CourseService.getCoursePpts(
        id,
        localStorage.getItem("token")
      );

      setAllPpts(coursePpts?.data);
      if (flag) {
        const allPpts = coursePpts.data;
        const temp = [];
        for (let i = 0; i < allPpts.length; i++) {
          for (let j = 0; j < allPpts[i].ppts.length; j++) {
            if (allPpts[i].ppts[j].day === upTplan.tp_day) {
              temp.push(allPpts[i].ppts[j]);
            }
          }
        }
        setPlanPpts(temp);
      }
    } catch (error) {
      console.log("error getting course ppts", error);
    }
  };

  const scrollToTop = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };

  // Validation for formatted Practices
  const isFormattedPracticeValid = () => {
    const {formattedPractices} =  upTplan;
    if(formattedPractices && formattedPractices.length > 0) {
      for(const practice of formattedPractices) {
        if(!practice?.que || practice.que?.length === 0) {
          setErrorObj((prev) => {
            return {
              ...prev,
              formattedPractices: `Please enter Practice Question.`,
            };
          });
          return false;
        }

        if(practice?.testCase && !isJsonValid(practice.testCase)) {
          setErrorObj((prev) => {
            return {
              ...prev,
              formattedPractices: `Please Enter valid json`,
            };
          });
          return false;
        }
      }
    }
    return true;
  }

  // Validation for Formatted Assignments
  const isAssignmentValid = () => {
    const {formattedAssignments} = upTplan;
    if(formattedAssignments && formattedAssignments.length > 0) {
      for(const assignment of formattedAssignments) {
        if(!assignment?.que || assignment.que?.length === 0) {
          setErrorObj((prev) => {
            return {
              ...prev,
              formattedAssignments: `Please enter Assignment.`,
            };
          });
          return false;
        }

        if(assignment?.testCase && !isJsonValid(assignment.testCase)) {
          setErrorObj((prev) => {
            return {
              ...prev,
              formattedAssignments: `Please Enter valid json`,
            };
          });
          return false;
        }
      }
    }
    return true;
  }

  const handleSave = async (field) => {
   
    const formdata = new FormData();
    const {
      cid,
      tp_day,
      tp_practiceimgarr,
      tp_assignmentimgarr,
      tp_supportedfiles,
      formattedWhatToLearn,
      formattedAssignments
    } = upTplan;

    if (!formattedWhatToLearn) {
      setErrorObj((prev) => {
        return {
          ...prev,
          formattedWhatToLearn: `Please add some details to learn.`,
        };
      });
    }

    const assignmentValid = isAssignmentValid();

    const formattedPracticeValid = isFormattedPracticeValid();

    // reset error if valid
    if(assignmentValid) {
      setErrorObj((prev) => {
        return {
          ...prev,
          formattedAssignments: ``,
        };
      });
    }

    // reset error if valid
    if(formattedPracticeValid) {
      setErrorObj((prev) => {
        return {
          ...prev,
          formattedPractices: ``,
        };
      });
    }

    // If not valid break function
    if(!assignmentValid || !formattedPracticeValid) {
      return;
    }

    if (!formattedWhatToLearn) {
      scrollToTop();
      return;
    }

    setIsOpen({
      ...isOpen,
      [field]: !isOpen[field],
      videoUpload: false,
      pptUpload: false,
    });
    setErrorMsg("");
    setSuccessMsg("");

    setValidate(true);
    if (cid && tp_day) {
      setLoading(true);
      // Uploading practiceImgArr to cloudinary
      if (tp_practiceimgarr.length > 0) {
        for (const item of tp_practiceimgarr) {
          formdata.append("tp_practiceimgarr", item);
        }
      }
      if (tp_assignmentimgarr.length > 0) {
        for (const item of tp_assignmentimgarr) {
          formdata.append("tp_assignmentimgarr", item);
        }
      }

      if(formattedAssignments.length > 0) {
        for(const item of formattedAssignments) {
          formdata.append("formattedAssignments", item);
        }
      }

      if (tp_supportedfiles.length > 0) {
        for (const item of tp_supportedfiles) {
          formdata.append("tp_supportedfiles", item);
        }
      }
      formdata.append("trainingplan", JSON.stringify(upTplan));
      upTplan.tp_onlineref = upTplan.tp_onlineref
        .toString()
        .replace(/,/g, "")
        .split("\n");
      try {
        if (field === "videoUpload") {
          if (videoupload === "yes") {
            await Promise.all(
              courses.map(async (video) => {
                const result = await CourseService.putCoursevideos(
                  video,
                  tp_day,
                  token
                );
                return result;
              })
            );
          }
          await videouploaded();
          await getCourseVideos(courseName);
          setupload("");
        }

        if (field === "pptUpload") {
          if (pptupload === "yes") {
            await Promise.all(
              course.map(async (ppt) => {
                const result = await CourseService.putPpts(ppt, tp_day, token);
                return result;
              })
            );

            setPpts([]);
            getCoursePpts(subCourseId, true);
            setpptupload("");
          }
        }

        upTplan.formattedPractices = upTplan.formattedPractices.filter(
          (i) => i !== null && i !== ""
        );
        upTplan.formattedAssignments = upTplan.formattedAssignments.filter(
          (i) => i !== null && i !== ""
        );
        let res;
        if (isUpdateMode) {
          res = await CourseService.putTrainingPlan(upTplan, formdata, token);
        } else {
          const createPlan = { ...upTplan };
          delete createPlan._id;
          res = await CourseService.postTrainingPlan(
            createPlan,
            formdata,
            token
          );
        }

        if (res.data) {
          if (!isUpdateMode) {
            setUpTplan(res.data.Trainingplan);
            setIsUpdateMode(true);
          }
          setLoading(false);
          setUpTplan({ ...res.data?.Trainingplan });
          setValidate(false);
          setErrorObj((prev) => {
            return {
              ...prev,
              formattedWhatToLearn: "",
              formattedAssignments: "",
            };
          });
        }
      } catch (ex) {
        setLoading(false);
        console.log(ex);
        if (ex.contain("status code 401")) {
          navigate("/login");
        }
        setIsOpen(initialCkEditorState);
        setValidate(false);
        setErrorMsg(`Failed to add Training plan for day ${tp_day}.`);
        console.log("error in adding plan", ex);
        console.log(ex);
        setTimeout(() => {
          setErrorMsg("");
        }, 2000);
      }
    }
  };

  const getCourseVideos = async (name) => {
    try {
      const courseVideos = await CourseService.getVideos(
        name,
        localStorage.getItem("token")
      );

      setAllVideos(courseVideos?.data);
      if (!!upTplan.tp_day) {
        const temp = courseVideos?.data.flatMap((video) =>
          video.videos.filter((videoItem) => videoItem.day === upTplan.tp_day)
        );
        setVideos([]);
        setPlanVideos(temp);
      }
    } catch (error) {
      console.log("error getting course videos", error);
    }
  };

  const discardChanges = (state) => {
    console.log(state);
    setErrorObj((prev) => {

      return {
        ...prev,
        [state]: ''
      }
    });
    setUpTplan((prev) => ({ ...prev, [state]: initialTrainingPlan[state] }));
    setIsOpen({ ...isOpen, [state]: !isOpen[state] });
  };

  const handleNextDay = () => {
    console.log(upTplan)
    const day = upTplan.tp_day;
    setIsUpdateMode(false);
    const resetTrainingPlan = { ...initialTrainingPlan };
    delete resetTrainingPlan.cid;
    delete resetTrainingPlan._id;
    setIsOpen(initialCkEditorState);
    setUpTplan((prev) => ({ ...prev, ...resetTrainingPlan, tp_day: day + 1 }));
    setCourses([]);
    setCourse([]);
    setVideos([]);
    setPpts([]);
    setPlanPpts([]);
    setPlanVideos([]);
    setupload("");
    setpptupload("");
  };

  return (
    <div className="container my-2 bg-white p-4">
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">
              Uploading Training plan...
            </div>
          </div>
        </div>
      )}

      <div className="form px-lg-5">
        <h5 className="color-dgreen text-uppercase text-center heading">
          Add new Training Plan
        </h5>
        <div className="d-flex mb-3 justify-content-end">
          <button
            className="btn btn-primary"
            disabled={!isUpdateMode}
            type="button"
            onClick={handleNextDay}
          >
            Next Day
          </button>
        </div>

        {!!successMsg && (
          <div class="alert alert-success p-3" role="alert">
            {successMsg}
          </div>
        )}
        {!!errorMsg && (
          <div class="alert alert-danger p-3" role="alert">
            {errorMsg}
          </div>
        )}

        <div className="row mb-3">
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 position-relative fw-bold">
            <label htmlFor="scname" className="form-label">
              Course name <span className="text-danger mx-1">*</span>
            </label>
            <select
              className={
                upTplan.cid || !validate
                  ? "form-select"
                  : "form-select border-danger"
              }
              id="cname"
              name="cname"
              value={selectedCourse}
              onChange={(e) => {
                setPlanVideos([]);
                setPlanPpts([]);
                setpptupload("");
                setupload("");

                setSelectedCourse(e.target.value);
                if (e.target.value) {
                  const { name } = getAllCources.find(
                    (item) => item._id === e.target.value
                  );
                  setCourseName(name ?? "");
                }
                setCourseId(e.target.value);
                setIsUpdateMode(false);
                handleCourseChange(e, true);
              }}
            >
              <option value="">Select Course</option>
              {getAllCources.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
            {!!errorObj && errorObj["course"] && (
              <small className="form-text d-block text-red">
                {errorObj["course"]}
              </small>
            )}
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 position-relative fw-bold">
            <label htmlFor="scname" className="form-label">
              SubCourse name <span className="text-danger mx-1">*</span>
            </label>
            <select
              className={
                upTplan.cid || !validate
                  ? "form-select"
                  : "form-select border-danger"
              }
              id="scname"
              name="scname"
              onChange={(e) => {
                setPlanVideos([]);
                setPlanPpts([]);
                setpptupload("");
                setupload("");
                setUpTplan((d) => ({ ...d, cid: e.target.value }));

                if (!e.target.value) {
                  setUpTplan((d) => ({ ...d, tp_day: "" }));
                }
                setErrorObj((prev) => {
                  return {
                    ...prev,
                    formattedWhatToLearn: "",
                  };
                });
                if (e.target.value) {
                  setSubCourseId(e.target.value);
                } else {
                  setSubCourseId("");
                }
                setIsOpen(initialCkEditorState);
                setIsUpdateMode(false);
                handleCourseChange(e);
              }}
            >
              <option value="">Select Subcourse</option>
              {scnames &&
                scnames.length > 0 &&
                scnames.map((item, index) => {
                  if (item.type._id.toString() === selectedCourse.toString()) {
                    return (
                      <option value={item._id} key={index}>
                        {item.name}
                      </option>
                    );
                  }
                })}
            </select>
            {!!errorObj && errorObj["subCourse"] && (
              <small className="form-text d-block text-red">
                {errorObj["subCourse"]}
              </small>
            )}
          </div>
          <div className="col-12 col-sm-12 col-md-4 col-lg-4 position-relative fw-bold">
            <label htmlFor="tp_day" className="form-label">
              Training plan day
            </label>
            <input
              type="number"
              name="tp_day"
              value={upTplan.tp_day}
              id="tp_day"
              className={
                upTplan.tp_day || !validate
                  ? "form-control "
                  : "form-control border-danger "
              }
              disabled
            />
            {!upTplan.tp_day && validate && (
              <div className="position-absolute rounded px-2 py-1 text-white mt-1 invalidate">
                Please enter plan day
              </div>
            )}
          </div>
        </div>

        {/* new code */}
        {upTplan.tp_day ? (
          <div className="form px-lg-5">
            <div className="">
              <div className="d-flex">
                <div className="me-4">
                  <p className="fw-bold">
                    What to Learn <span className="text-danger mx-1">*</span>
                  </p>
                </div>

                <div className="">
                  {isOpen.formattedWhatToLearn ? (
                    <div className="d-flex">
                      <div>
                        <ToolTip title={"Save changes"}>
                          <button
                            type="button"
                            className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                            onClick={() => {
                              handleSave("formattedWhatToLearn");
                            }}
                          >
                            <FaCheck />
                          </button>
                        </ToolTip>
                      </div>
                      <div>
                        <ToolTip title={"Discard changes"}>
                          <button
                            type="button"
                            className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                            onClick={() => {
                              discardChanges("formattedWhatToLearn");
                            }}
                          >
                            <FaTimes />
                          </button>
                        </ToolTip>
                      </div>
                    </div>
                  ) : (
                    <ToolTip title={"Edit"}>
                      <button
                        type="button"
                        className={`btn btn-submit me-2 bg-warning text-white btn-sm`}
                        onClick={() =>
                          setIsOpen({
                            ...isOpen,
                            formattedWhatToLearn: !isOpen.formattedWhatToLearn,
                          })
                        }
                      >
                        <FaPen />
                      </button>
                    </ToolTip>
                  )}
                </div>
              </div>
              {!!errorObj && errorObj["formattedWhatToLearn"] && (
                <small className="form-text d-block text-red">
                  {errorObj["formattedWhatToLearn"]}
                </small>
              )}
              {isOpen.formattedWhatToLearn ? (
                <div className="mb-3">
                  <CkEditor
                    data={upTplan.formattedWhatToLearn}
                    onChange={(e, editor) => {
                      const data = editor.getData();
                      setUpTplan((d) => ({
                        ...d,
                        formattedWhatToLearn: data,
                      }));
                    }}
                  />
                </div>
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: upTplan.formattedWhatToLearn,
                  }}
                ></div>
              )}
            </div>

            <hr />

            <div>
              <div className="d-flex my-2">
                <div className="fw-bold me-4">
                  <p>Practice Exercise</p>
                </div>
                <div className="">
                  {isOpen.formattedPractices ? (
                    <div className="d-flex">
                      <div>
                        <ToolTip title={"Save Changes"}>
                          <button
                            type="button"
                            className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                            onClick={() => {
                              handleSave("formattedPractices");
                            }}
                          >
                            <FaCheck />
                          </button>
                        </ToolTip>
                      </div>
                      <div>
                        <ToolTip title={"Discard changes"}>
                          <button
                            type="button"
                            className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                            onClick={() => {
                              discardChanges("formattedPractices");
                            }}
                          >
                            <FaTimes />
                          </button>
                        </ToolTip>
                      </div>
                    </div>
                  ) : (
                    <ToolTip title={"Edit"}>
                      <button
                        type="button"
                        className={`btn btn-submit me-2 bg-warning text-white btn-sm`}
                        onClick={() =>
                          setIsOpen({
                            ...isOpen,
                            formattedPractices: !isOpen.formattedPractices,
                          })
                        }
                      >
                        <FaPen />
                      </button>
                    </ToolTip>
                  )}
                </div>
              </div>
              {!!errorObj && errorObj["formattedPractices"] && (
                <small className="form-text d-block text-red">
                  {errorObj["formattedPractices"]}
                </small>
              )}
              {isOpen.formattedPractices ? (
                <div className="mb-3">
                  {upTplan.formattedPractices &&
                    upTplan.formattedPractices.length > 0 &&
                    upTplan.formattedPractices.map((item, index) => {
                      return (
                        <div className="row mt-2" key={index}>
                          <div className="col-md-1">
                            <p>{index + 1}</p>
                          </div>
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-xs-12 col-md-6">
                                {/* CkEditor for Practice */}
                                <CkEditor
                                    data={item.que ?? ''}
                                    onChange={(e, editor) => {
                                      const data = editor.getData();
                                      // const data = e.target.value;
                                      const temp = [...upTplan.formattedPractices];
                                      temp[index] = new QueWithTestCaseModel(data, temp[index]?.testCase ?? '' , item._id);
                                      setUpTplan({
                                        ...upTplan,
                                        formattedPractices: temp,
                                      });
                                    }}
                                  />
                              </div>
                              <div className="col-xs-12 col-md-6">
                              {/* Textarea for testcase */}
                                <label 
                                  className="w-100 h-100 fw-bold"
                                  >Write Test Cases (JSON)
                                  <textarea
                                    rows={13}
                                    className="w-100 overflow-y-auto"
                                    value={item.testCase ?? ''}
                                    onChange={(e) => {
                                      const data = e.target.value;
                                      // const data = e.target.value;
                                      const temp = [...upTplan.formattedPractices];
                                      temp[index] = new QueWithTestCaseModel(temp[index]?.que ?? '', data, item._id);
                                      setUpTplan({
                                        ...upTplan,
                                        formattedPractices: temp,
                                      });
                                    }}
                                    />
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-1 my-auto">
                            <ToolTip title="Remove">
                              <button
                                className="btn my-2 btn-danger btn-sm"
                                onClick={(e) => {
                                  upTplan.formattedPractices.splice(index, 1);
                                  setUpTplan({ ...upTplan });
                                }}
                                type="button"
                              >
                                <FaMinus />
                              </button>
                            </ToolTip>
                          </div>
                        </div>
                      );
                    })}
                  <button
                    type="button"
                    className="btn btn-primary btn-sm mt-2"
                    onClick={() => {
                      const temp = [...upTplan.formattedPractices];
                      temp.push("");
                      setUpTplan({
                        ...upTplan,
                        formattedPractices: temp,
                      });
                    }}
                  >
                    {upTplan.formattedPractices &&
                    upTplan.formattedPractices.length < 1
                      ? "Add"
                      : "Add More"}
                  </button>
                </div>
              ) : (
                <>
                  {upTplan.formattedPractices &&
                    upTplan.formattedPractices.length > 0 &&
                    upTplan.formattedPractices.map((item, index) => (
                      <div className="row mt-2" key={index}>
                        <div className="col-md-1">{index + 1}</div>
                        <div className="col-md-11">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `<div>${item.que}</div><div>${item.testCase}</div>`,
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>

            <hr />

            <div className="my-2">
              <div className="d-flex">
                <div className="fw-bold me-4">
                  <p>Assignment Exercise</p>
                </div>
                <div className="">
                  {isOpen.formattedAssignments ? (
                    <div className="d-flex">
                      <div>
                        <ToolTip title={"Save changes"}>
                          <button
                            type="button"
                            className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                            onClick={() => {
                              handleSave("formattedAssignments");
                            }}
                          >
                            <FaCheck />
                          </button>
                        </ToolTip>
                      </div>
                      <div>
                        <ToolTip title={"Discard changes"}>
                          <button
                            type="button"
                            className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                            onClick={() =>
                              discardChanges("formattedAssignments")
                            }
                          >
                            <FaTimes />
                          </button>
                        </ToolTip>
                      </div>
                    </div>
                  ) : (
                    <ToolTip title={"Edit"}>
                      <button
                        type="button"
                        className={`btn btn-submit me-2 bg-warning text-white btn-sm`}
                        onClick={() =>
                          setIsOpen({
                            ...isOpen,
                            formattedAssignments: !isOpen.formattedAssignments,
                          })
                        }
                      >
                        <FaPen />
                      </button>
                    </ToolTip>
                  )}
                </div>
              </div>
              {!!errorObj && errorObj["formattedAssignments"] && (
                <small className="form-text d-block text-red">
                  {errorObj["formattedAssignments"]}
                </small>
              )}
              {isOpen.formattedAssignments ? (
                <div className="mb-3">
                  {upTplan.formattedAssignments &&
                    upTplan.formattedAssignments.length > 0 &&
                    upTplan.formattedAssignments.map((item, index) => {
                      return (
                        <div className="row mt-2" key={index}>
                          <div className="col-md-1">
                            <p>{index + 1}</p>
                          </div>
                          <div className="col-md-10">
                            <div className="row">
                              <div className="col-xs-12 col-md-6">
                                {/* CkEditor for assignments */}
                                  <CkEditor
                                    data={item.que ?? ''}
                                    onChange={(e, editor) => {
                                      const data = editor.getData();
                                      // const data = e.target.value;
                                      const temp = [...upTplan.formattedAssignments];
                                      temp[index] = new QueWithTestCaseModel(data, temp[index]?.testCase ?? '' , item._id);
                                      setUpTplan({
                                        ...upTplan,
                                        formattedAssignments: temp,
                                      });
                                    }}
                                  />
                                </div>
                                <div className="col-xs-12 col-md-6">
                                  {/* Textarea for testcase */}
                                  <label
                                      className="w-100 h-100 fw-bold"
                                      >Write Test Cases (JSON)
                                    <textarea
                                      rows={13}
                                      className="w-100 overflow-y-auto"
                                      value={item.testCase ?? ''}
                                      onChange={(e) => {
                                        const data = e.target.value;
                                        // const data = e.target.value;
                                        const temp = [...upTplan.formattedAssignments];
                                        temp[index] = new QueWithTestCaseModel(temp[index]?.que ?? '', data, item._id);
                                        setUpTplan({
                                          ...upTplan,
                                          formattedAssignments: temp,
                                        });
                                      }}
                                      />
                                  </label>
                                </div>
                              </div>
                          </div>
                          <div className="col-md-1 my-auto">
                            <ToolTip title="Remove">
                              <button
                                className="btn my-2 btn-danger btn-sm"
                                onClick={(e) => {
                                  const temp = [
                                    ...upTplan.formattedAssignments,
                                  ];
                                  temp.splice(index, 1);
                                  setUpTplan({
                                    ...upTplan,
                                    formattedAssignments: temp,
                                  });
                                }}
                                type="button"
                              >
                                <FaMinus />
                              </button>
                            </ToolTip>
                          </div>
                        </div>
                      );
                    })}
                  <button
                    type="button"
                    className="btn btn-primary btn-sm mt-2"
                    onClick={() => {
                      const temp = [...upTplan.formattedAssignments];
                      temp.push("");
                      setUpTplan({
                        ...upTplan,
                        formattedAssignments: temp,
                      });
                    }}
                  >
                    {upTplan.formattedAssignments &&
                    upTplan.formattedAssignments.length < 1
                      ? "Add"
                      : "Add More"}
                  </button>
                </div>
              ) : (
                <>
                  {upTplan.formattedAssignments &&
                    upTplan.formattedAssignments.length > 0 &&
                    upTplan.formattedAssignments.map((item, index) => (
                      <div className="row mt-2" key={index}>
                        <div className="col-md-1">{index + 1}</div>
                        <div className="col-md-11">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: `<div>${item.que}</div><div>${item.testCase}</div>`,
                            }}
                          ></div>
                        </div>
                      </div>
                    ))}
                </>
              )}
            </div>

            <hr />

            <div className="d-flex my-2">
              <div className="d-flex">
                <div className="fw-bold me-4">
                  <p>Online References</p>
                </div>
              </div>
              <div className="">
                {isOpen.tp_onlineref ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            handleSave("tp_onlineref");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => discardChanges("tp_onlineref")}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <ToolTip title={"Edit"}>
                    <button
                      type="button"
                      className={`btn btn-submit me-2 bg-warning text-white btn-sm`}
                      onClick={() =>
                        setIsOpen({
                          ...isOpen,
                          tp_onlineref: !isOpen.tp_onlineref,
                        })
                      }
                    >
                      <FaPen />
                    </button>
                  </ToolTip>
                )}
              </div>
              <div>
                {isOpen.tp_onlineref && (
                  <small className="">
                    (Please add comma(,) between two link)
                  </small>
                )}
              </div>
            </div>

            {isOpen.tp_onlineref ? (
              <div className="mb-3">
                <textarea
                  id="tp_onlineref"
                  rows="10"
                  className="form-control"
                  name="tp_onlineref"
                  value={upTplan.tp_onlineref[0]}
                  onChange={(e) => {
                    setUpTplan((d) => ({
                      ...d,
                      tp_onlineref: [e.target.value],
                    }));
                  }}
                ></textarea>
              </div>
            ) : (
              <>
                {upTplan.tp_onlineref &&
                  upTplan.tp_onlineref.length > 0 &&
                  upTplan.tp_onlineref.map((item, index) => {
                    return (
                      <div className="row mt-2" key={index}>
                        {!!item &&
                          item
                            .split(",")
                            .map((onlineChildItem, onlineChildIndex) => {
                              return (
                                <>
                                  <div className="col-md-1">
                                    {index + onlineChildIndex + 1}
                                  </div>
                                  <div className="col-md-11">
                                    <div
                                      dangerouslySetInnerHTML={{
                                        __html: onlineChildItem,
                                      }}
                                      className="text-break"
                                    ></div>
                                  </div>
                                </>
                              );
                            })}
                      </div>
                    );
                  })}
              </>
            )}

            <hr />

            <div className="d-flex my-2">
              <div className="d-flex gap-4">
                <div className="fw-bold me-4">
                  <p>Video uploaded or not ?</p>
                </div>
                <div className="">
                  <input
                    type="radio"
                    name="upload"
                    id="yes"
                    onChange={(e) => {
                      setupload("yes");
                      videouploaded();
                      setIsOpen({
                        ...isOpen,
                        videoUpload: true,
                      });
                    }}
                    checked={videoupload === "yes" ? true : false}
                  />
                  <label
                    htmlFor="yes"
                    style={{ marginLeft: "2px", marginRight: "10px" }}
                  >
                    yes
                  </label>
                  <input
                    type="radio"
                    name="upload"
                    id="no"
                    onChange={(e) => {
                      setupload("no");
                      setVideos([]);
                      setIsOpen({ ...isOpen, videoUpload: true });
                    }}
                    checked={videoupload === "no" ? true : false}
                  />
                  <label htmlFor="no" style={{ marginLeft: "2px" }}>
                    no
                  </label>
                </div>
                {isOpen.videoUpload && videos.length ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            setIsOpen({
                              ...isOpen,
                              videoUpload: false,
                            });
                            handleSave("videoUpload");
                            setupload("");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => {
                            discardChanges("videoUpload");
                            setupload("");
                          }}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col col-6">
                {planVideos.length > 0 ? (
                  <div>
                    {planVideos.map((item, index) => {
                      return (
                        <>
                          <ul className="" key={index}>
                            <li htmlFor={item.filename} className="">
                              {item.filename.split("-").slice(1, 5).join(" ")}{" "}
                            </li>
                          </ul>
                        </>
                      );
                    })}
                  </div>
                ) : (
                  <p>No videos set in this training plan</p>
                )}
              </div>
              <div className="col col-6">
                <div className={videoupload === "yes" ? "mb-3" : "d-none"}>
                  <>
                    {videos.length > 0 ? (
                      <>
                        {videos.map((item, index) => {
                          return (
                            <>
                              {(item.day === 0 || !item.day) && (
                                <div className="col form-check" key={index}>
                                  <input
                                    type="checkbox"
                                    id={item.filename}
                                    placeholder="Password"
                                    className="form-check-input"
                                    value={item.filename}
                                    onChange={(e) => {
                                      return handlecheck(e);
                                    }}
                                  />
                                  <label
                                    htmlFor={item.filename}
                                    className="form-check-label color-dback"
                                  >
                                    {item.filename}
                                  </label>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <p>No videos in this course or all videos are set</p>
                    )}
                  </>
                </div>
              </div>
            </div>

            <hr />

            <div className="d-flex">
              <div className="d-flex gap-4">
                <div className="fw-bold me-4">
                  <p>Ppt uploaded or not ?</p>
                </div>
                <div className="mb-3">
                  <input
                    type="radio"
                    name="pptupload"
                    id="pptUploadYes"
                    onChange={(e) => {
                      setpptupload("yes");
                      pptuploaded();
                      setIsOpen({
                        ...isOpen,
                        pptUpload: true,
                      });
                    }}
                    checked={pptupload === "yes" ? true : false}
                  />
                  <label
                    htmlFor="yes"
                    style={{ marginLeft: "2px", marginRight: "10px" }}
                  >
                    yes
                  </label>
                  <input
                    type="radio"
                    name="pptupload"
                    id="pptUploadNo"
                    onChange={(e) => {
                      setpptupload("no");
                      setIsOpen({
                        ...isOpen,
                        pptUpload: true,
                      });
                      setPpts([]);
                    }}
                    checked={pptupload === "no" ? true : false}
                  />
                  <label htmlFor="no" style={{ marginLeft: "2px" }}>
                    no
                  </label>
                </div>
                {isOpen.pptUpload && ppts.length ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            setIsOpen({
                              ...isOpen,
                              pptUpload: false,
                            });
                            handleSave("pptUpload");
                            setpptupload("");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => {
                            discardChanges("pptUpload");
                            setpptupload("");
                          }}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="row">
              <div className="col col-6">
                {!!planPpts && planPpts.length > 0 ? (
                  <div>
                    {planPpts.map((item, index) => {
                      return (
                        <ul className="" key={index}>
                          <li htmlFor={item.filename} className="">
                            {item.filename.split("/")[7]}
                          </li>
                        </ul>
                      );
                    })}
                  </div>
                ) : (
                  <p>No ppts set in this training plan</p>
                )}
              </div>
              <div className="col col-6">
                <div className={pptupload === "yes" ? "mb-3" : "d-none"}>
                  <>
                    {ppts.length > 0 ? (
                      <>
                        {ppts.map((item, index) => {
                          return (
                            <>
                              {(item.day === 0 || !item.day) && (
                                <div className="col form-check" key={index}>
                                  <input
                                    type="checkbox"
                                    id={item.filename}
                                    placeholder="Password"
                                    className="form-check-input"
                                    value={item.filename}
                                    onChange={(e) => {
                                      return handlepptcheck(e);
                                    }}
                                  />
                                  <label
                                    htmlFor={item.filename}
                                    className="form-check-label color-dback"
                                  >
                                    {item.filename.split("/")[7]}
                                  </label>
                                </div>
                              )}
                            </>
                          );
                        })}
                      </>
                    ) : (
                      <p>No ppts in this course or all videos are set</p>
                    )}
                  </>
                </div>
              </div>
            </div>

            <hr />

            <div className="my-2">
              <div className="">
                <div className="fw-bold me-4">
                  <p>Supported Files</p>
                </div>
              </div>
              <div className="">
                {isOpen.supportedFilesUpload ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            handleSave("supportedFilesUpload");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => {
                            discardChanges("supportedFilesUpload");
                          }}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <div className="d-flex">
                    {upTplan?.tp_supportedfiles?.length > 0 &&
                      typeof upTplan.tp_supportedfiles[0] === "string" &&
                      upTplan.tp_supportedfiles.map((item, onindex) => {
                        const splitItems = item.split("-");
                        const imgText = splitItems
                          .slice(1, splitItems.length)
                          .join("-");
                        return (
                          <div className="me-2" key={onindex}>
                            <button
                              onClick={() =>
                                window.open(`${imgURL}/${item}`, "_blank")
                              }
                              className="btn m-0 p-0"
                              data-toggle="tooltip"
                              data-placement="right"
                              title={imgText}
                              type="button"
                            >
                              <img
                                src={`${imgURL}/${item}`}
                                width="100"
                                height="100"
                                className="border"
                                alt={item}
                              ></img>
                            </button>
                          </div>
                        );
                      })}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <input
                type="file"
                id="tp_supportedfiles"
                className="form-control"
                name="tp_supportedfiles"
                multiple
                onChange={(e) => {
                  setUpTplan((d) => ({
                    ...d,
                    tp_supportedfiles: e.target.files,
                  }));
                  setIsOpen({
                    ...isOpen,
                    supportedFilesUpload: true,
                  });
                }}
              />
            </div>

            <hr />

            <div className="my-2">
              <div className="">
                <div className="fw-bold me-4">
                  <p>Practice Reference Images</p>
                </div>
              </div>
              <div className="">
                {isOpen.tp_practiceimgarr ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            // setIsOpen({
                            //   ...isOpen,
                            //   tp_practiceimgarr: false,
                            // });
                            handleSave("tp_practiceimgarr");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => {
                            discardChanges("tp_practiceimgarr");
                          }}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <div>
                    {upTplan?.tp_practiceimgarr?.length > 0 &&
                      typeof upTplan.tp_practiceimgarr[0] === "string" && (
                        <div className="d-flex">
                          {upTplan?.tp_practiceimgarr?.map((item, pindex) => {
                            const splitItems = item.split("-");
                            const imgText = splitItems
                              .slice(1, splitItems.length)
                              .join("-");
                            return (
                              <div className="me-2" key={pindex}>
                                <button
                                  onClick={() =>
                                    window.open(`${imgURL}/${item}`, "_blank")
                                  }
                                  className="btn m-0 p-0"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title={imgText}
                                  type="button"
                                >
                                  <img
                                    src={`${imgURL}/${item}`}
                                    width="100"
                                    height="100"
                                    className="border"
                                    alt={item}
                                  ></img>
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <input
                type="file"
                id="tp_practiceimgarr"
                className="form-control"
                name="tp_practiceimgarr"
                accept="image/*"
                multiple
                onChange={(e) => {
                  setUpTplan((d) => ({
                    ...d,
                    tp_practiceimgarr: e.target.files,
                  }));
                  setIsOpen({
                    ...isOpen,
                    tp_practiceimgarr: true,
                  });
                }}
              />
            </div>

            <hr />

            <div className="my-2">
              <div className="">
                <div className="fw-bold me-4">
                  <p>Assignment Reference Images</p>
                </div>
              </div>
              <div className="">
                {isOpen.tp_assignmentimgarr ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            // setIsOpen({
                            //   ...isOpen,
                            //   tp_assignmentimgarr: false,
                            // });
                            handleSave("tp_assignmentimgarr");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => {
                            discardChanges("tp_assignmentimgarr");
                          }}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <div className="">
                    {upTplan?.tp_assignmentimgarr?.length > 0 &&
                      typeof upTplan.tp_assignmentimgarr[0] === "string" && (
                        <div className="d-flex">
                          {upTplan?.tp_assignmentimgarr?.map((item, pindex) => {
                            const splitItems = item.split("-");
                            const imgText = splitItems
                              .slice(1, splitItems.length)
                              .join("-");
                            return (
                              <div className="me-2" key={pindex}>
                                <button
                                  onClick={() =>
                                    window.open(`${imgURL}/${item}`, "_blank")
                                  }
                                  className="btn m-0 p-0"
                                  data-toggle="tooltip"
                                  data-placement="right"
                                  title={imgText}
                                  type="button"
                                >
                                  <img
                                    src={`${imgURL}/${item}`}
                                    width="100"
                                    height="100"
                                    className="border"
                                    alt={item}
                                  ></img>
                                </button>
                              </div>
                            );
                          })}
                        </div>
                      )}
                  </div>
                )}
              </div>
            </div>

            <div className="mb-3">
              <input
                type="file"
                accept="image/*"
                id="tp_assignmentimgarr"
                className="form-control"
                name="tp_assignmentimgarr"
                multiple
                onChange={(e) => {
                  setUpTplan((d) => ({
                    ...d,
                    tp_assignmentimgarr: e.target.files,
                  }));
                  setIsOpen({
                    ...isOpen,
                    tp_assignmentimgarr: true,
                  });
                }}
              />
            </div>

            <hr />

            <div className="d-flex my-2">
              <div className="me-4">
                <p className="fw-bold">Note</p>
              </div>
              <div className="">
                {isOpen.formattedNotePlan ? (
                  <div className="d-flex">
                    <div>
                      <ToolTip title={"Save changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-success text-white btn-sm`}
                          onClick={() => {
                            handleSave("formattedNotePlan");
                          }}
                        >
                          <FaCheck />
                        </button>
                      </ToolTip>
                    </div>
                    <div>
                      <ToolTip title={"Discard changes"}>
                        <button
                          type="button"
                          className={`btn btn-submit me-2 bg-danger text-white btn-sm`}
                          onClick={() => discardChanges("formattedNotePlan")}
                        >
                          <FaTimes />
                        </button>
                      </ToolTip>
                    </div>
                  </div>
                ) : (
                  <ToolTip title={"Edit"}>
                    <button
                      type="button"
                      className={`btn btn-submit me-2 bg-warning text-white btn-sm`}
                      onClick={() =>
                        setIsOpen({
                          ...isOpen,
                          formattedNotePlan: !isOpen.formattedNotePlan,
                        })
                      }
                    >
                      <FaPen />
                    </button>
                  </ToolTip>
                )}
              </div>
            </div>
            {isOpen.formattedNotePlan ? (
              <div className="mb-3">
                <CkEditor
                  data={upTplan.formattedNotePlan}
                  onChange={(e, editor) => {
                    const data = editor.getData();
                    setUpTplan((d) => ({
                      ...d,
                      formattedNotePlan: data,
                    }));
                  }}
                />
              </div>
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: upTplan.formattedNotePlan,
                }}
              ></div>
            )}
          </div>
        ) : null}
        {/* new code */}
      </div>
    </div>
  );
}
