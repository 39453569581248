import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import formloader from "../../images/formloading.svg";
import CourseService from "../../services/CourseService";
import global from "../../servicesvariable";
import { handleContinue } from "../../utils/course/handleContinue";
import { handleImageLoadingError } from "../../utils/image/handleImageLoadingError";
import "./myLearningCard.scss";

export default function MyLearningCard(props) {
  const { user, tcounter, continueLearning } = props;
  const [subcourses, setSubCourses] = useState([]);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  useEffect(() => {
    setLoading(true);

    const token = localStorage.getItem("token");
    var counter = 0;

    if (token) {
      const decoded = jwt_decode(token);
      CourseService.getSubCourses()
        .then((res) => {
          if (res.data) {
            setSubCourses(res.data);
          }
          setLoading(false);
        })
        .catch((ex) => {});
    }
  }, []);

  return (
    <>
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">Loading...</div>
          </div>
        </div>
      )}
      {continueLearning
        .sort((a, b) => b.progress - a.progress)
        .map((clearning) => {
          if (clearning.progress != 100) {
            return (
              <>
                <a
                  href="javascript:void()"
                  class="card mylearn-card mb-3 text-decoration-none"
                >
                  {/* homes.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)); */}
                  <div class="row g-0">
                    <div class="col-xl-4 col-3 course-img">
                      {clearning.courseId.icon === "default.jpg" ? (
                        <img
                          src={`./../${clearning.courseId.icon}`}
                          class="img-fluid"
                          alt="Course Title"
                          data-level="1"
                          onError={(e) => {if(parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e)}}
                        />
                      ) : clearning.courseId.icon.match(/http/g) ? (
                        <img
                          src={clearning.courseId.icon}
                          class="img-fluid"
                          alt="Course Title"
                          data-level="1"
                          onError={(e) => {if(parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e)}}
                        />
                      ) : (
                        <>
                          <img
                            src={`${global.imgURL}/${clearning.courseId.icon}`}
                            class="img-fluid"
                            alt={clearning.courseId.name}
                            data-level="1"
                            onError={(e) => {if(parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e)}}
                          ></img>
                        </>
                      )}
                    </div>
                    <div class="col-xl-8 col-9">
                      <div class="card-body p-lg-3 d-flex flex-wrap h-100 flex-column justify-content-between">
                        <h4 class="card-title text-black font-weight-400">
                          {clearning.courseId.name
                            .replaceAll("_", "  ")
                            .replaceAll("-", " ")}
                        </h4>
                        <p
                          class="card-text font-14 text-dark d-none"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "3",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {clearning.courseId.desc}
                        </p>
                        {/* homes.sort((a, b) => parseFloat(a.price) - parseFloat(b.price)); */}
                        {clearning.progress !== 100 ? (
                          <div class="d-flex justify-content-between align-items-center flex-wrap">
                            <div class="pt-lg-3 pt-2">
                              <p class="text-dark-gray font-14 mb-2">
                                <span>
                                  <strong>{clearning.progress}%</strong>{" "}
                                  Complete
                                </span>{" "}
                                <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>{" "}
                                <span>
                                  <strong>
                                    {(() => {
                                      const t = [];
                                      for (
                                        let i = 0;
                                        i < tcounter.length;
                                        i++
                                      ) {
                                        t.push(
                                          <>
                                            {tcounter[i].name ==
                                              clearning.courseId.name && (
                                              <>
                                                {Math.ceil(tcounter[i].length)}
                                              </>
                                            )}
                                          </>
                                        );
                                      }
                                      return t;
                                    })()}
                                  </strong>{" "}
                                  Hours Left
                                </span>
                              </p>
                              <div class="course-progressbar position-relative">
                                <div class="position-absolute h-100 w-100 bg-border-color"></div>
                                {clearning.progress > 75 ? (
                                  <div
                                    class="position-absolute h-100 bg-success"
                                    style={{ width: `${clearning.progress}%` }}
                                  ></div>
                                ) : (
                                  <div
                                    class="position-absolute h-100 bg-secondary"
                                    style={{ width: `${clearning.progress}%` }}
                                  ></div>
                                )}
                              </div>
                            </div>
                            {subcourses.map((subcourse) => {
                              if (subcourse.name === clearning.courseId.name) {
                                return (
                                  <div
                                    onClick={() => {
                                      const urlString = handleContinue(subcourse.type.name, clearning.courseId.name, subcourse.tplans, subcourse.videos, subcourse.ppts)
                                      if(urlString) {
                                        navigate(urlString);
                                      }
                                    }}
                                    class="text-primary d-lg-block pt-lg-3 pt-2 cursor-pointer"
                                  >
                                    Continue
                                  </div>
                                );
                              }
                            })}
                          </div>
                        ) : (
                          <div class="d-flex justify-content-between pt-lg-3 pt-2 align-items-center">
                            <div class="w-100">
                              <p class="text-success font-14 mb-2">
                                <span>Completed</span>{" "}
                              </p>
                              <div class="course-progressbar position-relative">
                                <div class="position-absolute h-100 w-100 bg-border-color"></div>
                                <div class="position-absolute h-100 w-100 bg-success"></div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </a>
                {/* {user.map((item, index) => {
                  if (item._id != clearning.courseId._id) {
                    return handleUser(item);
                  }
                })} */}
              </>
            );
          }
        })}
    </>
  );
}
