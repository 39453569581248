export const UlLi = {
    controls: [{
        type: "text",
        name: "selector",
        required: true
    },
    {
        type: "array",
        name: "data",
        required: true
    }
    ]
}