export const SortingModel = {
    controls: [{
        type: "text",
        name: "sortingButtonSelector",
        required: true
    },
    {
        type: "text",
        name: "outputSelector",
        required: true
    }
    ]
}