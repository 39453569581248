import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import {
  FaCheck,
  FaCheckSquare,
  FaChevronLeft,
  FaChevronRight,
  FaSpinner,
  FaTimes,
  FaWindowClose,
} from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import CourseService from "../../services/CourseService";

export default function UpdateContentRequests(props) {
  const navigate = useNavigate();
  const [page, setPage] = useState({ selected: 1, choice: "" });
  const [trainerRequest, setTrainerRequest] = useState([]);
  const [msg, setMsg] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [contentId, setContentId] = useState("");
  const [confirmBox, setConfirmBox] = useState(false);
  const [someusers, setsomeUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [pageno, setPageno] = useState(1);
  const [filterValues, setFilterValues] = useState({});
  const [adminRequest, setAdminRequest] = useState({
    adminId: jwt_decode(localStorage.getItem("token")).id,
    adminRemark: "",
    dayNumber: 0,
    Status: "",
  });
  const Options = [
    { label: "Approve", value: "status1" },
    { label: "Reject", value: "status2" },
    { label: "Pending", value: "status3" },
  ];

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      CourseService.getAllContent(token).then((res) => {
        if (res.data && res.data.length > 0) {
          setTrainerRequest(res.data.reverse());
          setsomeUsers(res.data.reverse());
        } else {
          setMsg("Requests Are Not Founded");
        }
      });
    }
    // window.addEventListener("scroll", toggleVisible);
  }, [confirmBox]);

  const handleChange = (e) => {
    if (e == null) {
      setConfirmBox(!confirmBox);
    } else {
      const filterData = trainerRequest.filter(
        (item) => item.Status === e.label
      );
      if (filterData.length > 0) {
        setTrainerRequest(filterData.reverse());
        setsomeUsers(filterData.reverse());
      } else {
        setMsg("No Data Avilable");
        setTrainerRequest([]);
      }
    }
  };

  const handleSubmit = () => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (adminRequest.Status === "Approve") {
        if (adminRequest.dayNumber > 0 && adminRequest.adminRemark !== "") {
          CourseService.putContent(adminRequest, contentId, token).then(
            (res) => {
              setConfirmBox(true);
              setOpenModal(!openModal);
              setAdminRequest({
                adminId: jwt_decode(localStorage.getItem("token")).id,
                adminRemark: "",
                dayNumber: 0,
                Status: "",
              });
            }
          );
        }
      } else {
        if (adminRequest.adminRemark !== "") {
          CourseService.putContent(adminRequest, contentId, token).then(
            (res) => {
              setConfirmBox(true);
              setOpenModal(!openModal);
              setAdminRequest({
                adminId: jwt_decode(localStorage.getItem("token")).id,
                adminRemark: "",
                dayNumber: 0,
                Status: "",
              });
            }
          );
        }
      }
    }
  };

  const handlecount = (count) => {
    setTrainerRequest(
      someusers.map((item, index) => {
        if (index >= count * 10 - 10 && index < count * 10) {
          return item;
        }
      })
    );
    setPageno(count);
  };

  const handlePagination = (useritem) => {
    return (
      page.selected === 1 && (
        <div className="col-lg-12 d-flex justify-content-center">
          <nav className="mt-4 p-0 pagefooter">
            <ul className="pagination row g-1" id="pageno">
              <li className="page-item col-auto">
                <button
                  className="btn page-link px-3 py-2 shadow-none"
                  disabled={pageno === 1 ? true : false}
                  onClick={() => {
                    handlecount(pageno - 1);
                  }}
                >
                  <FaChevronLeft className="text-muted" />
                </button>
              </li>
              {(() => {
                const element = [];

                for (
                  let index = 1;
                  index <= Math.ceil(useritem.length / 10);
                  index++
                ) {
                  element.push(
                    <li className="page-item col-auto">
                      <button
                        className={
                          pageno === index
                            ? "page-link rounded-pill px-3 py-2 active bg-primary text-white shadow-none"
                            : "page-link rounded-pill border border-primary px-3 py-2 "
                        }
                        onClick={() => {
                          handlecount(index);
                        }}
                      >
                        {index}
                      </button>
                    </li>
                  );
                }
                return element;
              })()}
              <li className="page-item col-auto">
                <button
                  className="btn page-link px-3 py-2 shadow-none"
                  disabled={
                    pageno === Math.ceil(useritem.length / 10) ? true : false
                  }
                  onClick={() => {
                    handlecount(pageno + 1);
                  }}
                >
                  <FaChevronRight className="text-muted" />
                </button>
              </li>
            </ul>
          </nav>
        </div>
      )
    );
  };

  let closeModal = () => {
    setOpenModal(!openModal);
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li
              className={
                page.selected === 1
                  ? "breadcrumb-item active"
                  : "breadcrumb-item text-primary"
              }
              style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
              onClick={() => {
                setPage({ selected: 1, choice: "" });
              }}
            >
              Requests
            </li>
            <li
              className={
                page.selected === 2 ? "breadcrumb-item active" : "d-none"
              }
            >
              {page.choice}
            </li>
          </ol>
        </nav>
      </div>
      <div className="container p-2 px-3">
        <h4 className={page.selected !== 1 ? "d-none" : "mt-2"}>
          All Update Content Request
        </h4>
        <div class="mt-4 row">
          <input
            className="col-lg-8 col-md-4 col-sm-12 py-2 me-5"
            type="search"
            placeholder="search by name"
            onChange={(e) => setSearch(e.target.value)}
            value={search}
          />
          <div className="col-lg-3 col-md-4 col-sm-12 ms-5">
            <Select
              placeholder="Apply Filters ..."
              isClearable={true}
              options={Options}
              className="basic-multi-select"
              classNamePrefix="select"
              onChange={handleChange}
            />
          </div>
        </div>
        {trainerRequest && trainerRequest.length > 0 ? (
          <table class="table mt-4">
            <thead>
              <tr>
                <th scope="col">#</th>

                <th scope="col">Trainer Name</th>

                <th scope="col">Course</th>

                <th scope="col">Content</th>

                <th scope="col">Remark</th>
                <th scope="col">Status</th>

                <th scope="col">Handle</th>
              </tr>
            </thead>

            <tbody>
              {trainerRequest
                .filter((item) => {
                  if (search === "") {
                    return item;
                  } else if (
                    item.trainerId.username
                      .toLowerCase()
                      .includes(search.toLowerCase())
                  ) {
                    return item;
                  }
                })
                .map((item, index) => {
                  if (item !== undefined && index < pageno * 10) {
                    return (
                      <tr>
                        <td>{index + 1}</td>
                        <td>{item.trainerId.username}</td>
                        <td>{item.cid.name}</td>
                        <td>{item.contentName}</td>
                        <td>{item.Remark}</td>
                        {item.Status === "Pending" && (
                          <td>
                            <FaSpinner className="fs-5 text-danger " />
                          </td>
                        )}
                        {item.Status === "Approve" && (
                          <td>
                            <FaCheckSquare className="fs-5 text-success " />
                          </td>
                        )}
                        {item.Status === "Reject" && (
                          <td>
                            <FaWindowClose className="fs-5 text-danger" />
                          </td>
                        )}

                        <td>
                          <button
                            class="btn btn-warning px-2 py-1 mx-1 fs-8"
                            onClick={(e) => {
                              setAdminRequest((d) => ({
                                ...d,
                                Status: "Approve",
                              }));
                              setOpenModal(!openModal);
                              setContentId(item._id);
                            }}
                          >
                            <FaCheck className="color-white text-white" />
                          </button>
                          <button
                            // onClick={() => {
                            //   handleDelete(item._id);
                            // }}
                            onClick={(e) => {
                              setAdminRequest((d) => ({
                                ...d,
                                Status: "Reject",
                              }));
                              setOpenModal(!openModal);
                              setContentId(item._id);
                            }}
                            class="btn btn-secondary px-2 py-1 mx-1"
                          >
                            <FaTimes className="color-white text-white" />
                          </button>
                        </td>
                      </tr>
                    );
                  }
                })}
            </tbody>
          </table>
        ) : (
          <h5 class="text-danger fw-bold mt-5 text-center">{msg}</h5>
        )}
        {trainerRequest &&
          trainerRequest.length > 0 &&
          handlePagination(trainerRequest)}
        <div>
          <Modal
            show={openModal}
            onHide={closeModal}
            style={{ marginTop: "5%" }}
            aria-labelledby="ModalHeader"
          >
            <Modal.Header>
              <Modal.Title>Add Day Number And Remark</Modal.Title>
              <button
                onClick={(e) => {
                  setOpenModal(!openModal);
                  setAdminRequest({
                    adminRemark: "",
                    dayNumber: 0,
                    Status: "",
                  });
                }}
              >
                <FaTimes />
              </button>
            </Modal.Header>
            <Modal.Body>
              {adminRequest.Status !== "Reject" && (
                <div class="row g-3 align-items-center mb-3">
                  <div class="col-md-3">
                    <label for="inputPassword6" class="col-form-label">
                      day Number
                    </label>
                  </div>
                  <div class="col-md-9">
                    <input
                      type="number"
                      class="form-control"
                      value={adminRequest.dayNumber}
                      onChange={(e) =>
                        setAdminRequest((d) => ({
                          ...d,
                          dayNumber: e.target.value,
                        }))
                      }
                    />
                  </div>
                </div>
              )}
              <div class="row g-3 align-items-center">
                <div class="col-md-3">
                  <label for="inputPassword6" class="col-form-label">
                    Remark
                  </label>
                </div>
                <div class="col-md-9">
                  <textarea
                    id="tp_wtl"
                    rows="3"
                    className="form-control"
                    name="Remark"
                    value={adminRequest.adminRemark}
                    onChange={(e) =>
                      setAdminRequest((d) => ({
                        ...d,
                        adminRemark: e.target.value,
                      }))
                    }
                  ></textarea>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button className="btn btn-primary" onClick={handleSubmit}>
                Submit Request
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}
