import React, { useEffect, useState } from "react";
export default function VanilaJsModal({
  isEdit,
  actionName,
  actionArray,
  index,
  setTestCase,
  testCase,
  getInputValue,
  handleInputValueType,
  manageFormsArrayLists,
  getFormList,
}) {
  const [modalList, setModalList] = useState(actionArray);

  useEffect(() => {
    if (isEdit) {
      const getFormData = getFormList(index);
      if (getFormData) {
        setModalList(
          getFormData?.[actionName] ? getFormData[actionName] : actionArray
        );
      }
    }
  }, [index]);

  const deleteModalList = (childIndex) => {
    const copyModalList = [...modalList];
    const copyTestCase = { ...testCase };
    const getActionObject = copyTestCase["actions"][index];
    copyModalList.splice(childIndex, 1);
    if (getActionObject[actionName]) {
      getActionObject[actionName] = copyModalList;
      setTestCase(copyTestCase);
    }
    setModalList(copyModalList);
  };

  const setModalValue = (e, childIndex, type) => {
    let { name, value } = e.target;
    value = handleInputValueType(value, type);
    const copyModalList = [...modalList];
    const copyTestCase = { ...testCase };
    const getActionObject = copyTestCase["actions"][index];
    copyModalList[childIndex][name] = value;
    const findModalObject = copyModalList[childIndex + 1];
    if (!findModalObject) {
      const modalKeyName = Object.keys(modalList[0]);
      let addObject = {};
      modalKeyName.forEach((key) => (addObject[key] = ""));
      // const handleType = modalKeyName[0] === "controlId" ? "" : [];
      copyModalList.push(addObject);
    }
    getActionObject[actionName] = copyModalList;
    manageFormsArrayLists(index, actionName, copyModalList);
    setTestCase(copyTestCase);
    setModalList(copyModalList);
  };

  return (
    <div className="col-md-12 row">
      <div className="col-md-2">{actionName}</div>
      <div className="col-md-10 row">
        {modalList.map((item, childIndex) => {
          const ObjectName = Object.keys(item);
          const handlePlaceHolderValue =
            ObjectName[0] === "controlId"
              ? { type: "string", placeholder: "ex: test" }
              : { type: "array", placeholder: "ex: dummy,10,test " };
          return (
            <>
              {ObjectName.length === 2 ? (
                <>
                  <div className="col-md-5 my-2">
                    <input
                      type="text"
                      name={ObjectName[0]}
                      placeholder={`${ObjectName[0]} ${handlePlaceHolderValue.placeholder}`}
                      className="w-100"
                      value={getInputValue(
                        actionName,
                        index,
                        childIndex,
                        ObjectName[0]
                      )}
                      onChange={(e) =>
                        setModalValue(
                          e,
                          childIndex,
                          handlePlaceHolderValue.type
                        )
                      }
                    />
                  </div>
                  <div className="col-md-5 my-2">
                    <input
                      type="text"
                      name={ObjectName[1]}
                      placeholder={`${ObjectName[1]} ex: test,10,dummy`}
                      className="w-100"
                      value={getInputValue(
                        actionName,
                        index,
                        childIndex,
                        ObjectName[1]
                      )}
                      onChange={(e) => setModalValue(e, childIndex, "array")}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="col-md-2 my-2">
                    <input
                      type="text"
                      name={ObjectName[0]}
                      placeholder={`${ObjectName[0]} ex: 10`}
                      className="w-100"
                      value={getInputValue(
                        actionName,
                        index,
                        childIndex,
                        ObjectName[0]
                      )}
                      onChange={(e) => setModalValue(e, childIndex, "number")}
                    />
                  </div>
                  <div className="col-md-2 my-2">
                    <input
                      type="text"
                      name={ObjectName[1]}
                      placeholder={`${ObjectName[1]} ex: test`}
                      className="w-100"
                      value={getInputValue(
                        actionName,
                        index,
                        childIndex,
                        ObjectName[1]
                      )}
                      onChange={(e) => setModalValue(e, childIndex, "text")}
                    />
                  </div>
                  <div className="col-md-2 my-2">
                    <input
                      type="text"
                      name={ObjectName[2]}
                      placeholder={`${ObjectName[2]} ex: 10`}
                      className="w-100"
                      value={getInputValue(
                        actionName,
                        index,
                        childIndex,
                        ObjectName[2]
                      )}
                      onChange={(e) => setModalValue(e, childIndex, "number")}
                    />
                  </div>
                  <div className="col-md-2 my-2">
                    <input
                      type="text"
                      name={ObjectName[3]}
                      placeholder={`${ObjectName[3]} ex:test`}
                      className="w-100"
                      value={getInputValue(
                        actionName,
                        index,
                        childIndex,
                        ObjectName[3]
                      )}
                      onChange={(e) => setModalValue(e, childIndex, "text")}
                    />
                  </div>
                </>
              )}
              {childIndex !== 0 && (
                <div className="col-md-2 my-2">
                  <button
                    type="button"
                    class="rounded bg-danger"
                    onClick={() => deleteModalList(childIndex)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      className="bi bi-trash"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                      <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                    </svg>
                  </button>
                </div>
              )}
            </>
          );
        })}
      </div>
      {/* <div className="col-md-2">
        <button
          type="button"
          className="btn btn-success"
          onClick={() => {
            const modalKeyName = Object.keys(modalList[0]);
            const handleType = modalKeyName[0] === "controlId" ? "" : [];
            setModalList([
              ...modalList,
              { [modalKeyName[0]]: handleType, [modalKeyName[1]]: [] },
            ]);
          }}
        >
          + Add Case
        </button>
      </div> */}
    </div>
  );
}
