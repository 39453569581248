import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

export default function ToolTip({ title, children, placement }) {
  return (
    <OverlayTrigger
      placement={placement ? placement : "top"}
      overlay={<Tooltip id={`tooltip-top`}>{title}</Tooltip>}
    >
      {children}
    </OverlayTrigger>
  );
}
