import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CourseService from "../../services/CourseService.js";
import TestCaseService from "../../services/TestCaseService.js";
import CommonActionForm from "./CommonActionForm.jsx";
import VanilaJsModal from "./VanilaJsModal.jsx";

export default function CreateTestCaseActions(props) {
  const [modalsList, setModalList] = useState([]);
  const [testCaseType, setTestCaseType] = useState("actions");
  const [testCase, setTestCase] = useState({
    name: "",
    actions: [{ action: "" }],
  });
  const [manageFormsChildArray, setManageFormsChildArray] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  useEffect(() => {
    getModalList(testCaseType);
  }, []);

  const getModalList = (actionType) => {
    if (token) {
      CourseService.getModalList(token, actionType)
        .then((res) => {
          setModalList(res.data.modalList);
        })
        .catch((err) => {});
    } else {
      navigate("/login");
    }
  };

  const handleTestCaseActions = (value, index) => {
    const testCaseActions = { ...testCase };
    const getActionObjet = testCaseActions["actions"][index];
    Object.keys(getActionObjet).forEach((key) => {
      delete getActionObjet[key];
    });
    getActionObjet["action"] = value;
    const getActionData = testCaseActions["actions"][index + 1];
    if (!getActionData) {
      testCaseActions["actions"].push({ action: "" });
    }
    setTestCase(testCaseActions);
  };

  const handleSaveCase = (e) => {
    e.preventDefault();
    // Filter out actions with empty or null values in the new test case
    const filteredActions = testCase.actions.filter(
      (action) => action.action && action.action.trim() !== ""
    );

    let setActiontype = testCaseType === "actions" ? true : false;

    if (filteredActions.length > 0) {
      const postTestCaseData = {
        ActionType: setActiontype,
        ActionName: testCase.name,
        Actions: filteredActions,
        ManageFormArray: manageFormsChildArray,
      };
      TestCaseService.postActions(token, postTestCaseData)
        .then((res) => {
          if (res.status === 201) {
            alert("Action saved successfully in database");
          }
        })
        .catch((err) => {
          console.log(Object.values(err));
          alert(
            "Something went wrong plese try again or contact administrator"
          );
        });
    } else {
      alert("Atleast select one action to save data");
      setTestCase({
        name: testCase.name,
        actions: [{ action: "" }],
      });
    }

    // Reset the state
    setTestCase({ name: "", actions: [{ action: "" }] });
  };

  const handleTestCaseType = (e) => {
    const { name, value } = e.target;
    setTestCaseType(value);
    setTestCase({ name: testCase.name, actions: [{ action: "" }] });
    getModalList(value);
  };

  const handleInputValueType = (value, type, checked = false) => {
    if (type === "boolean") {
      return (value = checked);
    } else if (type === "number") {
      return (value = parseInt(value?.trim()));
    } else if (type === "array") {
      return (value = value?.split(",").map((val) => val?.trim()));
    } else {
      return value?.trim();
    }
  };

  const getInputValue = (values, mainIndex, childIndex = 0, objctName = "") => {
    const splitValues = values?.split("-");
    const Object = testCase["actions"][mainIndex];
    let value;
    splitValues.forEach((valueName) => {
      if (!value) {
        value = Object?.[valueName];
      } else {
        value = value?.[valueName];
      }
    });
    return objctName ? value?.[childIndex]?.[objctName] : value || "";
  };

  const manageFormsArrayLists = (index, name, value) => {
    const findManageArray = manageFormsChildArray.find(
      (action) => action.action === index
    );
    if (findManageArray) {
      setManageFormsChildArray((manageFormsChildArray) =>
        manageFormsChildArray.map((action) => {
          if (action.action === index) {
            return {
              ...action,
              [name]: value,
            };
          }
          return action;
        })
      );
    } else {
      const object = {
        action: index,
        [name]: value,
      };
      setManageFormsChildArray((manageFormsChildArray) => [
        ...manageFormsChildArray,
        object,
      ]);
    }
  };

  return (
    <>
      <div className="text-center my-4 h3">Create Test Case</div>
      <form className="mx-5" onSubmit={handleSaveCase}>
        <div className="row">
          <div className="col-md-3">
            <input
              type="search"
              className="w-75 input input-group-lg"
              name="name"
              value={testCase.name}
              placeholder="Write a test case name"
              onChange={(e) =>
                setTestCase({ ...testCase, name: e.target.value })
              }
              required
            />
          </div>
          <div className="col-md-2">
            <input
              type="radio"
              checked={testCaseType === "actions"}
              value="actions"
              onClick={(e) => handleTestCaseType(e)}
            />{" "}
            Actions
            <input
              type="radio"
              checked={testCaseType === "evaluate"}
              value="evaluate"
              className="ms-2"
              onClick={(e) => handleTestCaseType(e)}
            />{" "}
            Evaluate
          </div>
        </div>
        <h3 className="px-2">{testCaseType}</h3>
        <div className="row">
          {testCase?.["actions"]?.length > 0 &&
            testCase["actions"].map((action, index) => {
              return (
                <>
                  <div className="col-md-2 my-2">
                    <select
                      className="form-select"
                      id="actionList"
                      value={action.action}
                      name="actionList"
                      onChange={(e) => {
                        handleTestCaseActions(e.target.value, index);
                      }}
                    >
                      <option value="">Select Actions</option>
                      {modalsList?.map((modal, index) => {
                        return (
                          <option value={modal.Id} key={index}>
                            {modal?.ModalName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                  <div className="col-md-9 row  my-2 mx-1">
                    {action?.action && action.action !== "8" && (
                      <CommonActionForm
                        isEdit={false}
                        actionName={action.action}
                        index={index}
                        setTestCase={setTestCase}
                        testCase={testCase}
                        handleInputValueType={handleInputValueType}
                        getInputValue={getInputValue}
                        fromwhichapent="Create"
                        manageFormsArrayLists={manageFormsArrayLists}
                      />
                    )}
                    {action?.action === "8" && (
                      <VanilaJsModal
                        isEdit={false}
                        actionName="data"
                        actionArray={[
                          { id: "", name: "", parentId: "", controlId: "" },
                        ]}
                        index={index}
                        setTestCase={setTestCase}
                        testCase={testCase}
                        getInputValue={getInputValue}
                        handleInputValueType={handleInputValueType}
                        manageFormsArrayLists={manageFormsArrayLists}
                      />
                    )}
                  </div>
                </>
              );
            })}
        </div>
        <button type="submit" className="btn btn-success text-center w-25 mx-1">
          Save Case
        </button>
      </form>
    </>
  );
}
