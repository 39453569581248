import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CourseService from "../../services/CourseService";
import global from "../../servicesvariable";
import { handleImageLoadingError } from '../../utils/image/handleImageLoadingError';
import "./myLearningCard.scss";

export default function MyLearningCard(props) {
  const { item, tcounter } = props;
  const [subcourses, setSubCourses] = useState([]);
  const [hoursLeft, setHoursLeft] = useState(0);

  var settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          autoplay: false,
          infinite: false,
          margin: 10,
          arrows: false,
          dots: false,
        },
      },
    ],
  };
  useEffect(() => {
    const token = localStorage.getItem("token");
    var counter = 0;

    if (token) {
      const decoded = jwt_decode(token);
      CourseService.getSubCourses()
        .then((res) => {
          if (res.data) {
            setSubCourses(res.data);
          }
        })
        .catch((ex) => { });
    }
  }, []);


  useEffect(() => {
    (() => {
      tcounter.forEach((counter, index) => {
        if(counter.name === item.courseId.name) {
          setHoursLeft(Math.ceil(tcounter[index].length));
        }
      });
    })()
  }, [tcounter])

  return (
    <a
      href="javascript:void()"
      class="card mylearn-card mb-3 text-decoration-none"
    >
      <div class="row g-0">
        <div class="col-xl-4 col-3 course-img">
          {item.courseId.icon === "default.jpg" ? (
            <img
              src={`./../${item.courseId.icon}`}
              class="img-fluid"
              alt="Course Title"
              data-level="1"
              onError={(e) => {if(parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e)}}
            />
          ) : item.courseId.icon.match(/http/g) ? (
            <img
              src={item.courseId.icon}
              class="img-fluid"
              alt="Course Title"
              data-level="1"
              onError={(e) => {if(parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e)}}
            />
          ) : (
            <>
              <img
                src={`${global.imgURL}/${item.courseId.icon}`}
                class="img-fluid"
                alt="Course Title"
                data-level="1"
                onError={(e) => {if(parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e)}}
              />
            </>
          )}
        </div>
        <div class="col-xl-8 col-9">
          <div class="card-body p-lg-3 d-flex flex-wrap h-100 flex-column justify-content-between">
            <h4 class="card-title text-black font-weight-400">
              {item.courseId.name.replaceAll("_",'  ').replaceAll("-",' ')}
            </h4>
            <p
              class="card-text font-14 text-dark d-none"
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
            >
              {item.courseId.desc}
            </p>

            {item.progress !== 100 ? (
              <div class="d-flex justify-content-between align-items-center flex-wrap">
                <div class="pt-lg-3 pt-2">
                  <p class="text-dark-gray font-14 mb-2">
                    <span>
                      <strong>{item.progress}%</strong> Complete
                    </span>{" "}
                    <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>{" "}
                    <span>
                      <strong>
                        {hoursLeft}
                      </strong>{" "}
                      Hours Left
                    </span>
                  </p>
                  <div class="course-progressbar position-relative">
                    <div class="position-absolute h-100 w-100 bg-border-color"></div>
                    {item.progress > 75 ? (
                      <div
                        class="position-absolute h-100 bg-success"
                        style={{ width: `${item.progress}%` }}
                      ></div>
                    ) : (
                      <div
                        class="position-absolute h-100 bg-secondary"
                        style={{ width: `${item.progress}%` }}
                      ></div>
                    )}
                  </div>
                </div>
                
                {/* Add Subcourse as a props */}
                {subcourses.map((subcourse) => {
                  if (subcourse.name === item.courseId.name) {
                    return (
                      <Link
                        to={`/course/${subcourse.type.name}/courseOverview/${item.courseId.name}/tplan`}
                        class="text-primary pt-lg-3 pt-2"
                      >
                        Continue
                      </Link>
                    );
                  }
                })}
              </div>
            ) : (
              <div class="d-flex justify-content-between pt-lg-3 pt-2 align-items-center">
                <div class="w-100">
                  <p class="text-success font-14 mb-2">
                    <span>Completed</span>{" "}
                  </p>
                  <div class="course-progressbar position-relative">
                    <div class="position-absolute h-100 w-100 bg-border-color"></div>
                    <div
                      class="position-absolute h-100 w-100 bg-success"
                    //   style={{ width: `${item.progress}%` }}
                    ></div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
}
