import $ from "jquery";
import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import img1 from "../../assets/images/Banner-image.png";
import CourseSlider from "../../components/courseSlider/courseSlider";
import FourCol from "../../components/fourCol/FourCol";
import IntrestedCources from "../../components/interestedCourses/interestedCourses";
import LearningCard from "../../components/learningCard/learningCard";
import LogoSlider from "../../components/logoSlider/logoSlider";
import SubscribeSlider from "../../components/subscribeSlider/subscribeSlider";
import TwoColCard from "../../components/twoColCard/twoColCard";
import formloader from "../../images/formloading.svg";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";

import { FaAngleUp } from "react-icons/fa";
import "./home.scss";
import { blacklistcourse } from "../../servicesvariable";
export default function Home(props) {
  const [courses, setCourses] = useState([]);
  const [itemsToShow, setItemsToShow] = useState(8);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { state } = useLocation();
  const [visible, setVisible] = useState(false);
  var searchcourse = "";
  if (state) {
    searchcourse = state.search;
  }
  // if (localStorage.getItem("reload") == "true") {
  //   window.location.reload();
  //   localStorage.removeItem("reload");
  // }
  useEffect(() => {
    const token = localStorage.getItem("token");
    setLoading(true);
    $(document).ready(function () {
      $(document).click(function (event) {
        var clickover = $(event.target);
        if (clickover.hasClass("home")) {
          CourseService.getCourses()
            .then((res) => {
              if (res.data) {
                try{
                  const data = res.data.filter(course => !blacklistcourse.includes(course.name))
                  setCourses(data);
                }
                catch(e){
                  console.log('Error in course fetching:'+e);
                }
              }
            })
            .catch((ex) => console.log(ex));
          $("a.mycourses").removeClass("is-active");
          $("a.home").addClass("is-active");
        } else if (clickover.hasClass("mycourses")) {
          if (token) {
            const decoded = jwt_decode(token);
            LoginService.getSingleUser(decoded.id, token).then((res) => {
              //Subcribed Courses
              if (res.data) {
                var courses = [];
                CourseService.getSubCoursesIn(
                  {
                    courses: res.data.courses,
                  },
                  token
                ).then((result) => {
                  result.data.map((res) => {
                    courses.push(res.type);
                  });
                  CourseService.getCoursesIn({ courses: courses }).then(
                    (res) => {
                      if (res.data) {
                        setCourses(res.data);
                      }
                    }
                  );
                });
              }
            });
          }
          $("a.home").removeClass("is-active");
          $("a.mycourses").addClass("is-active");
        }
      });
    });
    CourseService.getCategory().then((cat) => {
      if (cat.data) {
        CourseService.getCourses()
          .then((res) => {
            if (res.data) {
              try{
                const data = res.data.filter(course => !blacklistcourse.includes(course.name))
                setCourses(data);
              }
              catch(e){
                console.log('Error in course fetching:'+e);
              }
            }
          })
          .catch((ex) => {});
      }
      setLoading(false);
    });

    window.addEventListener("scroll", toggleVisible);
  }, []);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 80) {
      setVisible(true);
    } else if (scrolled <= 80) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      {/* ------------------ ui/ux ------------------ */}
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">Loading...</div>
          </div>
        </div>
      )}
      <div className="courses ">
        <div style={{ backgroundImage: `url(${img1})` }} class="home-banner">
          <section>
            <div class="container">
              <h1 class="text-dark-gray mb-0">Update your skills</h1>
              <h3 class="text-dark-gray font-weight-300">
                Advance your career
              </h3>
              <Link
                to={`/exploreCourses`}
                title="View all courses"
                class="btn btn-blue text-uppercase mt-md-3 font-weight-300"
              >
                View all courses
              </Link>
            </div>
          </section>
        </div>
        {localStorage.getItem("token") &&
          jwt_decode(localStorage.getItem("token")).role === "Student" && (
            <>
              <LearningCard />
              <IntrestedCources />
            </>
          )}
        <CourseSlider />

        {!localStorage.getItem("token") && (
          <section class="bg-light-gray">
            <div class="container">
              <div class="row">
                <SubscribeSlider />
              </div>
            </div>
          </section>
        )}
        {/* {!localStorage.getItem("token") && (
          <>
            <br />
            <b>What your colleagues are learning</b>
            {colleaguesLearning()}
            <br />
            <b>Recently Subscribe Courses</b>
            {recentlySubscribe()}
            <br />
            <b>New Courses We have Added</b>
            {newlyAddedCourses()}
          </>
        )}
        {localStorage.getItem("token") && (
          <>
            <br />
            <b>Let's Continue Learning</b>
            {continueLearning.map((item) => {
              return (
                <div>
                  {item.progress} ---
                  {item.userId.username} ---
                  {item.courseId.name}
                </div>
              );
            })}
            <br />
            <b>Courses you might be interested in</b>
            <br />
            <b>What your colleagues are learning</b>
            {colleaguesLearning()}
            <br />
            <b>New Courses We have Added</b>
            {newlyAddedCourses()}
          </>
        )} */}

        <section class="container">
          <TwoColCard />
        </section>

        <section class="bg-light-gray py-4">
          <LogoSlider />
        </section>
        <section class="container">
          <div class="four-col-wrapper">
            <h2 class="mb-lg-2 mb-0 text-dark-gray font-weight-400 ">
              Explore all the technologies
            </h2>
            <div class="row tech">
              {/* {courses.map((item, index) => {
                if (item) {
                  return (
                    <div class="col-6 col-lg-4 col-xl-3 pt-3 pt-xl-4 four-col-item d-xl-block d-none">
                      <FourCol
                        key={index}
                        course={item}
                        history={props.history}
                      />
                    </div>
                  );
                }
                return 0;
              })} */}
              {courses.slice(0, itemsToShow).map((item, index) => {
                if (item) {
                  return (
                    <div class="col-6 col-md-4 col-xl-3 pt-3 pt-xl-4 four-col-item d-block">
                      <FourCol key={index} course={item} />
                    </div>
                  );
                }
                return 0;
              })}
            </div>
            <div className="text-center mt-2">
              <button
                className="btn btn-white text-uppercase font-weight-300 mt-3 mt-lg-4 py-2"
                onClick={() => navigate("/exploreCourses")}
              >
                Show More
              </button>
            </div>
          </div>
        </section>

        <FaAngleUp
          className="scrollbutton rounded-circle mb-4"
          onClick={scrollToTop}
          style={{ display: visible ? "inline" : "none" }}
        />
      </div>
    </>
  );
}
