import React, { useEffect, useState } from "react";
import { FaAngleUp, FaPen, FaPlus, FaTimes } from "react-icons/fa";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import formloader from "../../images/formloading.svg";
import CourseService from "../../services/CourseService";
import Url from "../../servicesvariable";
import ToolTip from "../BsComponents/ToolTip";
const { getUserName } = require("../../utils/user/getUserName");
const { format } = require("../../../src/utils/Date/format");

const myStyle = {
  td: {
    width: 100,
    overflow: "hidden",
  },
};
export default function SubCourseList() {
  const navigate = useNavigate();
  const [subcourses, setSubcourses] = useState([]);
  const [filterSubcourses, setFilterSubcourses] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [alertConfirmBox, setAlertConfirmBox] = useState(false);
  const [msg, setMsg] = useState({ message: "", status: false });
  const [visible, setVisible] = useState(false);
  const [currentpage, setcurrentPage] = useState(1);
  const [pagesArr, setPagesArr] = useState([]);
  const [chunks, setChunks] = useState([]);
  const [subcoursesLength, setSubcoursesLength] = useState(0);
  const [Options, setOptions] = useState([]);
  const [selectedOptionsAll, setselectedOptionsAll] = useState([]);

  const paginationData = (allBatchesArr) => {
    let l = 0;
    let j = allBatchesArr.length % 10;
    let k = Math.ceil(allBatchesArr.length / 10);
    let i = 10;
    for (let x = 0; x < k; x++) {
      setPagesArr(...pagesArr, (pagesArr[x] = []));
      if (allBatchesArr.length % (i * (x + 1)) !== allBatchesArr.length) {
        for (let y = 0; y < 10; y++) {
          setPagesArr(...pagesArr, (pagesArr[x][y] = allBatchesArr[l]));
          l++;
        }
      } else if (
        allBatchesArr.length % (i * (x + 1)) ===
        allBatchesArr.length
      ) {
        for (let z = 0; z < j; z++) {
          setPagesArr(...pagesArr, (pagesArr[x][z] = allBatchesArr[l]));
          l++;
        }
      }
    }
    setChunks(pagesArr);
  };

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      CourseService.getSubCourses()
        .then(async (res) => {
          if (res.data.length > 0) {
            const allc = res.data;
            setSubcoursesLength(res.data.length);
            paginationData(res.data);
            setcurrentPage(1);
            setOptions(
              res.data.map((item) => ({
                label: item.name,
                name: item.name,
                value: item._id,
              }))
            );
            setFilterSubcourses(res.data);
            setSubcourses(allc.slice(0, 10));
          }
          setLoading(false);
        })
        .catch((ex) => console.log(ex));
    }
    window.addEventListener("scroll", toggleVisible);
  }, [confirmBox]);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 80) {
      setVisible(true);
    } else if (scrolled <= 80) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handlePageChange = (e) => {
    setcurrentPage(e);
    setSubcourses(chunks[e - 1]);
  };

  const handleChange = (selectedOptions, e) => {
    if (selectedOptions?.length > 0) {
      setselectedOptionsAll(selectedOptions);
      const filterSubCourseList = filterSubcourses.filter((course) => {
        return selectedOptions.some((selectCourse) => course._id == selectCourse.value);
      });
      setSubcoursesLength(filterSubCourseList.length);
      paginationData(filterSubCourseList);
      setcurrentPage(1);
      if (filterSubCourseList.length > 10) {
        setSubcourses(filterSubCourseList.slice(0, 10));
      } else {
        setSubcourses(filterSubCourseList);
      }
    } else {
      setselectedOptionsAll([])
      setSubcoursesLength(filterSubcourses.length);
      paginationData(filterSubcourses);
      setcurrentPage(1);
      setSubcourses(filterSubcourses.slice(0, 10));
    }
  };

  const handleRemoveSubCourse = (item) => {
    var result = window.confirm("Do you really want to delete subcourse?");
    if (result) {
      const token = localStorage.getItem("token");
      if (!token) {
        navigate("/login");
      } else {
        setLoading(true);
        CourseService.deleteSubCourse(item, token)
          .then((res) => {
            if (res.data.msg && res.data.status) {
              setConfirmBox(!confirmBox);
              setMsg({ message: res.data.msg, status: true });
              setAlertConfirmBox(true);
            }
          })
          .catch((ex) => console.log(ex));
        setLoading(false);
      }
    }
    setTimeout(() => setAlertConfirmBox(false), 3000);
  };

  const handleIndex = (index) => {
    const finalNumber = currentpage * 10 - 10;
    return finalNumber + index;
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li className={"breadcrumb-item active"}>Manage SubCourses</li>
          </ol>
        </nav>
        <div className="">
          <button
            htmlFor="adcourse"
            className="btn bg-green text-white mx-5"
            onClick={() => {
              navigate(`/admin/course/manage-subcourse/add`);
            }}
          >
            <FaPlus className="me-2 fs-5" />
            Add SubCourses
          </button>
        </div>
      </div>

      <div className="container p-2 px-3 mb-5">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading subcourse...</div>
            </div>
          </div>
        )}

        {alertConfirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div
                  className={`alert ${msg.status ? "alert-success" : "alert-danger"
                    }`}
                >
                  {msg.message}
                </div>
              </div>
            </div>
          </div>
        )}

        <div className="form px-lg-5">
          <div className="row g-4 listcourses py-4">
            <>
              <div class="col-4">
                <Select
                  placeholder="Filter by course ..."
                  isMulti
                  value={selectedOptionsAll}
                  options={Options}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={(selectedOptions, e) => {
                    handleChange(selectedOptions, e);
                  }}
                // value={filterOptions}
                />
              </div>
              <table className="table mt-4">
                <thead className="fw-bold">
                  <th scope="col">#</th>
                  <th scope="col" className="col-1">
                    {" "}
                    Name
                  </th>
                  <th scope="col" className="col-1">
                    {" "}
                    Type
                  </th>
                  <th scope="col" className="col-1">
                    {" "}
                    Level
                  </th>
                  <th scope="col" className="col-1">
                    {" "}
                    Color
                  </th>
                  <th scope="col" className="col-lg-2">
                    Description
                  </th>
                  <th scope="col" className="col-1">
                    Training <br></br> Plans
                  </th>
                  <th scope="col" className="col-1">
                    {" "}
                    Subtopic Plans
                  </th>
                  <th>Created by</th>
                  <th>Updated by</th>
                  <th scope="col" className="col-1">
                    {" "}
                    Ppts
                  </th>
                  <th scope="col" className="col-1">
                    {" "}
                    Videos
                  </th>
                  <th scope="col" className="col-2">
                    Handle
                  </th>
                </thead>

                <tbody>
                  {subcourses.map((item, index) => {
                    if (!Array.isArray(item)) {
                      return (
                        <List
                          item={item}
                          index={index}
                          handleRemoveSubCourse={handleRemoveSubCourse}
                          handleIndex={handleIndex}
                        />
                      );
                    }
                  })}
                </tbody>
              </table>
              <div className="d-flex justify-content-center mt-5">
                <Pagination
                  activePage={currentpage}
                  itemClass="page-item"
                  linkClass="page-link"
                  itemsCountPerPage={10}
                  totalItemsCount={subcoursesLength}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                />
              </div>
            </>
          </div>
        </div>

        <FaAngleUp
          className="scrollbutton rounded-circle mb-5"
          onClick={scrollToTop}
          style={{ display: visible ? "inline" : "none" }}
        />
      </div>
    </>
  );
}

const List = (props) => {
  const { item, index, handleRemoveSubCourse, handleIndex } = props;
  const [showAll, setshowAll] = useState(false);
  const navigate = useNavigate();

  const handlecreatedAndUpdateby = (createdupdatedby) => {
    return getUserName(createdupdatedby);
  };

  const handleCreateUpadateDate = (date) => {
    return format(date);
  };
  return (
    <>
      <tr>
        <td>{handleIndex(index + 1)}</td>
        <td className="fw-bold">{item.name.substring(0, 10)}</td>
        <td style={myStyle.td}>
          <img
            src={`${Url.SERVER}/public/images/${item.icon}`}
            style={{ height: "80px", width: "80px" }}
            alt={`${item.icon}`}
          />
        </td>
        <td>{item.level}</td>
        <td>
          <div
            style={{
              backgroundColor: `${item.color}`,
              padding: "10px",
            }}
          ></div>
        </td>
        <td>
          {!showAll ? (
            <>
              {item.desc.substring(0, 20)}...
              <button class="btn btn-link" onClick={() => setshowAll(true)}>
                more
              </button>
            </>
          ) : (
            <>
              {item.desc}
              <button class="btn btn-link" onClick={() => setshowAll(false)}>
                less
              </button>
            </>
          )}
        </td>
        <td>{item.tplans}</td>
        <td>{item.subTopicName.map((item) => item)}</td>

        <td>{handlecreatedAndUpdateby(item.createdBy)} on {handleCreateUpadateDate(item.createdAt)}</td>
        <td>{handlecreatedAndUpdateby(item.updatedBy)} on {handleCreateUpadateDate(item.updatedAt)}</td>
        <td>{item.ppts}</td>
        <td>{item.videos}</td>
        <td>
          <div className="d-flex">
            <div>
              <ToolTip title="Edit SubCourse">
                <button
                  className="btn btn-warning px-2 py-1 mx-1 fs-8"
                  onClick={() => {
                    navigate(
                      `/admin/course/manage-subcourse/edit/${item._id}`,
                      {
                        state: item,
                      }
                    );
                  }}
                >
                  <FaPen className="color-white text-white" />
                </button>
              </ToolTip>
            </div>
            <div>
              <ToolTip title="Delete SubCourse">
                <button
                  className="btn btn-danger px-2 py-1 mx-1"
                  id={item._id}
                  onClick={() => {
                    handleRemoveSubCourse(item);
                  }}
                >
                  <FaTimes className="color-white text-white" />
                </button>
              </ToolTip>
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};
