import React from "react";

export default function Static() {
  return (
    <section class="my-5 pt-5">
      <div class="container pt-5">
        <h1 class="mb-3">Lorem ipsum dolor sit amet - 48px(h1)</h1>
        <h1 class="h1-alt mb-3">Lorem ipsum dolor sit amet - 36px(h1)</h1>
        <h2 class="mb-3">Lorem ipsum dolor sit amet - 32px(h2)</h2>
        <h3 class="mb-3">Lorem ipsum dolor sit amet - 24px(h3)</h3>
        <h4 class="mb-3">Lorem ipsum dolor sit amet - 20px(h4)</h4>
        <h5 class="mb-3">Lorem ipsum dolor sit amet - 18px(h5)</h5>
        <h6 class="mb-3">Lorem ipsum dolor sit amet - 16px(h6)</h6>
        <p>Lorem ipsum dolor sit amet - 14px(p)</p>
      </div>
    </section>
  );
}
