import React, { useState } from "react";
import { MdSend } from "react-icons/md";
import moment from "moment";
import CourseService from "../../services/CourseService";

export default function DiscussionCard(props) {
  const { sname, message, _id, replies, cid } = props.item;
  const myname = props.myname;
  const setConfirm = props.setConfirm;
  const review = props.review;
  const colors = props.colors;
  const time = new Date(props.time || null);
  const [toggle, setToggle] = useState(false);
  const [reply, setReply] = useState({
    did: _id,
    sname: myname,
    message: "",
    review: review,
  });
  var cdiscount = props.cdiscount;
  const courses = props.courses;
  const handleAddReply = (e) => {
    e.preventDefault();
    const token = localStorage.getItem("token");
    if (reply.message && reply.sname) {
      CourseService.postDescussionReply(reply, token)
        .then((res) => {
          if (res.data.msg) {
            setConfirm((d) => !d);
            setReply((d) => ({ ...d, message: "" }));
            var course = courses.filter((c) => c._id === cid);
            if (cdiscount[course[0].name] > 0) {
              cdiscount[course[0].name] -= 1;
            }
          }
        })
        .catch((ex) => console.log(ex));
    }
  };

  return (
    <div className="col-12 py-3 pb-3 border-bottom discussion-card">
      <div className="row ">
        <div
          className={
            "col-md-1 col-3 d-flex mx-2 justify-content-center align-items-center text-white fs-5 rounded-circle text-uppercase " +
            colors[sname.replace(/ /g, "")]
          }
          style={{ height: "2.2rem", width: "2.2rem" }}
        >
          {sname.charAt(0)}
        </div>
        <div className="ms-3 bg-light-gray shadow-sm rounded p-2 col-9 col-md-10 col-lg-11">
          <h6 className="color-dback mb-0 text-capitalize">
            {sname}
            <label className="text-muted px-2" style={{ fontSize: "0.8rem" }}>
              {moment(time).fromNow()}
            </label>
          </h6>
          <p className="color-dback pt-2" style={{ fontSize: "0.9rem" }}>
            {message}
          </p>

          <button
            className="btn text-primary p-0 pt-2 color-dback fw-bold"
            style={{
              fontSize: "0.9rem",
              textDecoration: "none",
              boxShadow: "none",
            }}
            onClick={() => {
              setToggle((e) => !e);
            }}
          >
            {toggle ? (
              <>
                All Replies
                <span className="badge rounded-pill bg-primary ms-2 p-1 px-2">
                  {replies.length}
                </span>
              </>
            ) : replies.length <= 1 ? (
              replies.length + " reply"
            ) : (
              replies.length + " replies"
            )}
          </button>
        </div>
      </div>

      {toggle ? (
        <div className="row py-2   bg-white shadow-sm " style={{marginLeft  : '55px', marginTop : "5px"}}>
          <div className="col-12 py-1">
            <form onSubmit={handleAddReply}>
              <div className="input-group">
                <textarea
                  onChange={(e) =>
                    setReply((d) => ({ ...d, message: e.target.value, did : _id }))
                  }
                  value={reply.message}
                  rows="1"
                  className="form-control border-0 border-2 border-bottom"
                  placeholder="Add a public reply..."
                  style={{
                    fontSize: "0.9rem",
                    background: "none",
                    boxShadow: "none",
                  }}
                ></textarea>
                <button type="submit" className="btn m-0 p-0 mx-2 ms-3">
                  <MdSend className="color-green fs-3 m-0 p-0" />
                </button>
              </div>
            </form>
          </div>
          {replies.map((item, index) => {
            const createdAt = new Date(item.time);
            const fromNow = moment(createdAt).fromNow();
            return (
              <div className="col-12 py-3 ps-3 border-bottom" key={index}>
                <div className="d-flex">
                  <div
                    className={
                      "d-flex justify-content-center align-items-center text-white fs-5 rounded-circle text-uppercase " +
                      colors[item.sname.replace(/ /g, "")]
                    }
                    style={{ height: "2.2rem", width: "2.2rem" }}
                  >
                    {item.sname.charAt(0)}
                  </div>
                  <div className="ms-3 bg-light-gray shadow-sm rounded p-2 col-11">
                    <h6 className="color-dback mb-0 text-capitalize">
                      {item.sname}
                      <label className="text-muted px-2" style={{ fontSize: "0.8rem" }}>
                        {fromNow}
                      </label>
                    </h6>
                    <p className="color-dback pt-2" style={{ fontSize: "0.9rem" }}>
                      {item.message}
                    </p>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
    </div>
  );
}
