import CourseService from "../../services/CourseService";

/**
 * ASYNC Function
 * 
 * @returns 
 *      Number of PPTs of particular subcourse
 *
 */
export const getNoOfPpts = async (sname) => {

    let noOfPpts = 0;

    const token = localStorage.getItem("token");
    if(token) {
        await CourseService.getPpts(sname, token).then(res => {

            noOfPpts = res.data?.ppts.length ?? 0;
        })
    }

    return noOfPpts;
}