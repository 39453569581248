import { default as jwtDecode, default as jwt_decode } from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import { NewlyAddedCourses, SubscribeCourses, SubscribedCourses } from '../courseSlider/courseSlider';
import "./interestedCourses.scss";

function IntrestedCources(props) {
  const navigate = useNavigate();
  const [user, setUser] = useState([]);
  const [courcesId, setCourcesId] = useState([]);
  const [noOfVideos, setNoOfVideos] = useState([]);
  const [selectedcourses, setSelectedcourses] = useState([]);
  const [intrestedCources, setIntrestedCources] = useState([]);
  const [tcounter, setTcounter] = useState([]);
  const [subCourses, setSubCourses] = useState([]);
  const [allCourses, setAllCourses] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(8);
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");
  var settings = {
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2.2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  const getUser = async () => {
    LoginService.getSingleUser(
      jwtDecode(localStorage.getItem("token")).id,
      localStorage.getItem("token")
    )
      .then((res) => {
        setUser(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSubCourses = async () => {
    CourseService.getSubCourses()
      .then((res) => {
        setSubCourses(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getCourses = async () => {
    CourseService.getCourses().then((res) => {
      const data =
        res &&
        res.data &&
        res.data
          .filter((item) => {
            if (
              user &&
              user.intrests &&
              user.intrests.some((item1) => item1.label === item.name)
            ) {
              return item.type;
            }
          })
          .map(({ type }) => type);
      const uniqueCourceId = [...new Set(data)];
      setCourcesId(uniqueCourceId);
    });
  };

  useEffect(() => {
    getCourses();
  }, [user]);

  useEffect(() => {
    CourseService.getCourses().then((res) => {
      let temp = res.data.filter((item) => {
        let temp1 = courcesId.some((item1) => {
          return item1 === item.type;
        });
        return temp1;
      });
      setIntrestedCources(temp);
    });
  }, [courcesId]);

  useEffect(() => {
    setLoading(true);
    setConfirmBox(false);
    if (token) {
      const decoded = jwt_decode(token);

      LoginService.getSingleUser(decoded.id, token)
        .then((res) => {
          if (res.data) {
            CourseService.getSubCoursesIn(
              { courses: res.data.courses },
              token
            ).then((result) => {
              if (result.data) {
                setSelectedcourses(result.data);
              } else {
                setSelectedcourses([]);
              }
            });
          } else {
            navigate("/login");
          }
          setLoading(false);
        })
        .catch((ex) => { });
    }
  }, [confirmBox]);

  useEffect(() => {

    let result = subCourses.filter((item) =>
      intrestedCources.some((item1) => item.type._id === item1._id)
    );



    result.map(async (item) => {

      await CourseService.getTrainingPlan(item.name).then((tplan) => {
        if (tplan.data) {
          let length = {
            length: tplan.data.length,
            name: item.name,
          };
          setTcounter((d) => [...d, length]);
        } else {
          let length = {
            length: 0,
            name: item.name,
          };
          setTcounter((d) => [...d, length]);
        }
      });
    })


    setAllCourses(result);

  }, [intrestedCources]);

  useEffect(() => {
    getUser();
    getSubCourses();
  }, [])

  return (
    allCourses &&
    allCourses.length > 0 && (
      <section class="container py-0">
        <div class="learn-course position-relative">
          <h2 class="text-dark-gray font-weight-400 mb-md-4 mb-3">
            Courses you might be interest in
          </h2>
          <div class="px-12px">
            {allCourses && allCourses.length > 0 && (
              <Slider {...settings}>
                {allCourses.slice(0, itemsToShow).map((item, index) => {

                  // Get No of modules
                  let noOfModules = 0;

                  const findCourseWithModuleLength = tcounter.find(currCourse => currCourse.name === item.name);
                  if (findCourseWithModuleLength) {
                    noOfModules = findCourseWithModuleLength.length;
                  }

                  // No of PPts
                  const noOfPPts = item.ppts;

                  // No of Videos
                  const noOfVideos = item.videos;

                  // find the subcourse of this particular item
                  const subcourse = subCourses.find(subcourse => subcourse.name === item.name);

                  // No of Total Hours
                  const noOfTotalHours = Math.ceil(noOfModules * 8.5);

                  // Only render if the number of videos is greater than zero 
                  //   or 
                  // Number of Ppts is greater than zero 
                  //   or
                  // Number of Modues is greater than zero
                  if (noOfVideos[index] !== 0 || noOfPPts !== 0 || noOfModules !== 0) {

                    return localStorage.getItem("token") ? (
                      jwt_decode(localStorage.getItem("token")).role ===
                        "Student" &&
                        (Array.from(selectedcourses, (o) => o.name).indexOf(
                          item.name
                        ) === -1 ? (
                          <SubscribeCourses props={{ _id: item._id, noOfModules, cname: subcourse.type.name, noOfPPts, sname: item.name, noOfVideos, noOfTotalHours, setConfirmBox, icon: item.icon }} />
                        ) : (
                          <SubscribedCourses props={{ _id: item._id, noOfModules, cname: subcourse.type.name, noOfPPts, sname: item.name, noOfVideos, noOfTotalHours, setConfirmBox, icon: item.icon }} />
                        ))
                    ) : (
                      <NewlyAddedCourses props={{ _id: item._id, noOfModules, cname: subcourse.type.name, noOfPPts, sname: item.name, noOfVideos, noOfTotalHours, setConfirmBox, icon: item.icon }} />
                    );
                  }
                })}
              </Slider>
            )}
          </div>
        </div>
      </section>
    )
  );
}

export default IntrestedCources;
