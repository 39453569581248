import React from "react";
import ReactDOM from "react-dom";
import Routing from "./routing/Routing";
import "./Main.scss";


// require("./Main.scss")

ReactDOM.render(
  <React.StrictMode>
    <Routing />
  </React.StrictMode>,
  document.getElementById("root")
);
