import React, { useEffect, useState } from "react";
import { VscCheck } from "react-icons/vsc";
import { useLocation, useNavigate } from "react-router-dom";
import BatchManagementServices from "../../services/BatchManagementServices";

export default function UpdateSkillDaysAndWeightage() {
  const [skills, setSkills] = useState([]);
  const [confirmbox, setConfirmbox] = useState(false);
  const [Msg, setMsg] = useState({ status: true, message: "" });
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { state } = useLocation();

  const handleUpdateSkill = (skill) => {
    if (skill.days !== "" || skill.weightage !== "") {
      BatchManagementServices.updateSkillDays(state._id, skill, token)
        .then((res) => {
          if (res.data) {
            setConfirmbox(true);
            setMsg({ status: res.data.status, message: res.data.msg });
            setTimeout(() => {
              setConfirmbox(false);
            }, 4000);
          }
        })
        .catch((err) => console.log(err));
    }
  };

  useEffect(() => {
    if (!state) {
      return;
    }

    if (state?.skills.length > 0) {
      const temp1 = [];
      state.skills.forEach((element) => {
        temp1.push({
          label: element.skillId.name,
          value: element.skillId.name,
          id: element.skillId._id,
          days:
            element?.days > 0
              ? element.days
              : element.trainingPlansId?.length > 0
              ? element.trainingPlansId.length
              : "",
          weightage: element.weightage ? element.weightage : "",
          daysLength:
            element.trainingPlansId?.length > 0
              ? element.trainingPlansId.length
              : 0,
          daysError: "",
          trainingPlansId: element.trainingPlansId,
        });
      });

      setSkills([...temp1]);
    }
  }, []);

  const handleWeightage = (e, id) => {
    const list = [...skills];
    const findSkill = skills.find((item) => item.id === id);
    if (
      !e.target.value ||
      (parseFloat(e.target.value) <= 100 && parseFloat(e.target.value) >= 0)
    ) {
      findSkill.weightage = parseFloat(e.target.value);
      setSkills(list);
    }
  };

  const handleDays = (e, id) => {
    const list = [...skills];
    const findSkill = skills.find((item) => item.id === id);
    if (!e.target.value || parseInt(e.target.value) <= 0) {
      findSkill.days = "";
      findSkill.daysError = `Required day filed`;
      setSkills(list);
    } else {
      if (parseInt(e.target.value) > 99) {
        findSkill.days = "";
        findSkill.daysError = `Number of Days should be less than 99`;
        setSkills(list);
      } else {
        findSkill.days = parseInt(e.target.value);
        findSkill.daysError = "";
        setSkills(list);
      }
    }
  };

  const handleTotalWeightage = () => {
    if (skills?.length > 0) {
      return skills.reduce((acc, skill) => {
        return (acc += skill?.weightage ? skill.weightage : 0);
      }, 0);
    } else {
      return 0;
    }
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb mb-0 py-2">
            <li
              className={"breadcrumb-item text-primary"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/admin/batch-management/manage");
              }}
            >
              Batch
            </li>

            <li className={"breadcrumb-item active"}>
              Update Skill Days And Weightage
            </li>
          </ol>
        </nav>
      </div>

      <div className="container mt-5">
        <div className="row col-md-12">
          {!!state && (
            <div className="text-end mt-4  h4">
              Batch Name :- <span className="text-primary">{state.name}</span>
            </div>
          )}
        </div>
        {confirmbox && (
          <div className="formloade conatiner mx-5 mt-3 w-75">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div
                  className={`alert ${
                    Msg.status ? "alert-success" : "alert-danger"
                  }`}
                >
                  {Msg.message}
                </div>
              </div>
            </div>
          </div>
        )}
        <div
          className={`text-center h3 ${
            handleTotalWeightage() === 100 ? "text-success" : "text-danger"
          }`}
        >
          Skill Total Weightage : {handleTotalWeightage()?.toFixed(2)}%
        </div>
        {skills?.length > 0 && (
          <div className="table-responsive">
            <table className="table mt-4">
              <thead className="fw-bold thead-custom">
                <th scope="col">#</th>
                <th scope="col" className="col-md-3">
                  Skill Name
                </th>
                <th scope="col" className="col-md-4">
                  Days
                </th>
                <th scope="col" className="col-md-4">
                  Weightage (100%)
                </th>
                <th scope="col" className="col-md-4">
                  Handle
                </th>
              </thead>
              <tbody>
                {skills.map((item, index) => {
                  return (
                    <tr>
                      <td>{index + 1} </td>
                      <td>
                        {" "}
                        {item.label} -{" "}
                        <span className="text-success">
                          Modules ({item.days})
                        </span>
                      </td>
                      <td>
                        {" "}
                        <input
                          placeholder="Enter days"
                          type="number"
                          value={item.days}
                          onChange={(e) => {
                            handleDays(e, item.id);
                          }}
                        ></input>
                        {item.daysError?.length > 0 && (
                          <div>
                            {" "}
                            <span className="text-danger my-2">
                              {item.daysError}
                            </span>
                          </div>
                        )}
                      </td>
                      <td>
                        <input
                          placeholder="Enter weightage"
                          type="number"
                          value={item.weightage}
                          onChange={(e) => {
                            handleWeightage(e, item.id);
                          }}
                        ></input>
                      </td>
                      <td>
                        <button
                          type="button"
                          disabled={item.days || item.weightage ? false : true}
                          className="btn btn-sm mt-1 ms-1 btn-success"
                          onClick={() => handleUpdateSkill(item)}
                        >
                          <VscCheck />
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </>
  );
}
