import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TestCaseService from "../../services/TestCaseService";

export default function TestCaseList(props) {
  const [testCaseActionList, setTestCaseActionList] = useState([]);
  const [deleteSuccess, setDeleteSuccess] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData()
    // setTestCaseActionList(JSON.parse(localStorage.getItem("testCases")));
  }, []);

  useEffect(() => {
    // Show alert when deleteSuccess state changes
    if (deleteSuccess) {
      alert('Data deleted successfully');
      setDeleteSuccess(false); // Reset the deleteSuccess state
    }
  }, [deleteSuccess]);

  const fetchData = () => {
    let authToken = localStorage.getItem("token")
    if (authToken) {
      TestCaseService.getAllAction(authToken).then(res => {
        if (res.data) {
          setTestCaseActionList(res.data.allactionsData)
        }
      }).catch(err => console.error(err))
    } else {
      navigate('/login')
    }
  }

  const handleEdit = (actionID) => {
    navigate(`/admin/manage-testcase/updateaction/${actionID}`)
  }

  const handleDelete = (id, ActionName) => {

    // console.log(id);
    let authToken = localStorage.getItem("token")
    const isConfirmed = window.confirm(`Are you sure you want to delete ${ActionName}?`);
    if (isConfirmed) {
      TestCaseService.deleteTestCaseAction(id, authToken).then(res => {
        if (res.data) {
          fetchData();
          setDeleteSuccess(true);
        }
      })
    }
  }
  return (
    <div className="mx-5 my-5">
      <table className="table">
        <thead className="fw-bold">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Test Case Name</th>
            <th scope="col">Type</th>
            <th scope="col">Total Actions</th>
            <th scope="col">Action</th>

          </tr>
        </thead>
        <tbody>
          {testCaseActionList?.map((testCaseAction, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{testCaseAction?.actionName}</td>
                <td>{testCaseAction?.actionType === true ? 'Actions' : 'Evaluate'}</td>
                <td>
                  {testCaseAction?.actions.length}
                  {/* {testCase?.actions.map((action) => {
                    return <span>{action.action}</span>;
                  })} */}
                </td>
                <td>
                  <button className="btn btn-success w-30" onClick={() => handleEdit(testCaseAction._id)}>Edit</button>
                  <button className="btn btn-danger ms-1" onClick={() => { handleDelete(testCaseAction._id, testCaseAction?.actionName) }}>Delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>


    </div>
  );
}
