export const TypeModel = {
    controls: [{
        type: "text",
        name: "selector",
        required: true
    },
    {
        type: "text",
        name: "value",
        required: true,
    }
    ]
}