import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import formloader from "../../images/formloading.svg";
import CourseService from "../../services/CourseService";
import global from "../../servicesvariable";
import { handleContinue } from "../../utils/course/handleContinue";
import { handleImageLoadingError } from '../../utils/image/handleImageLoadingError';
import "./learningCard.scss";

export default function LearningCard() {
  const [continueLearning, setContinueLearning] = useState([]);
  const [tcounter, setTcounter] = useState([]);
  const [subcourses, setSubCourses] = useState([]);
  const [loading, setLoading] = useState(false);
  var settings = {
    slidesToShow: 5,
    slidesToScroll: 1,
    autoplay: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 9999,
        settings: "unslick",
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1.2,
          slidesToScroll: 1,
          autoplay: false,
          infinite: false,
          margin: 10,
          arrows: false,
          dots: false,
          adaptiveHeight: false,
        },
      },
    ],
  };

  const getStudentColleagueProgressedCourses = async (token, userId) => {
    try {
      setLoading(true);
      const limit = 4;
      const includeNotStarted = 1;
      let includeCompleted = "undefined";
      const progressedCourses =
        await CourseService.getStudentColleagueProgressedCourses(
          token,
          userId,
          limit,
          includeNotStarted,
          includeCompleted
        );
      let courses = progressedCourses.data.data;
      setContinueLearning(courses);
      if (tcounter.length === 0) {
        for (let i = 0; i < courses.length; i++) {
          const tplan = await CourseService.getTrainingPlan(
            courses[i].courseId.name
          );
          if (tplan.data && courses[i].completedTrainingDayPlan) {
            let length = {
              length:
                (tplan.data.length -
                  courses[i].completedTrainingDayPlan.length) *
                8.5,
              name: courses[i].courseId.name,
            };
            setTcounter((d) => [...d, length]);
          } else {
            let length = {
              length: tplan.data.length * 8.5,
              name: courses[i].courseId.name,
            };
            setTcounter((d) => [...d, length]);
          }
          if (i === courses.length - 1) {
            setLoading(false);
          }
        }
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    var counter = 0;

    if (token) {
      const decoded = jwt_decode(token);
      CourseService.getSubCourses()
        .then((res) => {
          if (res.data) {
            setSubCourses(res.data);
          }
        })
        .catch((ex) => { });
      getStudentColleagueProgressedCourses(token, decoded.id);
    }
  }, []);



  return (
    continueLearning &&
    continueLearning.length > 0 && (
      <section className="container">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading...</div>
            </div>
          </div>
        )}
        <div className="learning-card-wrapper">
          {loading && (
            <div className="formloader">
              <div className="row text-center">
                <div className="col-12">
                  <img src={formloader} alt="" height="100" />
                </div>
                <div className="col-12 text-white h4">Loading...</div>
              </div>
            </div>
          )}
          <h2 className="text-dark-gray font-weight-400 ">
            Let's continue learning
          </h2>
          <div className="new-course">
            {continueLearning && continueLearning.length > 0 && (
              <Slider className="row" {...settings}>
                {/* {continueData()} */}
                {
                  continueLearning.map((item, index) => {
                    return (
                      <div className="col-xl-6 pt-lg-4 pt-3 two-col-item" key={index}>
                        {
                          subcourses.map((subcourse) => {
                            if (window.screen.width > 1199) {
                              if (subcourse.name === item.courseId.name) {
                                return (
                                  <CardData props={{ item, index, tcounter, subcourse }} />
                                );
                              }
                            } else {
                              if (subcourse.name === item.courseId.name) {
                                return (
                                  <CardData props={{ item, index, tcounter, subcourse }} />
                                );
                              }
                            }
                          })
                        }
                      </div>
                    );
                  })
                }
              </Slider>
            )}
          </div>
        </div>
      </section>
    )
  );
}


export const CardData = ({ props }) => {

  const navigate = useNavigate();
  const { item, index, tcounter, subcourse } = props;
  const [hoursLeft, setHoursLeft] = useState(0);

  useEffect(() => {


    (() => {
      const courseFindWithHours = tcounter.find(course => course.name === item.courseId.name)
      if (courseFindWithHours) {
        setHoursLeft(Math.ceil(courseFindWithHours.length));
      }
    })()
  }, [tcounter])


  return (
    <div className="card">
      <div className="row g-0">
        <div className="col-xl-4 col-lg-4 col-md-3 course-img">
          {item.courseId.icon === "default.jpg" ? (
            <img
              src={`./../${item.courseId.icon}`}
              className="img-fluid"
              alt={item.courseId.icon}
              height="150"
              width="150"
              data-level="1"
              onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
            ></img>
          ) : item.courseId.icon.match(/http/g) ? (
            <img
              src={item.courseId.icon}
              className="img-fluid"
              alt={item.courseId.icon}
              height="150"
              width="150"
              data-level="1"
              onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
            />
          ) : (
            <>
              <img
                src={`${global.imgURL}/${item.courseId.icon}`}
                className="img-fluid"
                alt={item.courseId.name}
                height="150"
                width="150"
                data-level="1"
                onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
              ></img>
            </>
          )}
        </div>
        <div className="col-xl-8 col-lg-8 col-md-9">
          <div className="card-body p-3">
            <h4 className="card-title text-black font-weight-400">
              {item.courseId.name.replaceAll("_", "  ").replaceAll("-", " ")}
            </h4>
            <p
              className="card-text font-14 text-dark "
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "3",
                WebkitBoxOrient: "vertical",
              }}
            >
              {item.courseId.desc}
            </p>

            <div className="d-flex justify-content-between pt-lg-3 pt-2 pb-lg-0 pb-2 align-items-center mt-auto">
              <div>
                <p className="text-dark-gray font-14 mb-2">
                  <span>
                    <strong>{item.progress}%</strong> Complete
                  </span>{" "}
                  <span>&nbsp;&nbsp;|&nbsp;&nbsp;</span>{" "}
                  <span>
                    <strong>
                      {hoursLeft}
                    </strong>{" "}
                    Hours Left
                  </span>
                </p>

                <div
                  class="progress"
                  role="progressbar"
                  style={{ height: "5px" }}
                >
                  <div
                    class={`progress-bar ${item.progress > 75 ? "bg-success" : "bg-secondary"
                      }`}
                    style={{ width: `${item.progress}%` }}
                  ></div>
                </div>
                {/* </div> */}
              </div>
              <div
                // to={`/course/${subcourse.type.name}/courseOverview/${item.courseId.name}/tplan`}
                onClick={() => {
                  const urlString = handleContinue(subcourse.type.name, item.courseId.name, subcourse.tplans, subcourse.videos, subcourse.ppts)
                  if(urlString) {
                    navigate(urlString);
                  }
                }}
                className="text-primary d-lg-block d-none cursor-pointer"
              >
                Continue
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};