import React from "react";
import { Link, useNavigate } from "react-router-dom";
import global from "../../servicesvariable";
import { handleImageLoadingError } from "../../utils/image/handleImageLoadingError";
import { SubscribeCourses, SubscribedCourses } from "../twoColCard/twoColCard";
import "./accordionList.scss";
import { handleContinue } from "../../utils/course/handleContinue";

export default function AccordionList(props) {
  const {
    course,
    tcounter,
    jwt_decode,
    selectedcourses,
    filterSubCourse,
    setConfirmBox
  } = props;

  return (

    <div class="two-col-card-wrapper">
      <div class="row new-course">
        {filterSubCourse.map((item, index) => {

          // Get No of modules
          let noOfModules = 0;
          const findCourseWithModuleLength = tcounter.find(currCourse => currCourse.name === item.name);
          if (findCourseWithModuleLength) {
            noOfModules = findCourseWithModuleLength.length;
          }

          // No of Videos
          const noOfVideos = item.videos;

          // No of Total Hours
          const noOfTotalHours = Math.ceil(noOfModules * 8.5);


          return localStorage.getItem("token")
            ? jwt_decode(localStorage.getItem("token")).role === "Student"
              ? Array.from(selectedcourses, (o) => o.name).indexOf(
                item.name
              ) === -1
                ? course._id == item.type._id && <SubscribeCourses props={{ item, noOfTotalHours, noOfVideos, index, noOfModules, setConfirmBox }} />
                : course._id == item.type._id && <SubscribedCourses props={{ item, noOfTotalHours, noOfVideos, index, noOfModules, setConfirmBox }} />
              : course._id == item.type._id && <SubscribedCourses props={{ item, noOfTotalHours, noOfVideos, index, noOfModules, setConfirmBox }} />
            : course._id == item.type._id && <HandleSubcourses props={{ item, noOfTotalHours, noOfVideos, index, noOfModules, setConfirmBox }} />;
        })
        }
      </div>
    </div>
  );
}

export const HandleSubcourses = ({ props }) => {

  const { item, index, noOfVideos, noOfTotalHours, noOfModules, setConfirmBox } = props;
  const navigate = useNavigate();

  return (
    <div class="col-lg-6 pt-xl-4 pt-lg-3 pt-3 two-col-item">
      <div class="card mb-lg-3 h-100">
        <div class="row g-0 h-100">
          <div class="col-xl-4 col-lg-4 col-md-3 course-img">
            {item.icon === "default.jpg" ? (
              <img
                src={`./../${item.icon}`}
                class="img-fluid"
                alt=""
                data-level="1"
                onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
              ></img>
            ) : item.icon.match(/http/g) ? (
              <img
                src={item.icon}
                alt={item.name}
                class="img-fluid"
                height="150"
                width="150"
                data-level="1"
                onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
              />
            ) : (
              <>
                <img
                  src={`${global.imgURL}/${item.icon}`}
                  class="img-fluid"
                  alt={item.name}
                  height="150"
                  width="150"
                  data-level="1"
                  onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
                ></img>
              </>
            )}{" "}
          </div>
          <div class="col-xl-8 col-lg-8 col-md-9">
            <div class="card-body p-3 d-flex flex-column justify-content-between h-100">
              <div class="d-flex flex-column flex-wrap h-100">
                <h4 class="card-title text-black font-weight-400">
                  {item.name.replaceAll("_", "  ").replaceAll("-", " ")}
                </h4>
                <p className="card-text font-14 text-dark card-small-content mb-3 mb-lg-0">
                  {item.desc}
                </p>
                <div class="course-details flex-wrap d-flex pb-lg-3 pb-2 mt-auto">
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    <img
                      src={
                        require("../../assets/images/material-view-module.svg")
                          .default
                      }
                      alt=""
                      width="16"
                      height="16"
                      class="me-2"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      <strong>
                        {noOfModules}
                      </strong>{" "}
                      Modules
                    </span>
                  </div>
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    <img
                      src={
                        require("../../assets/images/material-timelapse.svg")
                          .default
                      }
                      alt=""
                      width="16"
                      height="16"
                      class="me-2"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      <strong>
                        {noOfTotalHours}
                      </strong>{" "}
                      Hours
                    </span>
                  </div>
                  <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                    <img
                      src={
                        require("../../assets/images/video_library_icon.svg")
                          .default
                      }
                      alt=""
                      width="16"
                      height="16"
                      class="me-2"
                    ></img>{" "}
                    <span class="font-14 text-dark-gray">
                      <strong>
                        {noOfVideos}
                      </strong>{" "}
                      Videos
                    </span>
                  </div>
                  {item.level && (
                    <div class="pe-lg-3 pe-3 course-item d-flex pb-md-0 pb-2 align-items-center py-1">
                      <img
                        src={
                          require("../../assets/images/feather-bar-chart.svg")
                            .default
                        }
                        alt=""
                        width="16"
                        height="16"
                        class="me-2"
                      ></img>{" "}
                      <span class="font-14 text-dark-gray">
                        For <strong>{item.level}</strong>
                      </span>
                    </div>
                  )}
                </div>
              </div>
              <div class="d-flex flex-wrap justify-content-between pt-lg-3 pt-2 btn-wrapper">
                {item.type && (
                  <Link
                    to={`/course/${item.type.name}/courseDetails/${item.name}`}
                    class="text-primary sm-white-btn"
                  >
                    Course Details
                  </Link>
                )}
                <div
                  onClick={() => {
                    const urlString = handleContinue(item._id, item.type.name, item.name, noOfModules, noOfVideos, item.ppts);
                    if (urlString) {
                      navigate(urlString);
                      setConfirmBox(true);
                    }
                  }}

                  className="cursor-pointer"
                >
                  Subscribe
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}