import React from "react";
import { FaCheckCircle, FaTimesCircle, FaTrash } from "react-icons/fa";
import { MdSelectAll } from "react-icons/md";
import ToolTip from "../BsComponents/ToolTip";

const ShoWHideToggle = ({
  videoAndPPT,
  allVideoAndPPT,
  setVideoAndPPT,
  setDSelectPPTAndVideo,
  setHandleDeletePopup,
  handleRemovePPTAndVideo,
  findVideoAndPPt,
  setHandlePPTAlert,
  handlePPTAlert = false,
  setPdfs,
  setHanadleVideoAlert,
}) => {
  return (
    <div className="bg-primary text-white p-2 rounded d-flex justify-content-between align-items-center">
      <div className="flex align-items-center">
        <FaCheckCircle className="ms-3 " />
        <span className="mx-1">{videoAndPPT.length} selected</span>
      </div>
      <div className="">
        <ToolTip title={"Select All"} placement={"bottom"}>
          <button
            className="btn text-white"
            onClick={() => {
              if (handlePPTAlert) {
                setVideoAndPPT(findVideoAndPPt(allVideoAndPPT, "video"));
                setPdfs(findVideoAndPPt(allVideoAndPPT, "ppt"));
              } else {
                setVideoAndPPT(allVideoAndPPT);
              }
              setDSelectPPTAndVideo(false);
              setHandleDeletePopup(false);
            }}
          >
            <MdSelectAll className="me-2 fs-5" /> Select All
          </button>
        </ToolTip>
      </div>
      <div className="">
        <ToolTip title={"Deselect All"} placement={"bottom"}>
          <button
            className="btn text-white"
            onClick={() => {
              if (handlePPTAlert) {
                setVideoAndPPT([]);
                setPdfs([]);
              } else {
                setVideoAndPPT([]);
              }
              setDSelectPPTAndVideo(true);
              setHandleDeletePopup(true);
            }}
          >
            <MdSelectAll className="me-2 fs-5" /> Deselect All
          </button>
        </ToolTip>
      </div>
      {videoAndPPT?.length > 0 && (
        <div className="">
          <ToolTip title={"Delete"} placement={"bottom"}>
            <button
              className="btn text-white"
              onClick={handleRemovePPTAndVideo}
            >
              <FaTrash className="me-2" /> Delete
            </button>
          </ToolTip>
        </div>
      )}
      <div className="">
        <ToolTip title={"Cancel"} placement={"bottom"}>
          <button
            className="btn text-white"
            onClick={() => {
              if (handlePPTAlert) {
                setVideoAndPPT([]);
                setPdfs([]);
                setHandlePPTAlert(false);
              } else {
                setVideoAndPPT([]);
              }
              setDSelectPPTAndVideo(false);
              setHandleDeletePopup(false);
              if (setHanadleVideoAlert) setHanadleVideoAlert(false);
            }}
          >
            <FaTimesCircle className="me-2" /> Cancel
          </button>
        </ToolTip>
      </div>
    </div>
  );
};

export default ShoWHideToggle;
