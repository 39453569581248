import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle";
import $ from "jquery";
import { React, createContext, useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import "../App.scss";

// Importing Pages
import Excel from "../components/Excel";
import Calendar from "../components/calendar/Calendar";
import Profile from "../components/userdashboard/UserProfile";
import VideoTutorial from "../components/videoTutorial/VideoTutorial";
import BatchInfo from "../pages/batchInfo/BatchInfo";
import CorrectAnswer from "../pages/correctanswers/CorrectAnswer";
import CloudVideom3u8 from "../pages/course/CloudVideom3u8";
import Cloudvideo from "../pages/course/Cloudvideo";
import SubCourse from "../pages/course/SubCourse";
import Videom3u8 from "../pages/course/Videom3u8";
import CourseDetails from "../pages/courseDetails/courseDetails";
import CourseList from "../pages/courseList/courseList";
import CourseOverview from "../pages/courseOverview/courseOverview";
import Description from "../pages/description/Description";
import ExamPage from "../pages/exampage/Exam";
import ExamVideo from "../pages/exampage/ExamVideo";
import McqExam from "../pages/exampage/McqExam";
import PracticalExam from "../pages/exampage/PracticalExam";
import ExploreCourses from "../pages/exploreCourses/exploreCourses";
import ForgotPassword from "../pages/forgot/ForgotPassword";
import Home from "../pages/home/Home";
import AccessRequest from "../pages/login/AccessRequest";
import Signup from "../pages/login/Signup";
import MyLearning from "../pages/myLearning/MyLearning";
import Leaves from "../pages/myLeaves/Leave";
import ResetPassword from "../pages/reset/ResetPassword";
import Static from "../pages/static/static";
import RouteAuthGuard from "./RouteAuthGuard";

import CreateSpec from "../components/TestCaseSpec/CreateSpec";
import EditSpec from "../components/TestCaseSpec/EditSpec";
import SpecListing from "../components/TestCaseSpec/SpecListing";
import TestCaseSpec from "../components/TestCaseSpec/TestCaseSpec";
import CreateTestCaseActions from "../components/TestCasesActions/CreateTestCaseActions";
import EditTestCaseActions from "../components/TestCasesActions/EditTestCaseActions";
import TestCasesActions from "../components/TestCasesActions/TestCasesAction";
import TestCaseList from "../components/TestCasesActions/TestCasesActionList";
import AddCategory from "../components/category/AddCategory";
import ManageCategory from "../components/category/ManageCategory";
import AddCourse from "../components/course/AddCourse";
import ManageCourses from "../components/course/ManageCourses";
import Dashboard from "../components/dashboard/Dashboard";
import ParentBatch from "../components/department/parent-batch/ParentBatch";
import Footer from "../components/footer/Footer";
import AddDayInBatch from "../components/forms/AddDayInBatch";
import AddTrainingPlan from "../components/forms/AddTrininigPlan";
import CloneTrainingPlan from "../components/forms/CloneTrainingPlan";
import CreateBatch from "../components/forms/CreateBatch";
import CreateExamSchedule from "../components/forms/CreateExamSchedule";
import ManageApproval from "../components/forms/ManageApproval";
import ManagePlan from "../components/forms/ManageBatch";
import Managem3u8video from "../components/forms/ManageM3U8videos";
import ManageMCQQuestionList from "../components/forms/ManageMCQQuestionList";
import ManagePracticalQuestionList from "../components/forms/ManagePracticalQuestionList";
import ManageQuestion from "../components/forms/ManageQuestion";
import ManageSubscription from "../components/forms/ManageSubscription";
import ManageTrainingPDF from "../components/forms/ManageTrainingPDF";
import ManageTrainingPlan from "../components/forms/ManageTrainingPlan";
import ManageTrainingProgress from "../components/forms/ManageTrainingProgress";
import RemoveTrainingPlan from "../components/forms/RemoveTrainingPlan";
import ResultBatchParent2 from "../components/forms/ResultBatchParent2";
import ResultBatchParentNew from "../components/forms/ResultBatchParentNew";
import UpdateContentRequests from "../components/forms/UpdateContentRequests";
import UpdateResultRequest from "../components/forms/UpdateResult";
import UpdateTrainingPlan from "../components/forms/UpdateTrainingPlan";
import UpdateTrainingPlanRequest from "../components/forms/UpdateTrainingPlanRequest";
import BatchList from "../components/manageBatch/BatchList";
import UpdateBatch from "../components/manageBatch/UpdateBatch";
import UpdateSkillDaysAndWeightage from "../components/manageBatch/UpdateSkillDaysAndWeightage";
import Navbar from "../components/navbar/Navbar";
import CoursePPT from "../components/ppt/CoursePPT";
import AddUpdateSubCourse from "../components/subCourse/AddUpdateSubCourse";
import SubCourseList from "../components/subCourse/SubCourseList";
import AddUser from "../components/user/AddUser";
import ManageUser from "../components/user/ManageUser";
import UpdateUser from "../components/user/UpdateUser";
import UpdateUserUsingExcel from "../components/user/UpdateUserUsingExcel";
import CoursesList from "../components/userAnalysis/CoursesList";
import ReviewChart from "../components/userAnalysis/ReviewChart";
import UserList from "../components/videoProgress/UserList";
import UserVideoProgress from "../components/videoProgress/UserVideoProgress";
import VideoList from "../components/videos/VideoList";
import Admin1 from "../pages/admin/Admin";
import Error from "../pages/error/error";
import Login from "../pages/login/Login";
import Mp4VideoUploadStatus from "../components/videos/Mp4VideoUploadStatus";

export const UserContext = createContext({});

// Importing components
const Routing = () => {
  const [loginStatus, setLoginStatus] = useState(false);
  const [view, setView] = useState("");
  console.warn = console.error = () => {};

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setLoginStatus(true);
    } else {
      setLoginStatus(false);
    }
    var headerHeight = $(".navbar").outerHeight();
    var footerHeight = $("footer").outerHeight();
    var footerCopyright = $(".footer-lg").outerHeight();
    var bannerHeight = 0;
    var totalHeight =
      headerHeight + bannerHeight + footerHeight + footerCopyright;
    $(".page-main").css({ "margin-top": headerHeight });
    $(".page-main").css({ "min-height": "calc(100vh - " + totalHeight + "px" });
  }, [setView]);

  return (
    <UserContext.Provider
      value={{ loginStatus, setLoginStatus, setView, view }}
    >
      <Router>
        <Navbar />
        <div class="page-main bg-page-bg">
          <Routes>
            {/* Public Routes */}
            <Route path="/" element={<Home />} />
            <Route
              path="correctanswer/:examId/:userId/:examType"
              element={
                <RouteAuthGuard
                      component={<CorrectAnswer />}
                      redirect="/"
                      isAdmin={true}
                    />
              }
            />
            <Route path="static" element={<Static />} />
            <Route path="login" element={<Login />} />
            <Route path="signup" element={<Signup />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route
              path="reset-password/:resetoken"
              element={<ResetPassword />}
            />

            <Route
              path="course/:cname/courseDetails/:sname"
              element={<CourseDetails />}
            />
            <Route path="exploreCourses" element={<ExploreCourses />} />

            {/* Student Route */}
            <Route
              path="batchinfo/:SkillName/:ExamType/:BatchId"
              element={
                <RouteAuthGuard component={<BatchInfo />} redirect="/" />
              }
            />

            <Route
              path="calendar"
              element={
                <RouteAuthGuard
                  component={
                    <Calendar
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />
            <Route
              path="usersResults"
              element={
                <RouteAuthGuard
                  component={
                    <ResultBatchParentNew
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />
            <Route
              path="profile"
              element={
                <RouteAuthGuard
                  component={
                    <Profile
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />
            <Route
              path="mylearning"
              element={
                <RouteAuthGuard component={<MyLearning />} redirect="/" />
              }
            />

            <Route
              path="applyleave"
              element={<RouteAuthGuard component={<Leaves />} redirect="/" />}
            />

            <Route
              path="course/:cname/courseOverview/:sname/:choice/:vid"
              element={
                <RouteAuthGuard component={<VideoTutorial />} redirect="/" />
              }
            />
            <Route
              path="course/:cname/courseOverview/:sname/:choice"
              element={
                <RouteAuthGuard component={<CourseOverview />} redirect="/" />
              }
            />
            <Route
              path="ExamVideo/:batchName/:skillName/:examCode/:hostId/:userId"
              element={
                <RouteAuthGuard component={<ExamVideo />} redirect="/" />
              }
            />

            <Route
              path="course/:cname"
              element={
                <RouteAuthGuard
                  component={
                    <CourseList
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/login"
                />
              }
            />
            <Route
              path="testExam"
              element={
                <RouteAuthGuard
                  component={
                    <ExamPage
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />
            <Route
              path="MCQtestExam/:examId"
              element={
                <RouteAuthGuard
                  component={
                    <McqExam
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="practicalExam"
              element={
                <RouteAuthGuard
                  component={
                    <PracticalExam
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="course/:cname/subcourse/:sname"
              element={
                <RouteAuthGuard
                  component={
                    <SubCourse
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="description/:sname"
              element={
                <RouteAuthGuard
                  component={
                    <Description
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="access-request/:token"
              element={<AccessRequest />}
              // element={
              //   <RouteAuthGuard component={<AccessRequest />} redirect="/" />
              // }
            />

            <Route
              path="cloudedemoplayer"
              element={
                <RouteAuthGuard
                  component={
                    <Cloudvideo
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="result/parent-batch"
              element={
                <RouteAuthGuard
                  component={
                    <ResultBatchParent2
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="cloudedemoplayerm3u8"
              element={
                <RouteAuthGuard
                  component={
                    <CloudVideom3u8
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />
            <Route
              path="demoplayerm3u8"
              element={
                <RouteAuthGuard
                  component={
                    <Videom3u8
                      setLoginStatus={setLoginStatus}
                      loginStatus={loginStatus}
                    />
                  }
                  redirect="/"
                />
              }
            />

            <Route
              path="excel"
              element={<RouteAuthGuard component={<Excel />} redirect="/" />}
            />

            {/* Admin Routes */}
            <Route
              path="admin"
              element={
                <RouteAuthGuard
                  component={<Admin1 />}
                  redirect="/"
                  isAdmin={true}
                />
              }
            >
              <Route path="dashboard" element={<Dashboard />} />

              {/* User Management Routes */}
              <Route path="user/manage" element={<ManageUser />}>
                {/* child routing */}
                <Route path="add" element={<AddUser />} />
                <Route
                  path="update-with-excel"
                  element={<UpdateUserUsingExcel />}
                />
                <Route path="update/:id" element={<UpdateUser />} />
              </Route>
              <Route
                path="user/subscription"
                element={<ManageSubscription />}
              />
              <Route path="user/approval" element={<ManageApproval />} />
              <Route
                path="user/training-progress"
                element={<ManageTrainingProgress />}
              />
              <Route
                path="user/manage-user-analysis"
                element={<CoursesList title="Courses" />}
              />
              <Route
                path="user/manage-user-analysis/courses/:id"
                element={<ReviewChart />}
              />
              <Route path="user/video-progress" element={<UserList />} />
              <Route
                path="user/:userId/video-progress"
                element={<UserVideoProgress />}
              />

              {/* Course Routes */}
              <Route path="course/manage-course" element={<ManageCourses />} />
              <Route path="course/manage-course/add" element={<AddCourse />} />
              <Route
                path="course/manage-category"
                element={<ManageCategory />}
              />
              <Route
                path="course/manage-category/add"
                element={<AddCategory />}
              />
              <Route
                path="course/manage-subcourse"
                element={<SubCourseList />}
              />
              <Route
                path="course/manage-subcourse/add"
                element={<AddUpdateSubCourse />}
              />
              <Route
                path="course/manage-subcourse/edit/:id"
                element={<AddUpdateSubCourse />}
              />
              <Route path="course/manage-plan" element={<ManagePlan />} />
              <Route
                path="course/update-training-plan-request"
                element={<UpdateTrainingPlanRequest />}
              />
              <Route
                path="course/update-content-request"
                element={<UpdateContentRequests />}
              />
              <Route path="course/ppt" element={<CoursesList title="PPTs" />} />
              <Route path="course/:id/ppt" element={<CoursePPT />} />
              <Route path="course/m3u8video" element={<Managem3u8video />} />
              <Route
                path="course/video"
                element={<CoursesList title="Videos" />}
              />
              <Route path="course/:id/video" element={<VideoList />} />
              <Route
                path="course/training-plan"
                element={<ManageTrainingPlan />}
              >
                <Route path="add" element={<AddTrainingPlan />} />
                <Route path="update" element={<UpdateTrainingPlan />} />
                <Route path="remove" element={<RemoveTrainingPlan />} />
                <Route path="clone" element={<CloneTrainingPlan />} />
              </Route>

              <Route path="course/manage-pdf" element={<ManageTrainingPDF />} />
              <Route path="course/mp4UploadStatus" element={<Mp4VideoUploadStatus />} />
              
              {/* test case routes */}
              <Route path="manage-testcase" element={<TestCasesActions />}>
                <Route path="list" element={<TestCaseList />} />
                <Route path="create" element={<CreateTestCaseActions />} />
                <Route
                  path="updateaction/:id"
                  element={<EditTestCaseActions />}
                />
              </Route>

              {/* Spec  Routes */}
              <Route path="manage-spec" element={<TestCaseSpec />}>
                <Route path="list" element={<SpecListing />} />
                <Route path="create" element={<CreateSpec />} />
                <Route path="updatespec/:specId" element={<EditSpec />} />
              </Route>

              {/* Test Routes */}
              <Route
                path="test/manage-questions"
                element={<ManageQuestion />}
              />
              <Route path="test/create-exam" element={<CreateExamSchedule />} />
              <Route
                path="test/manage-practical-questions"
                element={<ManagePracticalQuestionList />}
              />
              <Route
                path="test/manage-mcq-questions"
                element={<ManageMCQQuestionList />}
              />

              {/* Batch Manangement Routes */}
              <Route path="batch-management/create" element={<CreateBatch />} />
              <Route path="batch-management/manage" element={<BatchList />} />
              <Route
                path="batch-management/manage/:id/update-skill-days"
                element={<UpdateSkillDaysAndWeightage />}
              />
              <Route
                path="batch-management/manage/:id/update"
                element={<UpdateBatch />}
              />
              <Route
                path="batch-management/add-day"
                element={<AddDayInBatch />}
              />
              <Route
                path="batch-management/add-parent-batch"
                element={<ParentBatch />}
              />

              <Route
                path="result/parent-batch"
                element={<ResultBatchParentNew />}
              />
              {/* <Route path="result/temp" element={<ResultBatchParent2 />} /> */}
              <Route
                path="result/update-request"
                element={<UpdateResultRequest />}
              />
            </Route>

            <Route path="*" element={<Error />} />
          </Routes>
        </div>
        <Footer />
      </Router>
    </UserContext.Provider>
  );
};

export default Routing;
