import { CommonValues } from "./commonValues";

export const MinMaxLength = {
    controls: [
        {
            type: "number",
            name: "length",
            required: true,
        },
        CommonValues.controls
    ]
}