import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import FourCol from "../../components/fourCol/FourCol";
import "./exploreCourses.scss";
import { useLocation } from "react-router-dom";
import { blacklistcourse } from "../../servicesvariable";

export default function ExploreCourses(props) {
  const [courses, setCourses] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();

  var searchcourse = "";
  if (state) {
    searchcourse = props.location.state.search;
  }
  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    CourseService.getCategory().then((cat) => {
      if (cat.data) {
        CourseService.getCourses()
          .then((res) => {
            if (res.data) {
              try{
                const data = res.data.filter(course => !blacklistcourse.includes(course.name))
                setCourses(data);
              }
              catch(e){
                console.log('Error in course fetching:'+e);
              }
            }
          })
          .catch((ex) => {});
      }
      setLoading(false);
    });
  }, [confirmBox]);

  return (
    <>
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">Loading...</div>
          </div>
        </div>
      )}
      <div className="courses ">
        <section class="container">
          <div class="four-col-wrapper">
            <h2 class="m-0 text-dark-gray font-weight-400 mb-lg-2">
              Explore all the technologies
            </h2>
            <div class="row tech">
              {courses.map((item, index) => {
                if (item) {
                  return (
                    <div class="col-6 col-md-4 col-lg-3 pt-3 pt-xl-4 four-col-item">
                      <FourCol key={index} course={item} />
                    </div>
                  );
                }
                return 0;
              })}
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
