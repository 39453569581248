import { ShouldContain } from '../TestCaseModals/shouldContain'
import { DelayModel } from '../TestCaseModals/delayModel'
import { ClickModel } from '../TestCaseModals/clickModel'
import { ScreenShotModel } from '../TestCaseModals/screenShotModel'
import { VisitPageModel } from '../TestCaseModals/visitPageModel'
import { TypeModel } from '../TestCaseModals/typeModel'
import { FormValidationModel } from '../TestCaseModals/formValidationModel'
import { SelectModel } from '../TestCaseModals/selectModel'
import { SearchModel } from '../TestCaseModals/searchModel'
import { FillFormWithRandomValuesModel } from '../TestCaseModals/FillFormWithRandomValuesModel'
import { LoopModel } from '../TestCaseModals/loopModel'
import { FilterModel } from '../TestCaseModals/filterModel'
import { PaginationModel } from '../TestCaseModals/paginationModel'
import { SortingModel } from '../TestCaseModals/sortingModel'
import { VanillaJsModel } from '../TestCaseModals/vanillajsModel'
import { NodeFunctionModel } from '../TestCaseModals/nodeFunctionModel'
import { AlertModel } from '../TestCaseModals/alertModel'
import { Selectcheckboxradio } from '../TestCaseModals/selectcheckboxradio'
import { UlLi } from '../TestCaseModals/ulLiModel'
import { RangeModel } from '../TestCaseModals/rangeModel'
import { ActionsModel } from '../TestCaseModals/actionsModel'
import { DomElement } from '../TestCaseModals/domElement'
import { UrlModel } from '../TestCaseModals/urlModel'

const SCHEMA =
{
    // DelayModel
    1: DelayModel,

    //click modal
    2: ClickModel,

    // ScreenShotModel
    3: ScreenShotModel,

    // VisitPageModel
    4: VisitPageModel,

    // RefreshModel
    5: {
        controls: [
        ]
    },

    // TypeModel
    6: TypeModel,

    // FormValidationModel
    7: FormValidationModel,

    8: {
        // pending CascadingDropdownModel
        controls: []
        //  [{
        //     type: "number",
        //     name: "id",
        //     required: true
        // },
        // {
        //     type: "text",
        //     name: "submitButtonSelector",
        //     required: true
        // }
        // ]
    },

    //SelectModel
    9: SelectModel,

    // search modal pending regards valueToSearch field
    10: SearchModel,

    // pending design for FillFormWithRandomValuesModel
    11: FillFormWithRandomValuesModel,

    // LoopModel
    12: LoopModel,

    // filter modal
    13: FilterModel,

    // pagination modal
    14: PaginationModel,

    // SortingModel
    15: SortingModel,

    // pending VanillaJsModel
    16: VanillaJsModel,

    // pending NodeFunctionModel 
    17: NodeFunctionModel,

    // alert modal
    18: AlertModel,

    // selectcheckboxradio modal
    19: Selectcheckboxradio,

    // pending schema design for UlLiModel
    20: UlLi,

    // select range modal
    21: RangeModel,

    // actions
    22: ActionsModel,

    // shouldContain
    23: ShouldContain,

    // ShouldNotContain
    24: ShouldContain,

    // domelement
    25: DomElement,

    // url
    26: UrlModel

    // 3: { // Loop Action
    //     controls: [{
    //         type: "text",
    //         name: "startIndex"
    //     },
    //     {
    //         type: "text",
    //         name: "endIndex"
    //     },
    //     {
    //         type: "dropdown",
    //         name: "actions",
    //         sourceName: 'actionSource'
    //     }
    //     ]
    // },
    // 4: {
    //     componentName: 'Form Validation Component'
    // }
}
export default SCHEMA;

