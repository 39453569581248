export const Selectcheckboxradio = {
    controls: [{
        type: "text",
        name: "elementName",
        required: true
    },
    {
        type: "text",
        name: "option",
        required: true
    }
    ]
}