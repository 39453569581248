import React, { useEffect, useState } from "react";
import Select from "react-select";
import TestCaseSchema from "../../services/TestCaseSchema";
import TestCaseService from "../../services/TestCaseService.js";
import FormFillValidationForm from "./FormFillValidationForm";
import VanilaJsModal from "./VanilaJsModal";

export default function CommonActionForm({
  isEdit,
  actionName,
  index,
  setTestCase,
  testCase,
  handleInputValueType,
  getInputValue,
  manageFormsArrayLists,
  getFormList,
}) {
  const [actionControls, setActionControls] = useState([]);
  const [testCaseList, setTestCaseList] = useState([]);

  useEffect(() => {
    const getActioncontrols = TestCaseSchema[actionName]?.controls;
    setActionControls(getActioncontrols);
    let authtoken = localStorage.getItem("token");
    if (authtoken) {
      TestCaseService.getAllAction(authtoken)
        .then((res) => {
          setTestCaseList(res.data.allactionsData);
        })
        .catch((err) => console.log(err));
    }
  }, [actionName]);

  const setActionValue = (actionName, value) => {
    const copytestCase = { ...testCase };
    const getAction = copytestCase["actions"][index];
    getAction[actionName] = value;
    setTestCase(copytestCase);
  };

  const handleInputValue = (e, type) => {
    let { name, value, checked } = e.target;
    value = handleInputValueType(value, type, checked);
    setActionValue(name, value);
  };

  const handleInputControls = (control) => {
    if (control.type === "text") {
      return (
        <div className="col-md-4 row">
          {/* <div className="col-md-4">{control.name}</div> */}
          <div className="col-md-12 py-2">
            <input
              className="w-100"
              type="search"
              placeholder={`${control.name}`}
              value={getInputValue(control.name, index)}
              name={control.name}
              onChange={(e) =>
                handleInputValue(e, control?.valueType || "text", index)
              }
              required={control.required}
            />
          </div>
        </div>
      );
    } else if (control.type === "number") {
      return (
        <div className="col-md-4 row">
          {/* <div className="col-md-4">{control.name}</div> */}
          <div className="col-md-12 py-2">
            <input
              className="w-100"
              type="number"
              placeholder={`${control.name}`}
              name={control.name}
              value={getInputValue(control.name, index)}
              onChange={(e) => handleInputValue(e, control.type)}
              required={control.required}
            />
          </div>
        </div>
      );
    } else if (control.type === "boolean") {
      return (
        <div className="col-md-4 row">
          <div className="col-md-8 py-2">{control.name}</div>
          <div className="col-md-2 py-2">
            <input
              className="w-100"
              type="checkbox"
              checked={getInputValue(control.name, index)}
              name={control.name}
              placeholder={`${control.name}`}
              onChange={(e) => handleInputValue(e, control.type)}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="col-md-4 row">
          <div className="col-md-12 py-2">
            <Select
              options={testCaseList?.map((options) => {
                return {
                  value: options._id,
                  label: options.actionName,
                };
              })}
              placeholder={`Select ${control.name}`}
              onChange={(e) => setActionValue(control.name, e)}
              isMulti
              isSearchable
              value={getInputValue(control.name, index)}
            />
          </div>
        </div>
      );
    }
  };

  return (
    <>
      {actionControls?.length ? (
        actionControls.map((control) => {
          return <>{handleInputControls(control)}</>;
        })
      ) : (
        <div className="text-primary h3">Controls are not required</div>
      )}
      {(actionName === "7" || actionName === "11") && (
        <FormFillValidationForm
          isEdit={isEdit}
          actionId={actionName === "7" ? true : false}
          index={index}
          setTestCase={setTestCase}
          testCase={testCase}
          handleInputValueType={handleInputValueType}
          getInputValue={getInputValue}
          manageFormsArrayLists={manageFormsArrayLists}
          getFormList={getFormList}
        />
      )}
      {(actionName === "17" || actionName === "16") && (
        <VanilaJsModal
          isEdit={isEdit}
          actionName="testCase"
          // actionArray={[{ controlId: "", values: [] }]}
          actionArray={[{ parameters: [], expectedResult: [] }]}
          index={index}
          setTestCase={setTestCase}
          testCase={testCase}
          getInputValue={getInputValue}
          handleInputValueType={handleInputValueType}
          manageFormsArrayLists={manageFormsArrayLists}
          getFormList={getFormList}
        />
      )}
    </>
  );
}
