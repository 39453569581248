import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import global from "../../servicesvariable";
import { getNoOfPpts } from "../../utils/course/getNoOfPpts";
import { getNoOfVideo } from "../../utils/course/getNoOfVideo";
import { handleImageLoadingError } from "../../utils/image/handleImageLoadingError";
import "./FourCol.scss";
import { Result } from "antd";

export default function FourCol(props) {
  const { name, icon, desc, _id, status } = props.course;
  const [userdata, setUserdata] = useState([]);
  const [dcounter, setDcounter] = useState(0);
  const [vcount, setVcount] = useState(0);
  const [hcounter, setHcounter] = useState(0);
  const [confirmBox, setConfirmBox] = useState(false);

  useEffect(() => {
    setConfirmBox(false);
    const token = localStorage.getItem("token");

    if (token) {
      const decoded = jwt_decode(token);
      LoginService.getSingleUser(decoded.id, token).then((res) => {
        if (res.data) {
          setUserdata(res.data);
        }
      });
    }

    CourseService.getSinglesubcourse(_id).then(async (res) => {
      if (res.data) {
        let totalVideos = 0;
        let totalHours = 0;

        for (let i = 0; i < res.data.length; i++) {
          const subcourse = res.data[i];
          const noOfVideo = await getNoOfVideo(subcourse.name); // Number of videos
          const noOfPpts = await getNoOfPpts(subcourse.name); // Number of ppts
          const res1 = await CourseService.getTrainingPlan(subcourse.name);

          if (res1.data) {
            const noOfModules = res1.data.length ?? 0; // Number of Modules

            // If any of the following is not zero, then add to the totals
            if (noOfVideo !== 0 || noOfModules !== 0 || noOfPpts !== 0) {
              totalVideos += noOfVideo;
              totalHours += Math.ceil(res1.data.length * 8.5);
            }
          }
        }
        setVcount(totalVideos);
        setHcounter(totalHours);
        setDcounter(res.data.length);
      }
    });
  }, [confirmBox]);

  return (
    <>
      <Link className="card text-decoration-none h-100" to={`/course/${name}`}>
        <div class="tech-img">
          {icon === "default.jpg" ? (
            <img
              src={`./../${icon}`}
              alt={icon}
              class="img-fluid w-100"
              width="150"
              height="150"
              data-level="1"
              onError={(e) => {
                if (parseInt(e.target.dataset.level) === 1)
                  handleImageLoadingError(e);
              }}
            />
          ) : icon.match(/http/g) ? (
            <img
              src={icon}
              alt={icon}
              class="img-fluid w-100"
              width="150"
              height="150"
              data-level="1"
              onError={(e) => {
                if (parseInt(e.target.dataset.level) === 1)
                  handleImageLoadingError(e);
              }}
            />
          ) : (
            <>
              <img
                src={`${global.imgURL}/${icon}`}
                className="img-fluid w-100"
                alt={name}
                height="150"
                width="150"
                data-level="1"
                onError={(e) => {
                  if (parseInt(e.target.dataset.level) === 1)
                    handleImageLoadingError(e);
                }}
              ></img>
            </>
          )}
        </div>
        <div class="tech-details p-3 card-body d-flex flex-column">
          <div class="tech-name font-20 text-black">{name}</div>
          <div class="d-flex flex-wrap justify-content-between mt-auto">
            <span class="col-12 col-md-auto me-3">
              <strong>{dcounter === -1 ? <>0</> : <>{dcounter}</>}</strong>
              &nbsp;Courses
            </span>
            <span class="col-12 col-md-auto me-3">
              <strong>{hcounter === -1 ? <>0</> : <>{hcounter}</>}</strong>
              &nbsp;Hrs
            </span>
            <span class="col-12 col-md-auto me-3">
              <strong>{vcount}</strong>
              &nbsp;Videos
            </span>
          </div>
        </div>
      </Link>
    </>
  );
}
