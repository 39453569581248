import { Navigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";

const RouteAuthGuard = (props) => {
  const location = useLocation();

  let allowRoute = false;
  const token = localStorage.getItem("token");

  if (token) {
    const { role } = jwt_decode(token);
    if (role === "Admin" && props.isAdmin) {
      allowRoute = true;
    } else if (role === "Student" && props.isAdmin) {
      allowRoute = false;
    } else {
      allowRoute = true;
    }
  } else {
    allowRoute = false;
  }

  if (!allowRoute) {
    return (
      <Navigate
        to={props.redirect}
        state={{ from: location }}
        replace={false}
      />
    );
  }

  return <>{props.component}</>;
};
export default RouteAuthGuard;
