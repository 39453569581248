import React from "react";
import { FaClone, FaEdit, FaPlusCircle, FaTrashAlt } from "react-icons/fa";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import ToolTip from "../BsComponents/ToolTip";

const ManageTrainingPlan = () => {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  const choices = {
    1: { path: "add", state: "Add Training Plan" },
    2: { path: "update", state: "Update Training Plan" },
    3: { path: "remove", state: "Remove Training Plan" },
    4: { path: "clone", state: "Clone Training Plan" },
  };

  const changeChoice = (ch) => {
    const choice = choices[ch];
    if (choice) {
      navigate(choice.path, { state: choice.state });
    }
  };

  const renderToolTip = (title, placement, onClick, icon, text) => (
    <ToolTip title={title} placement={placement}>
      <div
        className="bg-white pt-4 pb-1 rounded citem text-center color-dback"
        onClick={onClick}
      >
        {icon}
        <p className="py-2">{text}</p>
      </div>
    </ToolTip>
  );

  return (
    <React.Fragment>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0 py-2">
            <li
              className={
                pathname === "/admin/course/training-plan"
                  ? "breadcrumb-item active"
                  : "breadcrumb-item text-primary"
              }
              style={{
                cursor:
                  pathname === "/admin/course/training-plan"
                    ? "auto"
                    : "pointer",
              }}
              onClick={() => {
                navigate("/admin/course/training-plan");
              }}
            >
              Manage Training Plans
            </li>
            {state ? <li className="breadcrumb-item active">{state}</li> : null}
          </ol>
        </nav>
      </div>

      <div className="container px-3">
        <div className="form px-lg-5 mb-4">
          <div className="row g-4 listcourses py-4 pt-5">
            {pathname === "/admin/course/training-plan" || <Outlet />}

            {pathname === "/admin/course/training-plan" && (
              <>
                <div className="col-lg-3">
                  {renderToolTip(
                    "Add New Training Plan",
                    "bottom",
                    () => changeChoice(1),
                    <FaPlusCircle className="fs-1 my-2" />,
                    "Add New Plan"
                  )}
                </div>

                <div className="col-lg-3">
                  {renderToolTip(
                    "Update Training Plan",
                    "bottom",
                    () => changeChoice(2),
                    <FaEdit className="fs-1 my-2" />,
                    "Update Plan"
                  )}
                </div>
                <div className="col-lg-3">
                  {renderToolTip(
                    "Remove Training Plan",
                    "bottom",
                    () => changeChoice(3),
                    <FaTrashAlt className="fs-1 my-2" />,
                    "Remove Plan"
                  )}
                </div>
                <div className="col-lg-3">
                  {renderToolTip(
                    "Clone Training Plan",
                    "bottom",
                    () => changeChoice(4),
                    <FaClone className="fs-1 my-2" />,
                    "Clone Plan"
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ManageTrainingPlan;
