import React, { createRef, useState } from "react";
import CloseRedSvgIcon from '../../assets/images/close_red.svg'

// Functional component for uploading MP4 files
export function ActualMp4Modal({ handleMp4Upload }) {
    // State for managing uploaded files
    const [files, setFiles] = useState([]);
    // Reference for file input element
    const filesInputElemRef = createRef();

    // Function to handle file input change
    const handleChangeUploadInput = (e) => {
        const filesObj = e.target.files;
        for (let key in filesObj) {
            if (filesObj[key] instanceof File)
                setFiles((prev) => [...prev, filesObj[key]]);
        }
    }

    // Function to handle form submission
    const handleFormSubmit = (e) => {
        e.preventDefault();
        setFiles([])
        handleMp4Upload(files);
    }

    // Function to reset uploaded files
    const handleMp4FormReset = () => {
        setFiles([]);
    }

    // Function to remove a file from the list
    const handleMp4FileRemove = (index) => {
        const filterArr = files.filter((_, currIndex) => currIndex !== index)
        setFiles([...filterArr])
    }

    return (
        <div className="modal fade" id="actualMp4Modal" tabIndex="-1" aria-labelledby="actualMp4ModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <form onSubmit={(e) => handleFormSubmit(e)} encType='multipart/form-data' onReset={handleMp4FormReset}>
                        <div className="modal-header">
                            <h1 className="modal-title fs-5" id="actualMp4ModalLabel">Upload Mp4 Video</h1>
                            <button type="reset" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <label>
                                Choose Files
                                <input
                                    className="d-none"
                                    ref={filesInputElemRef}
                                    type="file"
                                    id="mp4VideoUpload"
                                    name="mp4VideoUpload"
                                    accept="video/*"
                                    onChange={handleChangeUploadInput}
                                    multiple />
                            </label>
                            <table className="w-100">
                                <tbody>
                                    {files.map((file, index) => (
                                        <tr key={index}>
                                            <td>{file.name}</td>
                                            <td className="text-end" onClick={() => handleMp4FileRemove(index)}><img src={CloseRedSvgIcon} /></td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                        <div className="modal-footer">
                            <button type="reset" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                            <button type="submit" className="btn btn-primary" data-bs-dismiss={files.length > 0 ? 'modal' : ''}>Upload Mp4 Video</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
