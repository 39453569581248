import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import ManageCategoryCard from "../cards/ManageCategoryCard";
import { FaPlus } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

const ManageCategory = () => {
  const [allcategory, setallcategory] = useState([]);
  const [rcate, setRcate] = useState({});
  const [cateitem, setCateitem] = useState({});
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [errBox, setErrBox] = useState(false);

  const [msg, setMsg] = useState("");
  const [errMsg, setErrMsg] = useState("")
  const navigate = useNavigate();

  const getCategory = () => {
    CourseService.getCategory()
      .then((res) => {
        setallcategory(res.data);
      })
      .catch((ex) => console.log(ex));
  };

  useEffect(() => {
    getCategory();
  }, []);

  const handleEdit = (item) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (cateitem) {
        setLoading(true);
        CourseService.putCategory(cateitem, item, token)
          .then((res) => {
            console.log(res);
            if (res.data.msg) {
              setMsg("Category Updated Successfully...");
              setConfirmBox(true);
              getCategory();
            }
          })
          .catch((ex) => {
              console.log(ex);
            });
        setLoading(false);
      }
    }
    {
      setTimeout(() => setConfirmBox(false), 3000);
    }
  };

  const handleDelete = (item) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (item) {
        CourseService.deleteCategory(item, token)
          .then((res) => {
            if (res.data.msg !== "courses") {
              setMsg("Category Deleted Successfully...");
              setConfirmBox(true);
              getCategory();
            }else{
              setErrMsg("Category already used in differnt courses");
              setErrBox(true);
              getCategory();
            }
          })
          .catch((ex) => {
            console.log(ex)});
      }
    }
    {
      setTimeout(() => setConfirmBox(false), 3000);
      setTimeout(() => setErrBox(false), 3000);
    }
  };
  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0 py-2">
            <li className={"breadcrumb-item active"}>Manage Categories</li>
          </ol>
        </nav>
        <div className="">
          <button
            htmlFor="adcategory"
            className="btn bg-green text-white "
            onClick={() => {
              navigate("/admin/course/manage-category/add");
            }}>
            <FaPlus className="me-2 fs-4" />
            Add Category
          </button>
        </div>
      </div>

      <div className="container p-2 px-3">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading...</div>
            </div>
          </div>
        )}
        {confirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div className="alert alert-success">{msg}</div>
              </div>
            </div>
          </div>
        )}
        {errBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100" style={{background: "#ffffff",padding: "1rem",
      borderRadius: "0.2rem"}}>
                <div className="alert alert-danger">{errMsg}</div>
              </div>
            </div>
          </div>
        )}
        <div className="form px-lg-5">
          <div className="row g-4 listcourses py-4">
            {allcategory.map((item, index) => {
              return (
                <ManageCategoryCard
                  item={item.cname}
                  itemId={item._id}
                  key={index}
                  loading={loading}
                  setRcate={setRcate}
                  rcate={rcate}
                  setCateitem={setCateitem}
                  cateitem={cateitem}
                  handleDelete={handleDelete}
                  handleEdit={handleEdit}
                  setLoading={setLoading}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageCategory;
