import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import XLSX from "xlsx";
import LoginService from "../../services/LoginService";
import BatchManagementServices from "../../services/BatchManagementServices";
import formloader from "../../images/formloading.svg";

const UpdateUserUsingExcel = () => {
  const [loading, setLoading] = useState(false);
  const [editUser, setEditUser] = useState({
    batch: "",
    xlsx: "",
  });
  const [batches, setBatches] = useState([]);
  const [validation, setValidation] = useState(false);
  const [emailsAdded, setEmailsAdded] = useState([]);
  const [emailsNotAvail, setEmailsNotAvail] = useState([]);
  const [emailsAlreadyAdded, setEmailsAlreadyAdded] = useState([]);
  const [errMsg, setErrMsg] = useState([]);
  const [confirmBox, setConfirmBox] = useState(false);
  const [batchAvail, setBatchAvail] = useState([]);
  const [resData, setResData] = useState({});
  const [nonExistTrainer, setNonExistTrainer] = useState([]);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  var batchNotAvailable = [];
  useEffect(() => {
    BatchManagementServices.GetAllBatch(token)
      .then((res) => {
        setBatches(res.data.data);
      })
      .catch((ex) => console.log(ex));
  }, []);

  const handleEdit = async (e) => {
    e.preventDefault();
    if (!token) {
      navigate("/login");
    } else {
      if (editUser.xlsx) {
        // const batchname = editUser.batch;
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = async (e) => {
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary", bookVBA: true });
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          const data = await XLSX.utils.sheet_to_json(ws);
          let emailArr = [];
          var AllPractical = [];
          if (data.length > 0) {
            for (let i = 0; i < data.length; i++) {
              emailArr.push(data[i].Email);
              const Email = data[i].Email;
              var eId = "";
              if (data[i].EmployeeID) {
                eId = data[i].EmployeeID;
              } else {
                setErrMsg((old) => [...old, "EmployeeId"]);
              }
              var BatchID = "";
              if (data[i].BatchID) {
                BatchID = data[i].BatchID;
              } else {
                setErrMsg((old) => [...old, "BatchId"]);
              }
              var GitHubUserName = "";
              if (data[i].GitHubUserName) {
                GitHubUserName = data[i].GitHubUserName;
              }

              var tenthMarks = null;
              if (data[i].TenthMarks && !isNaN(data[i].TenthMarks)) {
                if (data[i].TenthMarks >= 0 && data[i].TenthMarks <= 100) {
                  tenthMarks = data[i].TenthMarks;
                } else {
                  setErrMsg((old) => [
                    ...old,
                    "tenthMarks value should be greater than equal 0 and less than equal 100",
                  ]);
                }
              } else {
                setErrMsg((old) => [
                  ...old,
                  "tenthMarks field should be number",
                ]);
              }

              var twelfthMarks = null;
              if (data[i].TwelfthMarks && !isNaN(data[i].TwelfthMarks)) {
                if (data[i].TwelfthMarks >= 0 && data[i].TwelfthMarks <= 100) {
                  twelfthMarks = data[i].TwelfthMarks;
                } else {
                  setErrMsg((old) => [
                    ...old,
                    "twelfthMarks value should be greater than equal 0 and less than equal 100",
                  ]);
                }
              } else {
                setErrMsg((old) => [
                  ...old,
                  "twelfthMarks field should be number",
                ]);
              }

              var username = "";
              if (data[i].Username) {
                username = data[i].Username;
              } else {
                setErrMsg((old) => [...old, "Username"]);
              }

              var password = "";
              if (data[i].Password) {
                password = data[i].Password;
              } else {
                setErrMsg((old) => [...old, "Password"]);
              }

              var role = "";
              if (data[i].Role) {
                role = data[i].Role;
              } else {
                setErrMsg((old) => [...old, "Role"]);
              }

              var trainer = "";
              if (data[i].Trainer) {
                trainer = data[i].Trainer;
              }

              var courses = [];
              if (data[i].Courses) {
                courses.push(data[i].Courses.split(","));
              }

              const OnePractical = [
                {
                  Email,
                  eId,
                  BatchID,
                  GitHubUserName,
                  tenthMarks,
                  twelfthMarks,
                  username,
                  password,
                  role,
                  trainer,
                  courses,
                },
              ];
              AllPractical.push(OnePractical[0]);
            }

            let newArr = AllPractical.map((elem) => {
              return {
                label: elem.BatchID,
                name: elem.BatchID,
                value: elem.BatchID,
              };
            });

            let info = {
              option: newArr,
            };

            ///// ***** User Emails that does not Exists ***** /////

            let matchedEmail = [];
            setLoading(true);
            const res1 = await LoginService.getAllUser(token);
            if (res1 !== null) {
              if (res1.status === 200) {
                setLoading(false);
                setValidation(true);
                const existedUserData = res1.data.filter((elem) => {
                  if (emailArr.includes(elem.email)) {
                    matchedEmail.push(elem.email);
                  }
                  return emailArr.includes(elem.email);
                });

                const unexist = emailArr.filter(
                  (elem) => !matchedEmail.includes(elem)
                );

                setEmailsNotAvail(unexist);
              }
            } else {
              console.log("Error (LoginService.getAllUser):");
            }

            ///// ***** // ***** // ***** // ***** /////
            const res2 = await BatchManagementServices.getBatchByName(
              token,
              info
            );
            if (res2 !== null) {
              batchNotAvailable.push(res2.data.data);
              setBatchAvail((old) => [...old, res2.data.data]);
            } else {
              console.log(
                "err in BatchManagementServices.getBatchByName(token, info)"
              );
            }
            ///// ***** Repeated Emails which are already added to batch ***** /////
            let alreadyAddedToBatch = [];
            const res3 =
              await BatchManagementServices.getAllBatchFilterWithName(
                token,
                info
              );
            if (res3 !== null) {
              if (res3.status === 200) {
                setLoading(false);
                setValidation(true);
                let repeatUserData = res3.data.data.map((elem) =>
                  elem.users.filter((item) => {
                    if (emailArr.includes(item.email)) {
                      alreadyAddedToBatch.push(item.email);
                      return item.email;
                    }
                  })
                );
                setEmailsAlreadyAdded(alreadyAddedToBatch);

                ///// ***** // ***** // ***** // ***** /////

                ///// ***** User Data which are adding to the Batch ***** /////

                let newRepeatUserData = repeatUserData.flatMap((elem) => elem);

                let repeatUser = newRepeatUserData[0]
                  ? newRepeatUserData.map((elem) => elem.email)
                  : null;

                var AllPracticalData = repeatUser
                  ? AllPractical.filter(
                      (value) =>
                        !repeatUser.includes(value.Email) &&
                        matchedEmail.includes(value.Email) &&
                        batchNotAvailable.some(
                          (item) => !item.includes(value.BatchID)
                        )
                    )
                  : AllPractical.filter(
                      (value) =>
                        matchedEmail.includes(value.Email) &&
                        batchNotAvailable.some(
                          (item) => !item.includes(value.BatchID)
                        )
                    );

                // if (AllPracticalData.length > 0 && batchNotAvailable.length > 0) {
                //   AllPracticalData = AllPracticalData.filter((ap) =>
                //     batchNotAvailable.some((item) => !item.includes(ap.BatchID))
                //   );
                // } else {
                //   AllPracticalData = AllPractical.filter((ap) =>
                //     batchNotAvailable.some((item) => !item.includes(ap.BatchID))
                //   );
                // }

                setEmailsAdded([...AllPracticalData]);
                if (errMsg.length == 0) {
                  if (AllPracticalData[0]) {
                    setLoading(true);
                    await LoginService.putUserData(AllPracticalData, token)
                      .then((res) => {
                        if (res.status === 200) {
                          console.log("LoginService.putUserData", res);
                          setResData(res);
                          if (res.data.nonExistTrainer.length > 0) {
                            setNonExistTrainer(res.data.nonExistTrainer);
                          }
                          setLoading(false);
                          setValidation(true);
                        }
                      })
                      .catch((ex) => {
                        setLoading(false);
                        setValidation(false);
                        if (ex.response.status == 422) {
                          setValidation(false);
                          setConfirmBox(true);
                          setErrMsg(ex.response.data.msg);
                        }
                      });
                  }
                }
              }
            } else {
              console.log(
                "Error (BatchManagementServices.getAllBatchFilterWithName):"
              );
            }

            ///// ***** // ***** // ***** // ***** /////
          }
        };
        if (rABS) {
          reader.readAsBinaryString(editUser.xlsx);
        }
      }
      e.target[1].form.reset();
    }
  };
  return (
    <>
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">Uploading user...</div>
          </div>
        </div>
      )}
      <div className="form px-lg-5">
        <h5 className="color-dgreen text-uppercase text-center heading">
          Update Users
        </h5>
        <form
          onSubmit={handleEdit}
          className="px-lg-5 py-3"
          method="post"
          id="myform"
        >
          {/* <div className="col position-relative mb-3">
            <label htmlFor="cname" className="form-label">
              Batch name
            </label>
            <select
              className={"form-select"}
              id="batch"
              name="batch"
              // value={editUser.batch}
              onChange={(e) =>
                setEditUser((d) => ({ ...d, batch: e.target.value }))
              }
              disabled="true"
            >
              <option value="">select batches</option>
              {batches.map((item, index) => {
                return (
                  <option value={item._id} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div> */}
          <div className="mb-3">
            <label htmlFor="xlsx" className="form-label">
              Select Xlsx file
            </label>
            <input
              type="file"
              name="xlsx"
              id="xlsx"
              // value={editUser.xlsx}
              accept=".xlsx, .xls, .csv"
              className="form-control"
              required
              onChange={(e) =>
                setEditUser((d) => ({
                  ...d,
                  xlsx: e.target.files[0],
                }))
              }
            />
          </div>
          <div className="mb-5">
            <a href={`/EmpIoyee.xlsx`} target="_blank">
              Click here to Download demo UserUpdate Excelsheet.
            </a>
          </div>
          <button type="submit" className="btn btn-submit">
            Update User
          </button>
          <button
            type="reset"
            className="btn btn-secondary ms-3"
            onClick={() => {
              setEmailsAdded([]);
              setEmailsNotAvail([]);
              setEmailsAlreadyAdded([]);
              setErrMsg([]);
              setBatchAvail([]);
              setValidation(false);
            }}
          >
            Reset
          </button>
        </form>
        {validation == true ? (
          <div
            className="container table-container"
            style={{ marginTop: "12px", marginBottom: "100px" }}
          >
            <table
              className="table table-bordered"
              style={{ border: "2px solid black" }}
            >
              <thead>
                {emailsAdded.length > 0 && errMsg.length == 0 ? (
                  <tr>
                    <th>Username</th>
                    <th>Password</th>
                    <th>Email Id</th>
                    <th>Employee Id</th>
                    <th>Role</th>
                    <th>Trainer</th>
                    <th>Batch Name</th>
                    <th>Github Username</th>
                    <th>Status</th>
                    <th>Courses</th>
                  </tr>
                ) : null}
              </thead>
              <tbody>
                {emailsAdded.length > 0
                  ? emailsAdded.map((elem) => (
                      <tr className="alert alert-success">
                        <td>{elem.username}</td>
                        <td>{elem.password}</td>
                        <td
                          style={{
                            wordBreak: "break-all",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            maxWidth: "230px",
                          }}
                        >
                          {elem.Email}
                        </td>
                        <td>{elem.eId}</td>
                        <td>{elem.role}</td>
                        {nonExistTrainer.includes(elem.trainer) ? (
                          <td className="text-danger">
                            {" "}
                            &#9888; {elem.trainer} trainer is not available
                          </td>
                        ) : (
                          <td>{elem.trainer}</td>
                        )}
                        <td>{elem.BatchID}</td>
                        <td>{elem.GitHubUserName}</td>
                        <td>
                          &#x2705;<span> (User added)</span>
                        </td>
                        <td
                          style={{
                            whiteSpace: "pre-wrap",
                            overflowWrap: "break-word",
                            maxWidth: "200px",
                          }}
                        >
                          {elem.courses.join(", ")}
                        </td>
                      </tr>
                    ))
                  : errMsg.length > 0 && (
                      <tr className="alert-danger text-danger d-inline-block">
                        <td colSpan={10}>
                          {/* Please provide all the required fields{" "} */}
                          Required
                          {errMsg.join(", ")}.
                        </td>
                      </tr>
                    )}
                {batchAvail[0] &&
                  batchAvail.map((value, index) => (
                    <tr key={index} className="alert alert-warning">
                      <td colSpan={3}>
                        &#9888;
                        {value.length > 1
                          ? `${value.join(", ")} batches are not available.`
                          : `${value} batch is not available.`}
                      </td>
                      <td colSpan={7}>
                        &#9888;
                        <span> (User is not added)</span>
                      </td>
                    </tr>
                  ))}
                {emailsAlreadyAdded[0]
                  ? emailsAlreadyAdded.map((elem) => (
                      <tr className="alert alert-danger">
                        <td>{elem}</td>
                        <td>
                          &#10060;
                          <span> (User already exist)</span>
                        </td>
                      </tr>
                    ))
                  : null}
                {emailsNotAvail[0]
                  ? emailsNotAvail.map((elem) => (
                      <tr className="alert alert-warning">
                        <td colSpan={3}>{elem}</td>

                        <td colSpan={7}>
                          &#9888;
                          <span> (User is not available)</span>
                        </td>
                      </tr>
                    ))
                  : null}
              </tbody>
            </table>

            <p
              className={
                emailsAdded.length > 0
                  ? "text-success"
                  : "alert alert-danger text-danger"
              }
            >
              {emailsAdded.length > 0
                ? `* ${emailsAdded.length}/${
                    emailsAdded.length +
                    emailsNotAvail.length +
                    emailsAlreadyAdded.length +
                    (batchAvail[0] ? batchAvail[0].length : 0)
                  }  users successfully updated`
                : "Uploaded data is invalid!!"}
            </p>
          </div>
        ) : confirmBox ? (
          <p className="alert alert-danger">{errMsg}</p>
        ) : null}
      </div>
    </>
  );
};

export default UpdateUserUsingExcel;
