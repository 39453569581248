export const RangeModel = {
    controls: [{
        type: "text",
        name: "selector",
        required: true
    },
    {
        type: "number",
        name: "value",
        required: true
    }
    ]
}