import React, { useState, useEffect } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import ToolTip from "../BsComponents/ToolTip";
import { useNavigate } from "react-router-dom";

export default function AddCourse() {
  const [course, setCourse] = useState({
    name: "",
    type: "",
    icon: "",
    desc: "",
    level: "",
  });
  const [page, setPage] = useState({ selected: 1 });

  const [allcategory, setallcategory] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);

  const [validate, setValidate] = useState(false);
  const [msg, setMsg] = useState("");
  const level = ["Beginner", "Intermediate", "Advanced"];
  const navigate = useNavigate();

  useEffect(() => {
    CourseService.getCategory()
      .then((res) => {
        setallcategory(res.data);
      })
      .catch((ex) => console.log(ex));
  }, []);

  const handleAddCourse = async (e) => {
    e.preventDefault();
    setValidate(true);
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      const { name, type, icon, desc, level } = course;
      if (name.trim() && type && desc && level) {
        const newObj = { ...course, name: name.trim() };
        setLoading(true);
        const formdata = new FormData();
        formdata.append("data", JSON.stringify(newObj));
        formdata.append("icon", icon);
        CourseService.postCourse(formdata, token)
          .then((res) => {
            if (res.data.msg) {
              setMsg("Course Added Successfully...");
              setConfirmBox(true);
            }
          })
          .catch((ex) => console.log(ex));

        setValidate(false);
        setCourse({
          name: "",
          type: "",
          icon: "",
          desc: "",
          level: "",
        });
        document.getElementById("myform").reset();
      }
      setLoading(false);
    }
    {
      setTimeout(() => setConfirmBox(false), 3000);
    }
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li
              className={"breadcrumb-item text-primary"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/admin/course/manage-course");
              }}
            >
              Manage Courses
            </li>
            <li className={"breadcrumb-item active"}>Add Course</li>
          </ol>
        </nav>
      </div>

      <div className="container p-2 px-3">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Uploading course...</div>
            </div>
          </div>
        )}

        {confirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div className="alert alert-success">{msg}</div>
              </div>
            </div>
          </div>
        )}

        <div className="formloade">
          <div className="row">
            <div className="col-6 w-100 confirmbox">
              <form
                onSubmit={handleAddCourse}
                className="px-lg-5 py-3"
                method="post"
                id="myform"
              >
                <div className="mb-3 position-relative">
                  <label htmlFor="name" className="form-label">
                    Course Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    placeholder="Enter course name"
                    maxLength={20}
                    className="form-control"
                    value={course.name}
                    style={
                      course.name || !validate
                        ? null
                        : {
                            border: "1px solid #df4957",
                            boxShadow: "0 0 5px #df4957",
                          }
                    }
                    onChange={(e) =>
                      setCourse((d) => ({ ...d, name: e.target.value }))
                    }
                  />
                </div>
                {!course.name.trim() && validate && (
                  <div
                    className="rounded px-2 py-1 text-white mt-1"
                    style={{
                      background: "rgba(220,53,69,.9)",
                      zIndex: "1",
                    }}
                  >
                    Please enter course name
                  </div>
                )}
                <div className="mb-3 position-relative">
                  <label htmlFor="type" className="form-label">
                    Select Category
                  </label>
                  <select
                    className="form-select"
                    id="type"
                    value={course.type}
                    style={
                      course.type || !validate
                        ? null
                        : {
                            border: "1px solid #df4957",
                            boxShadow: "0 0 5px #df4957",
                          }
                    }
                    onChange={(e) =>
                      setCourse((d) => ({ ...d, type: e.target.value }))
                    }
                  >
                    <option value="Common">Select Type</option>
                    {allcategory.map((cat, index) => {
                      return <option value={cat._id}>{cat.cname}</option>;
                    })}
                  </select>
                </div>
                {!course.type && validate && (
                  <div
                    className="rounded px-2 py-1 text-white mt-1"
                    style={{
                      background: "rgba(220,53,69,.9)",
                      zIndex: "1",
                    }}
                  >
                    Please select course type
                  </div>
                )}
                <div className="mb-3 position-relative">
                  <label htmlFor="level" className="form-label">
                    Select Level
                  </label>
                  <select
                    className="form-select"
                    id="level"
                    value={course.level}
                    style={
                      course.level || !validate
                        ? null
                        : {
                            border: "1px solid #df4957",
                            boxShadow: "0 0 5px #df4957",
                          }
                    }
                    onChange={(e) =>
                      setCourse((d) => ({ ...d, level: e.target.value }))
                    }
                  >
                    <option value="NoValue">Select Level</option>
                    {level.map((cat, index) => {
                      return (
                        <option value={cat} key={index}>
                          {cat}
                        </option>
                      );
                    })}
                  </select>
                </div>
                {!course.level && validate && (
                  <div
                    className="rounded px-2 py-1 text-white mt-1"
                    style={{
                      background: "rgba(220,53,69,.9)",
                      zIndex: "1",
                    }}
                  >
                    Please select course level
                  </div>
                )}
                <div className="mb-3 position-relative">
                  <label htmlFor="icon" className="form-label">
                    Select Icon
                    {/* (if icon img not show then copy image(link) src from below link(find icons)) */}
                  </label>
                  <input
                    type="file"
                    accept="image/*"
                    className="form-control"
                    id="icon"
                    placeholder="write the name of icon or icon Link"
                    onChange={(e) => {
                      setCourse((d) => ({ ...d, icon: e.target.files[0] }));
                    }}
                  />
                </div>
                {!course.icon && validate && (
                  <div
                    className="rounded px-2 py-1 text-white mt-1"
                    style={{
                      background: "rgba(220,53,69,.9)",
                      zIndex: "1",
                    }}
                  >
                    Please enter valid file
                  </div>
                )}
                <div className="mb-5 position-relative">
                  <label htmlFor="desc" className="form-label">
                    Add Description
                  </label>
                  <textarea
                    className="form-control"
                    maxLength={400}
                    id="desc"
                    value={course.desc}
                    style={
                      course.desc || !validate
                        ? null
                        : {
                            border: "1px solid #df4957",
                            boxShadow: "0 0 5px #df4957",
                          }
                    }
                    onChange={(e) =>
                      setCourse((d) => ({ ...d, desc: e.target.value }))
                    }
                  ></textarea>
                  {!course.desc && validate && (
                    <div
                      className="rounded px-2 py-1 text-white mt-1"
                      style={{
                        background: "rgba(220,53,69,.9)",
                        zIndex: "1",
                      }}
                    >
                      Please enter course description
                    </div>
                  )}
                </div>
                <ToolTip title="Cancel">
                  <button
                    type="reset"
                    className="btn btn-secondary me-3"
                    onClick={() => {
                      setCourse({ name: "", type: "", icon: "", desc: "" });
                      setValidate(false);
                      setPage({ selected: 1 });
                    }}
                  >
                    Cancel
                  </button>
                </ToolTip>
                <ToolTip title="Add Course">
                  <button type="submit" className="btn btn-success">
                    Add Course
                  </button>
                </ToolTip>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
