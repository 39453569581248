import React, { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function TestCasesActions(props) {
  const [page, setPage] = useState({ selected: 2, choice: "TestCasesList" });
  const navigate = useNavigate();

  const pathName = window.location.pathname;
  useEffect(() => {
    if (pathName.includes("updateaction")) {
      setPage({ selected: 2, choice: "update action" });
    }
    if (pathName.includes("list")) {
      setPage({ selected: 2, choice: "TestCasesList" });
    }
  }, [pathName]);

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0 py-2">
            <li
              className={
                page.selected === 1
                  ? "breadcrumb-item active"
                  : "breadcrumb-item text-primary"
              }
              style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
              onClick={() => {
                setPage({ selected: 1, choice: "" });
                navigate(
                  `${
                    page.choice === "TestCasesList"
                      ? "/admin/test/manage-questions"
                      : "/admin/manage-testcase/list"
                  }`
                );
              }}
            >
              {page.choice === "TestCasesList"
                ? "Manage Questions"
                : "TestCasesList"}
            </li>
            <li
              className={
                page.selected === 2 ? "breadcrumb-item active" : "d-none"
              }
            >
              {page.choice}
            </li>
          </ol>
        </nav>
        {page.selected === 1 && (
          <button
            onClick={() => {
              setPage({ selected: 2, choice: "Add Test Case" });
              navigate("/admin/manage-testcase/create");
            }}
            className="btn btn-primary"
          >
            Add Action
          </button>
        )}
      </div>
      <div className="conatiner">
        <Outlet />
      </div>
    </>
  );
}
