import React, { useEffect, useState } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import CourseService, { fileURL } from "../../services/CourseService";
import ManageBatchCard from "../cards/ManageBatchCard";
export default function ManageBatch(props) {
  const [batchdata, setBatchdata] = useState([]);
  const [batch, setBatch] = useState({ bname: "" });
  const [validate, setValidate] = useState(false);
  const [page, setPage] = useState({ selected: 1 });
  const [addpdfBox, setAddpdfBox] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [msg, setMsg] = useState("");
  const [bdata, setBdata] = useState({
    bname: "",
    docs: [],
  });
  const navigate = useNavigate();

  useEffect(() => {
    CourseService.getBatch()
      .then((res) => {
        setBatchdata(res.data);
      })
      .catch((ex) => console.log(ex));
    {
      setTimeout(() => setConfirmBox(false), 3000);
    }
  }, [confirmBox]);

  const handleBatch = (item) => {
    setPage({ selected: 3, batch: item.bname });
  };

  const handleDelete = (id) => {
    const token = localStorage.getItem("token");
    if (token) {
      CourseService.deleteBatch(id, token)
        .then((res) => {
          setMsg("Batch Removed Successfully...");
          setConfirmBox(true);
        })
        .catch((ex) => {
          console.log(ex);
        });
    } else {
      navigate("/login");
    }
  };

  const handlePostBatchData = async (param) => {
    var course = "";
    let { bname, docs } = param;
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      if (docs.length > 0 && bname) {
        const formdata = new FormData();
        formdata.append("bname", bname);
        for (const item of docs) {
          formdata.append("docs", item);
        }
        try {
          CourseService.postBatch(formdata).then((res) => {
            if (res.data) {
              setConfirmBox(true);
            }
          });
        } catch (ex) {
          console.log(ex);
        }
      }
    }
    {
      // setTimeout(() => setConfirmBox(false), 10000);
    }
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li
              className={
                page.selected === 1
                  ? "breadcrumb-item active"
                  : "breadcrumb-item text-primary"
              }
              style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
              onClick={() => {
                setPage((d) => ({ selected: 1, batch: "" }));
              }}
            >
              Batches
            </li>
            <li
              className={
                page.selected === 3
                  ? "breadcrumb-item active d-block"
                  : // : "breadcrumb-item text-primary d-block"
                    "d-none"
              }
              style={{ cursor: page.selected > 3 ? "pointer" : "auto" }}
              //   onClick={() => {
              //     setDvids([]);
              //   }}
            >
              Add PDF
            </li>
          </ol>
        </nav>
        <label
          htmlFor={page.selected === 3 ? "uvids" : "abc"}
          className={
            page.selected == 1
              ? "btn bg-green text-white visible"
              : "btn bg-green text-white invisible"
          }
          style={{ marginRight: "4px" }}
          onClick={
            page.selected !== 2
              ? () => {
                  // setAddpdfBox(true);
                  setPage({ selected: 3 });
                }
              : () => {
                  setPage({ selected: 1 });
                }
          }
        >
          <FaCloudUploadAlt className="me-2 fs-5" />
          Upload PDF
        </label>
      </div>
      <div className="container p-2 px-3">
        <div className="form px-lg-5 pt-3">
          {confirmBox && (
            <div className="formloade">
              <div className="row text-center">
                <div className="col-6 w-100 confirmbox">
                  <div className="alert alert-success">
                    Batch Plan Updated Successfully
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {confirmBox && (
                <div className="formloade">
                  <div className="row text-center">
                    <div className="col-6 w-100 confirmbox">
                      <div
                        // className={`alert ${
                        //   invalidmsg === "Day Already Exist" ||
                        //   invalidmsg === "Please Add Course and Day"
                        //     ? "alert-danger"
                        //     : "alert-success"
                        // }`}
                        className="alert-success"
                      >
                        Batch Plan Updated Successfully
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
          {page.selected === 1 && (
            <>
              <div className="col-12 col-sm-12 col-md-6 col-lg-6 row">
                <label htmlFor="scname" className=" col-lg-3 p-1">
                  Batch Name :
                </label>
                <select
                  className=" col-lg-9 border rounded border-secondary p-1"
                  id="bname"
                  name="bname"
                  onChange={(e) =>
                    setBatch((d) => ({ ...d, bname: e.target.value }))
                  }
                >
                  <option value="">Select Batch</option>
                  {batchdata.map((item, index) => {
                    return (
                      <option value={item._id} key={index}>
                        {item.bname}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="form px-lg-5">
                <div className="row g-4 listcourses py-4">
                  {batchdata.map((item, index) => {
                    return (
                      <>
                        {batch.bname == item._id && (
                          <ManageBatchCard
                            item={item}
                            fileURL={fileURL}
                            handleDelete={handleDelete}
                            setConfirmBox={setConfirmBox}
                          />
                        )}
                      </>
                    );
                  })}
                </div>
              </div>
            </>
          )}
          {page.selected === 3 && (
            <div>
              <div className="formloade bg-white my-2 p-4">
                <div className="row text-center">
                  <div className="col-6 w-100 confirmbox">
                    <form className="px-lg-5 pt-3" id="myform">
                      <div className="mb-3">
                        <label htmlFor="topic" className="form-label">
                          Enter topic
                        </label>
                        <input
                          type="text"
                          name="topic"
                          id="topic"
                          className="form-control"
                          required
                          onChange={(e) =>
                            setBdata((d) => ({
                              ...d,
                              bname: e.target.value,
                            }))
                          }
                        />
                      </div>

                      <div className="mb-3">
                        <label htmlFor="ppts" className="form-label">
                          Select PDF
                        </label>
                        <input
                          type="file"
                          id="videos"
                          className="form-control"
                          required
                          multiple
                          onChange={(e) =>
                            setBdata((d) => ({
                              ...d,
                              docs: e.target.files,
                            }))
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-secondary mt-5 me-3"
                          // onClick={() => {
                          //   setAddvideoBox(false);
                          // }}
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          className="btn bg-green text-white mt-5"
                          onClick={() => {
                            handlePostBatchData(bdata);
                            setConfirmBox(true);
                          }}
                        >
                          Upload
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
