import jwt_decode from "jwt-decode";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import formloader from "../../images/formloading.svg";
import CourseService from "../../services/CourseService";
import LoginService from "../../services/LoginService";
import ToolTip from "../BsComponents/ToolTip";

export default function RemoveTrainingPlan() {
  const [tplans, setTplans] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);
  const [confirmBox2, setConfirmBox2] = useState(false);
  const [rplan, setRplan] = useState({});
  const [scnames, setSCnames] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (
      localStorage.getItem("token") &&
      jwt_decode(localStorage.getItem("token")).role === "Trainer"
    ) {
      LoginService.getSingleUser(
        jwt_decode(localStorage.getItem("token")).id,
        localStorage.getItem("token")
      )
        .then((res) => {
          res.data.courses.map((item) => {
            CourseService.getSinglesubcourse(item)
              .then((result) => {
                if (result.data && result.data.length > 0) {
                  setSCnames((d) => [...d, result.data[0]]);
                }
              })
              .catch((ex) => console.log(ex));
          });
        })
        .catch((ex) => console.log(ex));
    } else if (
      localStorage.getItem("token") &&
      jwt_decode(localStorage.getItem("token")).role === "Admin"
    ) {
      CourseService.getSubCourses()
        .then((res) => {
          if (res.data) {
            setSCnames(res.data);
          }
        })
        .catch((ex) => console.log(ex));
    }

    setLoading(false);
  }, []);

  const handleRemoveTplan = async (e) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      setLoading(true);
      CourseService.removeTplan(rplan, token)
        .then((res) => {
          if (res.data.msg) {
            setConfirmBox(true);
          }
        })
        .catch((ex) => console.log(ex));
      setRplan({});
      setTplans([]);
      document.getElementById("myform").reset();
      setLoading(false);
    }
    {
      setTimeout(() => setConfirmBox(false), 5000);
    }
  };
  const handleCourseChange = (e) => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      CourseService.getTrainingPlan(e.target.value, token)
        .then((res) => {
          if (res.data.length > 0) {
            setTplans(res.data);
          } else {
            setTplans([]);
          }
        })
        .catch((ex) => console.log(ex));
    }
  };

  return (
    <div className="container my-2 bg-white p-4">
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">removing user...</div>
          </div>
        </div>
      )}
      {confirmBox && (
        <div className="formloade">
          <div className="row text-center">
            <div className="col-6 w-100 confirmbox">
              <div className="alert alert-success">
                Training plan removed successfully..
              </div>
            </div>
          </div>
        </div>
      )}
      {confirmBox2 && (
        <div className="formloade">
          <div className="row text-center">
            <div className="col-6 w-100 confirmbox">
              <div className="alert alert-warning">
                Do you really want to remove <b>{rplan.scname}</b> Training plan
                of day <b>{rplan.tp_day}</b>
                <button
                  className="btn btn-danger ms-4"
                  onClick={() => {
                    setConfirmBox2(false);
                    setRplan({});
                  }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-success ms-2"
                  onClick={() => {
                    setConfirmBox2(false);
                    handleRemoveTplan();
                  }}
                >
                  Remove
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="form px-lg-5">
        <h5 className="color-dgreen text-uppercase text-center heading">
          Remove Trainingplan
        </h5>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setConfirmBox2(true);
          }}
          className="px-lg-5 py-3"
          id="myform"
        >
          <div className="mb-3">
            <label htmlFor="scname" className="form-label">
              SubCourse name
            </label>
            <select
              className="form-select"
              id="scname"
              name="scname"
              // required
              onChange={handleCourseChange}
            >
              <option value="">Select SubCourse</option>
              {scnames.map((item, index) => {
                return (
                  <option value={item.name} key={index}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-5">
            <label htmlFor="tp_day" className="form-label">
              Select Day
            </label>
            <select
              className="form-select"
              id="tp_day"
              name="tp_day"
              onChange={(e) => {
                const day = Number(e.target.value);
                setRplan({
                  ...tplans.find((item) => item.tp_day === day),
                  scname: document.getElementById("scname").value,
                });
              }}
              disabled={tplans.length > 0 ? false : true}
              required
            >
              <option value="">Select Day</option>
              {tplans.map((item, index) => {
                return (
                  <option value={item.tp_day} key={index}>
                    {item.tp_day}
                  </option>
                );
              })}
            </select>
          </div>
          <ToolTip title={"Remove Plan"}>
            <button type="submit" className="btn btn-submit">
              Remove Plan
            </button>
          </ToolTip>
          <ToolTip title={"Reset"}>
            <button type="reset" className="btn btn-secondary ms-3">
              Reset
            </button>
          </ToolTip>
        </form>
      </div>
    </div>
  );
}
