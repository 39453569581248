import React from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { handleAutoSubscribe } from "../../utils/course/handleAutoSubscribe";
import { handleContinue } from "../../utils/course/handleContinue";
import "./courseBanner.scss";


export default function CourseBanner(props) {
  const {
    scourse,
    vcount,
    tcount,
    pcount,
    user,
    selectedcourses,
    userdata,
    setConfirmBox,
    handleAutoSubscribed,
    handlePendingRequest,
    handleSubscribe,
    handleSubscribed,
  } = props;
  let { cname, sname } = useParams();

  const navigate = useNavigate();

  return (
    <div class="course-banner bg-light-gray">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-xl-9 text-dark-gray">
            <h1 class="mb-2 mb-md-4">{scourse?.name?.replaceAll("_",' ')}</h1>
            <p>{scourse.desc}</p>
            <div class="d-none d-lg-block">
              <span class="btn btn-white py-2 d-inline-block">
                {user} learner have subscribed this
              </span>
            </div>
          </div>
          <div class="col-lg-4 col-xl-3">
            <div class="banner-card card">
              <h4 class="m-0">This course includes</h4>
              <div class="d-flex justify-content-md-between flex-wrap d-lg-block">
                <div class="d-flex course-item">
                  <img
                    src={
                      require("../../assets/images/material-view-module.svg")
                        .default
                    }
                    alt=""
                    width="16"
                    class="me-2 me-lg-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    <strong>{tcount}</strong> Modules
                  </span>
                </div>
                <div class="d-flex course-item">
                  <img
                    src={
                      require("../../assets/images/material-timelapse.svg")
                        .default
                    }
                    alt=""
                    width="16"
                    class="me-2 me-lg-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    <strong>{Math.round(tcount * 8.5)}</strong> Hours
                  </span>
                </div>
                <div class="d-flex course-item">
                  <img
                    src={
                      require("../../assets/images/run_video_icon.svg").default
                    }
                    alt=""
                    width="16"
                    class="me-2 me-lg-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    <strong>{vcount}</strong> Videos
                  </span>
                </div>
                <div class="d-flex course-item">
                  <img
                    src={
                      require("../../assets/images/slideshow_presentation.svg")
                        .default
                    }
                    alt=""
                    width="16"
                    class="me-2 me-lg-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    <strong>{pcount}</strong> Presentaions
                  </span>
                </div>
                <div class="d-flex course-item">
                  <img
                    src={
                      require("../../assets/images/feather-bar-chart.svg")
                        .default
                    }
                    alt=""
                    width="16"
                    class="me-2 me-lg-3"
                  ></img>{" "}
                  <span class="font-14 text-dark-gray">
                    For <strong>{scourse.level}</strong>
                  </span>
                </div>
              </div>
              <div class="text-center course-item d-none d-lg-block">
                {selectedcourses !== 0 && scourse.status === true ? (
                  <>
                    {userdata.role === "Student" ? (
                      Array.from(selectedcourses, (o) => o.name).indexOf(
                        sname
                      ) === -1 ? (
                        <button
                          className="btn btn-blue py-2 btn-blue-hover"
                          onClick={async() => {
                            const urlString = await handleAutoSubscribe(scourse._id, cname, sname, tcount, vcount, pcount);
                            if(urlString) {
                              setConfirmBox(true);
                              navigate(urlString);
                            }
                          }}
                        >
                          Subscribe
                        </button>
                      ) : (
                        <Link
                          className="btn btn-blue py-2 btn-blue-hover"
                          to={`/course/${cname}/courseOverview/${sname}/tplan`}
                        >
                          Learn
                        </Link>
                      )
                    ) : (
                      <Link
                        className="btn btn-blue py-2 btn-blue-hover"
                        onClick={async () => {
                          const urlString = await handleAutoSubscribe(scourse._id, cname, sname, tcount, vcount, pcount);
                            if(urlString) {
                              setConfirmBox(true);
                              navigate(urlString);
                            }
                        }}
                      >
                        Subscribe
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    {userdata.role === "Student" ? (
                      Array.from(selectedcourses, (o) => o.name).indexOf(
                        sname
                      ) === -1 ? (
                        <>
                          {userdata.subscription &&
                          Array.from(
                            userdata.subscription,
                            (o) => o.course
                          ).indexOf(scourse._id) !== -1 ? (
                            <button
                              className="btn btn-blue py-2"
                              onClick={() => {
                                handlePendingRequest();
                              }}
                            >
                              Request Pending
                            </button>
                          ) : (
                            <button
                              className="btn btn-blue py-2"
                              onClick={() => {
                                handleSubscribe();
                              }}
                            >
                              Subscribe Request
                            </button>
                          )}
                        </>
                      ) : (
                        <button
                          className="btn btn-blue py-2"
                          onClick={() => {
                            handleSubscribed();
                          }}
                        >
                          Subscribed
                        </button>
                      )
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
          <div class="d-md-flex d-inline-block align-items-center justify-content-center d-lg-none mt-3 text-center">
            <span class="btn btn-white py-2 d-inline-block mb-2 me-2">
              {user} Learner have subscribed
            </span>
            {selectedcourses !== 0 && scourse.status === true ? (
              <>
                {userdata.role === "Student" ? (
                  Array.from(selectedcourses, (o) => o.name).indexOf(sname) ===
                  -1 ? (
                    <button
                      className="btn btn-blue py-2 mb-2 me-2"
                      onClick={async () => {
                        const urlString = await handleAutoSubscribe(scourse._id, cname, sname, tcount, vcount, pcount);
                            if(urlString) {
                              setConfirmBox(true);
                              navigate(urlString);
                            }
                      }}
                    >
                      Subscribe
                    </button>
                  ) : (
                    <div
                      className="btn btn-blue py-2 mb-2 me-2 span cursor-pointer"
                      onClick={() => {
                        const urlString = handleContinue(cname, sname, tcount, vcount, pcount);
                        if(urlString) {
                          navigate(urlString);
                        }
                      }}
                    >
                      Learn
                    </div>
                  )
                ) : (
                  <div 
                    className="btn btn-blue py-2 mb-2 cursor-pointer" 
                    onClick={() => {
                      const urlString = handleContinue(cname, sname, tcount, vcount, pcount);
                      if(urlString) {
                        navigate(urlString);
                      }
                    }}>
                    Subscribe
                  </div>
                )}
              </>
            ) : (
              <>
                {userdata.role === "Student" ? (
                  Array.from(selectedcourses, (o) => o.name).indexOf(sname) ===
                  -1 ? (
                    <>
                      {userdata.subscription &&
                      Array.from(
                        userdata.subscription,
                        (o) => o.course
                      ).indexOf(scourse._id) !== -1 ? (
                        <button
                          className="btn btn-blue py-2 mb-2 me-2"
                          onClick={() => {
                            handlePendingRequest();
                          }}
                        >
                          Request Pending
                        </button>
                      ) : (
                        <button
                          className="btn btn-blue py-2 mb-2 me-2"
                          onClick={() => {
                            handleSubscribe();
                          }}
                        >
                          Subscribe Request
                        </button>
                      )}
                    </>
                  ) : (
                    <button
                      className="btn btn-blue py-2 mb-2 me-2"
                      onClick={() => {
                        handleSubscribed();
                      }}
                    >
                      Subscribed
                    </button>
                  )
                ) : (
                  <></>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
