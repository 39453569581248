import React, { useState, useEffect, useContext } from "react";
import CourseService from "../../services/CourseService";
import { useParams, Link, useNavigate } from "react-router-dom";
import TrainingPlan from "../../components/coursedatails/Trainingplan";
import Videos from "../../components/coursedatails/Videos";
import Ppts from "../../components/coursedatails/Ppts";
import Discussions from "../../components/coursedatails/Discussions";
import { FaAngleLeft } from "react-icons/fa";
import { UserContext } from "../../routing/Routing";

export default function SubCourse() {
  const { setLoginStatus } = useContext(UserContext);
  const navigate = useNavigate();
  let { cname, sname, data, vid } = useParams();
  const [course, setCourse] = useState({});
  const [subcourse, setSubCourse] = useState({});
  const [tplans, setTplans] = useState([]);
  const [tppts, setTppts] = useState([]);
  const [tvideos, setTvideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [collapseName, setCollapseName] = useState("TrainingPlan");
  const [details, setDetails] = useState({ selected: "", choice: "" });
  const [confirmBox, setConfirmBox] = useState(false);
  const token = localStorage.getItem("token");

  useEffect(() => {
    setLoading(true);
    setConfirmBox(false);
    if (!token) {
      localStorage.removeItem("token");
      navigate("/login");
      setLoginStatus(false);
    } else {
      CourseService.getSingleCourse(cname)
        .then((res) => {
          if (res.data) {
            setCourse(res.data);
          }
        })
        .catch((ex) => console.log(ex));

      CourseService.getSingleSubCourse(sname)
        .then((res) => {
          if (res.data) {
            setSubCourse(res.data);
            SubCourseTrainingPlan(res.data);
            if (data) {
              handleDatailChange(data, res.data);
              setCollapseName(data);
            }
          }
        })
        .catch((ex) => console.log(ex));
    }
    setLoading(false);
  }, [cname, confirmBox]);

  const SubCourseTrainingPlan = (item) => {
    if (!token) {
      navigate("/login");
    } else {
      if (item) {
        var getppts = 0,
          getvideos = 0;
        CourseService.getPpts(item.name, token)
          .then((res) => {
            if (res.data) {
              getppts = res.data.ppts;
              setTppts(res.data.ppts);
            }
          })
          .catch((ex) => console.log(ex));
        CourseService.getVideos(item.name)
          .then((res) => {
            if (res.data) {
              getvideos = res.data;
              setTvideos(res.data);
            }
          })
          .catch((ex) => console.log(ex));
        CourseService.getTrainingPlan(item.name, token)
          .then((res) => {
            if (res.data.length > 0) {
              const plans = res.data;
              setTplans(plans);
              if (!data) {
                setDetails({
                  selected: <TrainingPlan item={item} courseid={item._id} />,
                  choice: "tplan",
                });
                navigate("/course/" + cname + "/subcourse/" + sname + "/tplan");
              }
              if (vid) {
                setDetails({
                  selected: <Videos scname={item.name} />,
                  choice: "videos",
                });

                navigate(
                  "/course/" + cname + "/subcourse/" + sname + "/videos/" + vid
                );
              }
            } else {
              setTplans([]);
            }
          })
          .catch((ex) => {
            setLoginStatus(false);
            localStorage.removeItem("token");
            navigate("/login");
          });
      }
    }
  };
  const handleTrainingPlan = (item) => {
    return (
      <>
        <div className="col-lg-12 leftbar">
          <button
            className="d-lg-none shadow-none mt-0 text-center rounded w-100 py-1 border-0"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mynav1"
            aria-controls="mynav1"
            aria-expanded="false"
            style={{ float: "left", backgroundColor: "#dee2e6" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="30"
              style={{ marginLeft: "15px", float: "left" }}
            >
              <g fill="none" fill-rule="evenodd">
                <path fill="none" d="M14 0h29.16v29.156H14z" />
                <g fill="#222" transform="translate(0 5)">
                  <rect width="19" height="3" rx="2.5" />
                  <rect width="13" height="3" x="6" y="7" rx="2.5" />
                  <rect width="8" height="3" x="11" y="14" rx="2.5" />
                </g>
              </g>
            </svg>{" "}
            <lable style={{ fontSize: "15px", fontWeight: "bold" }}>
              Toggle {collapseName}
            </lable>
          </button>
          <div className="collapse navbar-collapse d-lg-block" id="mynav1">
            <nav className="mb-3 mt-5">
              <ul className="pagination row g-0">
                <li className="col-lg-3 page-item ">
                  <button
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    className={
                      details.choice === "tplan"
                        ? "page-link w-100 text-uppercase active"
                        : "page-link w-100 text-uppercase"
                    }
                    onClick={() => {
                      handleDatailChange("tplan", item);
                      setCollapseName("TrainingPlan");
                    }}
                  >
                    Training Plan
                  </button>
                </li>

                <li className="col-lg-3 page-item">
                  <button
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    className={
                      details.choice === "videos"
                        ? "page-link w-100 text-uppercase active"
                        : "page-link w-100 text-uppercase "
                    }
                    onClick={() => {
                      handleDatailChange("videos", item);
                      setCollapseName("Videos");
                    }}
                  >
                    Videos
                  </button>
                </li>
                <li className="col-lg-3 page-item">
                  <button
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    className={
                      details.choice === "ppts"
                        ? "page-link w-100 text-uppercase active"
                        : "page-link w-100 text-uppercase"
                    }
                    onClick={() => {
                      handleDatailChange("ppts", item);
                      setCollapseName("PPTs");
                    }}
                  >
                    {cname} PPTs
                  </button>
                </li>
                <li className="col-lg-3 page-item">
                  <button
                    data-bs-toggle="collapse"
                    data-bs-target=".navbar-collapse.show"
                    className={
                      details.choice === "discussion"
                        ? "page-link w-100 text-uppercase active"
                        : "page-link w-100 text-uppercase"
                    }
                    onClick={() => {
                      handleDatailChange("discussion", item);
                      setCollapseName("Discussions");
                    }}
                  >
                    Discussions
                  </button>
                </li>
              </ul>
            </nav>
          </div>
          <>{details.selected}</>
        </div>
      </>
    );
  };

  const handleDatailChange = (ch, item) => {
    switch (ch) {
      case "tplan":
        setDetails({
          selected: <TrainingPlan item={item} courseid={item._id} />,
          choice: ch,
        });
        navigate("/course/" + cname + "/subcourse/" + sname + "/tplan");
        break;
      case "videos":
        setDetails({ selected: <Videos scname={item.name} />, choice: ch });
        navigate("/course/" + cname + "/subcourse/" + sname + "/videos");
        break;
      case "ppts":
        setDetails({ selected: <Ppts item={item} />, choice: ch });
        navigate("/course/" + cname + "/subcourse/" + sname + "/ppts");
        break;
      case "discussion":
        setDetails({
          selected: <Discussions cid={course._id} />,
          choice: ch,
        });
        navigate("/course/" + cname + "/subcourse/" + sname + "/discussion");
        break;
      default:
    }
  };
  return (
    <div className="container course pt-5 mt-4">
      <h3 className="pt-3 px-3">
        <Link to={`/course/${cname}`} className="me-3 text-decoration-none">
          <FaAngleLeft
            className="h1  rounded-circle"
            style={{ color: "#ffffff", backgroundColor: "#31BBAE" }}
          />
        </Link>
        {sname}{" "}
      </h3>
      <div className="row pb-4 m-0">{handleTrainingPlan(subcourse)}</div>
    </div>
  );
}
