export const dateModel = {
    controls: [
        {
            type: "number",
            name: "futureDateInDaysCount",
            required: false,
        },
        {
            type: "number",
            name: "pastDateInDaysCount",
            required: false,
        },
        {
            type: "boolean",
            name: "todaysDate",
            required: false,
        },
        {
            type: "date",
            name: "before",
            required: false,
        },
        {
            type: "date",
            name: "after",
            required: false,
        },
    ]
}