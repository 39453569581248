import { CommonValues } from "./commonValues";

export const RangeModel = {
    controls: [
        {
            type: "number",
            name: "start",
            required: true,
        },
        {
            type: "number",
            name: "end",
            required: true,
        },
        CommonValues.controls
    ]
}