export const DomElement = {
    controls: [
        {
            type: "text",
            name: "selector",
            required: true,
        },
        {
            type: "boolean",
            name: "shouldExists",
            required: true,
        }
    ]
}