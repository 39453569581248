import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { imgURL } from "../../services/CourseService";
import CourseService from "../../services/CourseService";
import global from "../../servicesvariable";
import jwt_decode from "jwt-decode";
import "./accordion.scss";
import formloader from "../../images/formloading.svg";

// import { defaultAriaLiveMessages } from "react-select/dist/declarations/src/accessibility";
// import { DayTimeColsView } from "@fullcalendar/timegrid";

export default function Accordion(props) {
  const { tplan, videos, ppts, percentages, lastvideos } = props;
  const param = useParams();
  // var number = 0;
  // var no = 0;
  const navigate = useNavigate();
  const [progress, setprogress] = useState([]);
  // const [playvideo, setPlayvideo] = useState();
  // const [percents, setPercent] = useState(0);
  const [loading, setLoading] = useState(false);
  const video = useRef();
  const token = localStorage.getItem("token");

  useEffect(() => {
    if (tplan && param.choice === "tplan") {
      setLoading(true);
      CourseService.getTrainingProgress(
        jwt_decode(token).id,
        tplan[0].cid,
        token
      ).then((res) => {
        setprogress(res.data.progress.completedTrainingDayPlan);
      });
      setLoading(false);
    }
  }, [video]);

  const handleCourseComplete = async (tpId, courseid) => {
    if (token) {
      const decoded = jwt_decode(token);
      const temp = !progress ? [] : [...progress];
      temp.push(tpId);
      setprogress(temp);
      const data = { trainingPlans: temp };
      CourseService.updateTrainingProgress(
        decoded.id,
        courseid || tplan.cid,
        data,
        token
      )
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handlePlayVideo = (url) => {
    const vid = url.filename;
    // setPlayvideo(url.filename);
    percentages.forEach((element) => {
      if (element.id === url.id) {
        // setPercent(element.percentage);
      }
    });
  };

  if (param.choice) {
    if (tplan) {
      return (
        <div
          // className="accordion-content course-detail-accordion"
          id="accordiontExample"
        >
          {loading && (
            <div className="formloader">
              <div className="row text-center">
                <div className="col-12">
                  <img src={formloader} alt="" height="200" />
                </div>
                <div className="col-12 text-white h4">Loading...</div>
              </div>
            </div>
          )}
          {tplan &&
            tplan.map((plan, index) => {
              if (index === 0) {
                return (
                  <div
                  // className="accordion-item"
                  >
                    <h3
                      // className="accordion-header"
                      id="headingtOne"
                    >
                      <div
                      // className="accordion-button"
                      // type="button"
                      // data-bs-toggle="collapse"
                      // data-bs-target="#collapsetOne"
                      // aria-expanded="true"
                      // aria-controls="collapsetOne"
                      >
                        Module {index + 1}
                      </div>
                    </h3>
                    <div
                      id="collapsetOne"
                      // className="accordion-collapse collapse show"
                      // aria-labelledby="headingtOne"
                      // data-bs-parent="#accordiontExample"
                    >
                      <div
                      // className="accordion-body"
                      >
                        <ul>
                          {plan.tp_whattolearn &&
                            plan.tp_whattolearn
                              .split("\n")
                              .map((whattolearn) => {
                                return (
                                  <>
                                    {whattolearn.includes("-") ? (
                                      <ul>
                                        <li>
                                          <p>
                                            {whattolearn.split("-").slice(1, 2)}
                                          </p>
                                        </li>
                                      </ul>
                                    ) : (
                                      <li>
                                        <p>{whattolearn}</p>
                                      </li>
                                    )}
                                  </>
                                );
                              })}
                        </ul>
                        {(plan.tp_practice ||
                          plan.tp_practiceimgarr.length > 0) && (
                          <h6>Practice Exercise</h6>
                        )}
                        {plan.tp_practiceimgarr.length > 0 ? (
                          <div className="m-0 d-inline-block">
                            {plan.tp_practiceimgarr.map((item, pindex) => {
                              return (
                                <img
                                  src={`${imgURL}/${item}`}
                                  alt="practiceimg"
                                  className="m-1"
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: "100%",
                                  }}
                                />
                              );
                            })}
                          </div>
                        ) : null}
                        <ul>
                          {plan.tp_practice &&
                            plan.tp_practice
                              .split("\n")
                              .map((item, tpindex) => {
                                if (item.match(/^http/g)) {
                                  return (
                                    // <li className="py-1" key={tpindex}>
                                    <li>
                                      {" "}
                                      <a
                                        href={item}
                                        target="_blank"
                                        className="d-block"
                                        rel="noopener noreferrer"
                                        style={{
                                          width: "100%",
                                          display: "block",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <p key={tpindex}>{item}</p>
                                      </a>{" "}
                                    </li>
                                    // </li>
                                  );
                                } else {
                                  return (
                                    <li>
                                      {" "}
                                      <p key={tpindex}>{item}</p>{" "}
                                    </li>
                                  );
                                }
                              })}
                        </ul>
                        {(plan.tp_assignment ||
                          plan.tp_assignmentimgarr.length > 0) && (
                          <h6>Assignment</h6>
                        )}
                        {plan.tp_assignmentimgarr.length > 0 ? (
                          <div className="m-0 d-inline-block">
                            {plan.tp_assignmentimgarr.map((item, aindex) => {
                              return (
                                <img
                                  src={`${imgURL}/${item}`}
                                  alt="assignmentimg"
                                  key="aindex"
                                  className="m-1"
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: "100%",
                                  }}
                                />
                              );
                            })}
                          </div>
                        ) : null}

                        <ul className="wtllist mb-4">
                          {plan.tp_assignment
                            ? plan.tp_assignment
                                .split("\n")
                                .map((item, taindex) => {
                                  if (item.match(/^http/g)) {
                                    return (
                                      <li className="py-1">
                                        <a
                                          href={item}
                                          target="_blank"
                                          className="d-block"
                                          rel="noopener noreferrer"
                                          style={{
                                            width: "100%",
                                            display: "block",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                          }}
                                        ></a>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <>
                                        {item.includes("-") ? (
                                          <ul>
                                            <li>
                                              <p>
                                                {item.split("-").slice(1, 2)}
                                              </p>
                                            </li>
                                          </ul>
                                        ) : (
                                          <li>
                                            <p>{item}</p>
                                          </li>
                                        )}
                                      </>
                                    );
                                  }
                                })
                            : null}
                        </ul>
                        {plan.tp_onlineref && plan.tp_onlineref.length > 0 ? (
                          <>
                            {plan.tp_onlineref[0].length > 0 ? (
                              <h6>Online Reference</h6>
                            ) : (
                              <></>
                            )}

                            {/* {plan.tp_onlineref[0].length > 0 && <h6>Online Reference</h6>} */}
                            <ul>
                              {plan.tp_onlineref.map((item, onindex) => {
                                if (item.split(",").length > 1) {
                                } else {
                                  return (
                                    <li>
                                      <a
                                        href={item}
                                        target="_blank"
                                        className="d-block"
                                        rel="noopener noreferrer"
                                        style={{
                                          width: "100%",
                                          display: "block",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        {item}
                                      </a>
                                    </li>
                                  );
                                }
                              })}
                            </ul>
                          </>
                        ) : null}
                        <div>
                          {videos[0] &&
                          videos[0].videos[0] &&
                          videos[0].videos[0].length > 0 ? (
                            <p style={{ fontWeight: "600" }} className="my-2">
                              Videos
                            </p>
                          ) : (
                            <></>
                          )}
                          <div id="accordionVideos">
                            {videos &&
                              videos.map((vitem, vindex) => {
                                return (
                                  <div
                                    className="border-0 mb-0"
                                    key={vitem._id}
                                  >
                                    <div id={"myacc" + vindex}>
                                      <div className="">
                                        {vitem.videos.map((vid, vindex) => {
                                          if (
                                            vid.day === plan.tp_day &&
                                            vitem.cid === plan.cid
                                          ) {
                                            // number++;
                                            return (
                                              <div className="col-12 col-md-6 col-xl-3 res-item">
                                                <a
                                                  className="d-block text-decoration-none"
                                                  style={{
                                                    display: "table-cell",
                                                  }}
                                                  rel="noopener noreferrer"
                                                  href={
                                                    global.host +
                                                    "/course/" +
                                                    param.cname +
                                                    "/courseOverview/" +
                                                    param.sname +
                                                    "/videos/" +
                                                    vid.filename.replace(
                                                      ".ir_",
                                                      ""
                                                    )
                                                  }
                                                  target="blank"
                                                >
                                                  <div className="res-item-inner">
                                                    <div className="res-img">
                                                      <img
                                                        src={
                                                          require("../../assets/images/ppt.png")
                                                            .default
                                                        }
                                                        alt="presentation"
                                                        width="80"
                                                        height="80"
                                                      />
                                                    </div>
                                                    <div className="res-header d-flex justify-content-between align-items-center px-4 py-2">
                                                      <h5 className="text-dark m-0">
                                                        Lesson {index + 1}
                                                      </h5>
                                                      <div>
                                                        <img
                                                          src={
                                                            require("../../assets/images/play_video_player_icon.svg")
                                                              .default
                                                          }
                                                          alt="presentation"
                                                          width="22"
                                                          height="16"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                            );
                                          }
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>
                          <div className="">
                            {ppts && ppts.length > 0 ? (
                              <></>
                            ) : (
                              <p style={{ fontWeight: "600" }} className="">
                                Powerpoint Presentaion
                              </p>
                            )}

                            {ppts &&
                              ppts.map((pitem, pptindex) => {
                                if (pitem.day === plan.tp_day) {
                                  // no++;
                                  return (
                                    <div className=" ppt ml-5" key={pptindex}>
                                      <div className=" rounded">
                                        <div className="pb-2">
                                          <div className="col-12 col-md-6 col-xl-3 res-item">
                                            <a
                                              className="d-block text-decoration-none"
                                              href={`https://view.officeapps.live.com/op/embed.aspx?src=${pitem.filename}`}
                                              target="blank"
                                            >
                                              <div className="res-item-inner">
                                                <div className="res-img">
                                                  <img
                                                    src={
                                                      require("../../assets/images/ppt.png")
                                                        .default
                                                    }
                                                    alt="presentation"
                                                    width="80"
                                                    height="80"
                                                  />
                                                </div>
                                                <div className="res-header d-flex justify-content-between align-items-center px-4 py-2">
                                                  <h5 className="text-dark m-0">
                                                    Lesson {index + 1}
                                                  </h5>
                                                  <div>
                                                    <img
                                                      src={
                                                        require("../../assets/images/play_video_player_icon.svg")
                                                          .default
                                                      }
                                                      alt="presentation"
                                                      width="22"
                                                      height="16"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        </div>
                        {plan.tp_note ? (
                          <>
                            <div className="alert alert-warning mb-5">
                              <b>Note :</b>
                              {plan.tp_note}
                            </div>
                            {token && jwt_decode(token).role === "Student" && (
                              <div className="d-flex flex-row-reverse">
                                {progress &&
                                progress.indexOf(plan._id) === -1 ? (
                                  <button
                                    className="btn btn-blue float-right"
                                    onClick={() => {
                                      return handleCourseComplete(
                                        plan._id,
                                        plan.cid
                                      );
                                    }}
                                  >
                                    Mark as Completed
                                  </button>
                                ) : (
                                  <button className="btn btn-success float-right">
                                    Completed
                                  </button>
                                )}
                              </div>
                            )}
                            <hr />
                          </>
                        ) : (
                          <>
                            <div className="mb-5">
                              {token &&
                                jwt_decode(token).role === "Student" && (
                                  <div className="d-flex flex-row-reverse">
                                    {progress &&
                                    progress.indexOf(plan._id) === -1 ? (
                                      <button
                                        className="btn btn-blue float-right"
                                        onClick={() => {
                                          return handleCourseComplete(
                                            plan._id,
                                            plan.cid
                                          );
                                        }}
                                      >
                                        Mark as Completed
                                      </button>
                                    ) : (
                                      <button className="btn btn-success float-right">
                                        Completed
                                      </button>
                                    )}
                                  </div>
                                )}
                            </div>
                            <hr />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                  // className="accordion-item"
                  >
                    <h3
                      // className="accordion-header"
                      id={"headingt" + (index + 1)}
                    >
                      <div
                      // className="accordion-button collapsed"
                      // type="button"
                      // data-bs-toggle="collapse"
                      // data-bs-target={"#collapset" + (index + 1)}
                      // aria-expanded="true"
                      // aria-controls={"collapset" + (index + 1)}
                      >
                        Module {index + 1}
                      </div>
                    </h3>
                    <div
                      id={"collapset" + (index + 1)}
                      // className="accordion-collapse collapse"
                      // aria-labelledby={"headingt" + (index + 1)}
                      // data-bs-parent="#accordiontExample"
                    >
                      <div
                      // className="accordion-body"
                      >
                        <ul>
                          {plan.tp_whattolearn &&
                            plan.tp_whattolearn
                              .split("\n")
                              .map((whattolearn) => {
                                return (
                                  <>
                                    {whattolearn.includes("-") ? (
                                      <ul>
                                        <li>
                                          <p>
                                            {whattolearn.split("-").slice(1, 2)}
                                          </p>
                                        </li>
                                      </ul>
                                    ) : (
                                      <li>
                                        <p>{whattolearn}</p>
                                      </li>
                                    )}
                                  </>
                                );
                              })}
                        </ul>
                        {(plan.tp_practice ||
                          plan.tp_practiceimgarr.length > 0) && (
                          <h6>Practice Exercise</h6>
                        )}
                        {plan.tp_practiceimgarr.length > 0 ? (
                          <div className="m-0 d-inline-block">
                            {plan.tp_practiceimgarr.map((item, pindex) => {
                              return (
                                <img
                                  src={`${imgURL}/${item}`}
                                  alt="practiceimg"
                                  className="m-1"
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: "100%",
                                  }}
                                />
                              );
                            })}
                          </div>
                        ) : null}
                        <ul className="wtllist mb-4">
                          {plan.tp_practice &&
                            plan.tp_practice
                              .split("\n")
                              .map((item, tpindex) => {
                                if (item.match(/^http/g)) {
                                  return (
                                    // <li className="py-1" key={tpindex}>
                                    <li>
                                      <a
                                        href={item}
                                        target="_blank"
                                        className="d-block"
                                        rel="noopener noreferrer"
                                        style={{
                                          width: "100%",
                                          display: "block",
                                          whiteSpace: "nowrap",
                                          textOverflow: "ellipsis",
                                          overflow: "hidden",
                                        }}
                                      >
                                        <p key={tpindex}>{item}</p>
                                      </a>
                                    </li>
                                    // </li>
                                  );
                                } else {
                                  return (
                                    <li>
                                      <p key={tpindex}>{item}</p>
                                    </li>
                                  );
                                }
                              })}
                        </ul>
                        {(plan.tp_assignment ||
                          plan.tp_assignmentimgarr.length > 0) && (
                          <h6 className="color-dgreen wtl">Assignment</h6>
                        )}
                        {plan.tp_assignmentimgarr.length > 0 ? (
                          <div className="m-0 d-inline-block">
                            {plan.tp_assignmentimgarr.map((item, aindex) => {
                              return (
                                <img
                                  src={`${imgURL}/${item}`}
                                  alt="assignmentimg"
                                  key="aindex"
                                  className="m-1"
                                  style={{
                                    cursor: "pointer",
                                    maxWidth: "100%",
                                  }}
                                />
                              );
                            })}
                          </div>
                        ) : null}

                        <ul className="wtllist mb-4">
                          {plan.tp_assignment
                            ? plan.tp_assignment
                                .split("\n")
                                .map((item, taindex) => {
                                  if (item.match(/^http/g)) {
                                    return (
                                      <li>
                                        <a
                                          href={item}
                                          target="_blank"
                                          className="d-block"
                                          rel="noopener noreferrer"
                                          style={{
                                            width: "100%",
                                            display: "block",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                          }}
                                        ></a>
                                      </li>
                                    );
                                  } else {
                                    return (
                                      <>
                                        {/* <p key={taindex}> */}
                                        {item.includes("-") ? (
                                          <ul>
                                            <li>
                                              <p>
                                                {item.split("-").slice(1, 2)}
                                              </p>
                                            </li>
                                          </ul>
                                        ) : (
                                          <li>
                                            <p>{item}</p>
                                          </li>
                                        )}
                                        {/* </p> */}
                                      </>
                                    );
                                  }
                                })
                            : null}
                        </ul>

                        {plan.tp_onlineref && plan.tp_onlineref.length > 0 ? (
                          <>
                            {plan.tp_onlineref[0].length > 0 ? (
                              <h6 className="color-dgreen wtl">
                                Online Reference
                              </h6>
                            ) : (
                              <></>
                            )}
                            <ul className="wtllist mb-4">
                              {plan.tp_onlineref.map((item, onindex) => {
                                if (item.split(",").length > 1) {
                                  return item.split(",").map((ite, index1) => {
                                    return (
                                      <li className="py-1" key={onindex}>
                                        <a
                                          href={ite}
                                          target="_blank"
                                          className="d-block"
                                          rel="noopener noreferrer"
                                          style={{
                                            width: "100%",
                                            display: "block",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {ite}
                                        </a>
                                      </li>
                                    );
                                  });
                                } else {
                                }
                              })}
                            </ul>
                          </>
                        ) : null}
                        <div>
                          {videos[0] &&
                          videos[0].videos[0] &&
                          videos[0].videos[0].length > 0 ? (
                            <p style={{ fontWeight: "600" }} className="my-2">
                              Videos
                            </p>
                          ) : (
                            <></>
                          )}
                          <div id="accordionVideos">
                            {videos &&
                              videos.map((vitem, vindex) => {
                                return (
                                  <div
                                    className="border-0 mb-0"
                                    key={vitem._id}
                                  >
                                    <div id={"myacc" + vindex}>
                                      <div className="">
                                        {vitem.videos.map((vid, vindex) => {
                                          if (
                                            vid.day === plan.tp_day &&
                                            vitem.cid === plan.cid
                                          ) {
                                            // number++;
                                            return (
                                              <div className="col-12 col-md-6 col-xl-3 res-item">
                                                <a
                                                  className="d-block text-decoration-none"
                                                  style={{
                                                    display: "table-cell",
                                                  }}
                                                  rel="noopener noreferrer"
                                                  href={
                                                    global.host +
                                                    "/course/" +
                                                    param.cname +
                                                    "/courseOverview/" +
                                                    param.sname +
                                                    "/videos/" +
                                                    vid.filename.replace(
                                                      ".ir_",
                                                      ""
                                                    )
                                                  }
                                                  target="blank"
                                                >
                                                  <div className="res-item-inner">
                                                    <div className="res-img">
                                                      <img
                                                        src={
                                                          require("../../assets/images/ppt.png")
                                                            .default
                                                        }
                                                        alt="presentation"
                                                        width="80"
                                                        height="80"
                                                      />
                                                    </div>
                                                    <div className="res-header d-flex justify-content-between align-items-center px-4 py-2">
                                                      <h5 className="text-dark m-0">
                                                        Lesson {index + 1}
                                                      </h5>
                                                      <div>
                                                        <img
                                                          src={
                                                            require("../../assets/images/play_video_player_icon.svg")
                                                              .default
                                                          }
                                                          alt="presentation"
                                                          width="22"
                                                          height="16"
                                                        />
                                                      </div>
                                                    </div>
                                                  </div>
                                                </a>
                                              </div>
                                            );
                                          }
                                        })}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                          </div>

                          <div className="">
                            {ppts && ppts.length > 0 ? (
                              <></>
                            ) : (
                              <p style={{ fontWeight: "600" }} className="">
                                Powerpoint Presentaion
                              </p>
                            )}

                            {ppts &&
                              ppts.map((pitem, pptindex) => {
                                if (pitem.day === plan.tp_day) {
                                  // no++;
                                  return (
                                    <div className=" ppt ml-5" key={pptindex}>
                                      <div className=" rounded">
                                        <div className="pb-2">
                                          <div className="col-12 col-md-6 col-xl-3 res-item">
                                            <a
                                              className="d-block text-decoration-none"
                                              href={`https://view.officeapps.live.com/op/embed.aspx?src=${pitem.filename}`}
                                              target="blank"
                                            >
                                              <div className="res-item-inner">
                                                <div className="res-img">
                                                  <img
                                                    src={
                                                      require("../../assets/images/ppt.png")
                                                        .default
                                                    }
                                                    alt="presentation"
                                                    width="80"
                                                    height="80"
                                                  />
                                                </div>
                                                <div className="res-header d-flex justify-content-between align-items-center px-4 py-2">
                                                  <h5 className="text-dark m-0">
                                                    Lesson {index + 1}
                                                  </h5>
                                                  <div>
                                                    <img
                                                      src={
                                                        require("../../assets/images/play_video_player_icon.svg")
                                                          .default
                                                      }
                                                      alt="presentation"
                                                      width="22"
                                                      height="16"
                                                    />
                                                  </div>
                                                </div>
                                              </div>
                                            </a>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }
                              })}
                          </div>
                        </div>
                        {plan.tp_note ? (
                          <>
                            <div className="alert alert-warning mb-5">
                              <b>Note :</b>
                              {plan.tp_note}
                            </div>
                            {token && jwt_decode(token).role === "Student" && (
                              <div className="d-flex flex-row-reverse">
                                {progress &&
                                progress.indexOf(plan._id) === -1 ? (
                                  <button
                                    className="btn btn-blue float-right"
                                    onClick={() => {
                                      return handleCourseComplete(
                                        plan._id,
                                        plan.cid
                                      );
                                    }}
                                  >
                                    Mark as Completed
                                  </button>
                                ) : (
                                  <button className="btn btn-success float-right">
                                    Completed
                                  </button>
                                )}
                              </div>
                            )}
                            <hr />
                          </>
                        ) : (
                          <>
                            <div className="mb-5">
                              {token &&
                                jwt_decode(token).role === "Student" && (
                                  <div className="d-flex flex-row-reverse">
                                    {progress &&
                                    progress.indexOf(plan._id) === -1 ? (
                                      <button
                                        className="btn btn-blue float-right"
                                        onClick={() => {
                                          return handleCourseComplete(
                                            plan._id,
                                            plan.cid
                                          );
                                        }}
                                      >
                                        Mark as Completed
                                      </button>
                                    ) : (
                                      <button className="btn btn-success float-right">
                                        Completed
                                      </button>
                                    )}
                                  </div>
                                )}
                            </div>
                            <hr />
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }
            })}{" "}
        </div>
      );
    } else if (videos) {
      return (
        <div
          // className="accordion-content course-detail-accordion"
          id="accordionExample"
        >
          {loading && (
            <div className="formloader">
              <div className="row text-center">
                <div className="col-12">
                  <img src={formloader} alt="" height="200" />
                </div>
                <div className="col-12 text-white h4">Loading...</div>
              </div>
            </div>
          )}
          {videos &&
            videos.map((video, index) => {
              if (index === 0) {
                return (
                  <div
                  // className="accordion-item"
                  >
                    <h3
                      // className="accordion-header"
                      id="headingOne"
                    >
                      <div
                      // className="accordion-button"
                      // type="button"
                      // data-bs-toggle="collapse"
                      // data-bs-target="#collapseOne"
                      // aria-expanded="true"
                      // aria-controls="collapseOne"
                      >
                        {video.topic}
                      </div>
                    </h3>
                    <div
                      id="collapseOne"
                      // className="accordion-collapse collapse show"
                      // aria-labelledby="headingOne"
                      // data-bs-parent="#accordionExample"
                    >
                      <div
                      // className="accordion-body"
                      >
                        <ul>
                          {videos[0] &&
                            videos[0].videos[0] &&
                            videos[0].videos.map((vid, index1) => {
                              return (
                                <li
                                  onClick={(e) => {
                                    navigate(
                                      `/course/${param.cname}/courseOverview/${param.sname}/videos/${vid.filename}`,
                                      videos
                                    );
                                  }}
                                >
                                  <p
                                    className={
                                      lastvideos
                                        ? lastvideos.id === vid.id
                                          ? "text-secondary"
                                          : ""
                                        : ""
                                    }
                                    // className={
                                    //   param.vid === vid.filename?"text-secondary":""
                                    // }
                                  >
                                    {" "}
                                    {percentages[0] &&
                                      // lastvideos &&
                                      props.percentages &&
                                      props.percentages.map((item, index) => {
                                        if (token) {
                                          const decoded = jwt_decode(token);
                                          if (
                                            item.id == vid.id &&
                                            item.uid === decoded.id &&
                                            item.percentage == 100
                                          ) {
                                            return (
                                              <i
                                                className={
                                                  lastvideos &&
                                                  lastvideos.id == item.id
                                                    ? "actives"
                                                    : "notplay"
                                                }
                                                style={{
                                                  width: "16px",
                                                  height: "16px",
                                                }}
                                              />
                                            );
                                          }

                                          if (
                                            item.id == vid.id &&
                                            item.uid === decoded.id &&
                                            item.percentage !== 100
                                          ) {
                                            return (
                                              <i
                                                className={
                                                  lastvideos &&
                                                  lastvideos.id == item.id
                                                    ? "actives"
                                                    : "play"
                                                }
                                                style={{
                                                  width: "16px",
                                                  height: "16px",
                                                }}
                                              />
                                            );
                                          }
                                          if (
                                            !percentages.find(
                                              (item) => item.id == vid.id
                                            )
                                          ) {
                                            return (
                                              <i
                                                className={
                                                  lastvideos &&
                                                  lastvideos.id == vid.id
                                                    ? "actives"
                                                    : "play"
                                                }
                                                style={{
                                                  width: "16px",
                                                  height: "16px",
                                                }}
                                              />
                                            );
                                          }
                                        }

                                        return null;
                                      })}
                                    {vid.filename
                                      .split("-")
                                      .slice(1, 5)
                                      .join(" ")
                                      .replace(".m3u8", "")
                                      .replace(".ir", "")
                                      .replace(".ir_", "")
                                      .replace(/[^\D]/g, "")
                                      .replace(/^_/g, " ")
                                      .replace(/^./g, " ")
                                      .replace(/^[a-zA-Z]+_/g, "")}{" "}
                                  </p>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return (
                  <div
                  // className="accordion-item"
                  >
                    <h3
                      // className="accordion-header"
                      id={"heading" + (index + 1)}
                    >
                      <div
                      // className="accordion-button collapsed"
                      // type="button"
                      // data-bs-toggle="collapse"
                      // data-bs-target={"#collapse" + (index + 1)}
                      // aria-expanded="true"
                      // aria-controls={"collapse" + (index + 1)}
                      >
                        {video.topic}
                      </div>
                    </h3>
                    <div
                      id={"collapse" + (index + 1)}
                      // className="accordion-collapse collapse"
                      // aria-labelledby={"heading" + (index + 1)}
                      // data-bs-parent="#accordionExample"
                    >
                      <div
                      // className="accordion-body"
                      >
                        <ul>
                          {video.videos.map((vid, index2) => {
                            return (
                              <li
                                onClick={(e) => {
                                  navigate(
                                    `/course/${param.cname}/courseOverview/${param.sname}/videos/${vid.filename}`,
                                    videos
                                  );
                                }}
                              >
                                <p
                                  className={
                                    lastvideos
                                      ? lastvideos.id === vid.id
                                        ? "text-secondary"
                                        : ""
                                      : ""
                                  }
                                  // className={
                                  //   param.vid === vid.filename?"text-secondary":""
                                  // }
                                >
                                  {" "}
                                  {percentages[0] &&
                                    // lastvideos &&
                                    props.percentages &&
                                    props.percentages.map((item, index) => {
                                      if (token) {
                                        const decoded = jwt_decode(token);
                                        if (
                                          item.id == vid.id &&
                                          item.uid === decoded.id &&
                                          item.percentage == 100
                                        ) {
                                          return (
                                            <i
                                              className={
                                                lastvideos &&
                                                lastvideos.id == item.id
                                                  ? "actives"
                                                  : "notplay"
                                              }
                                              style={{
                                                width: "16px",
                                                height: "16px",
                                              }}
                                            />
                                          );
                                        }

                                        if (
                                          item.id == vid.id &&
                                          item.uid === decoded.id &&
                                          item.percentage !== 100
                                        ) {
                                          return (
                                            <i
                                              className={
                                                lastvideos &&
                                                lastvideos.id == item.id
                                                  ? "actives"
                                                  : "play"
                                              }
                                              style={{
                                                width: "16px",
                                                height: "16px",
                                              }}
                                            />
                                          );
                                        }
                                        if (
                                          !percentages.find(
                                            (item) => item.id == vid.id
                                          )
                                        ) {
                                          return (
                                            <i
                                              className={
                                                lastvideos &&
                                                lastvideos.id == vid.id
                                                  ? "actives"
                                                  : "play"
                                              }
                                              style={{
                                                width: "16px",
                                                height: "16px",
                                              }}
                                            />
                                          );
                                        }
                                      }

                                      return null;
                                    })}
                                  {vid.filename
                                    .split("-")
                                    .slice(1, 5)
                                    .join(" ")
                                    .replace(".m3u8", "")
                                    .replace(".ir", "")
                                    .replace(".ir_", "")
                                    .replace(/[^\D]/g, "")
                                    .replace(/^_/g, " ")
                                    .replace(/^./g, " ")
                                    .replace(/^[a-zA-Z]+_/g, "")}{" "}
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                );
              }
            })}{" "}
        </div>
      );
    }
  } else {
    return (
      <div
        className="accordion-content course-detail-accordion"
        id="accordiontExample"
      >
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="200" />
              </div>
              <div className="col-12 text-white h4">Loading...</div>
            </div>
          </div>
        )}
        {tplan &&
          tplan.map((plan, index) => {
            return (
              <div className="accordion-item">
                <h3 className="accordion-header" id={"headingt" + (index + 1)}>
                  <div
                    className="accordion-button collapsed h4"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={"#collapset" + (index + 1)}
                    aria-expanded="true"
                    aria-controls={"collapset" + (index + 1)}
                  >
                    Module {index + 1}
                  </div>
                </h3>
                <div
                  id={"collapset" + (index + 1)}
                  className="accordion-collapse collapse"
                  aria-labelledby={"headingt" + (index + 1)}
                  data-bs-parent="#accordiontExample"
                >
                  <div className="accordion-body">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          plan.tp_whattolearn || plan.formattedWhatToLearn,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}{" "}
      </div>
    );
  }
}
