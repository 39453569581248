import Image from "./Image";
import Slider from "react-slick";
import './imageList.scss';
import { Fancybox } from '@fancyapps/ui';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

const ImageList = ({ images, heading }) => {
    const sliderSettings = {
        dots: true,
        lazyLoad: "ondemand",
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: false,
    }
    Fancybox.bind('[data-fancybox=""]', {
        
      });
    
  

    return (
        <div className="image-list mb-5 px-2" >
            {heading && <h2>{heading}</h2>}
            <Slider {...sliderSettings}>
                {images.map((image, index) => (
                    <a href={image.src} data-fancybox="" data-caption={image.alt}>
                        <Image key={index} src={image.src} alt={image.alt} className={image.className} title={image.alt} />
                    </a>
                ))}
            </Slider>
        </div>
    )
}

export default ImageList;