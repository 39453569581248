import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import jwt_decode from "jwt-decode";

export default function ManageTrainingPDFCard(props) {
  const item = props.item;
  const fileURL = props.fileURL;
  const handleDelete = props.handleDelete;
  const setConfirmBox = props.setConfirmBox;
  const [confirmBox2, setConfirmBox2] = useState(false);
  const [edit, setEdit] = useState(false);

  return (
    <div className="col-lg-6">
      <div className="bg-white p-3 wounded citem position-relative">
        {confirmBox2 && (
          <div className="formloade ">
            <div className="row text-center ">
              <div className=" confirmbox ">
                <div className="alert">
                  Do you really want to remove {item.tname} subcourse?
                  <br />
                  <button
                    className="btn btn-danger ms-4"
                    onClick={() => {
                      setConfirmBox2(false);
                    }}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-success ms-2"
                    onClick={() => {
                      setConfirmBox2(false);
                      setConfirmBox(true);
                      handleDelete(item._id);
                    }}
                  >
                    Remove
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {edit ? (
            <div
              className="d-flex justify-content-end m-0 mb-2"
              style={{
                right: "0.6rem",
                top: "0.6rem",
                display: "flex",
              }}
            >
              <button
                className="btn btn-secondary py-1 me-2"
                // onClick={() => {
                //   setEdit(false);
                // }}
              >
                Cancel
              </button>
              <button
                className="btn btn-success py-1"
                // onClick={() => {
                //   handleSubCourseUpdate(upcourse);
                //   setEdit(false);
                // }}
              >
                Update
              </button>
            </div>
          ) : (
            <div
              className="position-absolute"
              style={{
                right: "0.6rem",
                top: "0.6rem",
                display: "flex",
              }}
            >
              {/* <button
                className="btn btn-warning rounded-pill d-flex justify-content-center align-items-center p-0 me-2 text-white"
                style={{
                  height: "1.8rem",
                  width: "1.8rem",
                }}
                disabled={edit ? true : false}
                // onClick={() => {
                //   setUpcourse(item);
                //   setEdit(true);
                // }}
              >
                <BiEdit className="fs-6" />
              </button> */}
              {localStorage.getItem("token") && jwt_decode(localStorage.getItem("token")).role === "Admin" && (
                <button
                  className="btn btn-danger rounded-pill d-flex justify-content-center align-items-center p-0"
                  style={{
                    height: "1.8rem",
                    width: "1.8rem",
                  }}
                  onClick={() => {
                    // setScourse(item);
                    setConfirmBox2(true);
                  }}
                >
                  <FaTrash className="fs-6" />
                </button>
              )}
            </div>
          )}
        <form className="">
          <table className="table table-sm">
            <tbody className="fs-6">
              <tr>
                <td className="fw-bold">Batch Name</td>
                <td>{item.tname}</td>
              </tr>
              <tr>
                <td className="fw-bold">PDF</td>
                <td>
                  {item.docs[0].split("-").slice(1, 5).join(" ")}
                  <br />
                  <iframe
                    src={`${fileURL}/${item.docs[0]}`}
                    className="mt-3 border border-dark border-2"
                  ></iframe>
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
}
