import React, { useState } from "react";
import { FaTrash } from "react-icons/fa";
import { BiEdit } from "react-icons/bi";
import jwt_decode from "jwt-decode";
import ToolTip from "../BsComponents/ToolTip";

export default function ManageCourseCard(props) {
  const item = props.item;
  const handleCourseUpdate = props.handleCourseUpdate;
  const handleRemoveCourse = props.handleRemoveCourse;
  const allcategory = props.allcategory;
  const level = props.level;
  const [showMore, setShowMore] = useState(false);
  const [confirmBox2, setConfirmBox2] = useState(false);
  const [edit, setEdit] = useState(false);
  const [upcourse, setUpcourse] = useState({
    name: "",
    type: "",
    desc: "",
    level: "",
  });

  return (
    <div className="col-lg-6">
      <div className="bg-white p-3 rounded citem position-relative h-100">
        {confirmBox2 && (
          <div className="formloade ">
            <div className="row text-center ">
              <div className=" confirmbox ">
                <div className="alert">
                  Do you really want to remove {item.name} subcourse?
                  <br />
                  <ToolTip title="Cancel">
                    <button
                      className="btn btn-danger ms-4"
                      onClick={() => {
                        setConfirmBox2(false);
                      }}
                    >
                      Cancel
                    </button>
                  </ToolTip>
                  <ToolTip title="Delete course">
                    <button
                      className="btn btn-success ms-2"
                      onClick={() => {
                        setConfirmBox2(false);
                        handleRemoveCourse(item);
                      }}
                    >
                      Remove
                    </button>
                  </ToolTip>
                </div>
              </div>
            </div>
          </div>
        )}
        {edit ? (
          <div
            className="d-flex justify-content-end m-0 mb-2"
            style={{
              right: "0.6rem",
              top: "0.6rem",
              display: "flex",
            }}
          >
            <button
              className="btn btn-secondary py-1 me-2"
              onClick={() => {
                setEdit(false);
              }}
            >
              Cancel
            </button>

            <button
              type="button"
              className="btn btn-success py-1"
              disabled={
                upcourse.name &&
                !!upcourse.name.trim() &&
                upcourse.type &&
                upcourse.desc &&
                upcourse._id &&
                upcourse.level
                  ? false
                  : true
              }
              onClick={() => {
                handleCourseUpdate(upcourse);
                setEdit(false);
              }}
            >
              Update
            </button>
          </div>
        ) : (
          <div
            className="position-absolute"
            style={{
              right: "0.6rem",
              top: "0.6rem",
              display: "flex",
            }}
          >
            {!confirmBox2 ? (
              <ToolTip title="Edit course">
                <button
                  className="btn btn-warning rounded-pill d-flex justify-content-center align-items-center p-0 me-2 text-white"
                  style={{
                    height: "1.8rem",
                    width: "1.8rem",
                  }}
                  disabled={edit ? true : false}
                  onClick={() => {
                    setUpcourse(item);
                    setEdit(true);
                  }}
                >
                  <BiEdit className="fs-6" />
                </button>
              </ToolTip>
            ) : null}
            {localStorage.getItem("token") &&
              jwt_decode(localStorage.getItem("token")).role === "Admin" && (
                <ToolTip title="Delete course">
                  <button
                    className="btn btn-danger rounded-pill d-flex justify-content-center align-items-center p-0"
                    style={{
                      height: "1.8rem",
                      width: "1.8rem",
                    }}
                    onClick={() => {
                      setConfirmBox2(true);
                    }}
                  >
                    <FaTrash className="fs-6" />
                  </button>
                </ToolTip>
              )}
          </div>
        )}

        <form className="">
          <table className="table table-sm">
            <tbody className="fs-6">
              <tr>
                <td className="fw-bold">Name</td>
                <td>
                  {edit ? (
                    <div className="position-relative">
                      <input
                        type="text"
                        value={upcourse.name}
                        className="form-control"
                        style={
                          upcourse.name
                            ? null
                            : {
                                border: "1px solid #df4957",
                                boxShadow: "0 0 5px #df4957",
                              }
                        }
                        onChange={(e) => {
                          setUpcourse((d) => ({ ...d, name: e.target.value }));
                        }}
                      />
                      {!upcourse.name.trim() && (
                        <div
                          className=" rounded px-2 py-1 text-white mt-1"
                          style={{
                            background: "rgba(220,53,69,.9)",
                            zIndex: "1",
                          }}
                        >
                          Please enter course name
                        </div>
                      )}
                    </div>
                  ) : (
                    <>{item.name}</>
                  )}
                </td>
              </tr>
              <tr>
                <td className="fw-bold">Type</td>
                <td>
                  {edit ? (
                    <div className="position-relative">
                      <select
                        className="form-select"
                        value={upcourse.type}
                        style={
                          upcourse.type
                            ? null
                            : {
                                border: "1px solid #df4957",
                                boxShadow: "0 0 5px #df4957",
                              }
                        }
                        onChange={(e) =>
                          setUpcourse((d) => ({ ...d, type: e.target.value }))
                        }
                      >
                        <option value="Common">Select Type</option>
                        {allcategory.map((cat, index) => {
                          return (
                            <option value={cat._id} key={index}>
                              {cat.cname}
                            </option>
                          );
                        })}
                      </select>
                      {upcourse.type !== "" ? null : (
                        <div
                          className="position-absolute rounded px-2 py-1 text-white mt-1"
                          style={{
                            background: "rgba(220,53,69,.9)",
                            zIndex: "1",
                          }}
                        >
                          Please enter course type
                        </div>
                      )}
                    </div>
                  ) : (
                    <p>
                      {allcategory.map((cat, index) => {
                        return (
                          <React.Fragment key={index}>
                            {cat._id === item.type && cat.cname}
                          </React.Fragment>
                        );
                      })}
                    </p>
                  )}
                </td>
              </tr>
              <tr>
                <td className="fw-bold">Description</td>
                <td>
                  {edit ? (
                    <div className="">
                      <textarea
                        value={upcourse.desc}
                        className="form-control"
                        rows="5"
                        style={
                          upcourse.desc
                            ? null
                            : {
                                border: "1px solid #df4957",
                                boxShadow: "0 0 5px #df4957",
                              }
                        }
                        onChange={(e) => {
                          setUpcourse((d) => ({ ...d, desc: e.target.value }));
                        }}
                      ></textarea>
                      {upcourse.desc !== "" ? null : (
                        <div
                          className="position-absolute rounded px-2 py-1 text-white mt-1"
                          style={{
                            background: "rgba(220,53,69,.9)",
                            zIndex: "1",
                          }}
                        >
                          Please enter course description
                        </div>
                      )}
                    </div>
                  ) : (
                    <>
                      <span>
                        {showMore
                          ? item.desc
                          : `${item.desc.substring(0, 120)}`}
                        {item.desc.length > 120 ? (
                          <button
                            className="btn text-primary"
                            type="button"
                            onClick={() => setShowMore(!showMore)}
                          >
                            {showMore ? "Read less" : "Read more"}
                          </button>
                        ) : null}
                      </span>
                    </>
                  )}
                </td>
              </tr>
              <tr>
                <td className="fw-bold">Level</td>
                <td>
                  {edit ? (
                    <div className="position-relative">
                      <select
                        className="form-select"
                        value={upcourse.level}
                        style={
                          upcourse.level
                            ? null
                            : {
                                border: "1px solid #df4957",
                                boxShadow: "0 0 5px #df4957",
                              }
                        }
                        onChange={(e) =>
                          setUpcourse((d) => ({ ...d, level: e.target.value }))
                        }
                      >
                        <option value="">Select Level</option>
                        {level.map((cat, index) => {
                          return (
                            <option value={cat} key={index}>
                              {cat}
                            </option>
                          );
                        })}
                      </select>
                      {!upcourse.level && (
                        <div
                          className=" rounded px-2 py-1 text-white mt-1"
                          style={{
                            background: "rgba(220,53,69,.9)",
                            zIndex: "1",
                          }}
                        >
                          Please enter course Level
                        </div>
                      )}
                    </div>
                  ) : (
                    <p>
                      {level.map((cat, index) => {
                        return (
                          <React.Fragment key={index}>
                            {cat === item.level && cat}
                          </React.Fragment>
                        );
                      })}
                    </p>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </form>
      </div>
    </div>
  );
}
