import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { FaPen, FaTimes } from "react-icons/fa";
import { FcCancel } from "react-icons/fc";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { VscCheck } from "react-icons/vsc";
import Pagination from "react-js-pagination";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import { SortableContainer } from "react-sortable-hoc";
import Loader from '../../components/loader/Loader';
import CourseService from "../../services/CourseService";
import ToolTip from "../BsComponents/ToolTip";
const { getUserName } = require("../../utils/user/getUserName");
const { format } = require("../../../src/utils/Date/format");

export default function ManageMCQQuestionList(props) {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false)
  const [noOfMCQPerPage, setNoOfMCQPerPage] = useState(10);
  const [examTypes, setExamTypes] = useState([]);
  const [activeQuestion, setActiveQuestion] = useState([]);
  const [difficultyList, setDifficultyList] = useState([]);
  const [message, setMessage] = useState({ status: false, msg: "" });
  const [examQuestion, setExamQuestion] = useState([]);
  const [subcourseOptions, setSubcoursesOptions] = useState([]);
  const [page, setPage] = useState({ selected: 0 });
  const [filterMcq, setFilterMcq] = useState({});
  const [questionId, setQuestionId] = useState("");
  const [confirmBox, setConfirmBox] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [addData, setVal] = useState("");
  const [addDataA, setAddDataA] = useState("");
  const [addDataB, setAddDataB] = useState("");
  const [addDataC, setAddDataC] = useState("");
  const [addDataD, setAddDataD] = useState("");
  const [answerArray, setAnswerArray] = useState([]);
  const [optionValid, setOptionValid] = useState([]);
  const [answerValid, setAnswerValid] = useState([]);
  const [addSubTopicName, setAddSubTopicName] = useState({ topicName: [] });
  const [questionData, setQuestionData] = useState({
    cid: "",
    type: "",
    Question: "",
    Option: [],
    Answer: [],
    examType: "",
    questionWeightage: "",
    questionTopic: "",
    difficulty: "",
  });
  const [questionOption, setQuestionOption] = useState([
    { label: "Radio", value: 0 },
    { label: "Checkbox", value: 1 },
  ]);
  const [toggleSelectDepartment, setToggleSelectDepartment] = useState(false);
  const [subCoursesList, setSubcoursesList] = useState([]);
  const [subCourseTopics, setSubCourseTopics] = useState([])
  const [currentpage, setcurrentPage] = useState(1);
  const [mcqsCount, setMcqsCount] = useState(0)
  const [questionDifficultyCount, setQuestionDifficultyCount] = useState({})
  const [searchQuestion, setSearchQuestion] = useState("");

  const css = `
    .ck-editor__editable {
        min-height: 100px !important;
    }
`;
  const token = localStorage.getItem("token");
  const handleAddTopic = () => {
    // e.preventDefault();
    if (token) {
      if (questionData.cid) {
        setLoader(true)
        CourseService.addSubTopicName(
          addSubTopicName,
          questionData.cid.value,
          token
        )
          .then((res) => {
            if (res.data) {
              if (res.data.status) {
                setLoader(false)
                const subtopicName = res.data.message.subTopicName;
                const subtopiclist = [{ label: "Empty", value: "" }];
                for (let names of subtopicName) {
                  subtopiclist.push({ value: names, label: names });
                }
                setSubCourseTopics(subtopiclist);
                setToggleSelectDepartment(false);
                setAddSubTopicName({ ...addSubTopicName, topicName: [] });
              } else {
                setLoader(false)
                setConfirmBox(true);
                setMessage({ status: false, msg: res.data.message });
                window.scrollTo(0, 0);
              }
            }
          })
          .catch((error) => {
            handleCatchError(error)
          });
      } else {
        setConfirmBox(true);
        setMessage({ status: false, msg: "Please select subcourse" });
        window.scrollTo(0, 0);
      }
      setTimeout(() => setConfirmBox(false), 5000);
    } else {
      navigate("/login");
    }
  };

  const SortableSelect = SortableContainer(Select);

  const handleChange = (e, editor) => {
    const data = editor.getData();
    setQuestionData((d) => ({ ...d, Question: data }));
  };
  const handleSelectTopic = (e) => {
    setQuestionData({ ...questionData, questionTopic: e });
  };
  const handleChangeA = (e, editor) => {
    const dataA = editor.getData();
    const options = questionData.Option.findIndex((item) => item.Id === "A");
    const findIndex = questionData.Answer.findIndex((item) => item.Id === "A");
    if (options > -1) {
      const newArray = [...questionData.Option];
      newArray[options].Value = dataA;
      setQuestionData((d) => ({ ...d, Option: newArray }));
      if (findIndex > -1) {
        const newArray1 = [...questionData.Answer];
        newArray1[findIndex].Value = dataA;
        setQuestionData((d) => ({ ...d, Answer: newArray1 }));
      }
    }
  };
  const handleChangeB = (e, editor) => {
    const dataB = editor.getData();
    const options = questionData.Option.findIndex((item) => item.Id === "B");
    const findIndex = questionData.Answer.findIndex((item) => item.Id === "B");
    if (options > -1) {
      const newArray = [...questionData.Option];
      newArray[options].Value = dataB;
      setQuestionData((d) => ({ ...d, Option: newArray }));
      if (findIndex > -1) {
        const newArray1 = [...questionData.Answer];
        newArray1[findIndex].Value = dataB;
        setQuestionData((d) => ({ ...d, Answer: newArray1 }));
      }
    }
  };
  const handleChangeC = (e, editor) => {
    const dataC = editor.getData();
    const options = questionData.Option.findIndex((item) => item.Id === "C");
    const findIndex = questionData.Answer.findIndex((item) => item.Id === "C");
    if (options > -1) {
      const newArray = [...questionData.Option];
      newArray[options].Value = dataC;
      setQuestionData((d) => ({ ...d, Option: newArray }));
      if (findIndex > -1) {
        const newArray1 = [...questionData.Answer];
        newArray1[findIndex].Value = dataC;
        setQuestionData((d) => ({ ...d, Answer: newArray1 }));
      }
    }
  };
  const handleChangeD = (e, editor) => {
    const dataD = editor.getData();
    const options = questionData.Option.findIndex((item) => item.Id === "D");
    const findIndex = questionData.Answer.findIndex((item) => item.Id === "D");
    if (options > -1) {
      const newArray = [...questionData.Option];
      newArray[options].Value = dataD;
      setQuestionData((d) => ({ ...d, Option: newArray }));
      if (findIndex > -1) {
        const newArray1 = [...questionData.Answer];
        newArray1[findIndex].Value = dataD;
        setQuestionData((d) => ({ ...d, Answer: newArray1 }));
      }
    }
  };

  const handleMessage = (message, status, magtime = false) => {
    setConfirmBox(true)
    setMessage({ msg: message, status: status })
    if (magtime) {
      setTimeout(() => setConfirmBox(false), 3000)
    }
  }


  const handleCatchError = (err) => {
    setLoader(false);
    if (err["response"]) {
      if (err["response"].status === 401) {
        if (err["response"]?.data?.message === "You are not authorized to view this content") {
          handleMessage(err["response"].data.message, false)
        } else {
          localStorage.removeItem("token");
          navigate("/login");
        }
      } else if (err["response"].status === 404) {
        handleMessage(err["response"]?.data?.message, false)
      } else {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  }


  const getsubcourseOptions = (filtersMcqs, page = 1) => {
    setLoader(true)
    CourseService.getMCQQuestions(filtersMcqs, page, token).then((res) => {
      if (res.data) {
        setLoader(false)
        setPage({ selected: 1 })
        setcurrentPage(page)
        setExamQuestion(res.data.filterQuestions);
        setMcqsCount(res.data.totalMcqs)
        setQuestionDifficultyCount(res.data.difficultyList)
        if (res.data.dataStatus) {
          setExamTypes(res.data.examTypes)
          setActiveQuestion(res.data.questionStatus)
          setDifficultyList(res.data.Difficulty)
          setSubCourseTopics(res.data.subTopics)
        }
      }
    }).catch(error => {
      handleCatchError(error)
    });
  };

  const handleIndex = (index) => {
    const finalNumber = ((currentpage * 10) - 10)
    return finalNumber + index
  };

  const difficultyLeval = (difficultyType) => {
    if (difficultyType === 1) {
      return "Easy";
    } else if (difficultyType === 2) {
      return "Medium";
    } else {
      return "Hard";
    }
  };
  const handlecreatedAndUpdateby = (createdupdatedby) => {
    return getUserName(createdupdatedby);
  };
  const handleCreateUpadateDate = (date) => {
    return format(date);
  };
  useEffect(() => {
    setLoader(true);
    CourseService.getSubCourses().then((res) => {
      if (res.data) {
        var subcourses = [];
        res.data.map(async (item, index) => {
          await subcourses.push({
            id: index + 1,
            value: item._id,
            label: item.name,
          });
        });
        setSubcoursesOptions(subcourses);
        setSubcoursesList(res.data)
        getsubcourseOptions({
          ...filterMcq,
          'Question': searchQuestion ? {
            label: searchQuestion,
            value: { $regex: searchQuestion, $options: "i" },
          } : null
        })
      }
    }).catch((error) => {
      handleCatchError(error)
    });
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getsubcourseOptions({
        ...filterMcq,
        'Question': searchQuestion ? {
          label: searchQuestion,
          value: { $regex: searchQuestion, $options: "i" },
        } : null
      }, 1)
    }, 500);
    return () => {
      clearTimeout(timeoutId)
    };
  }, [searchQuestion]);

  let closeModal = () => {
    setOpenModal(false);
  };

  const handleDelete = () => {
    if (!token) {
      navigate("/login");
    } else {
      if (questionId) {
        setLoader(true)
        const questionType = "MCQ";
        CourseService.deleteQuestion(questionId, questionType, token).then(
          (res) => {
            if (res.data) {
              setLoader(false)
              window.scrollTo(0, 0);
              setMessage({ msg: res.data.message, status: true });
              setConfirmBox(true);
              setTimeout(() => setConfirmBox(false), 5000);
              setOpenModal(false);
              getsubcourseOptions({
                ...filterMcq,
                'Question': searchQuestion ? {
                  label: searchQuestion,
                  value: { $regex: searchQuestion, $options: "i" },
                } : null
              });
            }
          }
        ).catch((error) => {
          handleCatchError(error)
        });
      } else {
        window.scrollTo(0, 0);
        setMessage({ msg: "delete id is not found", status: false });
        setConfirmBox(true);
        setTimeout(() => setConfirmBox(false), 3000);
      }
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    questionData.Option.forEach((obj) => {
      if (obj.Value !== "") {
        optionValid.push(false);
      } else {
        optionValid.push(true);
      }
    });
    questionData.Answer.forEach((obj) => {
      if (obj.Value !== "") {
        answerValid.push(false);
      } else {
        answerValid.push(true);
      }
    });
    if (!token) {
      navigate("/login");
    } else {
      if (
        questionData.cid &&
        questionData.Question &&
        ((questionData.type.value === 0 && questionData.Answer.length === 1) ||
          (questionData.type.value === 1 && questionData.Answer.length > 1)) &&
        questionData.examType &&
        !optionValid.includes(true) &&
        !answerValid.includes(true) &&
        !isNaN(questionData.questionWeightage) &&
        questionData.questionWeightage &&
        questionData.Option.length > 0 &&
        questionData.Answer.length > 0 &&
        questionData.difficulty !== ""
      ) {
        try {
          setLoader(true)
          const questionType = "MCQ";
          const res = await CourseService.editQuestions(
            questionId,
            questionType,
            questionData,
            token
          );
          if (res.data) {
            setLoader(false)
            setConfirmBox(true);
            setMessage({ msg: res.data.message, status: true });
            setOptionValid([]);
            setAnswerValid([]);
            getsubcourseOptions({
              ...filterMcq,
              'Question': searchQuestion ? {
                label: searchQuestion,
                value: { $regex: searchQuestion, $options: "i" },
              } : null
            });
          }
        } catch (error) {
          handleCatchError(error)
        }
        window.scrollTo(0, 0);
        setQuestionData({
          cid: "",
          type: "",
          Question: "",
          Option: [],
          Answer: [],
          examType: "",
          questionWeightage: "",
          questionTopic: "",
          difficulty: "",
        });
        setVal("");
        setAddDataA("");
        setAddDataB("");
        setAddDataC("");
        setAddDataD("");
        setAnswerArray([]);
        setOptionValid([]);
        setAnswerValid([]);
        setTimeout(() => setConfirmBox(false), 3000);
      } else {
        setMessage({
          msg: `Please verify your ${questionData.cid === "" ? "SubCourse Name, " : ""
            } ${questionData.Question === "" ? "Question,  " : ""} 
          ${questionData.Answer.length === 0 ? "Add Answer, " : ""}
          ${questionData.type.value === 0 && questionData.Answer.length > 1
              ? "Type Radio to set answer length only 1, "
              : ""
            }${questionData.type.value === 1 && questionData.Answer.length < 2
              ? "Type checkbox set mininmum answer length 2, "
              : ""
            }
          ${questionData.Option.length === 0 ? "Add Option, " : ""}${questionData.type === "" ? "Type,  " : ""
            }${questionData.examType === "" ? "Exam Type,  " : ""}
          ${questionData.questionWeightage === "" ? "Question Weightage,  " : ""
            }${questionData.difficulty === "" ? "Question Difficulty,  " : ""} ${optionValid.includes(true) ? "Option,  " : ""
            }
          ${answerValid.includes(true) ? "Answer,  " : ""}${isNaN(questionData.questionWeightage)
              ? "Question Weightage value enter only numeric value ,  "
              : ""
            }
           field`,
          status: false,
        });
        window.scrollTo(0, 0);
        setConfirmBox(true);
        setTimeout(() => setConfirmBox(false), 3000);
        setOptionValid([]);
        setAnswerValid([]);
      }
    }
  };
  const handleChangeQuestionStatus = (e, questionId, index) => {
    const questionType = "MCQ";
    if (e.target.checked) {
      const questionRight = true;
      const token = localStorage.getItem("token")
      CourseService.changeQuestionsRights(
        questionId,
        questionType,
        questionRight,
        token
      ).then((res) => {
        // if (res.data) {
        //   getsubcourseOptions(filterMcq);
        // }
      });
    } else {
      const questionRight = false;
      CourseService.changeQuestionsRights(
        questionId,
        questionType,
        questionRight,
        token
      ).then((res) => {
        // if (res.data) {
        //   getsubcourseOptions(filterMcq);
        // }
      });
    }
    setExamQuestion(examQuestion => {
      let copyQuestions = [...examQuestion]
      copyQuestions[index]['questionApprove'] = e.target.checked
      return copyQuestions
    })
  };
  const handleSkill = (id) => {
    const data = {
      label: id.value,
      value: id.id,
    };
    setQuestionData({ ...questionData, cid: data });
  };

  const handleType = (id) => {
    setQuestionData({ ...questionData, type: id });
  };

  const handleExamType = (id) => {
    setQuestionData({ ...questionData, examType: id });
  };
  const handleCheckbox = (e, id) => {
    if (e.target.checked) {
      const findOption = questionData.Option.find((item) => item.Id === id);
      const findIndex = questionData.Answer.findIndex((item) => item.Id === id);
      if (findOption && findIndex === -1) {
        questionData.Answer.push(findOption);
        setAnswerArray([...answerArray, id]);
      }
    } else {
      const index = questionData.Answer.findIndex((object) => {
        return object.Id === id;
      });
      const optionindex = answerArray.findIndex((object) => {
        return object === id;
      });
      answerArray.splice(optionindex, 1);
      let newArr = questionData.Answer;
      newArr.splice(index, 1);
      setQuestionData({
        ...questionData,
        Answer: newArr,
      });
      setAnswerArray(answerArray);
    }
  };

  const handleFilters = (name, value) => {
    let filterQuestionValue = {};
    if (name === 'subCourseId') {
      if (value?.length > 0) {
        setFilterMcq({ [name]: value });
        filterQuestionValue = { [name]: value }
      } else {
        setFilterMcq({});
        filterQuestionValue = {}
        setExamTypes([])
        setActiveQuestion([])
        setDifficultyList([])
        setSubCourseTopics([])
      }
    } else if (name === 'Question') {
      if (value?.length > 0) {
        setFilterMcq({
          ...filterMcq,
          [name]: value,
        });
      } else {
        setFilterMcq((filterData) => {
          const copyFilterData = { ...filterData };
          delete copyFilterData[name];
          return copyFilterData;
        });
      }
      filterQuestionValue = {
        ...filterMcq, [name]: {
          label: value,
          value: { $regex: value, $options: "i" },
        }
      }
    } else {
      setFilterMcq({ ...filterMcq, [name]: value });
      filterQuestionValue = { ...filterMcq, [name]: value }
    }
    if (filterQuestionValue) {
      getsubcourseOptions({
        ...filterQuestionValue,
        'Question': searchQuestion ? {
          label: searchQuestion,
          value: { $regex: searchQuestion, $options: "i" },
        } : null
      })
    }
  }

  const handlePageChange = (pagenumber) => {
    getsubcourseOptions({
      ...filterMcq,
      'Question': searchQuestion ? {
        label: searchQuestion,
        value: { $regex: searchQuestion, $options: "i" },
      } : null
    }, pagenumber)
    window.scrollTo(0, 0);
  }

  const handleQuestionStatusBatchToggle = async (e) => {
    
    const queStatus = e.target.checked;
    const paginationSize = Math.ceil(mcqsCount / noOfMCQPerPage);
    const filterOptions = filterMcq;
    const token = localStorage.getItem('token');
    const questionType = "MCQ";

    setLoader(true);

    // mcqArr for statusChange
    let mcqStatusArr = [];

    let currPage = 1;
    // Traverse from page 1 to paginationSize
    while (currPage <= paginationSize) {

      await CourseService.getMCQQuestions(filterOptions, currPage, token)
        .then(async (res) => {

          if (res.data && res.data.filterQuestions) {
            mcqStatusArr.push(...res.data.filterQuestions);
          }

        }).catch(error => {
          handleCatchError(error)
        });
      currPage++;
    }

    // Toggle the status of each Question
    await mcqStatusArr.forEach(async (que) => {
      await CourseService.changeQuestionsRights(
        que._id,
        questionType,
        queStatus,
        token
      )
    })

    // Change the status Dropdown From Active to Inactive or vice-versa
    const dropdownValue = { label: !queStatus ? 'inActive' : 'Active', value: queStatus };

    handleFilters("questionApprove", dropdownValue);

    setLoader(false);
  }

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0 py-2">
            <li
              className={
                page.selected === 1
                  ? "breadcrumb-item active"
                  : "breadcrumb-item text-primary"
              }
              style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
              onClick={() => {
                setPage({ selected: 1 });
                setAnswerArray([]);
                setOptionValid([]);
                setOptionValid([]);
                setQuestionData({
                  cid: "",
                  type: "",
                  Question: "",
                  Option: [],
                  Answer: [],
                  examType: "",
                  questionWeightage: "",
                  questionTopic: "",
                  difficulty: "",
                });
              }}
            >
              Handle MCQ Questions
            </li>
            {page.selected === 2 ? (
              <li
                className={
                  page.selected === 2 ? "breadcrumb-item active" : "d-none"
                }
              >
                Update MCQ Questions
              </li>
            ) : null}
          </ol>
        </nav>
      </div>
      {loader &&
        <Loader loaderStatus={loader} message="Loading ....." />
      }
      <div className="container my-2 bg-white p-4" style={{ maxWidth: "90%" }}>
        <style>{css}</style>
        {confirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div
                  className={`alert ${message.status ? "alert-success" : "alert-danger"
                    }`}
                >
                  {message.msg}
                </div>
              </div>
            </div>
          </div>
        )}
        {page.selected === 1 && (
          <>
            <div className="mx-auto d-flex justify-content-center mt-2">
              <div className="me-5" style={{ width: "200px" }}>
                <label>Select Skill</label>
                <Select
                  options={subcourseOptions}
                  placeholder={"Select skill"}
                  value={filterMcq?.subCourseId}
                  isSearchable
                  isClearable
                  isMulti
                  onChange={(e) =>
                    handleFilters("subCourseId", e)
                  }
                />
              </div>
              <div className="me-5" style={{ width: "200px" }}>
                <label>Select examType</label>
                <Select
                  options={examTypes}
                  placeholder={"Select examType"}
                  value={filterMcq?.typeOfExam ? filterMcq.typeOfExam : null}
                  isSearchable
                  isClearable
                  isDisabled={examTypes?.length > 1 ? false : true}
                  onChange={(e) =>
                    handleFilters("typeOfExam", e)
                  }
                />
              </div>
              <div className="me-5" style={{ width: "200px" }}>
                <label>Select Difficulty</label>
                <Select
                  options={difficultyList}
                  placeholder={"Select Difficulty"}
                  value={filterMcq?.difficulty ? filterMcq.difficulty : null}
                  isSearchable
                  isMulti
                  isDisabled={difficultyList?.length > 1 ? false : true}
                  onChange={(e) =>
                    handleFilters("difficulty", e)
                  }
                />
              </div>

              <div className="me-5" style={{ width: "200px" }}>
                <label>Select subTopic</label>
                <Select
                  options={subCourseTopics}
                  placeholder={"Select topic"}
                  value={filterMcq?.questionTopic ? filterMcq.questionTopic : null}
                  isSearchable
                  isMulti
                  isDisabled={subCourseTopics?.length > 1 ? false : true}
                  onChange={(e) =>
                    handleFilters("questionTopic", e)
                  }
                />
              </div>
              <div className="me-5" style={{ width: "200px" }}>
                <label>Select status</label>
                <Select
                  options={activeQuestion}
                  placeholder={"Select status"}
                  value={filterMcq?.questionApprove ? filterMcq.questionApprove : null}
                  isSearchable
                  isClearable
                  isDisabled={activeQuestion?.length > 1 ? false : true}
                  onChange={(e) =>
                    handleFilters("questionApprove", e)
                  }
                />
              </div>
            </div>
            <div className="text-end my-2">
              <input
                type="text"
                className="form-control"
                name="Question"
                placeholder="Search question"
                value={searchQuestion}
                onChange={(e) => setSearchQuestion(e.target.value)}
              />
              <div className="d-flex align-items-center mt-1">
                <span className="flex-grow-1"></span>

                {questionDifficultyCount?.Easy > 0 && <p className="mx-2 badge rounded-pill bg-success text-dark h6">
                  Easy {questionDifficultyCount.Easy}
                </p>}
                {questionDifficultyCount?.Medium > 0 && <p className="mx-2 badge rounded-pill bg-info text-dark h6">
                  Medium {questionDifficultyCount.Medium}
                </p>}
                {questionDifficultyCount?.Hard > 0 && <p className="mx-2 badge rounded-pill bg-danger text-dark h6">
                  Hard {questionDifficultyCount.Hard}
                </p>}

                {/* Toggle All Question Active/ InActive Switch */}
                {filterMcq.questionApprove &&
                  <label className="d-flex">
                    Set All Question {filterMcq.questionApprove.value ? 'Inactive' : 'Active'}
                    <span className="form-check form-switch mx-2">
                      <ToolTip title={`${filterMcq.questionApprove.value ? 'Active' : 'InActive'} Question`}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          checked={filterMcq.questionApprove.value}
                          onChange={(e) => {
                            handleQuestionStatusBatchToggle(e);
                          }}
                        />
                      </ToolTip>
                    </span>
                  </label>}
              </div>

            </div>
            {examQuestion?.length > 0 ? <>
              <div className="d-flex justify-content-center mb-5">
                <Pagination
                  activePage={currentpage}
                  itemClass="page-item"
                  linkClass="page-link"
                  itemsCountPerPage={noOfMCQPerPage}
                  totalItemsCount={mcqsCount}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                /></div>
              <ol className="pt-3 ms-5">
                {examQuestion.map((Question, index) => {
                  return (
                    <>
                      <div className="row col-md-12 my-2">
                        <div className="col-md-1 ms-2">
                          <span className="text-primary fw-normal">({handleIndex(index + 1)}). Type</span>
                          {Question.Type === 0 ? (
                            <div> Radio</div>
                          ) : Question.Type === 1 ? (
                            <div>Checkbox</div>
                          ) : (
                            <div className="text-danger fw-normal ms-5">-</div>
                          )}
                        </div>
                        <div className="col-md-1 ms-2">
                          <span className="text-primary fw-normal">
                            SubCourse
                          </span>
                          {Question?.subCourseId ? (
                            <div className="text-break"> {Question.subCourseId.name}</div>
                          ) : (
                            <div className="text-danger fw-normal ms-5">-</div>
                          )}
                        </div>
                        <div className="col-md-1 ms-2">
                          <span className="text-primary fw-normal">examType</span>
                          {Question.typeOfExam ? (
                            <div> {Question.typeOfExam}</div>
                          ) : (
                            <div className="text-danger fw-normal ms-5">-</div>
                          )}
                        </div>
                        <div className="col-md-1 ms-2">
                          <span className="text-primary fw-normal">
                            Difficulty
                          </span>
                          {Question.difficulty ? (
                            <div> {difficultyLeval(Question.difficulty)}</div>
                          ) : (
                            <div className="text-danger fw-normal ms-5">-</div>
                          )}
                        </div>
                        <div className="col-md-1 ms-2">
                          <span className="text-primary fw-normal">
                            topicName
                          </span>
                          {Question.questionTopic ? (
                            <div className="text-break"> {Question.questionTopic}</div>
                          ) : (
                            <div className="text-danger fw-normal ms-5">-</div>
                          )}
                        </div>
                        <div className="col-md-1 ms-2">
                          <span className="text-primary fw-normal">
                            Weightage
                          </span>
                          {Question.questionWeightage ? (
                            <div> {Question.questionWeightage} Mark</div>
                          ) : (
                            <div>1 Mark</div>
                          )}
                        </div>
                        <div className="col-md-2 row">
                          <div className="text-primary fw-normal">
                            <div>
                              {handlecreatedAndUpdateby(Question.createdBy) && Question.createdAt
                                ? `${handlecreatedAndUpdateby(Question.createdBy)} on ${handleCreateUpadateDate(Question.createdAt)}`
                                : ''}
                            </div>
                          </div>

                        </div>
                        {Question.updatedBy && Question.updatedBy && (
                          <div className="col-md-2 row">
                            <div className="text-primary fw-normal">
                              <div>
                                {handlecreatedAndUpdateby(Question.updatedBy) && Question.updatedAt
                                  ? `${handlecreatedAndUpdateby(Question.updatedBy)} on ${handleCreateUpadateDate(Question.updatedAt)}`
                                  : ''}
                              </div>
                            </div>
                          </div>
                        )}

                        {/* <div className="col-md-2 text-primary fw-normal">
                          <span className="text-primary fw-normal">
                            {handlecreatedAndUpdateby(Question.updatedBy) && Question.updatedAt
                              ? `${handlecreatedAndUpdateby(Question.updatedBy)} on ${handleCreateUpadateDate(Question.updatedAt)}`
                              : ''}

                          </span>
                        </div> */}
                        <div className="col-md-2 row">
                          <div className="col-md-6">
                            <div className="d-flex">
                              {/* Active- InActive question */}
                              <div className="form-check form-switch ms-3">
                                {!Question.questionApprove ? (
                                  <ToolTip title="Active Question">
                                    <input
                                      className="form-check-input"
                                      type="checkbox"
                                      checked={Question?.questionApprove}
                                      onChange={(e) => {
                                        handleChangeQuestionStatus(e, Question._id, index);
                                      }}
                                    />
                                  </ToolTip>
                                ) :
                                  (
                                    <ToolTip title="InActive Question">
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={Question?.questionApprove}
                                        onChange={(e) => {
                                          handleChangeQuestionStatus(e, Question._id, index);
                                        }}
                                      />
                                    </ToolTip>
                                  )}

                              </div>
                              {/* edit question */}
                              <div>
                                <ToolTip title="Edit Question">
                                  <button
                                    className="btn btn-warning px-2 py-1 mx-1 fs-8"
                                    onClick={() => {
                                      setPage({ selected: 2 });
                                      setQuestionId(Question._id);
                                      setVal(Question.Question);
                                      if (
                                        Question.Option &&
                                        Question.Option.length > 2
                                      ) {
                                        setAddDataA(Question.Option[0].Value);
                                        setAddDataB(Question.Option[1].Value);
                                        setAddDataC(Question.Option[2].Value);
                                        setAddDataD(Question.Option[3].Value);
                                      } else {
                                        setAddDataA(Question.Option[0].Value);
                                        setAddDataB(Question.Option[1].Value);
                                      }
                                      if (Question.Answer?.length > 0) {
                                        Question.Answer.forEach((obj) => {
                                          answerArray.push(obj.Id);
                                        });
                                      }
                                      setQuestionData((d) => ({
                                        ...d,
                                        cid: {
                                          value: Question.subCourseId._id,
                                          label: Question.subCourseId.name,
                                        },
                                        examType: {
                                          value: Question.typeOfExam,
                                          label: Question.typeOfExam,
                                        },
                                        questionTopic: {
                                          value: Question.questionTopic,
                                          label: Question.questionTopic,
                                        },
                                        difficulty: Question.difficulty
                                          ? Question.difficulty
                                          : "",
                                        type: {
                                          value: Question.Type,
                                          label:
                                            Question.Type === 0
                                              ? "Radio"
                                              : "Checkbox",
                                        },
                                        Question: Question.Question,
                                        Option: Question.Option,
                                        Answer: Question.Answer,
                                        questionWeightage:
                                          Question.questionWeightage
                                            ? Question.questionWeightage
                                            : 1,
                                      }));
                                    }}
                                  >
                                    <FaPen className="color-white text-white" />
                                  </button>
                                </ToolTip>

                              </div>
                              {/* Delete question */}
                              <div>
                                <ToolTip title="Delete question">
                                  <button
                                    className="btn btn-secondary px-2 py-1 mx-1"
                                    id={Question._id}
                                    onClick={() => {
                                      setQuestionId(Question._id);
                                      setOpenModal(true);
                                    }}
                                  >
                                    <FaTimes className="color-white text-white" />
                                  </button>
                                </ToolTip>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="mx-2"
                        dangerouslySetInnerHTML={{
                          __html: Question.Question,
                        }}
                      ></div>
                      <ol type="A">
                        {Question.Option.map((option) => {
                          // char = char + 1;
                          return (
                            <div className="d-flex">
                              <li
                                dangerouslySetInnerHTML={{
                                  __html: `${option.Value}`,
                                }}
                                className={`
                            ${Question.Answer.find(
                                  (item) => item.Id == option.Id
                                )
                                    ? "text-success"
                                    : ""
                                  }`}
                              ></li>
                              <p className="mx-1">
                                {Question.Answer.find(
                                  (item) => item.Id == option.Id
                                ) ? (
                                  <IoMdCheckmarkCircleOutline
                                    className={`
                              ${Question.Answer.find(
                                      (item) => item.Id == option.Id
                                    )
                                        ? "text-success"
                                        : ""
                                      }`}
                                  />
                                ) : (
                                  <></>
                                )}
                              </p>
                            </div>
                          );
                        })}
                      </ol>
                    </>
                  );
                })}
              </ol>
              <div className="d-flex justify-content-center mb-5">
                <Pagination
                  activePage={currentpage}
                  itemClass="page-item"
                  linkClass="page-link"
                  itemsCountPerPage={noOfMCQPerPage}
                  totalItemsCount={mcqsCount}
                  pageRangeDisplayed={5}
                  onChange={(e) => handlePageChange(e)}
                /></div></>
              : <div className="text-center text-danger my-5 h4">No records found</div>
            }
          </>
        )}
        {
          page?.selected === 2 && <div className="form px-lg-5">
            <h5 className="color-dgreen text-uppercase text-center heading">
              Edit MCQ
            </h5>
            <form
              onSubmit={handleEdit}
              className="px-lg-5 py-3"
              method="post"
              id="myform"
            >
              <div className="col position-relative mb-3">
                <label htmlFor="cname" className="form-label">
                  SubCourse Name
                </label>
                <Select
                  options={subcourseOptions}
                  value={questionData.cid}
                  placeholder={"Please Select Skill Name"}
                  isSearchable
                  onChange={handleSkill}
                />
              </div>
              <div className="col position-relative mb-3">
                <label htmlFor="cname" className="form-label">
                  Exam Type
                </label>
                <Select
                  options={examTypes.slice(1, 3)}
                  value={questionData.examType}
                  placeholder={"Please Select Type "}
                  isSearchable
                  onChange={handleExamType}
                />
              </div>
              <div className="col position-relative mb-3">
                <label htmlFor="cname" className="form-label">
                  Question Type
                </label>
                <Select
                  options={
                    questionData.Option.length >= 3
                      ? questionOption
                      : questionOption.filter((item) => item.value !== 1)
                  }
                  value={questionData.type}
                  placeholder={"Please Select Type "}
                  isSearchable
                  onChange={handleType}
                />
              </div>
              <div className="col position-relative mb-3">
                <label htmlFor="cname" className="form-label">
                  Question Weightage
                </label>
                <input
                  className="form-control"
                  name="weightage"
                  type="text"
                  inputmode="numeric"
                  placeholder="Enter Exam Time in Minutes (ex : 15)"
                  value={questionData.questionWeightage}
                  onChange={(e) =>
                    setQuestionData((d) => ({
                      ...d,
                      questionWeightage: e.target.value,
                    }))
                  }
                />
              </div>
              <div className="col position-relative mb-3">
                <label htmlFor="difficulty" className="form-label">
                  Difficulty
                </label>
                <select
                  className="form-select"
                  id="difficulty"
                  value={questionData.difficulty}
                  name="difficulty"
                  onChange={(e) =>
                    setQuestionData((d) => ({
                      ...d,
                      difficulty: e.target.value,
                    }))
                  }
                >
                  <option value="">select type</option>
                  {difficultyList
                    .filter((item1) => item1.value !== 0)
                    .map((item, index) => (
                      <option value={item.value}>{item.label}</option>
                    ))}
                </select>
              </div>
              <div className="col position-relative mb-3">
                {!toggleSelectDepartment ? (
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Select Question Topic
                  </label>
                ) : (
                  <label htmlFor="exampleInputPassword1" className="form-label">
                    Add sub topic name
                  </label>
                )}
                <div className="row">
                  <div
                    className={`col ${!toggleSelectDepartment ? "col-11" : "col-12"
                      }`}
                    style={{ zIndex: "10" }}
                  >
                    {!toggleSelectDepartment ? (
                      <SortableSelect
                        axis="xy"
                        // onSortEnd={onSortEnd}
                        distance={4}
                        getHelperDimensions={({ node }) =>
                          node.getBoundingClientRect()
                        }
                        options={subCourseTopics.filter(
                          (item) => item.value !== ''
                        )}
                        value={questionData.questionTopic}
                        onChange={handleSelectTopic}
                        closeMenuOnSelect={true}
                      />
                    ) : (
                      <div className="row">
                        <div className="col col-10">
                          <input
                            value={addSubTopicName.topicName}
                            onChange={(e) => {
                              setAddSubTopicName({
                                ...addSubTopicName,
                                topicName: [e.target.value],
                              });
                            }}
                            type="text"
                            class="form-control mt-1"
                            placeholder="Add topic name"
                          />
                        </div>
                        <div className="col col-2">
                          <button
                            type="button"
                            className="btn btn-sm mt-1 ms-1 btn-success"
                            onClick={handleAddTopic}
                          >
                            <VscCheck />
                          </button>
                          <button
                            className="btn btn-sm mt-1 ms-1 btn-light"
                            onClick={() =>
                              setToggleSelectDepartment(!toggleSelectDepartment)
                            }
                          >
                            <FcCancel />
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                  <div className="col col-1">
                    {!toggleSelectDepartment && (
                      <button
                        className="btn btn-primary my-auto btn-small"
                        onClick={() =>
                          setToggleSelectDepartment(!toggleSelectDepartment)
                        }
                      >
                        +
                      </button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col position-relative mb-3">
                <label htmlFor="cname" className="form-label">
                  Question
                </label>
                <div className="App">
                  <CKEditor
                    editor={ClassicEditor}
                    data={addData}
                    onChange={handleChange}
                    onBlur={(event, editor) => {
                      // console.log("Blur.", editor);
                    }}
                    onFocus={(event, editor) => {
                      // console.log("Focus.", editor);
                    }}
                  />
                </div>
              </div>
              <div className="mt-3">
                {addDataA !== "" && (
                  <>
                    <label htmlFor="xlsx" className="form-label">
                      Question Option
                    </label>
                    <div className="App">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          name="hobbies"
                          checked={answerArray.includes("A")}
                          onClick={(e) => handleCheckbox(e, "A")}
                        />
                        <label className="form-check-label">
                          This checkbox cheked to set answer
                        </label>
                      </div>
                      <CKEditor
                        editor={ClassicEditor}
                        data={addDataA}
                        onChange={handleChangeA}
                        onBlur={(event, editor) => {
                          // console.log("Blur.", editor);
                        }}
                        onFocus={(event, editor) => {
                          // console.log("Focus.", editor);
                        }}
                      />
                    </div>
                  </>
                )}
                {addDataB !== "" && (
                  <div className="App mt-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="hobbies"
                        checked={answerArray.includes("B")}
                        onClick={(e) => handleCheckbox(e, "B")}
                      />
                      <label className="form-check-label">
                        This checkbox cheked to set answer
                      </label>
                    </div>
                    <CKEditor
                      editor={ClassicEditor}
                      data={addDataB}
                      onChange={handleChangeB}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor);
                      }}
                    />
                  </div>
                )}
                {addDataC !== "" && (
                  <div className="App mt-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="hobbies"
                        checked={answerArray.includes("C")}
                        onClick={(e) => handleCheckbox(e, "C")}
                      />
                      <label className="form-check-label">
                        This checkbox cheked to set answer
                      </label>
                    </div>
                    <CKEditor
                      editor={ClassicEditor}
                      data={addDataC}
                      onChange={handleChangeC}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor);
                      }}
                    />
                  </div>
                )}
                {addDataD !== "" && (
                  <div className="App mt-2">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name="hobbies"
                        checked={answerArray.includes("D")}
                        onClick={(e) => handleCheckbox(e, "D")}
                      />
                      <label className="form-check-label">
                        This checkbox cheked to set answer
                      </label>
                    </div>
                    <CKEditor
                      editor={ClassicEditor}
                      data={addDataD}
                      onChange={handleChangeD}
                      onBlur={(event, editor) => {
                        // console.log("Blur.", editor);
                      }}
                      onFocus={(event, editor) => {
                        // console.log("Focus.", editor);
                      }}
                    />
                  </div>
                )}
              </div>
              <button type="submit" className="btn btn-submit mt-2">
                Update Questions
              </button>
              <button
                onClick={() => {
                  window.scrollTo(0, 0);
                  setPage({ selected: 1 });
                  setMessage({ status: false, msg: "" });
                  getsubcourseOptions(filterMcq);
                  setAnswerArray([]);
                  setOptionValid([]);
                  setOptionValid([]);
                  setQuestionData({
                    cid: "",
                    type: "",
                    Question: "",
                    Option: [],
                    Answer: [],
                    examType: "",
                    questionWeightage: "",
                    questionTopic: "",
                    difficulty: "",
                  });
                }}
                className="btn btn-secondary ms-3"
              >
                Cancel
              </button>
            </form>
          </div>
        }
        <div>
          <Modal
            show={openModal}
            onHide={closeModal}
            style={{ marginTop: "15%" }}
            aria-labelledby="ModalHeader"
          >
            <Modal.Body>
              <h5>Are you sure you want to delete this question ?</h5>
            </Modal.Body>
            <Modal.Footer class="text-center mb-2">
              <button className="btn btn-primary me-3" onClick={handleDelete}>
                Yes
              </button>
              <button
                className="btn btn-primary"
                onClick={(e) => setOpenModal(false)}
              >
                No
              </button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </>
  );
}
