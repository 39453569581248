import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import TestCaseService from "../../services/TestCaseService";
export default function SpecListing() {
  const [specList, setSpecList] = useState([])
  const [deleteSuccess, setDeleteSuccess] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    // Show alert when deleteSuccess state changes
    if (deleteSuccess) {
      alert('Data deleted successfully');
      setDeleteSuccess(false); // Reset the deleteSuccess state
    }
  }, [deleteSuccess])

  const fetchData = () => {
    const authToken = localStorage.getItem("token")
    if (authToken) {
      TestCaseService.getSpecs(authToken).then(
        res => {
          console.log(res)
          if (res.data) setSpecList(res.data)
        }
      ).catch(err => console.log(err))
    }
    else {
      navigate('/login')
    }
  }

  const handleDelete = (id, specName) => {
    // console.log(id);
    let authToken = localStorage.getItem("token")
    const isConfirmed = window.confirm(`Are you sure you want to delete ${specName}?`);
    if (isConfirmed) {
      TestCaseService.deleteSpec(id, authToken).then(res => {
        if (res.data) {
          fetchData();
          setDeleteSuccess(true);
        }
      })
    }
  }
  return <div>
    <div className="mx-5 my-5">
      <table className="table">
        <thead className="fw-bold">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Test Case SpecName</th>
            {/* <th scope>ActionName</th>
            <th scope>EvaluateName</th> */}
            <th scope="col">Action</th>


          </tr>
        </thead>
        <tbody>
          {specList?.map((testCaseSpec, index) => {
            return (
              <tr>
                <td>{index + 1}</td>
                <td>{testCaseSpec?.specName}</td>
                {/* <td>{testCaseSpec.actions[0].label}</td>
                <td>{testCaseSpec.evaluate[0].label}</td> */}
                <td>
                  <button className="btn btn-success w-30" onClick={() => { navigate(`/admin/manage-spec/updatespec/${testCaseSpec._id}`) }}>Edit</button>
                  <button className="btn btn-danger ms-1" onClick={() => handleDelete(testCaseSpec._id, testCaseSpec.specName)}>delete</button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  </div>;
}
