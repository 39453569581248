import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import CourseService from "../../services/CourseService";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/Loader";

export default function Exam(props) {
  const [showSchedule, setShowSchedule] = useState({});
  const [examId, setExamId] = useState("");
  const [examCode, setExamCode] = useState("");
  const [examStatus, setExamStatus] = useState({ msg: "", show: false });
  const [loader, setLoader] = useState(false)
  const navigate = useNavigate();
  const token = localStorage.getItem("token");

  const handleMessage = (message, status) => {
    setExamStatus({ msg: message, show: status })
  }

  const handleCatchError = (err) => {
    setLoader(false)
    if (err["response"]) {
      if (err["response"].status === 401) {
        if (err["response"]?.data?.message === "You are not authorized to view this content") {
          handleMessage(err["response"].data.message, false)
        } else {
          localStorage.removeItem("token");
          navigate("/login");
        }
      } else if (err["response"].status === 404) {
        handleMessage(err["response"]?.data?.message, false)
      } else {
        localStorage.removeItem("token");
        navigate("/login");
      }
    }
  }

  const handleSubmit = () => {
    if (examId !== "") {
      setLoader(true)
      const userId = jwt_decode(token).id;
      CourseService.getFindUserExamSchedule(
        examId,
        userId,
        token
      ).then(res => {
        if (res.data.status) {
          setLoader(false)
          setShowSchedule(res.data.showSchedule);
          setExamCode(res.data.examCode);
          setExamStatus({ msg: res.data.message, show: true });
        }
      }).catch(error => handleCatchError(error));
    } else {
      handleMessage("Please ExamId to add", false)
    }
  }

  const RemoveLocalStorage = () => {
    localStorage.removeItem("milliSecond");
    localStorage.removeItem("startNumber");
    localStorage.removeItem("endNumber");
    localStorage.removeItem("examQuestionId");
    localStorage.removeItem("examTable");
    localStorage.removeItem("studentData");
    localStorage.removeItem("selectedMcq");
    localStorage.removeItem("milliSecond");
    localStorage.removeItem("examId");
    localStorage.removeItem("courseId");
    localStorage.removeItem("batchId");
    localStorage.removeItem("examQuestionLength");
  };

  const handleTimeAndQuestionList = (examList, type) => {
    const timeAndQuestionList = examList.reduce((count, skills) => {
      return count += skills[type]
    }, 0)
    return timeAndQuestionList
  }

  return (
    <div class="mt-5 container">
      {loader &&
        <Loader loaderStatus={loader} message="Loading ....." />
      }
      <h1 class="pt-5 text-center">Hello Trainees, Enter Exam Code to Start your Exam</h1>
      <div class="d-flex justify-content-center">
        <input
          className="w-25 py-1"
          type="search"
          placeholder="Please Enter Exam Id"
          onChange={(e) => setExamId(e.target.value)}
          value={examId}
        />
        <div class="ms-2">
          <button onClick={handleSubmit} class="btn btn-success">
            Submit
          </button>
        </div>
      </div>
      {examStatus.msg !== "" && examStatus.show === false && (
        <div class="text-center text-danger mt-3 fs-3">{examStatus.msg}</div>
      )}
      {examStatus.msg !== "" && examStatus.show === true && (
        <div className="justify-content-center d-flex mt-3">
          {showSchedule.skillId ? (
            <div className="me-3">skillName : {showSchedule.skillId.name}</div>
          ) : (
            <div className="me-3">skillName : {showSchedule.map((skills) => {
              return <span> {skills.skill.name} {showSchedule > 1 && <>({skills.pickQuestions},)</>}</span>
            })}</div>
          )}
          {showSchedule?.totalQuestion ? (
            <div className="me-3">
              Total Questions : {showSchedule.totalQuestion}
            </div>
          ) : (
            <div className="me-3">
              Total Questions : {handleTimeAndQuestionList(showSchedule, 'pickQuestions')}
            </div>
          )}
          {showSchedule?.totalTime ? (
            <div className="me-3">
              Exam Duration : {showSchedule.totalTime} Minutes
            </div>
          ) : (
            <div className="me-3">
              Exam Duration : {handleTimeAndQuestionList(showSchedule, 'totalTimes')} Minutes
            </div>
          )}
          <div className="me-3">
            <a
              onClick={(e) => {
                RemoveLocalStorage();
                navigate(`/MCQtestExam/${examCode}`);
                setExamId("");
                setExamStatus("");
                setExamCode("");
              }}
              className="link-success"
            >
              Click Here to start your exam
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
