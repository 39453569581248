import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import { FaChevronRight, FaChevronLeft } from "react-icons/fa";

const UserVideoProgress = () => {
  const { userId } = useParams();
  const { state } = useLocation();
  const token = localStorage.getItem("token");

  const [coursevideos, setCoursevideos] = useState([]);
  const [cnames, setCnames] = useState([]);
  const [coursename, setCourseName] = useState("");
  const [progress, setProgress] = useState([]);
  const [userprogress, setUserprogress] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [pageno, setPageno] = useState(1);
  let count = 0;
  let videocount = 0;

  const getAllProgress = async (userId) => {
    return CourseService.getallProgress(token, userId);
  };

  const getVideos = async (name) => {
    return CourseService.getVideos(name, token);
  };
  useEffect(() => {
    if (!userId) {
      return;
    }
    setCnames(state.courses);
    setCourseName(state._id);
    setLoading(true);

    Promise.all([getAllProgress(userId), getVideos(state.courses[0].name)])
      .then((values) => {
        const [{ data: progress }, { data: videos }] = values;
        const items = [];

        progress &&
          progress.length > 0 &&
          progress.map((item) => {
            if (
              item.uid != null &&
              item.uid._id == state._id &&
              item.cname == state.courses[0].name &&
              item.uid.role == "Student"
            ) {
              items.push(item);
            }
          });

        const data = [];
        videos &&
          videos.length > 0 &&
          videos.map((item) => {
            item.videos.map(async (file) => {
              data.push(file);
            });
          });

        setProgress(items);
        setUserprogress(items);
        setLoading(false);
        setCoursevideos(data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const handleVideoChange = (course) => {
    setPageno(1);
    setLoading(true);

    Promise.all([getAllProgress(course._id), getVideos(course.name)])
      .then((values) => {
        const [{ data: progress }, { data: videos }] = values;
        const items = [];

        progress &&
          progress.length > 0 &&
          progress.map((item) => {
            if (
              item.uid != null &&
              item.uid._id == coursename &&
              item.cname == course.name &&
              item.uid.role == "Student"
            ) {
              items.push(item);
            }
          });

        const data = [];
        videos &&
          videos.length > 0 &&
          videos.map((item) => {
            item.videos.map(async (file) => {
              data.push(file);
            });
          });

        setProgress(items);
        setUserprogress(items);
        setLoading(false);
        setCoursevideos(data);
      })
      .catch((err) => {
        setProgress([]);
        setUserprogress([]);
        setLoading(false);
        setCoursevideos([]);
      });
  };

  const handlecount = async (countt) => {
    setProgress(
      userprogress.map((item, index) => {
        if (index >= countt * 10 - 10 && index < countt * 10) {
          return item;
        }
      })
    );
    setPageno(countt);
  };
  return (
    <>
      {userprogress &&
        userprogress.map((userProgress, index1) => {
          return coursevideos.map((video, index2) => {
            if (
              userProgress !== undefined &&
              // count < pageno * 10 &&
              video.id === userProgress.id
            ) {
              count++;
            }
          });
        })}
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0">
            <li
              className={"breadcrumb-item text-primary"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/admin/user/video-progress");
              }}
            >
              Video Progress
            </li>
            <li className={"breadcrumb-item active"}>{state.username}</li>
          </ol>
        </nav>
      </div>
      <div className="container p-2 px-3 mb-5">
        <div className="form px-lg-1 pt-3">
          <div className="row g-4  py-4">
            {cnames.map((item, index) => {
              var count = 0;
              return (
                <>
                  <div className="col">
                    <div
                      className="card pt-4 pb-2 px-4 text-center d-flex justify-content-center align-items-center"
                      onClick={() => {
                        setLoading(true);
                        setProgress([]);
                        handleVideoChange(item);
                      }}
                    >
                      <div
                        className={
                          "rounded-pill d-flex justify-content-center align-items-center text-capitalize text-white clr" +
                          (Math.floor(Math.random() * 7) + 1)
                        }
                        style={{
                          height: "3rem",
                          width: "3rem",
                          fontSize: "1.6rem",
                          // marginLeft:"50px"
                        }}
                      >
                        {item.name.charAt(0).match(/[a-zA-Z]/g)
                          ? item.name.charAt(0)
                          : item.name.charAt(1)}
                      </div>
                      <h6 className="card-title color-dgreen pt-3">
                        {item.name}
                      </h6>
                      <div className="text-center w-100 p-0">
                        <p
                          className="card-text color-dback"
                          style={{
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            display: "-webkit-box",
                            WebkitLineClamp: "2",
                            WebkitBoxOrient: "vertical",
                          }}
                        >
                          {}
                        </p>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}

            <div
              className="container my-5"
              style={{ height: "90vh", marginTop: "50px" }}
            >
              {loading && (
                <div className="formloader">
                  <div className="row text-center">
                    <div className="col-12">
                      <img src={formloader} alt="" height="100" />
                    </div>
                    <div className="col-12 text-white h4">Please Wait...</div>
                  </div>
                </div>
              )}
              {!loading && (!progress || progress.length === 0) ? (
                <h2 className="m-4 text-center">No Progress yet.</h2>
              ) : (
                <>
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Video Name</th>
                        <th scope="col">Course</th>
                        <th scope="col">Progress</th>
                      </tr>
                    </thead>
                    <tbody>
                      {progress &&
                        progress.map((userProgress, index1) => {
                          return coursevideos.map((video, index2) => {
                            if (
                              userProgress !== undefined &&
                              videocount < 10 &&
                              video.id == userProgress.id
                            ) {
                              videocount++;
                              return (
                                <tr key={videocount}>
                                  <th scope="row">
                                    {10 * (pageno - 1) + videocount}
                                  </th>
                                  <td>
                                    {/* {video.filename
                                          .split("-")
                                          .slice(2, 7)
                                          .join(" ")
                                          .replace(".m3u8", "")} */}
                                    {video.filename
                                      .split("-")
                                      .slice(1, 20)
                                      .join(" ")
                                      .replace(".m3u8", "")
                                      .replace(".ir", "")
                                      .replace(".ir_", "")
                                      .replace(/[^\D]/g, "")
                                      .replace(/^_/g, " ")
                                      .replace(/^./g, " ")
                                      .replace("-", "")
                                      .replace(/^[a-zA-Z]+_/g, "")}
                                  </td>
                                  <td>{userProgress.cname}</td>
                                  <td>
                                    <div className="progress">
                                      <div
                                        className="progress-bar progress-bar-animated"
                                        role="progressbar"
                                        aria-valuenow="75"
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                        style={{
                                          width: `${userProgress.percentage}%`,
                                        }}
                                      >
                                        {userProgress.percentage} %
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              );
                            }
                          });
                        })}
                    </tbody>
                  </table>

                  <div className="col-lg-12 d-flex justify-content-center position-static">
                    <nav className="mt-4 p-0 pagefooter">
                      <ul className="pagination row g-1" id="pageno">
                        <li className="page-item col-auto">
                          <button
                            className="btn page-link px-3 py-2 shadow-none"
                            disabled={pageno === 1 ? true : false}
                            onClick={() => {
                              handlecount(pageno - 1);
                            }}
                          >
                            <FaChevronLeft className="text-muted" />
                          </button>
                        </li>
                        {(() => {
                          const element = [];
                          for (
                            let index = 1;
                            index <= Math.ceil(count / 10);
                            index++
                          ) {
                            element.push(
                              <li className="page-item col-auto">
                                <button
                                  className={
                                    pageno === index
                                      ? "page-link rounded-pill px-3 py-2 active bg-primary text-white shadow-none"
                                      : "page-link rounded-pill border border-primary px-3 py-2 "
                                  }
                                  onClick={() => {
                                    handlecount(index);
                                  }}
                                >
                                  {index}
                                </button>
                              </li>
                            );
                          }
                          return element;
                        })()}
                        <li className="page-item col-auto">
                          <button
                            className="btn page-link px-3 py-2 shadow-none"
                            disabled={
                              pageno === Math.ceil(count / 10) ? true : false
                            }
                            onClick={() => {
                              handlecount(pageno + 1);
                            }}
                          >
                            <FaChevronRight className="text-muted" />
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserVideoProgress;
