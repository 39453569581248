import React, { useEffect, useState } from "react";
import { FaSyncAlt } from "react-icons/fa";
import formloader from "../../images/formloading.svg";
import LoginService from "../../services/LoginService";
import randomCrypto from "crypto-random-string";
import { useNavigate } from "react-router-dom";

const AddUser = () => {
  const [user, setUser] = useState({
    _id: 0,
    username: "",
    email: "",
    password: "",
    approved: true,
    empId: "",
    fname: "",
    lname: "",
    mname: "",
    tenthMarks: null,
    twelfthMarks: null,
    mattlScore: null,
    isMail: false,
    githubUserName: ""
  });
  const [userErrors, setUserErrors] = useState({
    username: "",
    email: "",
    password: "",
    empId: "",
    fname: "",
    lname: "",
    mname: "",
  });

  const [randomPassword, setRandomPassword] = useState(
    randomCrypto({ length: 10, type: "alphanumeric" })
  );
  const [loading, setLoading] = useState(false);
  const [msg, setMsg] = useState({ success: true, message: "" });
  const [confirmBox, setConfirmBox] = useState(false);
  const [emailMsg, setEmailMsg] = useState("");

  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  const valdations = () => {
    let flag = true;
    if (user.username.length < 3 || user.username.length > 40) {
      setUserErrors(userErrors => ({
        ...userErrors,
        username: "The username must be between 3 and 40 characters long.",
      }));
      flag = false
    } else {
      setUserErrors(userErrors => ({
        ...userErrors,
        username: "",
      }));
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(user.email)) {
      setUserErrors(userErrors => ({
        ...userErrors,
        email: "Please enter a valid email address",
      }));
      flag = false
    } else {
      setUserErrors(userErrors => ({
        ...userErrors,
        email: "",
      }));
    }
    if (user.fname.length < 3 || user.fname.length > 40) {
      setUserErrors(userErrors => ({
        ...userErrors,
        fname: "The first name must be between 3 and 40 characters long",
      }));
      flag = false
    } else {
      setUserErrors(userErrors => ({
        ...userErrors,
        fname: "",
      }));
    }
    if (user.lname.length < 3 || user.lname.length > 40) {
      setUserErrors(userErrors => ({
        ...userErrors,
        lname: "The last name must be between 3 and 40 characters long",
      }));
      flag = false
    } else {
      setUserErrors(userErrors => ({
        ...userErrors,
        lname: "",
      }));
    }
    if (user.mname.length < 3 || user.mname.length > 40) {
      setUserErrors(userErrors => ({
        ...userErrors,
        mname: "The middle name must be between 3 and 40 characters long",
      }));
      flag = false
    } else {
      setUserErrors(userErrors => ({
        ...userErrors,
        mname: "",
      }));
    }
    if (user.empId.length < 4) {
      setUserErrors(userErrors => ({
        ...userErrors,
        empId: "Employee Id length should be 4 digits",
      }));
      flag = false
    } else {
      setUserErrors(userErrors => ({
        ...userErrors,
        empId: "",
      }));
    }
    if (user.password.length < 8) {
      setUserErrors(userErrors => ({
        ...userErrors,
        password: "The password must be at least 8 characters long",
      }));
      flag = false
    } else {
      setUserErrors(userErrors => ({
        ...userErrors,
        password: "",
      }));
    }
    return flag
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!token) {
      navigate("/login");
    }
    else {
      if (valdations()) {
        setLoading(true);
        LoginService.postUser(user, token)
          .then((res) => {
            if (res.data) {
              setMsg({ message: res.data.msg, success: true });
              setConfirmBox(true);
            }
          })
          .catch((ex) => {
            setMsg({ message: ex.response.data.error, success: false });
            setConfirmBox(true);
          });
        setUser({
          username: "",
          email: "",
          password: "",
          approved: true,
          empId: "",
          fname: "",
          tenthMarks: null,
          twelfthMarks: null,
          mattlScore: null,
          isMail: false,
          lname: "",
          mname: "",
          githubUserName: ""
        });
        document.getElementById("myform").reset();
        setLoading(false);
      }
      else {
        console.log("error");

      }
    }
    {
      setTimeout(() => setConfirmBox(false), 3000);
    }
  };
  const handleEmailChange = (e) => {
    const { value } = e.target;
    if (value.length >= 50) {
      setEmailMsg("Email must be less than 50 characters.")
    } else setEmailMsg("");
    setUser((d) => ({ ...d, email: e.target.value }))
  }
  return (
    <>
      {loading && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-12">
              <img src={formloader} alt="" height="100" />
            </div>
            <div className="col-12 text-white h4">Uploading user...</div>
          </div>
        </div>
      )}
      {confirmBox && (
        <div className="formloade">
          <div className="row text-center">
            <div className="col-6 w-100 confirmbox">
              <div
                className={`alert ${msg.success ? "alert-success" : "alert-danger"
                  }`}
              >
                {msg.message}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="form px-lg-5">
        <div className="bg-white m-0 px-3">
          <form
            onSubmit={handleSubmit}
            className="px-lg-5 py-5"
            method="post"
            id="myform"
          >
            <div className="row">
              <div className="col-lg">
                <label htmlFor="username" className="form-label">
                  Username
                </label>
                <input
                  type="text"
                  name="username"
                  id="username"
                  value={user.username}
                  className="form-control"
                  onChange={(e) =>
                    setUser((d) => ({ ...d, username: e.target.value }))
                  }
                />
                {userErrors.username !== "" && (
                  <span className="text-danger d-block my-2">
                    {userErrors.username}
                  </span>
                )}
              </div>
              <div className="col-lg">
                <label htmlFor="email" className="form-label">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={user.email}
                  className="form-control"
                  maxLength={50}
                  onChange={handleEmailChange}
                />
                {userErrors.email !== "" && (
                  <span className="text-danger d-block my-2">
                    {userErrors.email}
                  </span>
                )}
                {emailMsg.length > 0 ? (<p className="alert alert-danger">{emailMsg}</p>) : ""}
              </div>
            </div>
            <div className="row">
              <div className="col col-6 my-2">
                <label htmlFor="fname" className="form-label">
                  First name
                </label>
                <input
                  type="text"
                  name="fname"
                  id="fname"
                  className="form-control"
                  onChange={(e) =>
                    setUser((d) => ({ ...d, fname: e.target.value }))
                  }
                  value={user.fname}
                />
                {userErrors.fname !== "" && (
                  <span className="text-danger d-block my-2">
                    {userErrors.fname}
                  </span>
                )}
              </div>
              <div className="col col-6 my-2">
                <label htmlFor="mname" className="form-label">
                  Middle name
                </label>
                <input
                  type="text"
                  name="mname"
                  id="mname"
                  className="form-control"
                  onChange={(e) =>
                    setUser((d) => ({ ...d, mname: e.target.value }))
                  }
                  value={user.mname}
                />
                {userErrors.mname !== "" && (
                  <span className="text-danger d-block my-2">
                    {userErrors.mname}
                  </span>
                )}
              </div>
              <div className="col col-6 my-2">
                <label htmlFor="lname" className="form-label">
                  Last name
                </label>
                <input
                  type="text"
                  name="lname"
                  id="lname"
                  className="form-control"
                  onChange={(e) =>
                    setUser((d) => ({ ...d, lname: e.target.value }))
                  }
                  value={user.lname}
                />
                {userErrors.lname !== "" && (
                  <span className="text-danger d-block my-2">
                    {userErrors.lname}
                  </span>
                )}
              </div>
              <div className="col col-6 my-2">
                <label htmlFor="empId" className="form-label">
                  Employee Id
                </label>
                <input
                  type="text"
                  name="empId"
                  id="empId"
                  value={user.empId}
                  className="form-control"
                  onChange={(e) =>
                    setUser((d) => ({ ...d, empId: e.target.value }))
                  }
                />
                {userErrors.empId !== "" && (
                  <span className="text-danger d-block my-2">
                    {userErrors.empId}
                  </span>
                )}
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  name="password"
                  id="password"
                  value={user.password}
                  className="form-control"
                  onChange={(e) =>
                    setUser((d) => ({ ...d, password: e.target.value }))
                  }
                />
                {userErrors.password !== "" && (
                  <span className="text-danger d-block my-2">
                    {userErrors.password}
                  </span>
                )}
              </div>
              <div className="col-lg">
                <label htmlFor="password" className="form-label">
                  Random Password
                </label>
                <div className="input-group">
                  <input
                    type="text"
                    value={randomPassword}
                    className="form-control"
                    disabled
                  />
                  <span
                    className="input-group-text btn bg-green text-white"
                    id="basic-addon2"
                    onClick={() => {
                      setRandomPassword(
                        randomCrypto({ length: 10, type: "alphanumeric" })
                      );
                    }}
                  >
                    <FaSyncAlt />
                  </span>
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg">
                <label htmlFor="Tenth_Mark" className="form-label">
                  Tenth Mark
                </label>
                <input
                  type="number"
                  inputmode="numeric"
                  min="0"
                  name="Tenth_Mark"
                  id="Tenth_Mark"
                  className="form-control"
                  onChange={(e) =>
                    setUser((d) => ({
                      ...d,
                      tenthMarks: e.target.value,
                    }))
                  }
                  value={user.tenthMarks}
                />
              </div>
              <div className="col-lg">
                <label htmlFor="Twelfth_Marks" className="form-label">
                  Twelveth Marks
                </label>
                <input
                  type="number"
                  inputmode="numeric"
                  min="0"
                  name="Twelfth_Marks"
                  id="Twelfth_Marks"
                  className="form-control"
                  onChange={(e) =>
                    setUser((d) => ({
                      ...d,
                      twelfthMarks: e.target.value,
                    }))
                  }
                  value={user.twelfthMarks}
                />
              </div>
            </div>
            <div className="row mb-3">
              <div className="col-lg">
                <label htmlFor="mattl_score" className="form-label">
                  Mattl Score
                </label>
                <input
                  type="number"
                  inputmode="numeric"
                  min="0"
                  name="mattl_score"
                  id="mattl_score"
                  value={user.mattlScore}
                  className="form-control"
                  onChange={(e) =>
                    setUser((d) => ({ ...d, mattlScore: e.target.value }))
                  }
                />
              </div>
              <div className="col-lg">
                <label htmlFor="github_user_name" className="form-label">
                  Gitlab user name
                </label>
                <input
                  type="text"
                  name="Gitlab_user_name"
                  id="Gitlab_user_name"
                  className="form-control"
                  onChange={(e) =>
                    setUser((d) => ({
                      ...d,
                      githubUserName: e.target.value,
                    }))
                  }
                  value={user.githubUserName}
                />
              </div>
            </div>
            <button type="submit" className="btn text-white bg-green mt-4">
              Add User
            </button>
            <button
              type="reset"
              className="btn btn-secondary ms-3 mt-4"
              onClick={() => {
                setUser({
                  username: "",
                  email: "",
                  password: "",
                  approved: true,
                  empId: "",
                  fname: "",
                  lname: "",
                  mname: "",
                  tenthMarks: null,
                  twelfthMarks: null,
                  mattlScore: null,
                  isMail: false,
                  githubUserName: "",
                });
              }}
            >
              Reset
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AddUser;
