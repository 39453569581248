import React, { useEffect, useState } from "react";
import TestCaseService from "../../services/TestCaseService";
import { useNavigate } from "react-router-dom";
import Select from "react-select";

export default function CreateSpec() {

  const initialSpecData = {
    specName: '',
    actions: [],
    evaluate: []
  }

  const [specData, setSpecData] = useState(initialSpecData);
  const [actions, setActions] = useState([]);
  const [evaluate, setEvaluate] = useState([]);

  const navigate = useNavigate('/')
  useEffect(() => {
    let authtoken = localStorage.getItem("token")
    if (authtoken) {
      TestCaseService.getTestCase(authtoken)
        .then((res) => {
          if (res.data) {
            if (res.data.actionsData) {

              setActions(res.data.actionsData);
              console.log(res.data.actionsData);
            }
            if (res.data.evaluateData) {
              setEvaluate(res.data.evaluateData);
              console.log(res.data.evaluateData);
            }
          }
        })
        .catch((error) => console.log(error));
    }
    else {
      navigate('/login')
    }
  }, []);

  const handleSaveSpec = () => {
    if (specData) {
      const authToken = localStorage.getItem("token")
      TestCaseService.postSpec(specData, authToken).then(
        res => {
          console.log('Response:', res);
          if (res.status === 201) {
            alert("Data stored successfully")
            setSpecData(initialSpecData)
          } else if (res.data && res.data.status === 409) {
            alert(res.data.error);
          } else {
            alert("Unexpected response status: " + res.status);
          }
        }
      ).catch(err => {
        console.log(err.message)
        alert(err.message)
        setSpecData(initialSpecData)
      }
      )
    }
  };

  return (
    <>
      <div className="text-center my-4 h3">Create Spec</div>
      <form className="mx-5">
        <div className="row">
          <div className="col-3">
            <label htmlFor="specName">SpecName :</label><br />
            <input
              type="text"
              width="75"
              name="specName"
              value={specData.specName}
              required
              placeholder="Write Spec Name"
              className="ms-1 mt-1"
              onChange={(e) =>
                setSpecData({ ...specData, specName: e.target.value })
              }
            />
          </div>
          <div className="col-4">
            <label htmlFor="Actions">Actions:</label>
            <Select
              className="ms-1"
              value={specData.actions}
              options={actions}
              isClearable
              onChange={(selectedOption) => {
                // const selectedValues = selectedOption ? [selectedOption.value] : [];
                setSpecData({ ...specData, actions: selectedOption });
              }}
            />
          </div>
          <div className="col-4">
            <label htmlFor="Evaluate">Evaluate:</label>
            <Select
              className="ms-1"
              value={specData.evaluate}
              options={evaluate}
              isClearable
              onChange={(selectedOption) =>
                setSpecData({ ...specData, evaluate: selectedOption })
              }
            />
          </div>
        </div>

        <div>
          <button
            className="btn btn-success mt-3"
            type="button"
            onClick={handleSaveSpec}
          >
            Save Specs
          </button>
        </div>
      </form>
    </>
  );
}
