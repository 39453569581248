import axios from "axios";
import variable from "../servicesvariable";

class LoginService {
  postLogin(credentials) {
    return axios.post(variable.TUTORIAL_API_LOGIN_URL, credentials);
  }

  getTrainers(token) {
    return axios.get(variable.TUTORIAL_API_API_USER, {
      headers: {
        Authorization: token,
      },
    });
  }
  getAllUser(token) {
    return axios.get(variable.TUTORIAL_API_API_USER, {
      headers: {
        Authorization: token,
      },
    });
  }

  getUser(token) {
    return axios.get(variable.TUTORIAL_API_LOGIN_URL, {
      headers: {
        Authorization: token,
      },
    });
  }

  //added for bug#252696
  getAllUsers(token) {
    return axios.get(variable.TUTORIAL_API_API_USER + "/getAllUsers/user", {
      headers: {
        Authorization: token,
      },
    });
  }
  putUserData(data, token) {
    return axios.put(variable.TUTORIAL_API_API_USER + "editUser", data, {
      headers: {
        Authorization: token,
      },
    });
  }
  getSortedUser() {
    return axios.get(variable.TUTORIAL_API_API_USER + "sortedUser");
  }
  getUsers() {
    return axios.get(variable.TUTORIAL_API_API_USER + "student");
  }
  getFilterUser(fdata) {
    return axios.post(variable.TUTORIAL_API_API_USER + "filteruser", fdata);
  }
  getSingleUser(id, token) {
    return axios.get(variable.TUTORIAL_API_API_USER + id, {
      headers: {
        Authorization: token,
      },
    });
  }
  getSingleUserById(id, token) {
    return axios.get(variable.TUTORIAL_API_API_USER + id + "/userById", {
      headers: {
        Authorization: token,
      },
    });
  }
  postUser(user, token) {
    return axios.post(variable.TUTORIAL_API_API_USER, user, { headers: { Authorization: token } });
  }
  postEmail(email) {
    return axios.post(variable.TUTORIAL_API_API_USER + "forgot", email);
  }
  postResetPassword(user, token) {
    return axios.post(variable.TUTORIAL_API_API_USER + "reset", user, {
      headers: {
        Authorization: token,
      },
    });
  }
  putUser(user, token) {
    return axios.put(variable.TUTORIAL_API_API_USER + user._id, user, {
      headers: {
        Authorization: token,
      },
    });
  }
  putHostIdByUser(mid, userId, token) {
    let hostId = { hostId: mid };
    return axios.put(
      variable.TUTORIAL_API_API_USER + "hostID/" + userId,
      hostId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  AddSubscribeCourse(userrole, userid, courseid, token) {
    return axios.put(
      variable.TUTORIAL_API_API_USER + "add/" + userid,
      {
        data: {
          role: userrole,
          course: courseid,
        },
      },
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  addSubscriptionRequest(userid, courseid, token) {
    let course = { courseid: courseid };
    return axios.put(
      variable.TUTORIAL_API_API_USER + "edit-subscription/" + userid,
      course,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  putProjectStatus(id, token) {
    return axios.put(variable.TUTORIAL_API_API_USER + "projectStatus/" + id, {
      headers: {
        Authorization: token,
      },
    });
  }
  deleteSubscriptionCourse(userid, courseid, token) {
    let course = { courseid: courseid };
    return axios.put(
      variable.TUTORIAL_API_API_USER + "delete-course/" + userid,
      course,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  deleteSubscriptionRequest(userid, courseid, token) {
    let course = { courseid: courseid };
    return axios.put(
      variable.TUTORIAL_API_API_USER + "delete-subscription/" + userid,
      course,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  // approveUser(user, token) {
  //   return axios.delete(variable.TUTORIAL_API_API_USER + user._id, {
  //     headers: {
  //       Authorization: token,
  //     },
  //   });
  // }

  acceptAccessRequest(token) {
    return axios.get(
      variable.TUTORIAL_API_API_USER + "access-request/" + token,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  updateApproveRequest(id, studentData, token) {
    return axios.put(
      variable.TUTORIAL_API_API_USER + "access-request/" + id,
      studentData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  updateApprovalRequest(id, data, token) {
    let body = { approved: data };
    return axios.put(
      variable.TUTORIAL_API_API_USER + "approval-request/" + id,
      body,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  deleteApproveRequest(id, token) {
    return axios.delete(
      variable.TUTORIAL_API_API_USER + "access-request/" + id,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  getUsersPaginate(pageNumber, data, token) {
    return axios.post(
      variable.TUTORIAL_API_API_USER + "get-users-paginate",
      data,
      {
        params: {
          page: pageNumber,
        },
        headers: {
          Authorization: token,
        },
      }
    );
  }
  getUserLeaveBalance(empId, token) {
    return axios.get(
      variable.TUTORIAL_API_API_USER + "findUserLeaveBalance/" + empId,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  postApplyUserLeave(leaveData, token) {
    return axios.post(
      variable.TUTORIAL_API_API_USER + "applyLeave",
      leaveData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  updateApplyUserLeave(leaveId, leaveData, token) {
    return axios.put(
      variable.TUTORIAL_API_API_USER + "updateLeave/" + leaveId,
      leaveData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }
  deleteApplyLeave(cancelData, token) {
    return axios.post(
      variable.TUTORIAL_API_API_USER + "deleteLeave",
      cancelData,
      {
        headers: {
          Authorization: token,
        },
      }
    );
  }

  // -------------------------------------------------------------------------------------------------------
  getRecentlySubscribedUsers() {
    return axios.get(
      variable.TUTORIAL_API_LOGIN_URL + "get-recently-subscribed-users"
    );
  }

  getCoursesSubscribedAndUsersSubscribed(token, courseId, limit) {
    return axios.get(
      `${variable.TUTORIAL_API_API_USER}courses-subscribed-and-users-subscribed/${courseId}`,
      {
        headers: {
          Authorization: token,
        },
        params: {
          limit,
        },
      }
    );
  }
}

export default new LoginService();
