import jwt_decode from "jwt-decode";
import LoginService from "../../services/LoginService";


/**
 * ASYNC Function
 * 
 * Returns the url 
 *  If 
 *      Number of PPTs is not zero or Number of Modules is not zero or Number of Videos is not zero
 *  Else
 *      Return Null
 *
 */
export const handleAutoSubscribe = async (sid, cname, sname, noOfModules, noOfVideos, noOfPpts) => {

    if(!sid || !cname || !sname || isNaN(noOfModules) || isNaN(noOfVideos) || isNaN(noOfPpts)) {
        throw new Error("Invalid Argument");
    }

    const token = localStorage.getItem("token");
    const decoded = jwt_decode(token);
    return await LoginService.AddSubscribeCourse(decoded.role, decoded.id, sid, token)
        .then((res) => {

            if (noOfModules !== 0) {
                return `/course/${cname}/courseOverview/${sname}/tplan`;
            }

            if (noOfVideos !== 0) {
                return `/course/${cname}/courseOverview/${sname}/videos/`
            }

            if (noOfPpts !== 0) {
                return `/course/${cname}/courseOverview/${sname}/additional-resources`
            }

            return null
        })
        .catch((ex) => { });
};


