export const ignoreAddModel = {
    controls: [
        {
            type: "text",
            name: "number",
            required: true,
            valueType: "array",
            placeholder: "Number ex: 10,20,30",
        },
        {
            type: "text",
            name: "date",
            required: true,
            valueType: "array",
            placeholder: "Date ex: 2023-01-25,2022-02-20",
        },
        {
            type: "text",
            name: "email",
            required: true,
            valueType: "array",
            placeholder: "Email ex: dummy@gmail.com,test@gmail.com",
        },
        {
            type: "text",
            name: "text",
            required: true,
            valueType: "array",
            placeholder: "text ex: john,Young",
        },
    ]
}