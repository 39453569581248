import CourseService from "../../services/CourseService";

export const getNoOfVideo = async (courseName) => {

    let noOfVideo = 0;

    if(typeof courseName === 'string') {

        await CourseService.getVideos(courseName).then((res) => {

            if(res.data.length > 0) {

                res.data.forEach(currElem => {

                    noOfVideo += currElem.videos.length;
                })
            }
        })
    }

    return noOfVideo;
}