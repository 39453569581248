import React, { useEffect, useState } from "react";
import CourseService from "../../services/CourseService";
import { useNavigate } from "react-router-dom";

export default function Ppts(props) {
  const { name } = props.item;
  const [ppts, setPpts] = useState({ ppts: [] });
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      CourseService.getPpts(name, token)
        .then((res) => {
          if (res.data) {
            setPpts(res.data);
          }
        })
        .catch((ex) => console.log(ex));
    } else {
      navigate("/login");
    }
  }, []);

  return (
    ppts &&
    ppts.ppts &&
    ppts.ppts.length &&
    ppts.ppts.length > 0 && (
      <div className="dataframe bg-white p-3">
        <div className="row g-5 ppts py-3 px-2">
          {ppts.ppts.map((item, index) => {
            return (
              <div className="col-lg-4 ppt" key={index}>
                <div className="card overflow-hidden rounded">
                  <iframe
                    src={`https://view.officeapps.live.com/op/embed.aspx?src=${item.filename}&embedded=true`}
                    frameborder="0"
                    height="300"
                  ></iframe>
                  <div className="card-body">
                    <h5 className="card-title color-dgreen">
                      Lesson {index + 1}
                    </h5>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}
