import React, { useEffect, useState } from "react";
import TestCaseService from "../../services/TestCaseService";
import { useNavigate, useParams } from "react-router-dom";
import Select from "react-select";

export default function EditSpec() {
  const { specId } = useParams(); // Assuming you're using React Router to get the specId from the URL
  const initialSpecData = {
    specName: '',
    actions: [],
    evaluate: []
  };

  const [specData, setSpecData] = useState(initialSpecData);
  const [actions, setActions] = useState([]);
  const [evaluate, setEvaluate] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    let authtoken = localStorage.getItem("token");
    console.log(specId);
    if (authtoken && specId) {
      // Fetch spec details by specId and update state
      TestCaseService.getSpecById(specId, authtoken)
        .then((res) => {
          if (res.data) {
            console.log(res.data);
            setSpecData(res.data);
          }
        })
        .catch((error) => console.log(error));

      TestCaseService.getTestCase(authtoken)
        .then((res) => {
          if (res.data) {
            if (res.data.actionsData) {
              console.log(res.data);
              setActions(res.data.actionsData);
            }
            if (res.data.evaluateData) {
              setEvaluate(res.data.evaluateData);
            }
          }
        })
        .catch((error) => console.log(error));

    } else {
      navigate('/login');
    }
  }, [specId]);

  const handleSaveSpec = () => {
    // Update the existing spec using specId
    if (specData && specId) {
      const authToken = localStorage.getItem("token");
      TestCaseService.editSpecData(authToken, specId, specData)
        .then((res) => {
          if (res.status === 200) {
            alert("Data updated successfully");
            navigate('/admin/manage-spec/list')
          } else if (res.data && res.data.status === 409) {
            alert(res.data.error);
          } else {
            alert("Unexpected response status: " + res.status);
          }
        })
        .catch((err) => {
          console.log(err.message);
          alert(err.message);
        });
    }
  };

  return (
    <>
      <div className="text-center my-4 h3">Edit Spec</div>
      <form className="mx-5">
        <form className="mx-5">
          <div className="row">
            <div className="col-3">
              <label htmlFor="specName">SpecName :</label><br />
              <input
                type="text"
                width="75"
                name="specName"
                value={specData.specName}
                required
                placeholder="Write Spec Name"
                className="ms-1 mt-1"
                onChange={(e) =>
                  setSpecData({ ...specData, specName: e.target.value })
                }
              />
            </div>
            <div className="col-4">
              <label htmlFor="Actions">Actions:</label>
              <Select
                className="ms-1"
                value={specData.actions}
                options={actions}
                isClearable
                onChange={(selectedOption) => {
                  const selectedValues = selectedOption
                    ? [selectedOption.value]
                    : [];
                  setSpecData({ ...specData, actions: selectedOption ? [selectedOption] : [] })

                }}
              />
            </div>
            <div className="col-4">
              <label htmlFor="Evaluate">Evaluate:</label>
              <Select
                className="ms-1"
                value={specData.evaluate}
                options={evaluate}
                isClearable
                onChange={(selectedOption) => {
                  setSpecData({ ...specData, evaluate: selectedOption ? [selectedOption] : [] })
                }
                }
              />
            </div>
          </div>

          <div>
            <button
              className="btn btn-success mt-3"
              type="button"
              onClick={handleSaveSpec}
            >
              Edit Specs
            </button>
          </div>
        </form>
      </form>
    </>
  );
}
