import React from "react";
import { useNavigate } from "react-router-dom";

function Cloudvideo() {
  const navigate = useNavigate();
  const back = () => {
    navigate("/course/HTML");
  };
  return (
    <>
      <div>
        <h4 style={{ marginLeft: "200px", marginTop: "100px" }}>
          Cloudinary Mp4
        </h4>
        <video
          src={
            "https://res.cloudinary.com/dvp30ky4z/video/upload/v1630409686/4.8_Flutter_SQFLITE_Example-_Complete_NoteKeeper_App._CRUD_Operation_in_SQLite_nohdsy.mp4"
          }
          controls
          height="50%"
          width="50%"
          style={{ marginLeft: "200px", marginTop: "10px" }}
        ></video>
      </div>
      <button
        className="btn btn-success"
        style={{ marginLeft: "200px" }}
        onClick={back}
      >
        Back
      </button>
    </>
  );
}

export default Cloudvideo;
