import React, { useEffect, useState } from "react";
import { CommonValues } from "../../TestCaseModals/FormFillValidationModels/commonValues";
import { dateModel } from "../../TestCaseModals/FormFillValidationModels/dateModel";
import { EmailValidationModel } from "../../TestCaseModals/FormFillValidationModels/emailModel";
import { ignoreAddModel } from "../../TestCaseModals/FormFillValidationModels/ignoreAddModel";
import { MinMaxLength } from "../../TestCaseModals/FormFillValidationModels/minLength";
import { NumberModel } from "../../TestCaseModals/FormFillValidationModels/numberModel";
import { RangeModel } from "../../TestCaseModals/FormFillValidationModels/rangeModels";
import { ShouldContainValueAddedModel } from "../../TestCaseModals/FormFillValidationModels/shouldContainValueAdded";
import { StringModel } from "../../TestCaseModals/FormFillValidationModels/stringModel";
import { ShouldContain } from "../../TestCaseModals/shouldContain";
import VanilaJsModal from "./VanilaJsModal";

export default function FormFillValidationForm({
  isEdit,
  actionId,
  index,
  setTestCase,
  testCase,
  handleInputValueType,
  getInputValue,
  manageFormsArrayLists,
  getFormList,
}) {
  const intilizeFormInput = [{ name: "" }];
  const intilizeFromInputConfig = [{ name: "", childValue: [{ name: "" }] }];
  const [formInputConfigName, setFormInputConfigName] =
    useState(intilizeFormInput);
  const [formValidationConfigName, setFormValidationConfigName] = useState(
    intilizeFromInputConfig
  );

  useEffect(() => {
    if (isEdit) {
      const getFormData = getFormList(index);
      if (getFormData) {
        setFormInputConfigName(
          getFormData?.formInputConfigName
            ? getFormData.formInputConfigName
            : intilizeFormInput
        );
        setFormValidationConfigName(
          getFormData?.formValidationConfigName
            ? getFormData.formValidationConfigName
            : intilizeFromInputConfig
        );
      }
    }
  }, [index]);

  const getFormInputControlList = {
    string: StringModel.controls,
    number: NumberModel.controls,
    date: dateModel.controls,
    email: EmailValidationModel.controls,
  };

  const getFormValidationChildObject = {
    shouldContain: ShouldContain.controls,
    shouldNotContain: ShouldContain.controls,
    shouldContainValuesAdded: ShouldContainValueAddedModel.controls,
    shouldNotContainValuesAdded: ShouldContainValueAddedModel.controls,
  };

  const getFormValidationChildList = [
    { name: "shouldContain" },
    { name: "shouldNotContain" },
    { name: "shouldContainValuesAdded" },
    { name: "shouldNotContainValuesAdded" },
  ];

  const getFormValidationControlList = {
    randomValues: CommonValues.controls,
    minLength: MinMaxLength.controls,
    maxLength: MinMaxLength.controls,
    required: CommonValues.controls,
    duplicate: CommonValues.controls,
    range: RangeModel.controls,
  };
  const actionList = ["ignore", "add"];

  const getIgnoreAddArray = ignoreAddModel.controls;

  const createNestedObject = (arr, value) => {
    let result = {};
    let currentLevel = result;
    for (let i = 0; i < arr.length; i++) {
      const key = arr[i];
      if (i === arr.length - 1) {
        // If it's the last element, set the value to an empty string
        currentLevel[key] = value;
      } else {
        // If it's not the last element, create an empty object for the next level
        currentLevel[key] = {};
        // Move to the next level
        currentLevel = currentLevel[key];
      }
    }
    return result;
  };

  const setActionValue = (actionName, value) => {
    const copytestCase = { ...testCase };
    const findActionData = copytestCase["actions"][index];
    const ObjecList = actionName.split("-");
    const firstObjectName = ObjecList.splice(0, 1);
    const Object = createNestedObject(ObjecList, value);
    if (findActionData?.[firstObjectName[0]]) {
      if (ObjecList.length === 4) {
        if (findActionData[firstObjectName[0]][ObjecList[0]]) {
          if (
            findActionData?.[firstObjectName[0]]?.[ObjecList[0]]?.[
              ObjecList[1]
            ]?.[ObjecList[2]]
          ) {
            findActionData[firstObjectName[0]][ObjecList[0]][ObjecList[1]][
              ObjecList[2]
            ][ObjecList[3]] = value;
          } else {
            console.log(
              "1",
              findActionData,
              Object,
              firstObjectName,
              ObjecList
            );
            if (findActionData[firstObjectName[0]][ObjecList[0]]) {
              if (
                findActionData[firstObjectName[0]][ObjecList[0]][ObjecList[1]]
              ) {
                findActionData[firstObjectName[0]][ObjecList[0]][ObjecList[1]][
                  ObjecList[2]
                ] = Object[ObjecList[0]][ObjecList[1]][ObjecList[2]];
              } else {
                findActionData[firstObjectName[0]][ObjecList[0]][ObjecList[1]] =
                  Object[ObjecList[0]][ObjecList[1]];
              }
            } else {
              findActionData[firstObjectName[0]][ObjecList[0]][ObjecList[1]][
                ObjecList[2]
              ] = {};
              findActionData[firstObjectName[0]][ObjecList[0]][ObjecList[1]][
                ObjecList[2]
              ][ObjecList[3]] = value;
            }
          }
        } else {
          const firstObjectName1 = ObjecList.splice(0, 1);
          const Object = createNestedObject(ObjecList, value);
          findActionData[firstObjectName[0]][firstObjectName1[0]] = Object;
        }
      } else if (ObjecList.length === 2) {
        if (findActionData[firstObjectName[0]][ObjecList[0]]) {
          findActionData[firstObjectName[0]][ObjecList[0]][ObjecList[1]] =
            value;
        } else {
          findActionData[firstObjectName[0]][ObjecList[0]] = {};
          findActionData[firstObjectName[0]][ObjecList[0]][ObjecList[1]] =
            value;
        }
      } else {
        if (findActionData[firstObjectName[0]]) {
          findActionData[firstObjectName[0]][ObjecList[0]] = value;
        }
      }
    } else {
      findActionData[firstObjectName[0]] = Object;
    }
    setTestCase(copytestCase);
  };
  const handleInputValues = (e, type) => {
    let { name, value, checked } = e.target;
    value = handleInputValueType(value, type, checked);
    setActionValue(name, value);
  };

  const handleFormConfigDropdwon = (
    componentName,
    e,
    childIndex,
    name,
    setValue,
    length,
    object
  ) => {
    const copyFormInput = [...name];
    const findActionName = copyFormInput.find(
      (actionName) => actionName.name === e.target.value
    );
    if (!findActionName) {
      const getInputObject = copyFormInput[childIndex];
      const keyName =
        componentName === "formInputConfigName"
          ? "formInputConfig"
          : "formValidations";
      deleteActionObject(keyName, getInputObject["name"]);
      getInputObject["name"] = e.target.value;
      const findNextInputVlue = copyFormInput[childIndex + 1];
      if (!findNextInputVlue && copyFormInput.length !== length) {
        copyFormInput.push(object);
      }
      manageFormsArrayLists(index, componentName, copyFormInput);
      setValue(copyFormInput);
    }
  };

  const deleteFormConfigChild = (parentIndex, childIndex) => {
    const copyFormValidation = [...formValidationConfigName];
    const getFindObject = copyFormValidation[parentIndex];
    const nameChildObject = getFindObject.childValue[childIndex]?.name;
    if (nameChildObject) {
      deleteActionObject(
        "formValidations",
        getFindObject.name,
        nameChildObject
      );
    }
    getFindObject.childValue.splice(childIndex, 1);
    setFormValidationConfigName(copyFormValidation);
  };

  const handleFormValidationDropdwon = (value, childIndex, childIndex1) => {
    const copyFormValidation = [...formValidationConfigName];
    const getFormObject = copyFormValidation[childIndex];
    const getObject = getFormObject["childValue"];
    const findActionName = getObject.find(
      (actionName) => actionName.name === value
    );
    if (!findActionName) {
      const getInputObject = getObject[childIndex1];
      console.log(getFormObject, getInputObject, value);
      deleteActionObject(
        "formValidations",
        getFormObject.name,
        getInputObject["name"]
      );
      getInputObject["name"] = value;
      const findNextInputVlue = getObject[childIndex1 + 1];
      if (!findNextInputVlue && getObject.length !== 4) {
        getObject.push({ name: "" });
      }
      manageFormsArrayLists(
        index,
        "formValidationConfigName",
        copyFormValidation
      );
      setFormValidationConfigName(copyFormValidation);
    }
  };

  const deleteActionObject = (name, childName, child = null) => {
    const copytestCase = { ...testCase };
    const findActionData = copytestCase["actions"][index];
    if (findActionData?.[name]?.[childName]) {
      if (child) {
        delete findActionData[name][childName]["onSuccess"][child];
      } else {
        delete findActionData[name][childName];
      }
      setTestCase(copytestCase);
    }
  };

  const handleFormvalidationsdelete = (childIndex, name, keyName, setValue) => {
    const copyformData = [...name];
    const findFormObject = copyformData[childIndex];
    const childName = findFormObject["name"];
    if (childName) {
      deleteActionObject(keyName, childName);
    }
    copyformData.splice(childIndex, 1);
    setValue(copyformData);
  };

  const handleInputControls = (control, name, childIndex, cssFlag) => {
    const labelName = `${name}-${control?.name || control[0]?.name}`;
    if (control.type === "text") {
      return (
        <div className={`my-2 ${cssFlag ? "col-md-6" : "col-md-4"}`}>
          {/* <div className="col-md-4">{control.name}</div> */}
          {/* {/* <div className="col-md-12 py-2"> */}
          {/* {console.log("getInuvalue", getInputValue(labelName, index))} */}
          <input
            className="w-100"
            type="search"
            placeholder={`${control.placeholder || control.name}`}
            value={getInputValue(labelName, index)}
            name={labelName}
            onChange={(e) => handleInputValues(e, control?.valueType)}
            required={control.required}
          />
          {/* </div> */}
        </div>
      );
    } else if (control.type === "number") {
      return (
        <div className={`my-2 ${cssFlag ? "col-md-6" : "col-md-4"}`}>
          {/* <div className="col-md-4">{control.name}</div> */}
          {/* <div className="col-md-12 py-2"> */}
          <input
            className="w-100"
            type="number"
            placeholder={`${control.name}`}
            name={labelName}
            value={getInputValue(labelName, index) || ""}
            onChange={(e) => handleInputValues(e, control.type)}
            required={control.required}
          />
          {/* </div> */}
        </div>
      );
    } else if (control.type === "boolean") {
      return (
        <div className="col-md-4 row my-2">
          <div className="col-md-8">{control.name}</div>
          <div className="col-md-4">
            <input
              className="w-100"
              type="checkbox"
              checked={getInputValue(labelName, index) || ""}
              name={labelName}
              placeholder={`${control.name}`}
              required={control.required}
              onChange={(e) => handleInputValues(e, control.type)}
            />
          </div>
        </div>
      );
    } else if (control.type === "date") {
      return (
        <div className={`${cssFlag ? "col-md-6" : "col-md-4"}`}>
          {/* <div className="col-md-4">{control.name}</div> */}
          {/* <div className="col-md-12 py-2"> */}
          <input
            className="w-100"
            type="date"
            placeholder={`${control.name}`}
            name={labelName}
            value={getInputValue(labelName, index) || ""}
            onChange={(e) => handleInputValues(e, control.type)}
            required={control.required}
          />
          {/* </div> */}
        </div>
      );
    } else {
      const getChildObject = formValidationConfigName[childIndex]["childValue"];
      return (
        <div className="col-md-12 row">
          {getChildObject.map((child, childIndex1) => {
            return (
              <>
                <div className="col-md-1">
                  {childIndex1 !== 0 && (
                    <button
                      type="button"
                      class="rounded bg-danger"
                      onClick={() =>
                        deleteFormConfigChild(childIndex, childIndex1)
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                      </svg>
                    </button>
                  )}
                </div>
                <div className="col-md-4">
                  <select
                    className="form-select"
                    id="actionList"
                    value={child.name}
                    // name="actionList"
                    onChange={(e) => {
                      handleFormValidationDropdwon(
                        e.target.value,
                        childIndex,
                        childIndex1
                      );
                    }}
                    required={childIndex1 === 0 ? false : true}
                  >
                    <option value="">Select</option>
                    {getFormValidationChildList.map((FormValidationList) => {
                      return (
                        <option value={FormValidationList.name}>
                          {FormValidationList.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div className="col-md-7 row my-2">
                  {child.name &&
                    getFormValidationChildObject[child.name]?.map((control) => {
                      return (
                        <>
                          {handleInputControls(
                            control,
                            `${labelName}-${child.name}`,
                            childIndex,
                            true
                          )}
                        </>
                      );
                    })}
                </div>
              </>
            );
          })}
        </div>
      );
    }
  };

  return (
    <>
      {actionList.map((action) => {
        return (
          <div className="col-md-12 row">
            <div className="col-md-2">{action}</div>
            <div className="col-md-10 row">
              {getIgnoreAddArray.map((control, index1) => {
                return (
                  <>{handleInputControls(control, `${action}`, index, true)}</>
                );
              })}
            </div>
          </div>
        );
      })}
      <VanilaJsModal
        isEdit={isEdit}
        actionName="Inputs"
        actionArray={[{ controlId: "", values: [] }]}
        index={index}
        setTestCase={setTestCase}
        testCase={testCase}
        getInputValue={getInputValue}
        handleInputValueType={handleInputValueType}
        manageFormsArrayLists={manageFormsArrayLists}
        getFormList={getFormList}
      />
      <div className="col-md-12 row">
        <div className="col-md-2">formInputConfig</div>
        <div className="col-md-10 row">
          {formInputConfigName?.map((formInput, index2) => {
            return (
              <>
                <div className="col-md-1">
                  {index2 !== 0 && (
                    <button
                      type="button"
                      class="rounded bg-danger"
                      onClick={() =>
                        handleFormvalidationsdelete(
                          index2,
                          formInputConfigName,
                          "formInputConfig",
                          setFormInputConfigName
                        )
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        className="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                        <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                      </svg>
                    </button>
                  )}
                </div>
                <div className="col-md-2">
                  <select
                    className="form-select my-2"
                    id="actionList"
                    value={formInput.name}
                    // name="actionList"
                    onChange={(e) => {
                      handleFormConfigDropdwon(
                        "formInputConfigName",
                        e,
                        index2,
                        formInputConfigName,
                        setFormInputConfigName,
                        Object.keys(getFormInputControlList).length,
                        { name: "" }
                      );
                    }}
                    required={index2 === 0 ? false : true}
                  >
                    <option value="">Select</option>
                    {Object.keys(getFormInputControlList).map((name) => {
                      return <option value={name}>{name}</option>;
                    })}
                  </select>
                </div>
                <div className="col-md-9 row my-2">
                  {formInput.name && (
                    <>
                      {getFormInputControlList[formInput.name].map(
                        (control, childIndex) => {
                          return (
                            <>
                              {handleInputControls(
                                control,
                                `formInputConfig-${formInput.name}`,
                                childIndex,
                                false
                              )}
                            </>
                          );
                        }
                      )}
                    </>
                  )}
                </div>
              </>
            );
          })}
        </div>
      </div>
      {actionId && (
        <div className="col-md-12 row my-2">
          <div className="col-md-2">formValidations</div>
          <div className="col-md-10 row">
            {formValidationConfigName?.length > 0 &&
              formValidationConfigName.map((formValidation, index3) => {
                return (
                  <>
                    <div className="col-md-1">
                      {index3 !== 0 && (
                        <button
                          type="button"
                          class="rounded bg-danger"
                          onClick={() =>
                            handleFormvalidationsdelete(
                              index3,
                              formValidationConfigName,
                              "formValidations",
                              setFormValidationConfigName
                            )
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            className="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5Zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6Z" />
                            <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1ZM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118ZM2.5 3h11V2h-11v1Z" />
                          </svg>
                        </button>
                      )}
                    </div>
                    <div className="col-md-2">
                      <select
                        className="form-select my-2"
                        id="actionList"
                        value={formValidation.name}
                        // name="actionList"
                        onChange={(e) => {
                          handleFormConfigDropdwon(
                            "formValidationConfigName",
                            e,
                            index3,
                            formValidationConfigName,
                            setFormValidationConfigName,
                            Object.keys(getFormValidationControlList).length,
                            { name: "", childValue: [{ name: "" }] }
                          );
                        }}
                        required={index3 === 0 ? false : true}
                      >
                        <option value="">Select</option>
                        {Object.keys(getFormValidationControlList).map(
                          (name) => {
                            return <option value={name}>{name}</option>;
                          }
                        )}
                      </select>
                    </div>
                    <div className="col-md-9 row my-2">
                      {/* {console.log("formvalidation", formValidation)} */}
                      {formValidation?.name && (
                        <>
                          {getFormValidationControlList[
                            formValidation.name
                          ].map((control) => {
                            return (
                              <>
                                {handleInputControls(
                                  control,
                                  `formValidations-${formValidation.name}`,
                                  index3,
                                  false
                                )}
                              </>
                            );
                          })}
                        </>
                      )}
                    </div>
                  </>
                );
              })}
          </div>
        </div>
      )}
    </>
  );
}
