export const ShouldContain = {
  controls: [{
    type: "text",
    name: "selector",
    required: true,
  },
  {
    type: "text",
    name: "values",
    required: true,
    valueType: "array",
  }]
}
  // export default schema