import React, { useEffect, useState } from "react";
import { FaTimes } from "react-icons/fa";
import { VscCheck } from "react-icons/vsc";
import Select from "react-select";
import formloader from "../../images/formloading.svg";
import LoginService from "../../services/LoginService";
import BatchManagementServices from "./../../services/BatchManagementServices";
// import formloader from "./../../images/formloading.svg";
import { useNavigate } from "react-router-dom";
import Tooltip from "../BsComponents/ToolTip";

// import { ImSad2 } from "react-icons/im";
export default function ManageApproval(props) {
  const [confirmBox, setConfirmBox] = useState(false);
  const navigate = useNavigate();
  const [approvalPending, setApprovalPending] = useState([]);
  const [batchesOptions, setBatchesOptions] = useState([]);
  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setid] = useState([]);
  const [msg, setMsg] = useState("");
  const [studentData, setStudentData] = useState({
    approved: true,
    batchId: "",
    userId: "",
    empId: "",
    hostId: "",
  });
  // const [buttonStatus, setButtonStatus] = useState(0);
const [confirmBox2, setConfirmBox2] = useState(false);
const [userId, setUserId] = useState(0)
  var token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      navigate("/login");
    } else {
      setLoading(true);
      LoginService.getUsers(token)
        .then((res) => {
          if (res.data.length > 0) {
            const users = res.data;
            const filterPendingApprovals = users.filter(
              (s) =>
                s.role === "Student" &&
                !s.approved &&
                !s.email.split("@")[1].startsWith("radix") &&
                !s.deactivate
            );
            setApprovalPending(filterPendingApprovals);
          }
        })
        .catch((err) => {
          console.log(err.response.data);
        });
      setLoading(false);
      BatchManagementServices.GetAllBatch(token)
        .then((res) => {
          setBatches(res.data.data);
          const tempBatches = [];
          if (res.data.data.length > 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              const batch = res.data.data[i];

              const temp = {
                value: batch.name,

                label: batch.name,

                id: batch._id,
              };

              tempBatches.push(temp);
            }
          }

          setBatchesOptions([...tempBatches]);
        })

        .catch((err) => {
          console.log(err);
        });
    }
  }, [loading, confirmBox]);

  const handleDelete = (studentId) => {
    setLoading(true);
    LoginService.deleteApproveRequest(studentId, token)
      .then((res) => {
        setLoading(false);
        setMsg("Approval Request Deleted...");
        setConfirmBox(true);
        setConfirmBox2(false)
      })
      .catch((ex) => {
        setLoading(false);
      });
    {
      setTimeout(() => setConfirmBox(false), 3000);
    }
  };
  
  // const handleSubmit = async (studentId) => {
  //   setLoading(true);
  //   // const body = { approved: true };
  //   LoginService.updateApproveRequest(studentId, studentData, token)
  //     .then((res) => {
  //       setLoading(false);
  //       // setButtonStatus(0);
  //       setMsg("Approval Request Approved...");
  //       setConfirmBox(true);
  //       setStudentData({
  //         approved: true,
  //         batchId: "",
  //         userId: "",
  //         empId: "",
  //         hostId: "",
  //       });
  //     })
  //     .catch((ex) => {
  //       setLoading(false);
  //       // setButtonStatus(2);
  //     });
  //   {
  //     setTimeout(() => setConfirmBox(false), 3000);
  //   }
  // };
  const handleSubmit = async (studentId) => {
    setLoading(true);
    
      // setTimeout(()=>{
        LoginService.updateApproveRequest(studentId, studentData,token)
        .then((res) => {
          setLoading(false);
          setMsg("Approval Request Approved...");
          setConfirmBox(true);
          setStudentData({
            approved: true,
            batchId: "",
            userId: "",
            empId: "",
            hostId: "",
          });
        })
        .catch((ex) => {
          setLoading(false);
        });
      // }
        // ,0);
      // eslint-disable-next-line no-lone-blocks
      {setTimeout(() => setConfirmBox(false), 5000);}
  }

  const handleTable = (size) => {
    return (
      <>
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Name</th>
            {size == "more" && <th scope="col">Email</th>}
            <th scope="col" className="text-center">
              SelectBatch
            </th>
            <th scope="col" className="text-center">
              EmpCode
            </th>
            <th scope="col" className="text-center">
              Handle
            </th>
          </tr>
        </thead>
        <tbody>
          {approvalPending &&
            approvalPending.map((user, index1) => {
              return (
                <tr key={index1}>
                  <th scope="row">{index1 + 1}</th>
                  <td style={{ wordBreak: 'break-all',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>{user.username}</td>
                  {size == "more" && <td style={{ wordBreak: 'break-all',whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '150px' }}>{user.email}</td>}
                  <td style={{ width: "17%" }}>
                    {batches && batches.length > 0 && (
                      <Select
                        placeholder="Select Batch"
                        options={batchesOptions}
                        onChange={(e) =>
                          setStudentData({ ...studentData, batchId: e.id })
                        }
                      />
                    )}
                  </td>
                  <td className="text-center">
                    <input
                      className="w-75 py-1"
                      type="text"
                      name="studentData"
                      inputmode="numeric"
                      placeholder="Please Enter Employee Code"
                      onChange={(e) =>
                        setStudentData({
                          ...studentData,
                          empId: e.target.value,
                          userId: user._id,
                          hostId:
                            user.username.replace(" ", "_") +
                            "_" +
                            e.target.value,
                        })
                      }
                      value={
                        studentData.userId == user.id ? studentData.empId : null
                      }
                    />
                  </td>
                  <td>
                    <div className="d-flex justify-content-center mx-2">
                      <div>
                        <Tooltip title="Approve Request">
                          <button
                            className="btn btn-success px-2 py-1 m-0"
                            onClick={() => {
                              handleSubmit(user._id);
                            }}
                            
                          >
                            <VscCheck />
                          </button>
                        </Tooltip>
                      </div>
                      <div>
                        <Tooltip title="Delete User">
                        <button
                          className="btn btn-danger px-2 py-1 mx-1 text-white"
                          onClick={() => {
                            // handleDelete(user._id);
                            setUserId(user._id)
                            setConfirmBox2(true);
                          }}
                        >
                          <FaTimes className="color-white text-white"/>
                        </button>
                        </Tooltip>
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </>
    );
  };

  if (loading) {
    return (
      <div className="formloader">
        <div className="row text-center">
          <div className="col-12">
            <img src={formloader} alt="" height="100" />
          </div>
          <div className="col-12 text-white h4">Wait...</div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="container vh-100 my-2">
        { (!approvalPending || approvalPending.length === 0) && !confirmBox  ? (
          <h2 className="m-4 text-center">No approval requests yet.</h2>
        ) : (
          <>
            <h4 className="">Approval requests</h4>
            <hr className="py-0 my-0" />
            {confirmBox && (
              <div className="formloade">
                <div className="row text-center">
                  <div className="col-6 w-100 confirmbox">
                    <div
                      className={`alert ${
                        msg === "Approval Request Deleted..."
                          ? "alert-danger"
                          : "alert-success"
                      }`}
                    >
                      {msg}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {confirmBox2 && (
                  <div className="formloade ">
                    <div className="row text-center ">
                      <div className=" confirmbox ">
                        <div className="alert">
                          Do you really want to delete user request?
                          <br />
                          <button
                            className="btn btn-danger ms-4"
                            onClick={() => {
                              setConfirmBox2(false);
                            }}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-success ms-2"
                            onClick={() => {
                              setConfirmBox2(false);
                              setConfirmBox(true);
                              handleDelete(userId)
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
            <table className="table mt-4">
              {window.screen.width < 992 && handleTable("less")}
              {window.screen.width > 992 && handleTable("more")}
            </table>
          </>
        )}
      </div>
    </>
  );
}
