import React, { useState, useEffect, useRef } from "react";
import "./TrainingPlan.scss";
import jwt_decode from "jwt-decode";
import $ from "jquery";
import CourseService from "../../services/CourseService";
import { imgURL } from "../../services/CourseService";
import { useNavigate, useParams } from "react-router-dom";
import datanotfoundimage from "../../assets/images/DataNotFound-image.png";
import formloader from "../../images/formloading.svg";
import global from "../../servicesvariable";
import Reacthlsplayer from "react-hls-player";

export default function TrainingPlan(props) {
  const token = localStorage.getItem("token");
  const param = useParams();
  const navigate = useNavigate();
  const [sidelg, setSideLG] = useState(true);
  const [sidesm, setSideSM] = useState(false);
  const [progress, setprogress] = useState([]);
  const [loading, setLoading] = useState(false);
  const [playingVideo, setPlayingVideo] = useState({ link: "" });
  const [confirmBox, setConfirmBox] = useState(false);
  const [msg, setMsg] = useState({ msg: "", status: false });
  var number = 0;
  var no = 0;
  const video = useRef();
  useEffect(() => {
    $(window).scrollTop(0);
  }, [window.location.href]);
  useEffect(() => {
    setLoading(true);
    const uid = jwt_decode(token).id;
    CourseService.getTrainingProgress(
      jwt_decode(token).id,
      props.course.modules && props.course.modules[0].cid,
      token
    )
      .then((res) => {
        setprogress(res.data.progress.completedTrainingDayPlan);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });

    //
  }, [props]);

  const handleCourseComplete = async (tpId, courseid) => {
    if (token) {
      const decoded = jwt_decode(token);
      const temp = !progress ? [] : [...progress];
      temp.push(tpId);
      setprogress(temp);
      const data = { trainingPlans: temp };
      CourseService.updateTrainingProgress(
        decoded.id,
        courseid || props.course.modules.cid,
        data,
        token
      )
        .then((res) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleCompletedModule = (tplanDay) => {
    if (jwt_decode(token).role === "Admin") {
      return navigate(`#${tplanDay}`);
    }
    if (progress?.length >= parseInt(tplanDay - 1)) {
      navigate(`#${tplanDay}`);
      return true;
    } else {
      setConfirmBox(true);
      setMsg({
        msg: "Please verify your previous module marks as completed not done",
      });
      setTimeout(() => setConfirmBox(false), 5000);
      return false;
    }
  };

  $(document).ready(function () {
    $("a.scrollLink").click(function () {
      setTimeout(function () {
        $("html, body").animate(
          {
            scrollTop: 0,
          },
          0.0001
        );
      }, 0);
    });
    var headerHeight = $("header").height();
    var bannerHeight = $(".tutorial-banner").height();
    var footerHeight = $("footer").height();
    var totalHeight = headerHeight + bannerHeight + footerHeight;
    if ($(window).width() > 1025) {
      $(".sidebar").css("height", "calc(99vh - " + totalHeight + "px - 90px");
      $(".video-tutorial").css(
        "height",
        "calc(99vh - " + totalHeight + "px - 50px"
      );
      sidebarlg();
      videoPosition();
      setSideSM(false);
    } else {
      sidebarsm();
      setSideLG(false);
    }
    $(window).scroll(function (e) {
      topbarSticky();
      sideBarSticky();
    });
    $(window).resize(function (e) {
      if ($(window).width() > 1025) {
        $(".sidebar").css("height", "calc(99vh - " + totalHeight + "px - 90px");
        $(".video-tutorial").css(
          "height",
          "calc(99vh - " + totalHeight + "px - 50px"
        );
        $(".sidebar-wrapper").css({
          top: $("header").height() + $(".tutorial-banner").height() + 20,
        });
        $(".sidebar-wrapper").css(
          "height",
          "calc(99vh - " + totalHeight + "px - 90px"
        );

        sidebarlg();
        videoPosition();
        setSideSM(false);
      } else {
        sidebarsm();
        setSideLG(false);
      }
      $(window).scroll(function (e) {
        topbarSticky();
        sideBarSticky();
      });
    });
    $(".sidebar-wrapper").css({
      top: $("header").height() + $(".tutorial-banner").height() + 50,
    });
  });
  function sidebarlg() {
    $(".collapse-btn").click(function () {
      setSideLG(false);
      $(".sidebar-wrapper").addClass("collapse-sidebar");
      $(".sidebar-wrapper").removeClass("col-lg-3 col-12  pt-4");
      $(".tab-contents").addClass("mx-auto");
    });
    $(".slider-right-btn").click(function () {
      setSideLG(true);
      $(".sidebar-wrapper").removeClass("collapse-sidebar");
      $(".sidebar-wrapper").addClass("col-lg-3 col-12  pt-4");
      $(".tab-contents").removeClass("mx-auto");
    });
  }
  function videoPosition() {
    $(window).scroll(function (e) {
      if ($(this).scrollTop() > 0) {
        if ($(this).scrollTop() > $(".video-tutorial").height() / 1.5) {
          $(".video-tutorial").addClass("video-fixed");
          $(".video-tutorial").css({ height: "17.25rem", width: "30.625rem" });
        }
      } else {
        $(".video-tutorial").removeClass("video-fixed");
      }
    });
  }
  function sidebarsm() {
    $(".smsidebar").css("height", "99vh");
    $(".sidebar-wrapper").addClass("collapse-sidebar");
    $(".sidebar-wrapper").removeClass("col-lg-3 col-12  pt-4");
    $(".toggle-sidebar").click(function (e) {
      setSideSM(true);
      $(".sidebar-wrapper").removeClass("collapse-sidebar");
      $("body").addClass("overflow-hidden");
      $(".sidebar-bg").removeClass("d-none");
    });
    $(".close-sidebar").click(function (e) {
      setSideSM(false);
      $(".sidebar-wrapper").addClass("collapse-sidebar");
      $("body").removeClass("overflow-hidden");
      $(".sidebar-bg").addClass("d-none");
    });
  }
  function topbarSticky() {
    var $el = $(".tutorial-banner");
    if ($(window).scrollTop() > 50) {
      $("header").css({ height: "0px", overflow: "hidden" });
      $el.css({ top: "0px" });
      $el.addClass("position-sticky");
    } else {
      $el.removeClass("position-sticky");
      $("header").css({ height: "", overflow: "" });
    }
  }
  function sideBarSticky() {
    var headerHeight = $("header").height();
    var bannerHeight = $(".tutorial-banner").height();
    var footerHeight = $("footer").height();
    var totalHeight = headerHeight + bannerHeight + footerHeight;

    if ($(window).scrollTop() > 0) {
      $(".sidebar-wrapper").css({
        top: $("header").height() + $(".tutorial-banner").height() + 50,
      });
    } else {
      $(".sidebar-wrapper").css({
        top: $("header").height() + $(".tutorial-banner").height() + 50,
      });
    }
  }

  const hidebar = () => {
    $(document).ready(function () {
      if ($(window).width() < 1025) {
        setSideSM(false);
        $(".sidebar-wrapper").addClass("collapse-sidebar");
        $("body").removeClass("overflow-hidden");
        $(".sidebar-bg").addClass("d-none");
      }
    });
  };

  const htmlRegex = new RegExp(/<\/?[a-z][\s\S]*>/i);
  if (props.course.modules) {
    return (
      <>
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading...</div>
            </div>
          </div>
        )}

        <div class="sidebar-bg d-none">
          <button
            type="button"
            class="btn-close btn-close-white close-sidebar me-0 d-xl-none d-block"
            aria-label="Close"
          ></button>
        </div>
        <div class="col-12 col-xl-3 col-lg-4 sidebar-wrapper">
          {sidelg == true && (
            <div class="sidebar px-4 pb-4 sidebar-module">
              <div class="accordion-content course-detail-accordion tutorial-content">
                {props.course &&
                  props.course.modules &&
                  props.course.modules
                    .sort((a, b) => a.tp_day - b.tp_day)
                    .map((item, index) => {
                      return (
                        <div class="accordion-item">
                          {" "}
                          <h3 class="accordion-header px-0">
                            <a
                              className={
                                window.location.href.split("#")[1]
                                  ? window.location.href.split("#")[1] ==
                                    item.tp_day
                                    ? "text-green accordion-button scrollLink"
                                    : "bg-white accordion-button scrollLink"
                                  : item.tp_day == 1
                                  ? "text-green accordion-button scrollLink"
                                  : "bg-white accordion-button scrollLink"
                              }
                              // href={`#${item.tp_day}`}
                              onClick={() => {
                                handleCompletedModule(item.tp_day);
                              }}
                              value={item.tp_day}
                            >
                              Module {item.tp_day}
                            </a>
                          </h3>
                        </div>
                      );
                    })}
              </div>
            </div>
          )}

          {sidesm == true && (
            <div class="sidebar px-0 pb-4 sidebar-module smsidebar">
              <div class="accordion-content course-detail-accordion tutorial-content">
                {props.course &&
                  props.course.modules &&
                  props.course.modules
                    .sort((a, b) => a.tp_day - b.tp_day)
                    .map((item, index) => {
                      return (
                        <div class="accordion-item">
                          {" "}
                          <h3 class="accordion-header px-0">
                            <div onClick={() => hidebar()}>
                              <a
                                className={
                                  window.location.href.split("#")[1]
                                    ? window.location.href.split("#")[1] ==
                                      item.tp_day
                                      ? "text-green accordion-button scrollLink"
                                      : "bg-white accordion-button scrollLink"
                                    : item.tp_day == 1
                                    ? "text-green accordion-button scrollLink"
                                    : "bg-white accordion-button scrollLink"
                                }
                                onClick={() => {
                                  handleCompletedModule(item.tp_day);
                                }}
                                value={item.tp_day}
                              >
                                Module {item.tp_day}
                              </a>
                            </div>
                          </h3>
                        </div>
                      );
                    })}
              </div>
            </div>
          )}
        </div>
        <div class="col-12 col-xl-9 col-lg-8 mb-lg-4 tab-contents sidebar-course-content">
          {confirmBox && (
            <div className="formloade">
              <div className="row text-center">
                <div className="col-6 w-100 confirmbox">
                  <div
                    className={`alert ${
                      msg.status ? "alert-success" : "alert-danger"
                    }`}
                  >
                    {msg.msg}
                  </div>
                </div>
              </div>
            </div>
          )}
          {props.course.modules ? (
            <section class="text-dark-gray px-lg-0 px-xl-4 pt-4">
              <div className="accordion-content course-detail-accordion tutorial-content">
                {props.course.modules &&
                  props.course.modules.map((plan, index) => {
                    return window.location.href &&
                      window.location.href.split("#")[1]
                      ? window.location.href.split("#")[1] == plan.tp_day && (
                          <div id={plan.tp_day}>
                            <h3 id={"headingt" + (index + 1)}>
                              <div>Module {plan.tp_day}</div>
                            </h3>
                            <div
                              id={"collapset" + (index + 1)}
                              className="accordion-collapse"
                            >
                              <div className="accordion-body px-0">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: plan.formattedWhatToLearn,
                                  }}
                                />
                                <h6>Practice Exercise</h6>
                                {plan.tp_practiceimgarr &&
                                plan.tp_practiceimgarr.length > 0 ? (
                                  <div className="m-0 d-inline-block">
                                    {plan.tp_practiceimgarr.map(
                                      (item, pindex) => {
                                        return (
                                          <img
                                            src={`${imgURL}/${item}`}
                                            alt="practiceimg"
                                            className="m-1"
                                            style={{
                                              cursor: "pointer",
                                              maxWidth: "100%",
                                            }}
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                ) : null}
                                {plan.formattedPractices[0] &&
                                plan.formattedPractices.length > 0 ? (
                                  <ul className="wtllist mb-4">
                                    {plan.formattedPractices.map(
                                      (item, index) => {
                                        if (htmlRegex.test(item)) {
                                          return (
                                            <div
                                              dangerouslySetInnerHTML={{
                                                __html: item,
                                              }}
                                            ></div>
                                          );
                                        }
                                        return (
                                          <li key={index}>
                                            <a target="_blank">{item}</a>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                ) : (
                                  <ul className="wtllist mb-4">
                                    No practice exercise
                                  </ul>
                                )}

                                <h6 className="color-dgreen wtl">Assignment</h6>
                                {plan.formattedAssignments[0] &&
                                plan.formattedAssignments.length > 0 ? (
                                  <ul className="wtllist mb-4">
                                    {plan.formattedAssignments.map(
                                      (item, index) => {
                                        if (htmlRegex.test(item)) {
                                          return (
                                            <li
                                              dangerouslySetInnerHTML={{
                                                __html: item,
                                              }}
                                            ></li>
                                          );
                                        }
                                        return (
                                          <li key={index}>
                                            <a target="_blank">{item}</a>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                ) : (
                                  <ul className="wtllist mb-4">
                                    No assigment exercise
                                  </ul>
                                )}
                                {plan.tp_assignmentimgarr &&
                                plan.tp_assignmentimgarr.length > 0 ? (
                                  <div className="m-0 d-inline-block">
                                    {plan.tp_assignmentimgarr.map(
                                      (item, aindex) => {
                                        return (
                                          <img
                                            src={`${imgURL}/${item}`}
                                            alt="assignmentimg"
                                            key="aindex"
                                            className="m-1"
                                            style={{
                                              cursor: "pointer",
                                              maxWidth: "100%",
                                            }}
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                ) : null}
                                <ul className="wtllist mb-4 d-none">
                                  {plan.tp_assignment
                                    ? plan.tp_assignment
                                        .split("\n")
                                        .map((item, taindex) => {
                                          if (
                                            item.match(/^--http/g) ||
                                            item.match(/^http/g)
                                          ) {
                                            return (
                                              <>
                                                {item.includes("--") ? (
                                                  <ul>
                                                    <li>
                                                      <a
                                                        href={
                                                          item.split("--")[2]
                                                        }
                                                        target="_blank"
                                                        className="d-block"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                          width: "100%",
                                                          display: "block",
                                                          whiteSpace: "nowrap",
                                                          textOverflow:
                                                            "ellipsis",
                                                          overflow: "hidden",
                                                        }}
                                                      >
                                                        {item
                                                          .split("--")
                                                          .slice(1, 2)}
                                                      </a>
                                                    </li>
                                                  </ul>
                                                ) : (
                                                  <li>
                                                    <a
                                                      href={item}
                                                      target="_blank"
                                                      className="d-block"
                                                      rel="noopener noreferrer"
                                                      style={{
                                                        width: "100%",
                                                        display: "block",
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                          "ellipsis",
                                                        overflow: "hidden",
                                                      }}
                                                    >
                                                      {item}
                                                    </a>
                                                  </li>
                                                )}
                                              </>
                                            );
                                          } else {
                                            return (
                                              <>
                                                {item.includes("--") ? (
                                                  <ul>
                                                    <li>
                                                      <p>
                                                        {item
                                                          .split("--")
                                                          .slice(1, 2)}
                                                      </p>
                                                    </li>
                                                  </ul>
                                                ) : (
                                                  <li>
                                                    <p>{item}</p>
                                                  </li>
                                                )}
                                              </>
                                            );
                                          }
                                        })
                                    : null}
                                </ul>
                                {plan &&
                                plan.assignments &&
                                plan.assignments.length > 0 ? (
                                  <div>
                                    {" "}
                                    <h6 className="color-dgreen wtl d-none">
                                      Assignments Edit
                                    </h6>
                                    <ul className="wtllist mb-4">
                                      {plan.assignments &&
                                        plan.assignments.map(
                                          (item, taindex) => {
                                            if (
                                              Object.values(item)[0].match(
                                                /^--http/g
                                              ) ||
                                              Object.values(item)[0].match(
                                                /^http/g
                                              )
                                            ) {
                                              return (
                                                <li>
                                                  {" "}
                                                  <a
                                                    href={
                                                      Object.values(item)[0]
                                                    }
                                                    key={taindex}
                                                  >
                                                    {Object.values(item)[0]}
                                                  </a>
                                                </li>
                                              );
                                            } else {
                                              return (
                                                <li>
                                                  {" "}
                                                  <p key={taindex}>
                                                    {Object.values(item)[0]}
                                                  </p>
                                                </li>
                                              );
                                            }
                                          }
                                        )}
                                    </ul>
                                  </div>
                                ) : null}
                                <h6 className="color-dgreen wtl">
                                  Online Reference
                                </h6>
                                {plan.tp_onlineref?.length > 0 &&
                                  ~plan?.tp_onlineref[0] && (
                                    <p>No online Reference</p>
                                  )}
                                {plan.tp_onlineref &&
                                plan?.tp_onlineref[0] &&
                                plan.tp_onlineref.length > 0 ? (
                                  <>
                                    <ul className="wtllist mb-4">
                                      {plan.tp_onlineref.map(
                                        (item, onindex) => {
                                          if (item.split(",").length > 1) {
                                            return item
                                              .split(",")
                                              .map((ite, index1) => {
                                                return (
                                                  <li
                                                    className="py-1"
                                                    key={onindex}
                                                  >
                                                    <a
                                                      href={ite}
                                                      target="_blank"
                                                      className="d-block"
                                                      rel="noopener noreferrer"
                                                      style={{
                                                        width: "100%",
                                                        display: "block",
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                          "ellipsis",
                                                        overflow: "hidden",
                                                      }}
                                                    >
                                                      {ite}
                                                    </a>
                                                  </li>
                                                );
                                              });
                                          } else {
                                            return (
                                              <li
                                                className="py-1"
                                                key={onindex}
                                              >
                                                <a
                                                  href={item}
                                                  target="_blank"
                                                  className="d-block"
                                                  rel="noopener noreferrer"
                                                  style={{
                                                    width: "100%",
                                                    display: "block",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {item}
                                                </a>
                                              </li>
                                            );
                                          }
                                        }
                                      )}
                                    </ul>
                                  </>
                                ) : null}

                                {plan.tp_supportedfiles &&
                                plan.tp_supportedfiles.length > 0 ? (
                                  <>
                                    <h6 className="color-dgreen wtl">
                                      Supported Files
                                    </h6>
                                    <ul className="wtllist mb-4">
                                      {plan.tp_supportedfiles.map(
                                        (item, onindex) => {
                                          if (item) {
                                            return (
                                              <li
                                                className="py-1"
                                                key={onindex}
                                              >
                                                <a
                                                  href={`${imgURL}/${item}`}
                                                  download
                                                  className="d-block"
                                                  rel="noopener noreferrer"
                                                  style={{
                                                    width: "100%",
                                                    display: "block",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {item.split("-")[1]}
                                                </a>
                                              </li>
                                            );
                                          } else {
                                            return (
                                              <li
                                                className="py-1"
                                                key={onindex}
                                              >
                                                <a
                                                  href={item}
                                                  download
                                                  target="_blank"
                                                  className="d-block"
                                                  rel="noopener noreferrer"
                                                  style={{
                                                    width: "100%",
                                                    display: "block",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {item}
                                                </a>
                                              </li>
                                            );
                                          }
                                        }
                                      )}
                                    </ul>
                                  </>
                                ) : null}

                                <div className="row">
                                  <div className="col">
                                    <div>
                                      {props.videos[0] &&
                                      props.videos[0].videos &&
                                      props.videos[0].videos.find(
                                        (item) => item.day
                                      ) ? (
                                        <p
                                          style={{ fontWeight: "600" }}
                                          className="my-2"
                                        >
                                          Videos
                                        </p>
                                      ) : (
                                        <></>
                                      )}
                                      <div id="accordionVideos">
                                        {props.videos &&
                                          props.videos.map((vitem, vindex) => {
                                            return (
                                              <div
                                                className="border-0 mb-0"
                                                key={vitem._id}
                                              >
                                                <div id={"myacc" + vindex}>
                                                  <div className="">
                                                    <h4>{vitem.topic}</h4>
                                                    {vitem.videos.map(
                                                      (vid, vvindex) => {
                                                        return (
                                                          <>
                                                            {vid.day ===
                                                              plan.tp_day &&
                                                              vitem.cid ===
                                                                plan.cid && (
                                                                <div className="col-12 res-item">
                                                                  <p
                                                                    className="d-block text-primary"
                                                                    style={{
                                                                      cursor:
                                                                        "pointer",
                                                                    }}
                                                                    onClick={() => {
                                                                      setPlayingVideo(
                                                                        {
                                                                          link: `${global.SERVER}/${vid.filename}`,
                                                                        }
                                                                      );
                                                                    }}
                                                                  >
                                                                    {vid.filename
                                                                      .split(
                                                                        "-"
                                                                      )
                                                                      .slice(
                                                                        1,
                                                                        5
                                                                      )
                                                                      .join(" ")
                                                                      .replace(
                                                                        ".m3u8",
                                                                        ""
                                                                      )
                                                                      .replace(
                                                                        ".ir",
                                                                        ""
                                                                      )
                                                                      .replace(
                                                                        ".ir_",
                                                                        ""
                                                                      )}{" "}
                                                                  </p>
                                                                  {playingVideo.link ===
                                                                    `${global.SERVER}/${vid.filename}` && (
                                                                    <figure>
                                                                      <Reacthlsplayer
                                                                        id="video"
                                                                        src={
                                                                          playingVideo.link
                                                                        }
                                                                        className="my-2"
                                                                        style={{
                                                                          width:
                                                                            "800px",
                                                                          boxShadow:
                                                                            "2px 5px 10px 5px #D3D3D3",
                                                                        }}
                                                                        playerRef={
                                                                          video
                                                                        }
                                                                        controls
                                                                      ></Reacthlsplayer>
                                                                    </figure>
                                                                  )}
                                                                </div>
                                                              )}
                                                          </>
                                                        );
                                                      }
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          })}
                                      </div>

                                      <div className="">
                                        {props.course.ppts &&
                                        props.course.ppts.length > 0 ? (
                                          <></>
                                        ) : (
                                          <p
                                            style={{ fontWeight: "600" }}
                                            className=""
                                          >
                                            Powerpoint Presentaion
                                          </p>
                                        )}

                                        {props.course.ppts &&
                                          props.course.ppts.map(
                                            (pitem, pptindex) => {
                                              if (pitem.day === plan.tp_day) {
                                                no++;
                                                return (
                                                  <div
                                                    className=" ppt ml-5"
                                                    key={pptindex}
                                                  >
                                                    <div className="rounded">
                                                      <div className="pb-2">
                                                        <div className="col-12 col-md-6 col-xl-3 res-item">
                                                          <a
                                                            className="d-block text-decoration-none"
                                                            href={`https://view.officeapps.live.com/op/embed.aspx?src=${pitem.filename}`}
                                                            target="blank"
                                                          >
                                                            <div className="res-item-inner">
                                                              <div className="res-img">
                                                                <img
                                                                  src={
                                                                    require("../../assets/images/ppt.png")
                                                                      .default
                                                                  }
                                                                  alt="presentation"
                                                                  width="80"
                                                                  height="80"
                                                                />
                                                              </div>
                                                              <div className="res-header d-flex justify-content-between align-items-center px-4 py-2">
                                                                <h5 className="text-dark m-0">
                                                                  Lesson{" "}
                                                                  {index + 1}
                                                                </h5>
                                                                <div>
                                                                  <img
                                                                    src={
                                                                      require("../../assets/images/play_video_player_icon.svg")
                                                                        .default
                                                                    }
                                                                    alt="presentation"
                                                                    width="22"
                                                                    height="16"
                                                                  />
                                                                </div>
                                                              </div>
                                                            </div>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            }
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                {plan.formattedNotePlan ? (
                                  <>
                                    <div className="alert alert-warning">
                                      <b>Note :</b>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: plan.formattedNotePlan,
                                        }}
                                      ></div>
                                    </div>
                                    {token &&
                                      jwt_decode(token).role === "Student" && (
                                        <div className="d-flex flex-row-reverse">
                                          {progress &&
                                          progress.indexOf(plan._id) === -1 ? (
                                            <button
                                              className="btn btn-blue float-right py-1 px-2 mb-5"
                                              onClick={() => {
                                                return handleCourseComplete(
                                                  plan._id,
                                                  plan.cid
                                                );
                                              }}
                                            >
                                              Mark as Completed
                                            </button>
                                          ) : (
                                            <button className="btn btn-success float-right py-1 px-2 mb-5">
                                              Completed
                                            </button>
                                          )}
                                        </div>
                                      )}
                                  </>
                                ) : (
                                  <>
                                    <div className="mb-5">
                                      {token &&
                                        jwt_decode(token).role ===
                                          "Student" && (
                                          <div className="d-flex flex-row-reverse">
                                            {progress &&
                                            progress.indexOf(plan._id) ===
                                              -1 ? (
                                              <button
                                                className="btn btn-blue float-right py-1 px-2"
                                                onClick={() => {
                                                  return handleCourseComplete(
                                                    plan._id,
                                                    plan.cid
                                                  );
                                                }}
                                              >
                                                Mark as Completed
                                              </button>
                                            ) : (
                                              <button className="btn btn-success float-right py-1 px-2">
                                                Completed
                                              </button>
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </>
                                )}
                                <div class="d-flex justify-content-between">
                                  <a
                                    // className="disabled scrollLink"
                                    href={`#${plan.tp_day - 1}`}
                                    value={plan.tp_day}
                                    className={`${
                                      plan.tp_day > 1
                                        ? ""
                                        : "disabled-link scrollLink visually-hidden"
                                    }`}
                                  >
                                    Previous
                                  </a>{" "}
                                  <br />
                                  <a
                                    // href={`#${plan.tp_day + 1}`}

                                    onClick={() => {
                                      handleCompletedModule(plan.tp_day + 1);
                                    }}
                                    value={plan.tp_day}
                                    className={`${
                                      props.course.modules.length == plan.tp_day
                                        ? "disabled-link scrollLink visually-hidden"
                                        : "scrollLink"
                                    }`}
                                  >
                                    Next
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                      : plan.tp_day == 1 && (
                          <div id={plan.tp_day}>
                            <h3 id={"headingt" + (index + 1)}>
                              <div>Module {plan.tp_day}</div>
                            </h3>
                            <div
                              id={"collapset" + (index + 1)}
                              className="accordion-collapse"
                            >
                              <div className="accordion-body px-0">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: plan.formattedWhatToLearn,
                                  }}
                                />

                                <ul className="wtllist mb-4 d-none">
                                  {plan.tp_practice &&
                                    plan.tp_practice
                                      .split("\n")
                                      .map((item, tpindex) => {
                                        if (
                                          item.match(/^--http/g) ||
                                          item.match(/^http/g)
                                        ) {
                                          return (
                                            <>
                                              {item.includes("--") ? (
                                                <ul>
                                                  <li>
                                                    <a
                                                      href={item.split("--")[2]}
                                                      target="_blank"
                                                      className="d-block"
                                                      rel="noopener noreferrer"
                                                      style={{
                                                        width: "100%",
                                                        display: "block",
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                          "ellipsis",
                                                        overflow: "hidden",
                                                      }}
                                                    >
                                                      {item
                                                        .split("--")
                                                        .slice(1, 2)}
                                                    </a>
                                                  </li>
                                                </ul>
                                              ) : (
                                                <li>
                                                  <a
                                                    href={item}
                                                    target="_blank"
                                                    className="d-block"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                      width: "100%",
                                                      display: "block",
                                                      whiteSpace: "nowrap",
                                                      textOverflow: "ellipsis",
                                                      overflow: "hidden",
                                                    }}
                                                  >
                                                    {item}
                                                  </a>
                                                </li>
                                              )}
                                            </>
                                          );
                                        } else {
                                          return (
                                            <>
                                              {item.includes("--") ? (
                                                <ul>
                                                  <li>
                                                    <p key={tpindex}>
                                                      {item
                                                        .split("--")
                                                        .slice(1, 2)}
                                                    </p>
                                                  </li>
                                                </ul>
                                              ) : (
                                                <li>
                                                  <p key={tpindex}>{item}</p>
                                                </li>
                                              )}
                                            </>
                                          );
                                        }
                                      })}
                                </ul>

                                {plan.formattedPractices &&
                                  plan.formattedPractices.length > 0 && (
                                    <h6>Practice Exercise</h6>
                                  )}
                                <ul className="wtllist mb-4">
                                  {plan.formattedPractices.map(
                                    (item, index) => {
                                      let text = item.replace(
                                        /(\r\n|\r|\n)/g,
                                        "<br>"
                                      );
                                      return (
                                        <li
                                          dangerouslySetInnerHTML={{
                                            __html: text,
                                          }}
                                        ></li>
                                      );
                                    }
                                  )}
                                </ul>

                                {plan.formattedAssignments &&
                                  plan.formattedAssignments.length > 0 && (
                                    <h6>Assingment Exercise</h6>
                                  )}
                                <ul className="wtllist mb-4">
                                  {plan.formattedAssignments.map(
                                    (item, index) => {
                                      let text = item.replace(
                                        /(\r\n|\r|\n)/g,
                                        "<br>"
                                      );
                                      return (
                                        <li
                                          dangerouslySetInnerHTML={{
                                            __html: text,
                                          }}
                                        ></li>
                                      );
                                    }
                                  )}
                                </ul>
                                {plan.tp_assignmentimgarr &&
                                plan.tp_assignmentimgarr.length > 0 ? (
                                  <div className="m-0 d-inline-block">
                                    {plan.tp_assignmentimgarr.map(
                                      (item, aindex) => {
                                        return (
                                          <img
                                            src={`${imgURL}/${item}`}
                                            alt="assignmentimg"
                                            key="aindex"
                                            className="m-1"
                                            style={{
                                              cursor: "pointer",
                                              maxWidth: "100%",
                                            }}
                                          />
                                        );
                                      }
                                    )}
                                  </div>
                                ) : null}

                                <ul className="wtllist mb-4 d-none">
                                  {plan.tp_assignment
                                    ? plan.tp_assignment
                                        .split("\n")
                                        .map((item, taindex) => {
                                          if (
                                            item.match(/^--http/g) ||
                                            item.match(/^http/g)
                                          ) {
                                            return (
                                              <>
                                                {item.includes("--") ? (
                                                  <ul>
                                                    <li>
                                                      <a
                                                        href={
                                                          item.split("--")[2]
                                                        }
                                                        target="_blank"
                                                        className="d-block"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                          width: "100%",
                                                          display: "block",
                                                          whiteSpace: "nowrap",
                                                          textOverflow:
                                                            "ellipsis",
                                                          overflow: "hidden",
                                                        }}
                                                      >
                                                        {item
                                                          .split("--")
                                                          .slice(1, 2)}
                                                      </a>
                                                    </li>
                                                  </ul>
                                                ) : (
                                                  <li>
                                                    <a
                                                      href={item}
                                                      target="_blank"
                                                      className="d-block"
                                                      rel="noopener noreferrer"
                                                      style={{
                                                        width: "100%",
                                                        display: "block",
                                                        whiteSpace: "nowrap",
                                                        textOverflow:
                                                          "ellipsis",
                                                        overflow: "hidden",
                                                      }}
                                                    >
                                                      {item}
                                                    </a>
                                                  </li>
                                                )}
                                              </>
                                            );
                                          } else {
                                            return (
                                              <>
                                                {item.includes("--") ? (
                                                  <ul>
                                                    <li>
                                                      <p>
                                                        {item
                                                          .split("--")
                                                          .slice(1, 2)}
                                                      </p>
                                                    </li>
                                                  </ul>
                                                ) : (
                                                  <li>
                                                    <p>{item}c</p>
                                                  </li>
                                                )}
                                              </>
                                            );
                                          }
                                        })
                                    : null}
                                </ul>

                                <ul className="wtllist mb-4">
                                  {plan.formattedAssignments.map(
                                    (item, index) => {
                                      return (
                                        <li key={index}>
                                          <a
                                            target="_blank"
                                            // href={Object.values(item)[0]}
                                          >
                                            {/* {Object.values(item)[0]} */}
                                            {item}
                                          </a>
                                        </li>
                                      );
                                    }
                                  )}
                                </ul>

                                {plan.tp_onlineref &&
                                plan.tp_onlineref.length > 0 ? (
                                  <>
                                    {plan.tp_onlineref.length > 0 &&
                                    plan.tp_onlineref[0] !== "" ? (
                                      <h6 className="color-dgreen wtl">
                                        Online Reference
                                      </h6>
                                    ) : (
                                      <></>
                                    )}
                                    <ul className="wtllist mb-4">
                                      {plan.tp_onlineref.length > 0 &&
                                        plan.tp_onlineref[0] !== "" &&
                                        plan.tp_onlineref.map(
                                          (item, onindex) => {
                                            if (item.split(",").length > 1) {
                                              return item
                                                .split(",")
                                                .map((ite, index1) => {
                                                  return (
                                                    <li
                                                      className="py-1"
                                                      key={onindex}
                                                    >
                                                      <a
                                                        href={ite}
                                                        target="_blank"
                                                        className="d-block"
                                                        rel="noopener noreferrer"
                                                        style={{
                                                          width: "100%",
                                                          display: "block",
                                                          whiteSpace: "nowrap",
                                                          textOverflow:
                                                            "ellipsis",
                                                          overflow: "hidden",
                                                        }}
                                                      >
                                                        {ite}
                                                      </a>
                                                    </li>
                                                  );
                                                });
                                            } else {
                                              // return item
                                              //   // .split(",")
                                              //   .map((ite, index1) => {
                                              return (
                                                <li
                                                  className="py-1"
                                                  key={onindex}
                                                >
                                                  <a
                                                    href={item}
                                                    target="_blank"
                                                    className="d-block"
                                                    rel="noopener noreferrer"
                                                    style={{
                                                      width: "100%",
                                                      display: "block",
                                                      whiteSpace: "nowrap",
                                                      textOverflow: "ellipsis",
                                                      overflow: "hidden",
                                                    }}
                                                  >
                                                    {item}
                                                  </a>
                                                </li>
                                              );
                                              // });
                                            }
                                          }
                                        )}
                                    </ul>
                                  </>
                                ) : null}

                                {plan.tp_supportedfiles &&
                                plan.tp_supportedfiles.length > 0 ? (
                                  <>
                                    <h6 className="color-dgreen wtl">
                                      Supported Files
                                    </h6>
                                    <ul className="wtllist mb-4">
                                      {plan.tp_supportedfiles.map(
                                        (item, onindex) => {
                                          if (item) {
                                            return (
                                              <li
                                                className="py-1"
                                                key={onindex}
                                              >
                                                <a
                                                  href={`${imgURL}/${item}`}
                                                  download
                                                  className="d-block"
                                                  rel="noopener noreferrer"
                                                  style={{
                                                    width: "100%",
                                                    display: "block",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {item.split("-")[1]}
                                                </a>
                                              </li>
                                            );
                                          } else {
                                            // return item
                                            //   // .split(",")
                                            //   .map((ite, index1) => {
                                            return (
                                              <li
                                                className="py-1"
                                                key={onindex}
                                              >
                                                <a
                                                  href={item}
                                                  download
                                                  target="_blank"
                                                  className="d-block"
                                                  rel="noopener noreferrer"
                                                  style={{
                                                    width: "100%",
                                                    display: "block",
                                                    whiteSpace: "nowrap",
                                                    textOverflow: "ellipsis",
                                                    overflow: "hidden",
                                                  }}
                                                >
                                                  {item}
                                                </a>
                                              </li>
                                            );
                                            // });
                                          }
                                        }
                                      )}
                                    </ul>
                                  </>
                                ) : null}

                                <div>
                                  {props.videos[0] &&
                                  props.videos[0].videos &&
                                  props.videos[0].videos.find(
                                    (item) => item.day
                                  ) ? (
                                    <p
                                      style={{ fontWeight: "600" }}
                                      className="my-2"
                                    >
                                      Videos
                                    </p>
                                  ) : (
                                    <></>
                                  )}
                                  <div id="accordionVideos">
                                    {props.videos &&
                                      props.videos.map((vitem, vindex) => {
                                        return (
                                          <div
                                            className="border-0 mb-0"
                                            key={vitem._id}
                                          >
                                            <div id={"myacc" + vindex}>
                                              <div className="">
                                                <h4>{vitem.topic}</h4>
                                                {vitem.videos.map(
                                                  (vid, vvindex) => {
                                                    return (
                                                      <>
                                                        {vid.day ===
                                                          plan.tp_day &&
                                                          vitem.cid ===
                                                            plan.cid && (
                                                            <div className="col-12 res-item">
                                                              <p
                                                                className="d-block text-primary "
                                                                style={{
                                                                  cursor:
                                                                    "pointer",
                                                                }}
                                                                onClick={() => {
                                                                  setPlayingVideo(
                                                                    {
                                                                      link: `${global.SERVER}/${vid.filename}`,
                                                                    }
                                                                  );
                                                                }}
                                                              >
                                                                {vid.filename
                                                                  .split("-")
                                                                  .slice(1, 5)
                                                                  .join(" ")
                                                                  .replace(
                                                                    ".m3u8",
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    ".ir",
                                                                    ""
                                                                  )
                                                                  .replace(
                                                                    ".ir_",
                                                                    ""
                                                                  )}{" "}
                                                              </p>
                                                              {playingVideo.link ===
                                                                `${global.SERVER}/${vid.filename}` && (
                                                                <figure>
                                                                  <Reacthlsplayer
                                                                    id="video"
                                                                    src={
                                                                      playingVideo.link
                                                                    }
                                                                    className="my-2"
                                                                    style={{
                                                                      width:
                                                                        "800px",
                                                                      boxShadow:
                                                                        "2px 5px 10px 5px #D3D3D3",
                                                                    }}
                                                                    playerRef={
                                                                      video
                                                                    }
                                                                    controls
                                                                  ></Reacthlsplayer>
                                                                </figure>
                                                              )}
                                                            </div>
                                                          )}
                                                      </>
                                                    );
                                                  }
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>

                                  <div className="">
                                    {props.course.ppts &&
                                    props.course.ppts.length > 0 ? (
                                      <></>
                                    ) : (
                                      <p
                                        style={{ fontWeight: "600" }}
                                        className=""
                                      >
                                        Powerpoint Presentaion
                                      </p>
                                    )}

                                    {props.course.ppts &&
                                      props.course.ppts.map(
                                        (pitem, pptindex) => {
                                          if (pitem.day === plan.tp_day) {
                                            no++;
                                            return (
                                              <div
                                                className=" ppt ml-5"
                                                key={pptindex}
                                              >
                                                <div className=" rounded">
                                                  <div className="pb-2">
                                                    <div className="col-12 col-md-6 col-xl-3 res-item">
                                                      <a
                                                        className="d-block text-decoration-none"
                                                        href={`https://view.officeapps.live.com/op/embed.aspx?src=${pitem.filename}`}
                                                        target="blank"
                                                      >
                                                        <div className="res-item-inner">
                                                          <div className="res-img">
                                                            <img
                                                              src={
                                                                require("../../assets/images/ppt.png")
                                                                  .default
                                                              }
                                                              alt="presentation"
                                                              width="80"
                                                              height="80"
                                                            />
                                                          </div>
                                                          <div className="res-header d-flex justify-content-between align-items-center px-4 py-2">
                                                            <h5 className="text-dark m-0">
                                                              Lesson {index + 1}
                                                            </h5>
                                                            <div>
                                                              <img
                                                                src={
                                                                  require("../../assets/images/play_video_player_icon.svg")
                                                                    .default
                                                                }
                                                                alt="presentation"
                                                                width="22"
                                                                height="16"
                                                              />
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </a>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                </div>
                                {plan.formattedNotePlan ? (
                                  <>
                                    <div className="alert alert-warning mb-5">
                                      <b>Note :</b>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: plan.formattedNotePlan,
                                        }}
                                      ></div>
                                    </div>
                                    {token &&
                                      jwt_decode(token).role === "Student" && (
                                        <div className="d-flex flex-row-reverse">
                                          {progress &&
                                          progress.indexOf(plan._id) === -1 ? (
                                            <button
                                              className="btn btn-blue float-right py-1 px-2 mb-5"
                                              onClick={() => {
                                                return handleCourseComplete(
                                                  plan._id,
                                                  plan.cid
                                                );
                                              }}
                                            >
                                              Mark as Completed
                                            </button>
                                          ) : (
                                            <button className="btn btn-success float-right py-1 px-2 mb-5">
                                              Completed
                                            </button>
                                          )}
                                        </div>
                                      )}
                                  </>
                                ) : (
                                  <>
                                    <div className="mb-5">
                                      {token &&
                                        jwt_decode(token).role ===
                                          "Student" && (
                                          <div className="d-flex flex-row-reverse">
                                            {progress &&
                                            progress.indexOf(plan._id) ===
                                              -1 ? (
                                              <button
                                                className="btn btn-blue float-right py-1 px-2"
                                                onClick={() => {
                                                  return handleCourseComplete(
                                                    plan._id,
                                                    plan.cid
                                                  );
                                                }}
                                              >
                                                Mark as Completed
                                              </button>
                                            ) : (
                                              <button className="btn btn-success float-right py-1 px-2">
                                                Completed
                                              </button>
                                            )}
                                          </div>
                                        )}
                                    </div>
                                  </>
                                )}
                                <div class="d-flex justify-content-between">
                                  <a
                                    className={`${
                                      plan.tp_day > 1
                                        ? "scrollLink"
                                        : "disabled-link scrollLink hide"
                                    }`}
                                    // href={`#${plan.tp_day - 1}`}
                                    value={plan.tp_day}
                                  >
                                    Previous
                                  </a>
                                  <a
                                    href={`#${plan.tp_day + 1}`}
                                    value={plan.tp_day}
                                    className="scrollLink"
                                  >
                                    Next
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                  })}{" "}
              </div>
            </section>
          ) : (
            <div class="pt-lg-4 align-items-center tab-contents">
              <div class="text-center my-lg-5 my-3">
                <img
                  src={datanotfoundimage}
                  width="450px"
                  className="datanotfoundimage"
                />
                <h3 class="mt-lg-4 mt-3">
                  We currently don't have any training plan for this course.
                </h3>
              </div>
            </div>
          )}
        </div>
      </>
    );
  } else {
    return (
      <div class="pt-lg-4 align-items-center tab-contents">
        <div class="text-center my-lg-5 my-3">
          <img
            src={datanotfoundimage}
            width="450px"
            className="datanotfoundimage"
          />
          <h3 class="mt-lg-4 mt-3">
            We currently don't have any training plan for this course.
          </h3>
        </div>
      </div>
    );
  }
}
