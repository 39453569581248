import React, { useState } from "react";
import CourseService from "../../services/CourseService";
import formloader from "../../images/formloading.svg";
import { useNavigate } from "react-router-dom";

const AddCategory = () => {
  const [category, setCategory] = useState("");

  const [loading, setLoading] = useState(false);
  const [confirmBox, setConfirmBox] = useState(false);

  const [msg, setMsg] = useState("");

  const [validate, setValidate] = useState(false);
  const navigate = useNavigate();

  const handleAddCategory = async (e) => {
    e.preventDefault();
    setValidate(true);
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/login");
    } else {
      const { name } = category;
      if (name) {
        setLoading(true);
        
        CourseService.postCategory(category, token)
          .then((res) => {
            if (res.data.msg) {
              setMsg("Category Added Successfully...");
              setConfirmBox(true);
            }
          })
          .catch((ex) => console.log(ex));

        setValidate(false);
        setCategory({
          name: "",
        });
        document.getElementById("myform2").reset();
      }
      setLoading(false);
    }
    {
      setTimeout(() => setConfirmBox(false), 3000);
    }
  };

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0 py-2">
            <li
              className={"breadcrumb-item text-primary"}
              style={{ cursor: "pointer" }}
              onClick={() => {
                navigate("/admin/course/manage-category");
              }}
            >
              Manage Categories
            </li>

            <li className={"breadcrumb-item active"}>Add Category</li>
          </ol>
        </nav>
      </div>

      <div className="container p-2 px-3">
        {loading && (
          <div className="formloader">
            <div className="row text-center">
              <div className="col-12">
                <img src={formloader} alt="" height="100" />
              </div>
              <div className="col-12 text-white h4">Loading...</div>
            </div>
          </div>
        )}
        {confirmBox && (
          <div className="formloade">
            <div className="row text-center">
              <div className="col-6 w-100 confirmbox">
                <div className="alert alert-success">{msg}</div>
              </div>
            </div>
          </div>
        )}

        <div className="formloade">
          <div className="row">
            <div className="col-6 w-100 confirmbox">
              <form
                onSubmit={handleAddCategory}
                className="px-lg-5 py-3"
                method="post"
                id="myform2"
              >
                <div className="mb-3 position-relative">
                  <label htmlFor="cname" className="form-label">
                    Category name
                  </label>
                  <input
                    type="text"
                    id="cname"
                    maxLength={20}
                    className="form-control"
                    value={category.cname}
                    style={
                      category.cname || !validate
                        ? null
                        : {
                            border: "1px solid #df4957",
                            boxShadow: "0 0 5px #df4957",
                          }
                    }
                    onChange={(e) =>
                      setCategory((d) => ({ ...d, name: e.target.value }))
                    }
                  />
                </div>
                {/* {!category && validate && (
                    <div
                      className="mb-2 rounded px-2 py-1 text-white"
                      style={{
                        background: "rgba(220,53,69,.9)",
                        zIndex: "1",
                      }}
                    >
                      Please enter category name
                    </div>
                  )} */}
                   {validate  && (
                    <div
                      className="mb-2 rounded px-2 py-1 text-white"
                      style={{
                        background: "rgba(220,53,69,.9)",
                        zIndex: "1",
                      }}
                    >
                      Please enter valid category name
                    </div>
                  )}
                <button type="submit" className="btn btn-success">
                  Add Category
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCategory;
