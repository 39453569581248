function format(date) {
  const formattedDate = new Date(date);

  // Get date components
  const year = formattedDate.getFullYear();
  const month = (formattedDate.getMonth() + 1).toString().padStart(2, '0');
  const day = formattedDate.getDate().toString().padStart(2, '0');

  // Get time components
  const hours = formattedDate.getHours().toString().padStart(2, '0');
  const minutes = formattedDate.getMinutes().toString().padStart(2, '0');
  const seconds = formattedDate.getSeconds().toString().padStart(2, '0');

  // Combine date and time components
  const formattedDateTime = `${year}-${month}-${day} at ${hours}:${minutes}:${seconds}`;

  return formattedDateTime;
};

module.exports = { format }
