import React, { useEffect, useState } from "react";
import global from "../../servicesvariable";
import { handleImageLoadingError } from "../../utils/image/handleImageLoadingError";
import "./courseListBanner.scss";

export default function CourseBanner(props) {
  const {
    course,
    tcounter,
    users,
    filterSubCourse,
    // allsubcourse,
  } = props;
  const [sumTotalHours, setSumTotalHours] = useState(0);

  useEffect(() => {
    setSumTotalHours(0);
    tcounter.map((i) => (setSumTotalHours((prev) => prev += Math.ceil(i.length * 8.5))));
  }, [tcounter])

  
  return (
    <div class="course-banner bg-light-gray">
      <div class="container">
        <div class="row align-items-center">
          <div class="col-lg-8 col-xl-9 text-dark-gray order-2 order-lg-1">
            <h1 class="mb-2 mb-md-4">{course?.name?.replaceAll("_", " ")}</h1>
            <p>{course.desc}</p>
            <div class="d-none d-lg-block">
              <span class="btn btn-white py-2 me-2 me-lg-3 mb-2 d-inline-block">
                {filterSubCourse.length > 1 ? `${filterSubCourse.length} courses` : `${filterSubCourse.length} course`}
              </span>
              <span class="btn btn-white py-2 me-2 me-lg-3 mb-2 d-inline-block">
                {/* {tcounter[0] && Math.ceil(tcounter[0].length * 8.5)}  */}
                {sumTotalHours + " "}
                Learning Hours
              </span>

              <span class="btn btn-blue py-2 d-inline-block mb-2">
                {users > 0 ? (
                  <>{users} Course you have subscribed</>
                ) : (
                  <>You have not subscribed any course</>
                )}
              </span>
            </div>
          </div>
          <div class="col-lg-4 col-xl-3 text-lg-center order-1 order-lg-2">
            {course.icon &&
              (course.icon == "default.jpg" ? (
                <img
                  src={`./../${course.icon}`}
                  class="card-img-top sub-course-img mb-lg-0 mb-4"
                  alt={course.icon}
                  height="150"
                  width="150"
                  data-level="1"
                  onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
                ></img>
              ) : course.icon.match(/http/g) ? (
                <img
                  src={course.icon}
                  class="card-img-top sub-course-img mb-lg-0 mb-4"
                  alt={course.icon}
                  height="150"
                  width="150"
                  data-level="1"
                  onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
                />
              ) : (
                <>
                  <img
                    src={`${global.imgURL}/${course.icon}`}
                    class="card-img-top sub-course-img mb-lg-0 mb-4"
                    alt={course.name}
                    height="150"
                    width="150"
                    data-level="1"
                    onError={(e) => { if (parseInt(e.target.dataset.level) === 1) handleImageLoadingError(e) }}
                  ></img>
                </>
              ))}
          </div>
          <div class="d-block d-lg-none mt-3 text-center order-3">
            <span class="btn btn-white py-2 d-inline-block me-2 my-2">
              {filterSubCourse.length} courses
            </span>
            <span class="btn btn-white py-2 d-inline-block me-2 my-2">
              {tcounter[0] && Math.ceil(tcounter[0].length * 8.5)} Learning
              Hours
            </span>
            <span class="btn btn-blue py-2 d-inline-block me-2 my-2">
              {users > 0 ? (
                <>{users} Course you have subscribed</>
              ) : (
                <>You have not subscribed any course</>
              )}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
