import React, { useEffect } from "react";
import { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

export default function TestCaseSpec() {
  const [page, setPage] = useState({ selected: 1, choice: "" });
  const navigate = useNavigate();
  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0 py-2">
            <li
              className={
                page.selected === 1
                  ? "breadcrumb-item active"
                  : "breadcrumb-item text-primary"
              }
              style={{ cursor: page.selected > 1 ? "pointer" : "auto" }}
              onClick={() => {
                setPage({ selected: 1, choice: "" });
                navigate("/admin/manage-spec/list");
              }}
            >
              TestCaseSpec
            </li>
            <li
              className={
                page.selected === 2 ? "breadcrumb-item active" : "d-none"
              }
            >
              {page.choice}
            </li>
          </ol>
        </nav>
        {page.selected === 1 && (
          <button
            onClick={() => {
              setPage({ selected: 2, choice: "Add Spec" });
              navigate("/admin/manage-spec/create");
            }}
            className="btn btn-primary"
          >
            Add Spec
          </button>
        )}
      </div>
      <div className="conatiner">
        <Outlet />
      </div>
    </>
  );
}
