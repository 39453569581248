import jwt_decode from "jwt-decode";
import {
  FaCloudUploadAlt,
  FaEdit,
  FaFileExcel,
  FaFileExport,
  FaPlus,
  FaPlusCircle,
} from "react-icons/fa";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import UserListing from "./UserListing";

export default function ManageUser() {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  return (
    <>
      <div className="px-3 py-1 border-bottom d-flex justify-content-between align-items-center">
        <nav>
          <ol className="breadcrumb mb-0 py-2">
            <li
              className={
                pathname === "/admin/user/manage"
                  ? "breadcrumb-item active"
                  : "breadcrumb-item text-primary"
              }
              style={{
                cursor: pathname !== "/admin/user/manage" ? "pointer" : "auto",
              }}
              onClick={() => {
                navigate("/admin/user/manage");
              }}
            >
              Manage Users
            </li>
            {pathname === "/admin/user/manage/add" && (
              <li className="breadcrumb-item active">Add User</li>
            )}
            {pathname.includes("/admin/user/manage/update/") ? (
              <>
                <li className="breadcrumb-item active">Update User</li>
                {!!state && (
                  <li className="breadcrumb-item active"> {state.username}</li>
                )}
              </>
            ) : null}
            {pathname === "/admin/user/manage/update-with-excel" && (
              <li className="breadcrumb-item active">
                Update users using excel
              </li>
            )}
          </ol>
        </nav>
        <div className="me-5">
          {pathname === "/admin/user/manage" &&
            ["Admin", "HR"].includes(jwt_decode(localStorage.getItem("token")).role) && (
              <button
                htmlFor="adcourse"
                className="btn bg-green text-white mx-1"
                onClick={() => {
                  navigate("/admin/user/manage/add");
                }}
              >
                <FaPlusCircle className="me-2 fs-5" />
                Add User
              </button>
            )}
          {pathname === "/admin/user/manage" &&
            jwt_decode(localStorage.getItem("token")).role === "Admin" && (
              <button
                htmlFor="adcourse2"
                className="btn bg-green text-white mx-1"
                onClick={() => {
                  navigate("/admin/user/manage/update-with-excel");
                }}
              >
                <FaEdit className="me-2 " />
                Update users using excel
              </button>
            )}
        </div>
      </div>

      <div className="p-2 px-3 mx-2">
        {pathname === "/admin/user/manage" ? <UserListing /> : null}
        <Outlet />
      </div>
    </>
  );
}
