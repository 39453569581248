import React, { useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import LoginService from "../../services/LoginService";
import Header from "../../components/header/Header";
import { useNavigate, useParams } from "react-router-dom";

export default function Login(props) {
  const [user, setUser] = useState({});
  const [confirmBox, setConfirmBox] = useState(false);
  const [msg, setMsg] = useState({ msg: "", success: false });
  const navigate = useNavigate();
  const { resetoken } = useParams();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      navigate("/login");
    } else {
      if (resetoken) {
        const tok = resetoken.replace("%20", " ");
        const decoded = jwt_decode(tok);
        if (decoded.for === "forgotpassword") {
          setUser(decoded);
        } else {
          navigate("/login");
        }
      } else {
        navigate("/forgot-password");
      }
    }
  }, []);

  const handleResetPassword = (e) => {
    e.preventDefault();
    if (user?.newpass === user?.conpass && user?.newpass?.length > 3) {
      const tok = resetoken.replace("%20", " ");
      LoginService.postResetPassword(user, tok)
        .then((res) => {
          if (res.data.msg) {
            setConfirmBox(true);
            setMsg({ msg: res.data.msg, success: res.data.success });
            setTimeout(() => {
              setConfirmBox(false);
            }, 3000);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err) {
            if (err["response"].status === 404) {
              console.log(err["response"].data.msg);
              setMsg({
                msg: err["response"].data.msg,
                success: err["response"].data.success,
              });
              setConfirmBox(true);
              setTimeout(() => {
                setConfirmBox(false);
                navigate("/forgot-password");
              }, 3000);
            }
          }
        });
    } else {
      if (user?.newpass?.length < 4 || user?.conpass?.length < 4) {
        setConfirmBox(true);
        setMsg({ msg: "Password length should require a Minimum of 3" });
      } else {
        setConfirmBox(true);
        setMsg({ msg: "Your old and new password does not match" });
      }
      setTimeout(() => setConfirmBox(false), 3000);
    }
  };

  return (
    <>
      {confirmBox && (
        <div className="formloader">
          <div className="row text-center">
            <div className="col-6 w-100 confirmbox">
              <div
                className={
                  msg.success ? "alert alert-success" : "alert alert-danger"
                }
              >
                {msg.msg}
              </div>
              {msg.success && (
                <button
                  className="btn btn-success"
                  onClick={() => {
                    setConfirmBox(false);
                    navigate("/login");
                  }}
                >
                  Goto Login
                </button>
              )}
            </div>
          </div>
        </div>
      )}
      {/* <Header text="Reset Password" /> */}
      <section className="container login">
        <div className="row m-0 py-5">
          <div className="col-lg-6 mx-auto bg-white py-5 formcard">
            <h2 className="text-center heading">Reset your password?</h2>
            <p className="text-center py-2"></p>
            <form
              onSubmit={handleResetPassword}
              className="px-lg-4 pt-2 pb-4"
              method="post"
            >
              <div className="mb-3">
                <label htmlFor="npass" className="form-label color-dback ps-1">
                  Enter new password
                </label>
                <input
                  type="password"
                  name="newpass"
                  id="npass"
                  placeholder="New password"
                  className="form-control"
                  onChange={(e) => {
                    setUser((d) => ({ ...d, newpass: e.target.value }));
                  }}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="npass" className="form-label color-dback ps-1">
                  Confirm new password
                </label>
                <input
                  type="password"
                  name="newpass"
                  id="npass"
                  placeholder="Confirm password"
                  className="form-control"
                  onChange={(e) => {
                    setUser((d) => ({ ...d, conpass: e.target.value }));
                  }}
                  required
                />
              </div>
              <div className="footer text-center pt-4">
                <button type="submit" className="btn bg-green text-white">
                  Reset password
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
