import axios from "axios";
import variable from "../servicesvariable";

class TestcaseService {

    postActions(token, data) {
        return axios.post(variable.Tutorial_API_POSTTESTCASE_URL,
            data,
            {
                headers: {
                    Authorization: token,
                },
            }
        );
    }

    updateAction(token, data, id) {
        return axios.put(variable.Tutorial_API_UPDATETESTCASE_URL + '/' + id,
            data,
            {
                headers: {
                    Authorization: token,
                },
            }
        );
    }

    getTestCase(token) {
        return axios.get(variable.Tutorial_API_GETTESTCASE_URL,
            {
                headers: {
                    Authorization: token,
                },
            }
        )
    }

    getAllAction(token) {
        return axios.get(variable.Tutorial_API_GETTESTCASEACTIONS_URL,
            {
                headers: {
                    Authorization: token,
                }
            }
        )

    }

    deleteTestCaseAction(actionId, token) {
        return axios.delete(variable.Tutorial_API_DeleteTestCaseAction_URl + actionId,
            {
                headers: {
                    Authorization: token,
                }
            }
        )
    }

    postSpec(specData, token) {
        return axios.post(variable.Tutorial_API_postSpecData_Url, specData,
            {
                headers: {
                    Authorization: token,
                }
            })
    }

    getSpecs(token) {
        return axios.get(variable.Tutorial_API_GETSpecData_Url,
            {
                headers: {
                    Authorization: token,
                }
            })
    }

    getActionById(id, token) {
        return axios.get(variable.Tutorial_API_GETTESTCASEACTIONS_URL + id,
            {
                headers: {
                    Authorization: token,
                }
            }
        )
    }

    getSpecById(id, token) {
        return axios.get(variable.Tutorial_API_GETSpecData_Url + id)
    }
    getSpecJsonData(data, token) {
        return axios.post(variable.Tutorial_API_GETSpecData_Url + 'specId', data, {
            headers: {
                Authorization: token,
            }
        })
    }
    editSpecData(token, specId, specData) {
        return axios.put(variable.Tutorial_API_GETSpecData_Url + specId, {
            specData
        })
    }

    deleteSpec(specId, token) {
        return axios.delete(variable.Tutorial_API_GETSpecData_Url + specId)
    }

    getPassedTestCases(batchName, skillName, examCode, hostId, queNumber, token) {
        return axios.get(variable.TUTORIAL_API_Exam_URL + 'passedTestCase',
            {
                params: { batchName, skillName, examCode, hostId, queNumber },
                headers: {
                    Authorization: token,
                }
            })
    }

    getFailedTestCases(batchName, skillName, examCode, hostId, queNumber, token) {
        return axios.get(variable.TUTORIAL_API_Exam_URL + 'failedTestCase',
            {
                params: { batchName, skillName, examCode, hostId, queNumber },
                headers: {
                    Authorization: token,
                }
            })
    }

    getErrorTestCases(batchName, skillName, examCode, hostId, queNumber, token) {
        return axios.get(variable.TUTORIAL_API_Exam_URL + 'errorTestCase',
            {
                params: { batchName, skillName, examCode, hostId, queNumber },
                headers: {
                    Authorization: token,
                }

            })
    }

    getTestCasesImages(batchName, skillName, examCode, hostId, queNumber, token) {

        return axios.get(variable.TUTORIAL_API_Exam_URL + 'getTestCaseImageName',
            {
                params: { batchName, skillName, examCode, hostId, queNumber },
                headers: {
                    Authorization: token,
                }
            })
    }
}
export default new TestcaseService();